import React from "react";
import { Spinner } from "@veneer/core";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const SpinnerWrapper = styled.div`
  position: relative;
  padding-right: 8px;
  top: 3px;
  display: inline;
`;

const ReportModalDownloading: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <SpinnerWrapper>
        <Spinner size={16} />
      </SpinnerWrapper>
      {t("reports.modal.preparing")}
    </div>
  );
};

export default ReportModalDownloading;
