import React from "react";
import styled from "styled-components";
import { Icon } from "@veneer/core";
import { useTranslation } from "react-i18next";
import useCardInfo from "../../shared/card-info";
import QuickActionButtonGroup from "./quick-action-button-group";

interface Props {
  title: string;
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  @media ${(props) => props.theme.breakpoints.mobileMaximum} {
    padding-top: 30px;
    flex-direction: column;
  }
`;

const WrapperBackground = styled.div`
  z-index: 0;
  position: absolute;
  background-color: ${props => props.theme.colors.background4};
  width: 100%;
  top: 20px;
  bottom: 27px;
  left: 0;
  right: 0;
`;

const CardPositioner = styled.div`
  width: 290px;
  min-height: 140px;
  align-self: stretch;
  z-index: 1;
  padding: 0 0 0 20px;

  @media ${(props) => props.theme.breakpoints.mobileMaximum} {
    width: 100%;
    padding: 0 20px;
    margin-top: -30px;
  }
`;

const Card = styled.div`
  background-color: ${props => props.theme.colors.white};
  position: relative;
  box-shadow: 0 0 1px 0;
  padding: 12px;
  height: 100%;

  @media ${(props) => props.theme.breakpoints.mobileMaximum} {
    width: 100%;
  }
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e8e8e8;
`;

const CustomerIcon = styled.div`
  svg {
    width: 60px;
    height: 60px;
    color: ${props => props.theme.colors.orange};
  }
`;

const Customer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

const CustomerName = styled.div`
  padding: 4px 0;
  font-family: ${(props) => props.theme.font.regular};
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const CustomerInfo = styled.div`
  padding: 0 25px 8px 0;
  display: flex;
  flex-direction: row;
  
  span {
    padding: 0 8px 0 0;
  }

  span + span {
    padding: 0 8px;
    border-left: 1px solid ${props => props.theme.colors.leftBorder}
  }

  .vn-spinner {
    margin-left: 10px;
  }
`;

const CardContact = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 10px;

  > span {
    flex-grow: 0;
    white-space: nowrap;
  }

  > div {
    flex-grow: 1;
  }
`;

const CardContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  overflow-x: hidden;

  > * {
    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  max-width: 100%;
  z-index: 1;
  overflow: hidden;
  
  > div + div {
    padding-left: 10px;
  }
  
  > div + div > div {
    margin-left: 10px;
    margin-top: 10px;
  }
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.font.regular};
  font-size: 20px;
  padding: 40px 0 0 20px;
  flex-grow: 1;
  
  @media ${(props) => props.theme.breakpoints.mobileMaximum} {
    padding: 20px;
    text-align: center;
  }
`;


const ListCardHeader: React.FC<Props> = ({ children, title }) => {
  const { t } = useTranslation();
  const { contracts, customerUsers, customerName, primaryContact, primaryEmail, showCard } = useCardInfo();

  if (!showCard || !title) {
    return (
      <QuickActionButtonGroup>
        {children}
      </QuickActionButtonGroup>
    );
  }

  return (
    <Wrapper>
      <WrapperBackground />

      <CardPositioner>
        <Card>
          <CardHeader>
            <CustomerIcon>
              <Icon name="company" size={64} />
            </CustomerIcon>

            <Customer>
              <CustomerName>{customerName}</CustomerName>

              <CustomerInfo>
                <span>
                  {t(contracts === 1 ? "contracts.count.one" : "contracts.count", { count: contracts })}
                </span>
                <span>
                  {t(customerUsers === 1 ? "user.count.one" : "user.count", { count: customerUsers })}
                </span>
              </CustomerInfo>
            </Customer>
          </CardHeader>

          <CardContact>
            <span>{t("contract.mainContact")}</span>
            <CardContactDetails>
              <span>{primaryContact}</span>
              <a href={`mailto:${encodeURIComponent(primaryEmail)}`}>{primaryEmail}</a>
            </CardContactDetails>
          </CardContact>
        </Card>
      </CardPositioner>

      <ActionsWrapper>
        <Title>{title}</Title>
        <QuickActionButtonGroup>
          {children}
        </QuickActionButtonGroup>
      </ActionsWrapper>
    </Wrapper>
  );
};

export default ListCardHeader;
