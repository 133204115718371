import React from "react";
import { Select, RadioButtons, RadioButton, Password } from "@veneer/core";
import styled from "styled-components";
import { useReportDownloadState } from "../../../redux/report-download-hooks";
import { useAuth } from "../../../redux/auth-hooks";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { RootActionTypes } from "../../../redux/root-action";
import {
  downloadReportSetCustomers,
  downloadReportSetInternal,
  downloadReportStart,
  downloadReportSetPassword,
} from "../../../redux/report-download-actions";
import { Column, SearchType, useSearch } from "../../../hooks/table-utils";
import { useTranslation } from "react-i18next";
import Button from "components/button";

interface VeneerSelectOption {
  value: string;
  label: string;
}

const FormField = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;

  > .vn-select, .vn-checkbox__label {
    flex: 1;
    min-width: 0;
  }

  > button {
    margin-left: 12px;
  }
`;

const ButtonWrapper = styled.div`
  > * {
    float: right;
  }
`;

const PasswordBlock = styled.div`
  .vn-password {
    width: 300px;
}
`;

const Text = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
`;

const ReportModalOptions: React.FC = () => {
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const availableCustomers = currentUser.customers || [];

  const {
    userHasInternalExternalChoice,
    userHasCustomerChoice,
    internal,
    customerIds,
    allowMultipleCustomers,
    passwordChoice,
    password,
  } = useReportDownloadState();

  const pwUpperCaseOk = /[A-Z]/.test(password);
  const pwLowerCaseOk = /[a-z]/.test(password);
  const pwNumbersOk = /[0-9]/.test(password);
  const pwSpecialOk = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password);
  const pwMinStrength = 3;
  const pwStrengthOk = [pwUpperCaseOk, pwLowerCaseOk, pwNumbersOk, pwSpecialOk].filter(Boolean).length >= pwMinStrength;
  const pwMinLength = 12;
  const pwLengthOk = (password?.length ?? 0) >= pwMinLength;

  const isValidPassword = (!passwordChoice || (pwStrengthOk && pwLengthOk));

  const canContinue = (!userHasCustomerChoice || customerIds.length > 0) && isValidPassword;

  const customerOptions: VeneerSelectOption[] = availableCustomers.map((customer) => ({
    value: customer.id,
    label: customer.itsmCustomerName || customer.name || "",
  }));

  const cols: Column<VeneerSelectOption>[] = [
    { property: "value", label: "Value" },
    { property: "label", label: "Label", searchType: SearchType.Text },
  ];

  const { search, result: displayCustomerOptions } = useSearch(customerOptions, cols);

  const customerSelectionChange = (item?: VeneerSelectOption) => {
    if (!item) {
      return;
    }

    const newCustomerIds = allowMultipleCustomers ? [...customerIds] : [];
    const index = newCustomerIds.indexOf(item.value);
    if (index < 0) {
      newCustomerIds.push(item.value);
    } else {
      newCustomerIds.splice(index, 1);
    }

    dispatch(downloadReportSetCustomers(newCustomerIds));
  };

  const selectAll = () => {
    const newCustomerIds = availableCustomers.map((customer) => customer.id);
    dispatch(downloadReportSetCustomers(newCustomerIds));
  };

  const customerSelectionClear = () => {
    dispatch(downloadReportSetCustomers([]));
  };

  const customerSelectionSearch = (arg?: string) => {
    search("label", arg || "");
  };

  const internalChanged = (value: boolean) => {
    dispatch(downloadReportSetInternal(value));
  };

  const doContinue = () => {
    dispatch(downloadReportStart());
  };

  return (
    <>
      {userHasCustomerChoice && (
        <FormField>
          <Select
            multiple={allowMultipleCustomers}
            onChange={customerSelectionChange}
            onClear={customerSelectionClear}
            onSearch={customerSelectionSearch}
            options={displayCustomerOptions}
            placeholder={t(allowMultipleCustomers ? "reports.modal.pickCustomers" : "reports.modal.pickCustomer")}
            showSearch
            value={customerIds || []}
            name="selectCustomer"
          />
          {allowMultipleCustomers && (
            <Button onClick={selectAll}>{t("select.all")}</Button>
          )}
        </FormField>
      )}
      {userHasInternalExternalChoice && (
        <FormField>
          <RadioButtons
            name="internalReport"
            alignment="vertical"
            selectedIndex={internal ? 0 : 1}
            onChange={(index: number) => {
              internalChanged(index === 0);
            }}
          >
            <RadioButton index={1} value="1" label={t("reports.modal.internalReportRadio")} />
            <RadioButton index={0} value="0" label={t("reports.modal.externalReportRadio")} />
          </RadioButtons>
        </FormField>
      )}
      {passwordChoice && (
        <PasswordBlock>
          <Text>{t("password.title.text")}</Text>
          <Password
            autocomplete="off"
            placeholder={t("password.enterPassword")}
            showRequirements
            onChange={(value: string) => {
              dispatch(downloadReportSetPassword(value));
            }}
            requirements={[
              {
                id: 1,
                checked: pwLengthOk,
                title: t("password.minimumLength", { charLength: pwMinLength })
              },
              {
                id: 2,
                checked: pwStrengthOk,
                title: t("password.conditionsRequired", { value: pwMinStrength }),
                requirements: [
                  {
                    id: 2.1,
                    checked: pwUpperCaseOk,
                    title: t("password.uppercaseRequired"),
                  },
                  {
                    id: 2.2,
                    checked: pwLowerCaseOk,
                    title: t("password.lowercaseRequired"),
                  },
                  {
                    id: 2.3,
                    checked: pwNumbersOk,
                    title: t("password.numberRequired"),
                  },
                  {
                    id: 2.4,
                    checked: pwSpecialOk,
                    title: t("password.specialCharRequired"),
                  }
                ]
              }
            ]}
            value={password}
          />
        </PasswordBlock>
      )}
      <ButtonWrapper>
        <Button disabled={!canContinue} onClick={doContinue} data-cy="downloadReport">{t("btn.continue")}</Button>
      </ButtonWrapper>
    </>
  );
};

export default ReportModalOptions;
