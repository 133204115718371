import React, { useEffect, useState } from "react";
import useLDFlag from "../hooks/use-ld-flag";

const InitMedallia: React.FC = () => {
  const enableMedallia = useLDFlag("mssiPsspEnableMedallia20220908");
  const [done, setDone] = useState(false);

  useEffect(
    () => {
      if (enableMedallia && !done) {
        const script = document.createElement("script");
        script.src = "https://nebula-cdn.kampyle.com/us/wu/1115411/onsite/embed.js";
        script.async = true;
        document.body.appendChild(script);
        setDone(true);
      }
    },
    [done, enableMedallia],
  );

  return null;
};

export default InitMedallia;
