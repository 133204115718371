import * as React from "react";
import styled from "styled-components";

const StyledFlexContainer = styled.div<StyledFlexContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  @media ${(props) => (
      props.adjustSize
        ? props.theme.breakpoints.tablet
        : props.theme.breakpoints.laptop
  )} {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
`;

interface StyledFlexContainerProps {
  children: any;
  adjustSize?: boolean;
}

const FlexContainer = ({
  children,
  adjustSize = false,
}: StyledFlexContainerProps): JSX.Element => {
  return (
    <StyledFlexContainer adjustSize={adjustSize}>
      {children}
    </StyledFlexContainer>
  );
};

export default FlexContainer;
