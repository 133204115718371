import {
  NEW_ORDER_SELECT_COUNTRY,
  NEW_ORDER_SELECT_CONTRACT,
  NEW_ORDER_SELECT_SITE,
  NEW_ORDER_ADDITIONAL_INFO,
  NEW_ORDER_GOTO_NEXT_STEP,
  NEW_ORDER_GOTO_PREVIOUS_STEP,
  NEW_ORDER_SUBMIT,
  NEW_ORDER_ERROR,
  NEW_ORDER_SET_DEFAULT_LOCALIZATION,
  NEW_ORDER_GOTO_STEP,
  NEW_ORDER_SUBMITTED,
  NEW_ORDER_ADD_ITEM,
  NEW_ORDER_ITEM,
  NEW_ORDER_DELETE_ITEM,
  NEW_ORDER_SET_HARDWARE,
  NEW_ORDER_SET_ACCESSORY,
} from "./order-endUser-actions";
import { RootActionTypes } from "./root-action";
import { OrderEndUserState, initialOrderEndUserState } from "redux/redux-state";
import { skuLocalization } from "shared/sku-localization";
import { OrderStepEndUser } from "types/order-step";

const orderEndUserReducer = (state: OrderEndUserState = initialOrderEndUserState, action: RootActionTypes): OrderEndUserState => {
  const maxSteps = OrderStepEndUser.MAX_STEPS;

  switch (action.type) {
    case NEW_ORDER_SELECT_COUNTRY: {
        const localizationCode = skuLocalization.find((entry) => state.country && entry.country === state.country);
      return {
        ...state,
        country: action.country,
        localizationCodes: localizationCode ? localizationCode.countryCode : [],
      };
    }

    case NEW_ORDER_SELECT_CONTRACT: {
      return {
        ...state,
        contractId: action.contractId,
      };
    }

    case NEW_ORDER_SELECT_SITE:
      return {
        ...state,
        site: action.site,
      };

    case NEW_ORDER_ADDITIONAL_INFO: {
      return {
        ...state,
        shippingInfo: action.shippingInfo,
      };
    }

    case NEW_ORDER_GOTO_NEXT_STEP:
      return {
        ...state,
        currentStep: Math.max(Math.min(state.currentStep + 1, maxSteps - 1), 0),
      };

    case NEW_ORDER_GOTO_PREVIOUS_STEP:
      return {
        ...state,
        currentStep: Math.max(Math.min(state.currentStep - 1, maxSteps - 1), 0),
      };

    case NEW_ORDER_GOTO_STEP:
      return {
        ...state,
        currentStep: action.stepNumber,
      };

    case NEW_ORDER_SET_DEFAULT_LOCALIZATION:
      return {
        ...state,
        defaultLocalizationCode: action.skuCode,
      };

    case NEW_ORDER_SUBMIT:
      return {
        ...state,
        submitting: true,
      };

    case NEW_ORDER_SUBMITTED:
      return {
        ...state,
        submitting: false,
        submitted: true,
      };

    case NEW_ORDER_ADD_ITEM: {
      const index = state.catalogitems.findIndex(catalog => catalog.item.bundleId === action.item.bundleId);
      const newQuantity = index !== -1 ? state.catalogitems[index].quantity + action.quantity : 0;
      if (index !== -1) {
        state.catalogitems.splice(index, 1);
      }
      state.catalogitems.push(
        { item: action.item,
          quantity: index !== -1 ? newQuantity : action.quantity,
          localization: action.localization,
        }
      );
      return {
        ...state,
        catalogitems: state.catalogitems,
      };
    }

    case NEW_ORDER_DELETE_ITEM: {
      const index = state.catalogitems.findIndex(catalog => catalog.item.bundleId === action.item.bundleId);
      if (index !== -1) {
        state.catalogitems.splice(index, 1);
      }
      return {
        ...state,
        catalogitems: state.catalogitems,
      };
    }

    case NEW_ORDER_ITEM:
      return {
      ...state,
      item: action.item,
      };

    case NEW_ORDER_ERROR:
      return {
        ...state,
        submitting: false,
        error: action.error,
      };

    case NEW_ORDER_SET_ACCESSORY:
      return {
      ...state,
      hasAccessory: action.hasAccessory,
      };

    case NEW_ORDER_SET_HARDWARE:
      return {
      ...state,
      hasHardware: action.hasHardware,
      };

    default:
      return state;
  }
};

export default orderEndUserReducer;
