import { permissions } from "@mssi/pssp-shared";
import { useAuth } from "../redux/auth-hooks";
import { useEffect, useState } from "react";
import { PreferenceLookupType } from "../components/profile-preference/preference-lookup-type";
import { getUserLocalizationCodes } from "../api/contract-catalog";

export interface LocalizationCodeListState {
  localizationCodeList: [string, string][];
  canSetLocalizationCode: boolean;
  loading: boolean;
}

const useLocalizationCodeList = (): LocalizationCodeListState => {
  const { authorizedFor } = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [localizationCodeList, setLocalizationCodeList] = useState<PreferenceLookupType<string>>([["", "None"]]);
  const canSetLocalizationCode = authorizedFor(permissions.canCreateOrders);

  useEffect(
    () => {
      let mounted = true;

      if (!canSetLocalizationCode) {
        setLoading(false);
        return;
      }

      setLoading(true);

      getUserLocalizationCodes()
        .then((codes?: [string, string][]) => {
          if (!mounted) {
            return;
          }

          if (codes) {
            setLocalizationCodeList(codes);
          }

          setLoading(false);
        });

      return () => {
        mounted = false;
      };
    },
    [canSetLocalizationCode],
  );

  return { loading, canSetLocalizationCode, localizationCodeList };
};

export default useLocalizationCodeList;
