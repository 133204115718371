import React, { useEffect, useState } from "react";
import { FieldProps, getIn } from "formik";
import { FormField, IconButton } from "@veneer/core";
import { getSdmDocumentStatus } from "../../api/upload";
import { SdmUploadStatus } from "../../types/upload";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import FilePickerSDMModal from "./file-picker-sdm-modal";
import listOfValidUploadTypes from "../../types/accepted-file";

const RedIconButton = styled(IconButton)`
  color: ${(props) => props.theme.colors.red};
  position: absolute;
  top: 0;
  right: 0;

  &:hover, &:active, &:focus {
    color: ${(props) => props.theme.colors.red};
  }

  &:active {
    background-color: #ffdfd9;
  }
`;

const Wrapper = styled.div`
  padding-right: 34px;
  > span { 
    display: block; 
    width: 100%; 
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis; 
  }
`;

export interface OnFileInfoProps {
  id?: string;
  fileName?: string;
}

interface Props extends FieldProps {
  label?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  type?: string;
  accept?: string;
  onRemoveClicked?: (event: React.MouseEvent<any>) => void;
  onFileInfo?: (props: OnFileInfoProps) => void;
  allowEdit?: boolean;
  allowRemove?: boolean;
  fileNameField?: string;
}

const FilePickerSDM: React.FC<Props> = ({
  label,
  form,
  field,
  required = false,
  disabled = false,
  readOnly = false,
  accept = listOfValidUploadTypes,
  onRemoveClicked,
  allowEdit = !readOnly && !disabled,
  allowRemove = !readOnly && !disabled,
  fileNameField,
  onFileInfo,
}) => {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState<string>("");
  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);
  const hasError = error && touched;
  const { setFieldValue } = form;

  useEffect(
    () => {
      if (!field.value) {
        setFileName("");
        return;
      }

      getSdmDocumentStatus(field.value)
        .then((state) => {
          setFileName(state.fileName);
        })
        .catch(() => {
          setFileName(field.value || "");
        });
    },
    [field.value],
  );

  const onFileSelected = (state: SdmUploadStatus) => {
    setFileName(state.fileName);
    setFieldValue(field.name, state.id);

    if (fileNameField) {
      setFieldValue(fileNameField, state.fileName);
    }

    if (onFileInfo) {
      onFileInfo({
        id: state.id,
        fileName: state.fileName,
      });
    }
  };

  const onFileRemoved = (e: any) => {
    setFileName("");
    setFieldValue(field.name, undefined);

    if (fileNameField) {
      setFieldValue(fileNameField, undefined);
    }

    if (onRemoveClicked) {
      onRemoveClicked(e);
    }

    if (onFileInfo) {
      onFileInfo({});
    }
  };

  return (
    <FormField
      label={label}
      htmlFor={field.name}
      errorMessage={hasError && error}
      required={required}
    >
      <div className="vn-filepicker vn-filepicker--expanded">
        <div className="vn-filepicker__link">
          <Wrapper className="vn-filepicker__file-name vn-filepicker__file-name--no-focused vn-filepicker__file-name--no-hover">
            <span className="vn-filepicker__placeholder">{fileName ? `File Name: ${fileName}` : t("dashboard.btn.uploadfile")}</span>
            {allowRemove && !!fileName && <RedIconButton icon="delete" onClick={onFileRemoved} title={t("btn.delete")} />}
          </Wrapper>
          <FilePickerSDMModal accept={accept} allowEdit={allowEdit} onFileSelected={onFileSelected} />
        </div>
      </div>
    </FormField>
  );
};

export default FilePickerSDM;
