import React from "react";
import { ThemeProvider } from "styled-components";
import theme from "./styles/theme";
import { Provider } from "react-redux";
import store from "./redux/store";
import InitAuth from "./redux/init-auth";
import InitMedallia from "./redux/init-medallia";
import InitCurrentCustomer from "./redux/init-current-customer";
import InitShoppingCart from "./redux/init-shopping-cart";
import Toasts from "./components/toasts";
import { LeaveWarningProvider } from "./contexts/leaveWarning-context";
import Pages from "./pages";
import CaseRequestModal from "./pages/support-tickets/components/case-request-modal";
import { LDProvider } from "launchdarkly-react-client-sdk";
import getConfig from "./client-config";
import InitCoolDown from "./redux/init-cooldown";

const AppInner: React.FC = () => {
  const launchDarklyReactKey = getConfig("launchDarklyReactKey");

  return (
    <LDProvider
      reactOptions={{ useCamelCaseFlagKeys: true }}
      clientSideID={launchDarklyReactKey}
    >
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <InitAuth />
          <InitCurrentCustomer />
          <InitShoppingCart />
          <Toasts />
          <InitCoolDown />
          <LeaveWarningProvider>
            <Pages />
          </LeaveWarningProvider>
          <CaseRequestModal />
          <InitMedallia />
        </Provider>
      </ThemeProvider>
    </LDProvider>
  );
};

export default AppInner;
