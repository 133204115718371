export default {
  UAMlink: "https://accessmanager.austin.hp.com/uam/ProfileRequest.aspx?ProFlag=True&ProfileID=57562&",
  cognitoDomain: "https://itg-daas-portal.auth-fips.us-west-2.amazoncognito.com",
  cognitoRegion: "us-west-2",
  cognitoPoolId: "us-west-2_eaEqqeqUU",
  cognitoClientId: "5dtudrtnpra4n1jjgqis8psrud",
  hpidUrl: "https://directory.stg.cd.id.hp.com",
  editProfileEmployee: " https://directoryworks.hpicorp.net",
  editProfileNonEmployee: "https://myaccount.stg.cd.id.hp.com",
  launchDarklyReactKey: "6149527150545a26bdc9edc3",
  jupiterStack: "itg",
  testEmailNote: true,
};
