import { CURRENT_CUSTOMER_CHANGE, CURRENT_CUSTOMER_INIT, CURRENT_CUSTOMER_LOADING, CURRENT_CUSTOMER_REGEX, CURRENT_CONTRACT_FOR_ORDER } from "./current-customer-actions";
import Customer from "../types/customer";
import { RootActionTypes } from "./root-action";
import { get } from "@mssi/pssp-shared";
import { AuthCustomer } from "../types/user";
import cloneDeep from "clone-deep";
import { CurrentCustomerState, initialCurrentCustomerState, RootState } from "redux/redux-state";
import Cookies from "js-cookie";

export const cleanupAuthCustomer = (customer: Customer | AuthCustomer): Customer => {
  const result: any = cloneDeep(customer);

  delete result.contracts;
  delete result.defaultContract;
  delete result.catalogSetupComplete;
  delete result.users;
  delete result.devices;
  delete result.devicesInUse;

  return result as Customer;
};

const currentCustomerReducer = (
  state: CurrentCustomerState = initialCurrentCustomerState,
  action: RootActionTypes,
  rootState: RootState,
): CurrentCustomerState => {
  if (!CURRENT_CUSTOMER_REGEX.test(action.type)) {
    return state;
  }

  const authCustomers: AuthCustomer[] = get(rootState, "auth.currentUser.customers") || [];

  const defaultCustomer = authCustomers && authCustomers.length === 1
    ? authCustomers[0]
    : undefined;

  switch (action.type) {
    case CURRENT_CUSTOMER_INIT: {
      const lastCustomerId = localStorage.getItem("lastCustomerId") || Cookies.get("cy_customer") || "";
      const match = defaultCustomer || authCustomers.find((customer) => customer.id === lastCustomerId);
      const customer = (match && cleanupAuthCustomer(match)) || state.customer;

      if (!customer.id && !state.customer.id) {
        return state;
      }

      if (state.initialized && state.customer?.id === customer?.id) {
        return state;
      }

      return { ...state, initialized: true, customer };
    }
    case CURRENT_CUSTOMER_CHANGE: {
      const match = defaultCustomer || authCustomers.find(({ id }) => id === action.customerId);

      if (!match || match.id === state.customer.id) {
        return state.initialized ? state : { ...state, initialized: true };
      }

      localStorage.setItem("lastCustomerId", action.customerId || "");

      if (action.reloadPage) {
        // let reload + localStorage take care of the display update
        window.location.href = "/";
        return state.initialized ? state : { ...state, initialized: true };
      }

      const customer = cleanupAuthCustomer(match);
      return { ...state, initialized: true, customer };
    }

    case CURRENT_CUSTOMER_LOADING:
            return { ...state, loading: action.loading };

    case CURRENT_CONTRACT_FOR_ORDER:
      return { ...state, contractId: action.contractId };
    default:
      return state;
  }
};

export default currentCustomerReducer;
