import i18n, { InitOptions } from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import { languageResources, languages } from "./i18n-languages";
import { format, processUpper } from "./i18n-processors";

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(processUpper()) // use with t("key", { postProcess: "upper" }) to make text like "Text" become "TEXT"
  .init({
    resources: languageResources,
    // keySeparator: false, // we do not use keys in form messages.welcome
    fallbackLng: languages[0],
    ignoreJSONStructure: true, // note: i18next bug - this isn't in d.ts
    interpolation: {
      escapeValue: false, // react already safes from xss
      format,
    },
  } as InitOptions);

export default i18n;
