export const REPORT_DOWNLOAD_REGEX = /^reportDownload\//;
export const REPORT_DOWNLOAD_CLICK = "reportDownload/click";
export const REPORT_DOWNLOAD_SET_INTERNAL = "reportDownload/setInternal";
export const REPORT_DOWNLOAD_SET_CUSTOMERS = "reportDownload/setCustomers";
export const REPORT_DOWNLOAD_START = "reportDownload/start";
export const REPORT_DOWNLOAD_SET_DOWNLOADING = "reportDownload/setDownloading";
export const REPORT_DOWNLOAD_SET_ERROR = "reportDownload/setError";
export const REPORT_DOWNLOAD_SET_DONE = "reportDownload/setDone";
export const REPORT_DOWNLOAD_EXIT = "reportDownload/exit";
export const REPORT_DOWNLOAD_SET_PASSWORD = "reportDownload/setPassword";

export interface ReportDownloadClick {
  type: typeof REPORT_DOWNLOAD_CLICK;
  reportTitle: string;
  fileName: string;
  url: string;
  reportHasCustomerChoice: boolean;
  reportHasInternalExternalChoice: boolean;
  allowMultipleCustomers: boolean;
  passwordChoice: boolean;
}

export interface ReportDownloadSetInternal {
  type: typeof REPORT_DOWNLOAD_SET_INTERNAL;
  internal: boolean;
}
export interface ReportDownloadSetPassword {
  type: typeof REPORT_DOWNLOAD_SET_PASSWORD;
  password: string;
}

export interface ReportDownloadSetCustomers {
  type: typeof REPORT_DOWNLOAD_SET_CUSTOMERS;
  customerIds?: string[];
}

export interface ReportDownloadStart {
  type: typeof REPORT_DOWNLOAD_START;
}

export interface ReportDownloadSetDownloading {
  type: typeof REPORT_DOWNLOAD_SET_DOWNLOADING;
  reportTitle?: string;
}

export interface ReportDownloadSetError {
  type: typeof REPORT_DOWNLOAD_SET_ERROR;
  error: string;
}

export interface ReportDownloadSetDone {
  type: typeof REPORT_DOWNLOAD_SET_DONE;
}

export interface ReportDownloadExit {
  type: typeof REPORT_DOWNLOAD_EXIT;
}

export type ReportDownloadTypes = (
  ReportDownloadClick
  | ReportDownloadSetInternal
  | ReportDownloadSetPassword
  | ReportDownloadSetCustomers
  | ReportDownloadStart
  | ReportDownloadSetDownloading
  | ReportDownloadSetError
  | ReportDownloadSetDone
  | ReportDownloadExit
);

export const downloadReportInit = (
  url: string,
  reportTitle: string,
  fileName: string,
  reportHasCustomerChoice: boolean = false,
  reportHasInternalExternalChoice: boolean = false,
  allowMultipleCustomers: boolean = false,
  passwordChoice: boolean = false,
): ReportDownloadTypes => ({
  type: REPORT_DOWNLOAD_CLICK,
  url,
  reportTitle,
  fileName,
  reportHasCustomerChoice,
  reportHasInternalExternalChoice,
  allowMultipleCustomers,
  passwordChoice,
});

export const downloadReportSetInternal = (internal: boolean = false): ReportDownloadTypes => ({
  type: REPORT_DOWNLOAD_SET_INTERNAL,
  internal,
});

export const downloadReportSetCustomers = (customerIds: string[] = []): ReportDownloadTypes => ({
  type: REPORT_DOWNLOAD_SET_CUSTOMERS,
  customerIds,
});

export const downloadReportSetPassword = (password: string): ReportDownloadTypes => ({
  type: REPORT_DOWNLOAD_SET_PASSWORD,
  password,
});

export const downloadReportStart = (): ReportDownloadTypes => ({
  type: REPORT_DOWNLOAD_START,
});

export const downloadReportSetDownloading = (reportTitle?: string): ReportDownloadTypes => ({
  type: REPORT_DOWNLOAD_SET_DOWNLOADING,
  reportTitle,
});

export const downloadReportSetError = (error: string): ReportDownloadTypes => ({
  type: REPORT_DOWNLOAD_SET_ERROR,
  error,
});

export const downloadReportSetDone = (): ReportDownloadTypes => ({
  type: REPORT_DOWNLOAD_SET_DONE,
});

export const downloadReportExit = (): ReportDownloadTypes => ({
  type: REPORT_DOWNLOAD_EXIT,
});
