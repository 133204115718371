import React from "react";
import styled from "styled-components";
import { useConfig } from "../client-config";
import { useAuth } from "../redux/auth-hooks";

const Wrapper = styled.div`
  font-size: 0.9em;
  margin: 8px;
  padding: 8px;
  border: 1px solid ${(props) => props.theme.colors.border};
  background: ${(props) => props.theme.colors.background};
  p {
    font-size: 1em;
    padding-bottom: 1em;
  }
  p:last-child {
    padding-bottom: 0;
  }
`;

const TestEmailNote: React.FC = () => {
  const { currentUser } = useAuth();
  const displayNote = useConfig("testEmailNote");

  if (!displayNote || !/@hp\.com$/i.test(currentUser?.email)) {
    return null;
  }

  return (
    <Wrapper>
      <p>
        <b>WARNING:</b>
      </p>
      <p>
        The HP Standards of Business Conduct require that you only enter a valid email address which you own, or which
        you are authorized to use by its owner, which can actually receive email messages.
        Do not use fake, test, or customer email addresses.
      </p>
      <p>
        Violations may expose both you and HP to substantial civil and/or criminal penalties.
        It will also harm HP's reputation towards its current and potential customers.
      </p>
      <p>
        This message is only displayed in test instances of PS Services Portal.
      </p>
    </Wrapper>
  );
};

export default TestEmailNote;
