import React from "react";

interface CatalogImageProps {
  url: string;
  size: string;
  Style?: any;
  width?: string;
}

const CatalogImage: React.FC<CatalogImageProps> = ({ url, size, Style = "div", width }) => {
  return (
    <Style>
      <img src={url} alt="catalog-img" height={size} width={width ? width : size} />
    </Style>
  );
};

export default CatalogImage;
