import i18n from "../i18n";
import { ValidationErrorItem } from "joi";

/**
 * Custom transform for field messages
 */
export type FieldMessageTransform = (error: ValidationErrorItem) => string;

/**
 * A map of custom validation messages by type
 */
export interface ValidationMessageErrorTypeMap {
  default?: string | FieldMessageTransform;
  [key: string]: string | FieldMessageTransform | undefined;
}

/**
 * A map of custom validation messages by type
 */
export interface ValidationMessageFieldMap {
  default?: string | FieldMessageTransform;
  [key: string]: string | ValidationMessageErrorTypeMap | FieldMessageTransform | undefined;
}

/**
 * A map of custom validation messages
 */
export interface ValidationMessageMap {
  default: string;
  [key: number]: string | ValidationMessageFieldMap;
}

/**
 * Transforms the error using a map. Returns default if no option can be found.
 * @param map A Joi validation error
 */
export const getErrorTransform = (map: ValidationMessageFieldMap) => (error: ValidationErrorItem) => {
  const options = map[error.context?.key ?? ""];

  // look for a message transform
  if (typeof options === "function") {
    return options(error);
  }

  // look for a message specific to this error type
  if (typeof options === "object") {
    const typeOption = options[error.type];

    // look for a message transform
    if (typeof typeOption === "function") {
      return typeOption(error);
    }

    // return the same message for all error types
    if (typeof typeOption === "string") {
      return typeOption;
    }

    if (typeof options.default === "function") {
      return options.default(error);
    }

    return options.default || `${error?.context?.key ?? ""} ${i18n.t("validationerror")}`;
  }

  // return the same message for all error types
  if (typeof options === "string") {
    return options;
  }

  if (typeof map.default === "function") {
    return map.default(error);
  }

  // no custom message set
  // use the default error message if available
  // or a generic message (to avoid exposing Joi directly)
  return map.default || `${error?.context?.key ?? ""} ${i18n.t("validationerror")}`;
};

/**
 * Transforms a fetch error using a map. Returns default if no option can be found.
 * @param map A FetchError
 */
export const getFetchErrorTransform = (map: ValidationMessageMap) => (error: any) => {
  const statusCodeMap = map[error.status];
  if (statusCodeMap) {
    if (typeof statusCodeMap === "object") {
      // transform field level errors
      if (error.details) {
        return error.details.map(getErrorTransform(statusCodeMap));
      }
    }

    if (typeof statusCodeMap === "string") {
      return [statusCodeMap];
    }
  }
  return [map.default];
};
