import Persona, { PersonaId } from "./persona";
import Customer from "./customer";
import { RegistrationState } from "./registration-state";
import Site from "./site";

export type UserId = string;

export type RegistrationDescription = string;

export enum LoginMethod {
  "HP_CUSTOMER" = "HPID",
  "HP_EMPLOYEE" = "HPEmployee",
}

export const loginMethodDescriptions = {
  [LoginMethod.HP_CUSTOMER]: "Customer",
  [LoginMethod.HP_EMPLOYEE]: "HP Employee",
};

export const loginMethodTranslations = {
  [LoginMethod.HP_CUSTOMER]: "user.customer",
  [LoginMethod.HP_EMPLOYEE]: "user.employee",
};

export interface BaseUser {
  id: UserId;
  firstName: string;
  lastName: string;
  email: string;
  customerIds: string[];
  personaId: PersonaId;
  userLoginMethod: LoginMethod;
  registrationState: RegistrationState;
  emailRegions: string[];
}

export interface UserForList extends BaseUser {
  name: string;
  categoryId: string;
  categoryName: string;
  personaName: string;
  customers: string[];
  createdAt?: string;
}

export interface UserDetail extends BaseUser {
  categoryId: string;
  categoryName: string;
  customers: string[];
  createdAt?: string;
  createType?: string;
  createdBy?: string;
  persona?: Persona;
  site?: Site;
  lastLogin?: string;
  lastAudit?: string;
}

export default interface User extends BaseUser {
  customers?: Customer[];
  persona?: Persona;
  registrationDescription?: RegistrationDescription;
  preferences: { [key: string]: any };
  /** @deprecated */
  category?: {
    categoryId?: string;
    categoryName?: string;
  };
  categoryId?: string;
  createType?: string;
  createdBy?: string;
  createdAt?: string;
  site?: Site;
  requireInternalUser?: boolean;
}

export interface AuthCustomer extends Customer {
  contracts: number;
  defaultContract?: string;
  catalogSetupComplete?: boolean;
  users: number;
  devices: number;
  devicesInUse: number;
}

export interface Me extends User {
  features: { [key: string]: any };
  customers?: AuthCustomer[];

  consentCollected?: string;
  preferencesSet?: boolean;

  coolDown?: Date;
}

export interface UserXLSX {
  xlsx: string;
  csv: string;
}

export type UserUpdateInfo = Partial<
  Pick<User, "personaId" | "registrationState" | "customerIds" | "site" | "category" | "requireInternalUser">
>;

export interface CreateInvite {
  firstName: string;
  lastName: string;
  email: string;
  personaId: string;
  customerIds?: string[];
  emailRegions?: string[];
  categoryId?: string;
  site?: Site;
  createdBy?: string;
  createType?: string;
  requireInternalUser?: boolean;
}
