import React from "react";

import { withRouter, Switch, Route } from "react-router-dom";
import InvitationRoutes from "../../shared/invitation-routes";
import { useAuth } from "redux/auth-hooks";
import ErrorPage from "./components/error-page";

const InvitationError = (): JSX.Element => {
  const { currentUser } = useAuth();

  return (
    <Switch>
      <Route path={`/invitation${InvitationRoutes.MISSING}`}>
        <ErrorPage title="Invitation Not Found">
          We are unable to find an invitation matching the sign up link used.
          <br />
          Please contact your administrator or HP support for help.
        </ErrorPage>
      </Route>
      <Route path={`/invitation${InvitationRoutes.EXPIRED}`}>
        <ErrorPage title="Invitation Expired">
          Your invitation has expired, please contact your administrator for a new invite link.
        </ErrorPage>
      </Route>
      <Route path={`/invitation${InvitationRoutes.EMAIL_MISMATCH}`}>
        <ErrorPage title="Invitation Error">
          Sign up email must match the invitation email.
          <br />
          You are currently signed in as {currentUser.email} which does not match the invitation link used.
          <br />
          You may use the invitation link with the correct email address, or contact your administrator or HP support for help.
        </ErrorPage>
      </Route>
      <Route path={`/invitation${InvitationRoutes.EXISTING_USER_WRONG_EMAIL}`}>
        <ErrorPage title="Invitation Error">
          You are signed in with the email address {currentUser.email}.
          <br />
          This email address is associated with an existing user and cannot use an invitation url for a different email address.
          <br />
          Please contact your administrator or HP support for help.
        </ErrorPage>
      </Route>
      <Route path={`/invitation${InvitationRoutes.EXISTING_USER_SAME_EMAIL}`}>
        <ErrorPage title="Invitation Error">
          You are signed in with the email address {currentUser.email}.
          <br />
          This email address is associated with an existing user and you cannot use an invitation link.
          <br />
          Please try logging in without the link, or contact your administrator or HP support for help.
        </ErrorPage>
      </Route>
      <Route path={`/invitation${InvitationRoutes.INTERNAL_ERROR}`}>
        <ErrorPage title="Invitation Error">
          Internal server error.
          <br />
          Please contact your administrator or HP support for help.
        </ErrorPage>
      </Route>
      <Route path={`/invitation${InvitationRoutes.DEACTIVATED}`}>
        <ErrorPage title="Account Deactivated">
          Your account has been deactivated because you have not logged in within the past 3 months.
          <br />
          Please contact your administrator or HP support for help.
        </ErrorPage>
      </Route>
      <Route path="/">
        <ErrorPage title="Invitation Error">
          You are signed in with the email address {currentUser.email}.
          <br />
          An unknown error occurred while processing your invitation.
          <br />
          Please contact your administrator or HP support for help.
        </ErrorPage>
      </Route>
    </Switch>
  );
};

export default withRouter(InvitationError);
