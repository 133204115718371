import { DocumentInit, DocumentResult, ReportParams, ReportStatus } from "../types/document";
import { getJson, getS3Blob, postJson } from "./request";
import queryString from "query-string";
import download from "../shared/download";
import dates from "shared/dates";

const mimeTypes: { [key: string]: string } = {
  "text/csv": ".csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ".xlsx",
};

const initReport = (url: string, query?: ReportParams): Promise<DocumentInit> => (
  query
    ? postJson(url, query)
    : postJson(url, {})
);

const getReport = (id: string): Promise<DocumentResult> => getJson(`/report?${queryString.stringify({ id })}`);

const getReportData = (reportUrl: string): Promise<Blob> => getS3Blob(reportUrl);

export const REPORT_TIMEOUT = 15 * 60 * 60 * 1000; // 15 minutes

export const downloadDocument = async (url: string, query: ReportParams | undefined, fileName: string): Promise<void> => {
  const { error, id: reportId } = await initReport(url, query);
  let stop = false;
  let downloadUrl: string | undefined;

  if (error) {
    throw new Error(error);
  }

  const timeout = setTimeout(() => { stop = true; }, REPORT_TIMEOUT);

  if (reportId) {
    for (let i = 0; i < 100 && !stop && !downloadUrl; i++) {
      const reportResult = await getReport(reportId)
        .catch(() => undefined);

      switch (reportResult?.status) {
        case ReportStatus.ERROR:
          clearTimeout(timeout);
          throw new Error(reportResult.error || "UNEXPECTED");
        case ReportStatus.READY:
          downloadUrl = reportResult.url;
          break;
        case ReportStatus.PREPARING:
        default:
          // do nothing
          break;
      }
    }
  }

  clearTimeout(timeout);

  if (!downloadUrl) {
    throw new Error("UNEXPECTED");
  }

  const result = await getReportData(downloadUrl);

  if (!result) {
    throw new Error("UNEXPECTED");
  }

  const extension = mimeTypes[result.type];
  fileName = `${fileName} ${dates.format(new Date().getTime(), "YYYY-MM-DD HH-mm")}${extension}`;
  download.blob(result, fileName);
};
