import React, { useMemo, useState } from "react";
import { Icon, Tooltip } from "@veneer/core";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  caseRequestError,
  caseRequestSubmit,
  caseRequestSubmitComplete,
  changeCaseRequestCustomerTicketNumber,
  changeCaseRequestDetailedDescription,
  changeCaseRequestSDMAttachments,
  changeCaseRequestShortDescription,
  changeCaseRequestTroubleshootingDescription,
} from "../../../redux/case-request-actions";
import { useCaseDetailParams } from "../../../redux/case-request-hooks";
import { Dispatch } from "redux";
import { RootActionTypes } from "../../../redux/root-action";
import CaseRequestModalButtonWrapper from "./case-request-modal-button-wrapper";
import CaseRequestModalReverseButton from "./case-request-modal-reverse-button";
import CaseRequestModalForwardButton from "./case-request-modal-forward-button";
import { createCase } from "../../../api/caseRequest";
import { useCurrentCustomerID } from "../../../redux/current-customer-hooks";
import { CaseRequestEnum } from "types/case-request";
import AttachFiles from "./add-attachments-v2";
import deviceType from "shared/devices";
import useIsDevice from "shared/isDevice";
import { useConfig } from "../../../client-config";
import { useIsCoolDown, useSetCoolDown } from "../../../redux/init-cooldown";
import CoolDownDisplay from "../../../redux/cooldown-display";
import { Field, Form, Formik } from "formik";
import TextBox from "../../../components/forms/text-box";
import TextArea from "../../../components/forms/text-area";
import AutoSubmit from "../../../components/forms/auto-submit";

const CaseDetailsWrapper = styled.div`
  .errorMessage {
    color: ${(props) => props.theme.colors.red};
  }

  .submitErrorMessage {
    color: ${(props) => props.theme.colors.red};
    text-align: right;
    padding-top: 10px;
  }

  .tooltip {
    display: flex;
  }

  .vn-tooltip {
    position: relative !important;
    top: 4px;
    left: 2px;
  }

  .vn-tooltip__content {
    @media ${(props) => props.theme.breakpoints.desktop} {
      width: 325px;
      padding-left: 25px;
    }

    @media ${(props) => props.theme.breakpoints.laptop} {
      width: 300px;
      padding-left: 25px;
    }

    @media ${(props) => props.theme.breakpoints.tabletMaximum} {
      width: 175px;
      padding-left: 20px;
   }

    @media ${(props) => props.theme.breakpoints.mobileMaximum} {
        width: 150px;
        padding-left: 20px;
    }
  }

  .vn-icon--size-24 {
    height: 20px;
  }

  ul {
    list-style: circle;
    @media ${(props) => props.theme.breakpoints.laptop} {
      padding-left: 30px;
    }
  }

  ol {
    list-style: disc;
  }
`;

export interface FormValues {
  customerTicketNumber?: string;
  shortDescription?: string;
  detailedDescription?: string;
  troubleshootingDescription?: string;
  sdmAttachments?: { id: string, [key: string]: string }[];
}

const CaseDetails: React.FC = () => {
  const {
    shortDescription,
    detailedDescription,
    sdmAttachments,
    customerTicketNumber,
    submitting,
    category,
    subCategory,
    subType,
    country,
    orderNumber,
    contact,
    alternateContact,
    serviceLocation,
    newServiceLocation,
    serialNumber,
    productNumber,
    error,
    selectedFor,
    troubleshootingDescription,
    issueType,
  } = useCaseDetailParams();
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();
  const { t } = useTranslation();
  const currentCustomerId = useCurrentCustomerID();
  const hpCustomerId = useConfig("hpCustomerId");
  const [maxLimitError, setMaxLimitError] = useState<any>();
  const isMobile = useIsDevice(deviceType.MOBILE, deviceType.MOBILELARGE);
  const setCoolDown = useSetCoolDown();
  const customerId = selectedFor === CaseRequestEnum.HP_USER ? hpCustomerId : currentCustomerId;
  const MAX_CHAR_COUNT = 3998;

  const finalDescription = useMemo(() => {
    const completeDescription = `${detailedDescription}\n\n${troubleshootingDescription}`;
    if (completeDescription.length > MAX_CHAR_COUNT) {
      setMaxLimitError([t("caserequest.error.maximumCharacters")]);
    } else {
      setMaxLimitError(undefined);
    }
    return completeDescription;
  }, [detailedDescription, t, troubleshootingDescription]);

  const submit = async () => {
    dispatch(caseRequestSubmit());

    let location = serviceLocation;
    if (newServiceLocation?.isCreatingNewLocation) {
      delete newServiceLocation.isCreatingNewLocation;
      location = newServiceLocation;
    }

    createCase(
      {
        customerId,
        country,
        orderNumber,
        shortDescription,
        description: finalDescription,
        customerTicket: customerTicketNumber,
        category,
        subCategory,
        subType,
        contact,
        alternateContact,
        location,
        serialNumber,
        productNumber,
        selectedFor,
        sdmAttachments,
        issueType: issueType?.type,
      },
      t,
    )
      .then(({ caseNumber, coolDown }) => {
        dispatch(caseRequestSubmitComplete(caseNumber));
        setCoolDown(coolDown);
      })
      .catch((errorMessage) => {
        dispatch(caseRequestError(errorMessage));
      });
    return false;
  };

  const isCoolDown = useIsCoolDown();
  const canContinue = !!(!submitting && shortDescription && detailedDescription && !maxLimitError && !isCoolDown);

  const formAutoSubmit = (values: FormValues) => {
    dispatch(changeCaseRequestCustomerTicketNumber(values.customerTicketNumber));
    dispatch(changeCaseRequestDetailedDescription(values.detailedDescription));
    dispatch(changeCaseRequestShortDescription(values.shortDescription));
    dispatch(changeCaseRequestTroubleshootingDescription(values.troubleshootingDescription));
    dispatch(changeCaseRequestSDMAttachments(values.sdmAttachments));
  };

  const initialValues: FormValues = {
    customerTicketNumber,
    shortDescription,
    detailedDescription,
    troubleshootingDescription,
    sdmAttachments,
  };

  return (
    <Formik initialValues={initialValues} onSubmit={formAutoSubmit}>
      {({ values, submitForm }) => (
        <Form>
          <AutoSubmit values={values} submitForm={submitForm} />
          <CaseDetailsWrapper>
            <Field
              label={t("customerticket")}
              value={values.customerTicketNumber}
              maxLength={15}
              name="customerTicketNumber"
              placeholder={t("caserequest.hardwareCase.customerTicket.placeholder")}
              component={TextBox}
            />

            <Field
              label={t("shortdescription")}
              required
              value={values.shortDescription}
              name="shortDescription"
              placeholder={t("caserequest.hardwareCase.shortDescription.placeholder")}
              maxLength={50}
              component={TextBox}
            />

            {
              (category === CaseRequestEnum.HARDWARE_FALLBACK)
                ? (
                  <>
                    <Field
                      name="detailedDescription"
                      label={(
                        <>
                          {t("caserequest.hardwareCase.issueDescription")}
                          <Tooltip
                            content={t("caserequest.hardwareCase.issueDescriptionInfo")}
                            contentHideDelayOnHover={0}
                            position="right"
                          >
                            <Icon name="information" size={24} />
                          </Tooltip>
                        </>
                      )}
                      placeholder={t("caserequest.hardwareCase.issueDescription.placeholder")}
                      required
                      value={values.detailedDescription}
                      component={TextArea}
                    />
                    <Field
                      name="troubleshootingDescription"
                      label={(
                        <>
                          {t("caserequest.hardwareCase.troubleShooting")}
                          <Tooltip
                            content={t("caserequest.hardwareCase.troubleshootingInfo")}
                            contentHideDelayOnHover={0}
                            position={isMobile ? "left" : "right"}
                          >
                            <Icon name="information" size={24} />
                          </Tooltip>
                        </>
                      )}
                      value={values.troubleshootingDescription}
                      placeholder={t("caserequest.hardwareCase.troubleshooting.placeholder")}
                      component={TextArea}
                    />
                  </>
                )
                : (
                  <>
                    <Field
                      label={t("detaileddescription")}
                      name="detailedDescription"
                      value={values.detailedDescription}
                      placeholder={t("caserequest.hardwareCase.issueDescription.placeholder")}
                      required
                      component={TextArea}
                    />
                  </>
                )
            }

            <Field
              label={t("upload.attachments")}
              subTitle={t("file.size.limit")}
              name="sdmAttachments"
              value={values.sdmAttachments}
              component={AttachFiles}
            />

            <CaseRequestModalButtonWrapper data-cy="createCaseButton">
              <CaseRequestModalReverseButton />
              <CaseRequestModalForwardButton canContinue={canContinue} onContinue={() => submit()} caption="Submit Case" loading={submitting} />
            </CaseRequestModalButtonWrapper>

            {error && <p className="submitErrorMessage">{error}</p>}

            <CoolDownDisplay />
          </CaseDetailsWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default CaseDetails;
