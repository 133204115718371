import React from "react";
import { useTranslation } from "react-i18next";
import { Avatar } from "@veneer/core";
import styled from "styled-components";
import { useCurrentCustomer } from "redux/current-customer-hooks";
import { useHistory } from "react-router-dom";
import { permissions } from "@mssi/pssp-shared";
import { useAuth } from "redux/auth-hooks";
import Button from "components/button";

const ButtonWrapper = styled.div`
  .vn-button {
    width: 100%;
    text-transform: capitalize;
  }
`;

const Wrapper = styled.div` 
  overflow-x: auto; 
  .vn-avatar {
    background-color: unset;
  }
  .vn-panel__content {
    padding: 0px; 
  }
  
  table {
    width: 100%;
  }

  tr:nth-child(odd) {
    background-color: ${props => props.theme.colors.bgV1};
  }

  td:nth-child(2) {
    display: flex;
  }

  th, td {
  text-align: left;
  padding: 8px;
  }
  tr {
    margin: 10px;
  }
`;

const NoContactsAvailable = styled.div`
  padding-top: 5%;
  padding-bottom: 3%;
  text-align: center;
`;

const AccountTeam: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { authorizedFor } = useAuth();
  const { customer } = useCurrentCustomer();
  const currentCustomerID = customer.id;
  const accountData = customer.accountInfo?.filter((data) => {
    return !!data.highlight;
  });


  return (
    <>
      <Wrapper>
        <table>
          <thead>
            <tr>
              <th> </th>
              <th>Name</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {accountData?.map((item, index) => (
              <tr key={index}>
                <td><Avatar size={24} height="24px" /></td>
                <td>{item.name}</td>
                <td>{item.role}</td>
              </tr>
                ))}
          </tbody>
        </table>
        {!accountData?.length && <NoContactsAvailable>{t("nocontacts")}</NoContactsAvailable>}
      </Wrapper>
      {authorizedFor([permissions.canAccessSupportPage]) && (
        <ButtonWrapper>
          <Button onClick={() => { history.push(`/${currentCustomerID}/support-account-team`); }}>{t("viewFullAccountTeam")}</Button>
        </ButtonWrapper>
      )}
    </>
  );
};

export default AccountTeam;
