import React from "react";
import { Icon } from "@veneer/core";

interface CatalogIconProps {
  name: string;
  size: number;
}

const categoryIconMap: Record<string, string> = {
  laptop: "notebook",
  "laptop pc": "notebook",
  notebook: "notebook",
  desktop: "desktop",
  "desktop pc": "desktop",
  workstation: "workstation",
  display: "monitor",
  monitor: "monitor",
  "point of sale": "rpos",
  pos: "rpos",
  "retail point of sale": "rpos",
};

/**
   * It returns the icon name based on category given
   * @param {string} name Name of the catagory
   */
const getCategoryIcon = (name: string) => {
  const iconName = categoryIconMap[name.toLowerCase()];
  return iconName || "devices";
};

const CatalogIcon: React.FC<CatalogIconProps> = ({ name, size = 24 }) => {
  return <Icon name={getCategoryIcon(name)} size={size} />;
};

export default CatalogIcon;
