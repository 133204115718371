import React, { useState, useEffect, useMemo } from "react";
import { Button, Spinner, Modal } from "@veneer/core";
import styled from "styled-components";
import { Form, Formik } from "formik";
import { useAuth } from "../redux/auth-hooks";
import { setEmailPreferences, getEmailPreferences } from "../api/email-unsubscribe";
import { useTranslation } from "react-i18next";
import { EmailUnsubscribes } from "../types/email-unsubscribe";
import { useAddError, useAddSuccess } from "redux/toast-hooks";
import { useParams } from "react-router-dom";

interface Email {
  emailAddress: string;
  orderNumber: string;
}

interface Props {
  emailUnsubscribe?: Email;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 8px 8px;
  background-color: ${(props) => props.theme.colors.white};
  width: 100%;
  height: initial;

  @media (max-width: 400px) {
    .vn-button {
      height: 70px;
      margin-right: 10px;
      white-space: break-spaces;
    }
  }
`;

const StyledText = styled.div`
  margin: 20px 20px 0px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 0px 15px 10px;
`;

const StyledButtons = styled.div`
  margin: 20px 0px 0px 30px;
`;

const StyledSubText = styled.div`
  display: flex;
  margin-top: 5px;
  color: ${(props) => props.theme.colors.lightGray};
  font-size: ${(props) => props.theme.sizes.size12};
`;

const LoadingWrapper = styled.div`
  margin: 3% 0% 0% 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute !important;
  z-index: 999;
`;

const EmailUnsubscribe: React.FC<Props> = ({ emailUnsubscribe }) => {
  const { t } = useTranslation();
  const addSuccess = useAddSuccess();
  const addError = useAddError();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [unsubscribe, setUnsubscribe] = useState<String[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [loadingOrder, setLoadingOrder] = useState<boolean>(false);
  const [loadingAllEmail, setLoadingAllEmail] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const { orderNumber } = useParams<{ orderNumber: string }>();
  const orderId = orderNumber ? orderNumber : emailUnsubscribe?.orderNumber || "";

  const email = currentUser?.email || emailUnsubscribe?.emailAddress || "";

  const handleUnsubscribeOrder = () => {
    setLoadingOrder(true);
    setEmailPreferences(email, unsubscribe, orderId)
      .then(() => {
        setLoadingOrder(false);
        setModalMessage(t("emailUnsubscribe.orderSuccess", { orderId }));
        setShowModal(true);
      })
      .catch(() => {
        setLoadingOrder(false);
        addError(t("emailUnsubscribe.failure"));
      });
  };

  const handleUnsubscribeAllEmail = () => {
    setLoadingAllEmail(true);
    setButtonDisabled(true);
    setEmailPreferences(email, unsubscribe)
      .then(() => {
        setLoadingAllEmail(false);
        setModalMessage(t("emailUnsubscribe.emailSuccess"));
        setShowModal(true);
      })
      .catch(() => {
        setLoadingAllEmail(false);
        addError(t("emailUnsubscribe.failure"));
      });
  };

  const handleModalClose = () => {
    setShowModal(false);
    addSuccess(t("emailUnsubscribe.success"));
  };

  useEffect(() => {
    setLoading(true);
    getEmailPreferences({ email, orderId })
      .then((res) => {
        setUnsubscribe(res);
        setLoading(false);
      })
      .catch((error) => {
        addError(error.message);
      });
  }, [email, addError, orderId]);

  const initialValues = useMemo(() => ({ unsubscribe }), [unsubscribe]);

  if (loading) {
    return (
      <Wrapper>
        <ButtonWrapper>
          <StyledText>{t("emailUnsubscribe.buttonsToUnsubscribe")}</StyledText>
          <LoadingWrapper>
            <Spinner />
          </LoadingWrapper>
        </ButtonWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Formik initialValues={initialValues} onSubmit={() => undefined}>
        {() => (
          <Form>
            <ButtonWrapper>
              <StyledText>
                {t("emailUnsubscribe.buttonsToUnsubscribe")}
              </StyledText>
              {unsubscribe?.map((val, index) => (
                <StyledButtons key={index}>
                  {val === EmailUnsubscribes.UNSUBSCRIBE_FROM_PS_PORTAL_ORDER && (
                    <Button
                      loading={loadingOrder}
                      onClick={handleUnsubscribeOrder}
                      disabled={buttonDisabled}
                    >
                      {t(`emailUnsubscribe.${val}`)}
                    </Button>
                  )}
                  {val === EmailUnsubscribes.UNSUBSCRIBE_FROM_ALL_PORTAL_EMAILS && (
                    <Button
                      loading={loadingAllEmail}
                      onClick={handleUnsubscribeAllEmail}
                      disabled={buttonDisabled}
                    >
                      {t(`emailUnsubscribe.${val}`)}
                    </Button>
                  )}
                  <Modal
                    size="sm"
                    show={showModal}
                    onClose={handleModalClose}
                    dismissOnOverlayClick={false}
                  >
                    <div>{modalMessage}</div>
                  </Modal>
                  <StyledSubText>
                    {t(`emailUnsubscribe.${val}.subText`)}
                  </StyledSubText>
                </StyledButtons>
              ))}
            </ButtonWrapper>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default EmailUnsubscribe;
