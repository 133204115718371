import React, { useCallback, useEffect, useState } from "react";
import Bloodhound from "corejs-typeahead/dist/bloodhound";
import "corejs-typeahead/dist/typeahead.jquery";
import $ from "jquery";
import { getIn } from "formik";
import { FormField, Search } from "@veneer/core";

import { makeDataSource } from "./datasource";
import { makeBloodhound, TYPEAHEAD_QUERY_STR } from "./bloodhound";
import { TypeaheadProps, TypeaheadRef, TypeaheadSelectionEvent } from "./types";
import { makeTypeaheadChangeEvent, makeTypeaheadSelectionEvent } from "./events";
import { TypeaheadWrapper } from "./typeahead-wrapper";

export { TYPEAHEAD_QUERY_STR };

// Notes on using Typeahead library:
// https://github.com/twitter/typeahead.js/blob/master/doc/bloodhound.md
// https://github.com/erikschlegel/React-Twitter-Typeahead/blob/master/lib/js/react-typeahead.js

const Typeahead = <T extends {[key: string]: any}>({
  label,
  placeholder,
  form,
  field,
  required,
  remoteUrl,
  localData,
  fieldKey,
  onFocus,
  onChange,
  disabled = false,
  filterKeys = [fieldKey],
  matchKey = fieldKey,
  initialDisplayValue = field.value,
  templates = {},
  updateMapping = { [fieldKey]: fieldKey },
  update,
  valueChanged,
}: TypeaheadProps<T>): React.ReactElement<TypeaheadProps<T>> => {
  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);
  const hasError = error && touched;

  const filterKeysJSON = JSON.stringify(filterKeys);
  const templatesJSON = JSON.stringify(templates);
  const updateMappingJSON = JSON.stringify(updateMapping);

  const [displayValue, setDisplayValue] = useState<string>(initialDisplayValue);
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [ref, setRef] = useState<HTMLInputElement | null>(null);
  const [typeahead, setTypeahead] = useState<TypeaheadRef<T>>();

  const getBloodhound = useCallback(
    () => makeBloodhound<T>({ remoteUrl, localData, filterKeysJSON }),
    [remoteUrl, localData, filterKeysJSON],
  );

  const getDataSource = useCallback(
    (bloodhound: Bloodhound<T>) => makeDataSource<T>({ fieldKey, templatesJSON, bloodhound }),
    [fieldKey, templatesJSON],
  );

  const getTypeaheadSelectionEvent = useCallback(
    () => makeTypeaheadSelectionEvent<T>({ updateMappingJSON, update, fieldKey, setDisplayValue, setSelectedValue }),
    [updateMappingJSON, update, fieldKey],
  );

  const getTypeaheadChangeEvent = useCallback(
    (bloodhound: Bloodhound<T>, handleSelection: TypeaheadSelectionEvent<T>) =>
      makeTypeaheadChangeEvent<T>({ displayValue, selectedValue, matchKey, bloodhound, handleSelection }),
    [displayValue, selectedValue, matchKey],
  );

  useEffect(() => {
    if (!ref) {
      return () => {};
    }

    if ($(ref).data().ttTypeahead) {
      try {
        $(ref).typeahead("destroy");
      } catch (e: any) {
        console.warn(e);
      }
    }

    const bloodhound = getBloodhound();
    const dataSource = getDataSource(bloodhound);

    setTypeahead({
      jquery: $(ref).typeahead({ minLength: 1, hint: false, highlight: true }, dataSource),
      bloodhound,
    });

    return () => {
      if (ref && $(ref).data().ttTypeahead) {
        try {
          $(ref).typeahead("destroy");
        } catch (e: any) {
          console.warn(e);
        }
      }
    };
  }, [ref, getBloodhound, getDataSource]);

  useEffect(() => {
    if (!typeahead) {
      return () => {};
    }

    const handleSelection = getTypeaheadSelectionEvent();
    const handleChange = getTypeaheadChangeEvent(typeahead.bloodhound, handleSelection);

    typeahead.jquery.on("typeahead:select", handleSelection);
    typeahead.jquery.on("typeahead:change", handleChange);
    typeahead.jquery.on("typeahead:open", () => setIsOpen(true));
    typeahead.jquery.on("typeahead:close", () => setIsOpen(false));

    return () => {
      typeahead.jquery.off("typeahead:select");
      typeahead.jquery.off("typeahead:change");
      typeahead.jquery.off("typeahead:open");
      typeahead.jquery.off("typeahead:close");
    };
  }, [getTypeaheadSelectionEvent, getTypeaheadChangeEvent, typeahead]);

  useEffect(
    () => {
      if (typeof valueChanged === "function") {
        valueChanged(displayValue);
      }
    },
    [displayValue, valueChanged]
  );

  const safeSetRef = (e: HTMLInputElement | null) => {
    // only update ref if element is non-null and also not already the same as ref
    if (e && e !== ref) {
      setRef(e);
    }
  };

  return (
    <TypeaheadWrapper displayValue={displayValue} isOpen={isOpen}>
      <FormField
        label={label}
        htmlFor={field.name}
        errorMessage={hasError && error}
        disabled={disabled}
        required={required}
      >
        <Search
          name={field.name}
          placeholder={placeholder}
          type="text"
          onChange={(v: string) => {
            if (onChange) {
              onChange(v);
            }
            setDisplayValue(v);
          }}
          onFocus={onFocus}
          value={displayValue}
          error={hasError}
          inputRef={(e: HTMLInputElement | null) => safeSetRef(e)}
        />
      </FormField>
    </TypeaheadWrapper>
  );
};

export default Typeahead;
