import React, { useCallback, useMemo } from "react";
import { Switch } from "react-router-dom";

import withLayout from "components/with-layout";
import { NavItem } from "types/nav-item";
import { useCurrentCustomer } from "redux/current-customer-hooks";
import { useAuthIsLoading, useAuthIsLoggedIn, usePermissions } from "../redux/auth-hooks";
import PageSpinner from "./page-spinner";
import { Redirect, Route } from "react-router";
import useMenuList, { canUseMenuItem } from "../pages/routes";

const AuthenticatedRoutes: React.FC = () => {
  const navData = useMenuList();
  const loading = useAuthIsLoading();
  const isLoggedIn = useAuthIsLoggedIn();
  const userPermissions = usePermissions();
  const { customer } = useCurrentCustomer();
  const currentCustomerID = customer.id;

  const getItemUrl = useCallback(
    (item: NavItem) => (
      item.path && item.customerBased
        ? `/${currentCustomerID}${item.path}`
        : item.path
    ),
    [currentCustomerID],
  );

  const navRoutes = useMemo(
    () => {
      const routeKeys: Record<string, boolean> = {};

      navData
        .filter((item) => canUseMenuItem(userPermissions, customer, item))
        .forEach((item) => {
          routeKeys[item.key] = true;

          if (item.subMenu) {
            item.subMenu
              .filter((subItem) => canUseMenuItem(userPermissions, customer, subItem))
              .forEach((subItem) => {
                routeKeys[subItem.key] = true;
              });
          }
        });

      return navData
        .filter((item) => routeKeys[item.key])
        .map((route) => {
          if (route.subMenu) {
            return route.subMenu.filter((item) => routeKeys[item.key])
              .map((item) => {
                return (
                  <Route
                    path={item.path ? getItemUrl(item || "") : item.path}
                    component={withLayout(item)}
                    key={item.key}
                    exact={item.path === "/"}
                  />
                );
              });
          }

          return (
            <Route
              path={route.path ? getItemUrl(route || "") : route.path}
              component={withLayout(route)}
              key={route.key}
              exact={route.path === "/"}
            />
          );
        });
    },
    [customer, navData, userPermissions, getItemUrl],
  );

  if (loading) {
    return <PageSpinner />;
  }

  if (!isLoggedIn) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return (
    <Switch>
      {navRoutes}
    </Switch>
  );
};

export default AuthenticatedRoutes;
