import React from "react";
import { IconButton } from "@veneer/core";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface WrapperProps {
  controlHeight: boolean;
}

interface HeaderProps {
  noBorder: boolean;
  isRequiredUpdatedPanel?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  background-color: ${(props) => props.theme.colors.white};
  width: 100%;
  height: ${(props) => (props.controlHeight ? "100%" : "initial")};
`;

const Header = styled.div<HeaderProps>`
  padding: ${(props) => (props.isRequiredUpdatedPanel ? "0px 10px 0px 20px" : "16px 20px")};
  font-size: ${(props) => (props.isRequiredUpdatedPanel ? "14px" : "18px")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${(props) => (props.noBorder ? "none" : `1px solid ${props.theme.colors.border}`)};
  background-color: ${(props) => (props.isRequiredUpdatedPanel ? `${props.theme.colors.bgV7} !important` : "unset")};
  color: ${(props) => (props.isRequiredUpdatedPanel ? `${props.theme.colors.white} !important` : "unset")};
  height: ${(props) => (props.isRequiredUpdatedPanel ? "40px" : "unset")};
  font-weight: ${(props) => (props.isRequiredUpdatedPanel ? "bold" : "unset")};
  margin-left: ${(props) => (props.isRequiredUpdatedPanel ? "1px" : "unset")};
`;

const Actions = styled.div`
  > * + * {
    margin-left: 20px;
  }
`;

const StyledIcon = styled.div<HeaderProps>`
  .vn-icon-button {
    background-color: transparent;
    border: none;
    color: ${(props) => (props.isRequiredUpdatedPanel ? `${props.theme.colors.white} !important` : "unset")};
  }
`;

interface PanelProps {
  children: React.ReactNode;
  icon?: string;
  onClick?(): void;
  title?: any;
  to?: string;
  controlHeight?: boolean;
  noBorder?: boolean;
  actions?: any;
  isRequiredUpdatedPanel?: boolean;
  styleClass?: string;
}

/**
 * Panel based on the veneer panel but also supports icons in the header
 */
const Panel: React.FC<PanelProps> = ({
  children,
  icon,
  title,
  onClick,
  to,
  actions,
  noBorder = false,
  controlHeight = false,
  isRequiredUpdatedPanel = false,
  styleClass = "vn-panel__content",
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper controlHeight={controlHeight}>
      {title && (
      <Header noBorder={noBorder} isRequiredUpdatedPanel={isRequiredUpdatedPanel}>
        {to ? (
          <Link to={to}>
            <span id="panel-title">{title}</span>
          </Link>
        ) : (
          <span id="panel-title">{title}</span>
        )}
        <Actions id="panel-action_buttons">
          {icon && (
            <StyledIcon noBorder={noBorder} isRequiredUpdatedPanel={isRequiredUpdatedPanel}>
              <IconButton data-cy={`${icon}Icon`} icon={icon} title={t("btn.edit")} onClick={onClick} />
            </StyledIcon>
          )}
          {actions && actions}
        </Actions>
      </Header>
    )}
      <div className={styleClass}>{children}</div>
    </Wrapper>
  );
};

export default Panel;
