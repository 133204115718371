import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  font: {
    light: "HP Simplified Light, Arial, sans-serif",
    regular: "HP Simplified, Arial, sans-serif",
  },

  breakpoints: {
    mobile: `(min-width: 320px)`,
    mobileLarge: `(min-width: 414px)`,
    tablet: `(min-width: 768px)`,
    tabletLarge: `(min-width: 776px)`,
    laptop: `(min-width: 1024px)`,
    desktop: `(min-width: 1440px)`,
    mobileMaximum: `(max-width: 767px)`,
    tabletMaximum: `(max-width: 1023px)`,
    mobile640: `(max-width: 640px)`,
    mobile540: `(max-width: 540px)`,
    mobile467: `(max-width: 467px)`,
    mobile385: `(max-width: 385px)`,
    mobile355: `(max-width: 355px)`,
    mobile280: `(max-width: 280px)`,
  },

  gutter: "20px",
  globalTransition: "all 0.3s ease 0s",
  marginTop: {
    invitationExpired: "100px",
    invitationSent: "0px",
  },

  colors: {
    blue: "#0096D6",
    blue2: "#0195d6",
    lightBlue: "#def2fd",
    darkBlue: "#015f8a",
    violet: "#7246BF",
    purple: "#A33FA3",
    pink: "#E51869",
    red: "#F9350F",
    orange: "#f28200",
    transparent: "#00000000",
    yellow: "#FCDD1F",
    lightGreen: "#84C43E",
    green: "#4FA33D",
    turquoise: "#2DD1C8",
    success: "#1ca727",
    warning: "#F9FFD9",
    error: "#E32E13",
    body: "#666666",
    bodyDark: "#333333",
    background: "#F2F2F2",
    border: "#E6E6E6",
    white: "#ffffff",
    black: "#000000",
    gray: "#4D4D4D",
    lightGray: "#999",
    gold: "#D4AF37",
    bgV5: "#ececec",
    bgV1: "#f9f9f9",
    bgV2: "#7e7676",
    bgV3: "#0096d6",
    bgV7: "#0084bc",
    astrik: "#cf4030",
    background1: "#e6eeff",
    background2: "#fbfbfb",
    background3: "#E5EFF3",
    background4: "#b2dff2",
    background5: "#ddf2fc",
    background6: "#f7f7f7",
    textColor1: "#808080",
    textColor2: "#b1b5b3",
    textColor3: "#57bae4",
    textColor4: "#7E8080",
    textColor5: "#5A5A5A",
    borderColor: "#cccccc",
    darkGray: "#EBEBEB",
    saffron: "#D87400",
    grayFont: "#c5c2c2",
    greenFont: "#8bc34a",
    greenLabel: "#4ea33c",
    grey: "#808080",
    lightBlack: "#333333",
    lightWhite: "#DDDDDD",
    warningBackground: "#fee970",
    lightBlue1: "#e5f4fa",
    blueShade: "blue-shade-05",
    mediumGrey: "#666",
    textColor6: "#f9350f0d",
    textColor7: "#def1f5",
    textColor8: "#f28202",
    leftBorder: "#e8e8e8"
  },
  sizes: {
    size11: "11px",
    size12: "12px",
    size14: "14px",
    size15: "15px",
    size16: "16px",
    size17: "17px",
    size18: "18px",
    size20: "20px",
    size22: "22px",
    size24: "24px",
    size26: "26px",
    size28: "28px",
    size32: "32px",
  },
};

export default theme;
