import {
  CASE_REQUEST_CHANGE_SDM_ATTACHMENTS,
  CASE_REQUEST_CHANGE_CONTACT,
  CASE_REQUEST_CHANGE_ALTERNATE_CONTACT,
  CASE_REQUEST_CHANGE_COUNTRY,
  CASE_REQUEST_CHANGE_CUSTOMER_TICKET_NUMBER,
  CASE_REQUEST_CHANGE_DETAILED_DESCRIPTION,
  CASE_REQUEST_CHANGE_DEVICE,
  CASE_REQUEST_CHANGE_ORDER_NUMBER,
  CASE_REQUEST_CHANGE_SELECTED_FOR,
  CASE_REQUEST_CHANGE_SERVICE_LOCATION,
  CASE_REQUEST_CHANGE_SHORT_DESCRIPTION,
  CASE_REQUEST_CHANGE_TROUBLESHOOTING_DESCRIPTION,
  CASE_REQUEST_CLOSE_MODAL,
  CASE_REQUEST_CONTRACT_ISSUE_TYPE,
  CASE_REQUEST_ERROR,
  CASE_REQUEST_GOTO_STEP,
  CASE_REQUEST_NEXT_STEP,
  CASE_REQUEST_PREVIOUS_STEP,
  CASE_REQUEST_REGEX,
  CASE_REQUEST_SHOW_MODAL,
  CASE_REQUEST_SUBMIT,
  CASE_REQUEST_SUBMIT_COMPLETE,
} from "./case-request-actions";
import { RootActionTypes } from "./root-action";
import { CaseRequestState, initialCaseRequestState } from "redux/redux-state";
import { subCategoryMapping, SubCategoryType, } from "../pages/support-tickets/components/case-request-categories";

const getSingleSubCategory = (category?: string): string | undefined => {
  if (!category || !subCategoryMapping[category]) {
    return undefined;
  }

  const availableSubCategories: SubCategoryType[] = subCategoryMapping[category];
  return (availableSubCategories.length === 1 && availableSubCategories[0] && availableSubCategories[0].subCategory)
    || undefined;
};

const getSingleSubType = (category?: string, subCategory?: string): string | undefined => {
  if (!category || !subCategory || !subCategoryMapping[category]) {
    return undefined;
  }

  const subCategoryObj = subCategoryMapping[category]
    .find((it) => it.subCategory === subCategory);

  const availableSubTypes: string[] = (subCategoryObj && subCategoryObj.subTypes) || [];
  return (availableSubTypes.length === 1 && availableSubTypes[0]) || undefined;
};

const caseRequestReducer = (
  state: CaseRequestState = initialCaseRequestState,
  action: RootActionTypes,
): CaseRequestState => {
  if (!CASE_REQUEST_REGEX.test(action.type)) {
    return state;
  }

  const getMaximumSteps = () => {
    if (state.category === "portal") {
    if (state.selectedFor === "Someone Else") {
      return 4;
    } else if (state.selectedFor === "Me") {
      return 3;
    } else if (state.submittedUserType === "External") {
      return 2;
    }
   } return 3;
  };

  const maxSteps = getMaximumSteps();

  switch (action.type) {
    case CASE_REQUEST_SHOW_MODAL: {
      const subCategory = getSingleSubCategory(action.subCategory) || action.subCategory;
      const subType = getSingleSubType(action.category, subCategory);
      return {
        ...initialCaseRequestState,
        category: action.category,
        subCategory,
        subType,
        submittedUserType: action.submittedUserType,
        orderNumber: action.orderNumber,
        currentStep: action.orderNumber ? 1 : 0,
      };
    }
    case CASE_REQUEST_CLOSE_MODAL:
      return { ...initialCaseRequestState };
    case CASE_REQUEST_CHANGE_DEVICE:
      return {
        ...state,
        deviceId: action.deviceId,
        serialNumber: action.serialNumber,
        productNumber: action.productNumber,
        osAndVersion: action.osAndVersion
      };
    case CASE_REQUEST_NEXT_STEP:
      return {
        ...state,
        currentStep: Math.max(Math.min(state.currentStep + 1, maxSteps - 1), 0),
      };
    case CASE_REQUEST_SUBMIT:
      return {
        ...state,
        submitting: true,
      };
    case CASE_REQUEST_SUBMIT_COMPLETE:
      return {
        ...state,
        submitting: false,
        caseNumber: action.caseNumber || "",
      };
    case CASE_REQUEST_ERROR:
      return {
        ...state,
        submitting: false,
        error: action.error,
      };
    case CASE_REQUEST_PREVIOUS_STEP:
      return {
        ...state,
        currentStep: Math.max(Math.min(state.currentStep - 1, maxSteps - 1), 0),
      };
    case CASE_REQUEST_GOTO_STEP:
      return {
        ...state,
        currentStep: Math.max(Math.min(action.stepNumber, maxSteps - 1), 0),
      };
    case CASE_REQUEST_CHANGE_SELECTED_FOR:
      return {
        ...state,
        selectedFor: action.selectedFor,
      };
    case CASE_REQUEST_CHANGE_COUNTRY:
      return {
        ...state,
        country: action.country,
      };
    case CASE_REQUEST_CHANGE_ORDER_NUMBER:
      return {
        ...state,
        orderNumber: action.orderNumber,
      };
    case CASE_REQUEST_CHANGE_CONTACT:
      return {
        ...state,
        contact: action.contact,
        contactLoaded: true,
      };
    case CASE_REQUEST_CHANGE_ALTERNATE_CONTACT:
      return {
      ...state,
      alternateContact: action.alternateContact,
      contactLoaded: true,
      };
    case CASE_REQUEST_CHANGE_SERVICE_LOCATION:
      return {
        ...state,
        serviceLocation: action.serviceLocation,
      };
    case CASE_REQUEST_CHANGE_SHORT_DESCRIPTION:
      return {
        ...state,
        shortDescription: action.shortDescription,
      };
    case CASE_REQUEST_CHANGE_DETAILED_DESCRIPTION:
      return {
        ...state,
        detailedDescription: action.detailedDescription,
      };
    case CASE_REQUEST_CHANGE_SDM_ATTACHMENTS:
      return {
        ...state,
        sdmAttachments: action.sdmAttachments,
      };
    case CASE_REQUEST_CHANGE_CUSTOMER_TICKET_NUMBER:
      return {
        ...state,
        customerTicketNumber: action.customerTicketNumber,
      };
    case CASE_REQUEST_CHANGE_TROUBLESHOOTING_DESCRIPTION:
       return {
         ...state,
         troubleshootingDescription: action.troubleshootingDescription,
       };
    case CASE_REQUEST_CONTRACT_ISSUE_TYPE:
      return {
        ...state,
        issueType: action.issueType,
      };
    default:
      return state;
  }
};

export default caseRequestReducer;
