import { deleteRequest, postJson, getJson } from "./request";
import Order, { ShippingInfo, ShoppingCart } from "../types/order";
import { getFetchErrorTransform } from "../types/validation-error";
import Site from "../types/site";
import queryString from "query-string";

export const updateCart = (
  customerId: string,
  bundleId: any,
  localizationCode: string,
  quantity: number,
  solutionId: any
): Promise<ShoppingCart> =>
  postJson(`/shopping-cart/update-cart?${queryString.stringify({ customerId })}`, {
    bundleId,
    localizationCode,
    quantity,
    solutionId,
  })
    .then(({ cart }: { cart: ShoppingCart }) => cart)
    .catch((error: any) => {
      throw getFetchErrorTransform({
        default: "Error adding to cart, please try again",
      })(error);
    });

export const setCartContract = (customerId: string, contractId?: string): Promise<ShoppingCart> =>
  postJson(`/shopping-cart/select-contract?${queryString.stringify({ customerId })}`, { contractId })
    .then(({ cart }: { cart: ShoppingCart }) => cart)
    .catch((error: any) => {
      throw getFetchErrorTransform({
        default: "Error choosing contract, please try again",
      })(error);
    });

export const setCartHomeAddress = (customerId: string, homeAddress?: any): Promise<ShoppingCart> =>
  postJson(`/shopping-cart/home-address?${queryString.stringify({ customerId })}`, { homeAddress })
    .then(({ cart }: { cart: ShoppingCart }) => cart)
    .catch((error: any) => {
      throw getFetchErrorTransform({
        default: "Error choosing contract, please try again",
      })(error);
    });

export const setCartCountry = (customerId: string, countryId?: string): Promise<ShoppingCart> =>
  postJson(`/shopping-cart/select-country?${queryString.stringify({ customerId })}`, { countryId })
    .then(({ cart }: { cart: ShoppingCart }) => cart)
    .catch((error: any) => {
      throw getFetchErrorTransform({
        default: "Error choosing country, please try again",
      })(error);
    });

export const setCartSite = (customerId: string, site?: Site): Promise<ShoppingCart> =>
  postJson(`/shopping-cart/select-site?${queryString.stringify({ customerId })}`, { site })
    .then(({ cart }: { cart: ShoppingCart }) => cart)
    .catch((error: any) => {
      throw getFetchErrorTransform({
        default: "Error choosing site, please try again",
      })(error);
    });

export const getCart = (customerId: string): Promise<ShoppingCart> =>
  getJson(`/shopping-cart/get-cart?${queryString.stringify({ customerId })}`)
    .then(({ cart }: { cart: ShoppingCart }) => cart)
    .catch((error: any) => {
      throw getFetchErrorTransform({
        default: "Error fetching cart, please try again",
      })(error);
    });

export const updateHPFSAndBigDealNumbers = (
  customerId: string,
  hpfsNumber: string,
  bigDealNumber: string
): Promise<ShoppingCart> =>
  postJson(`/shopping-cart/cart-shipping-info?${queryString.stringify({ customerId })}`, { hpfsNumber, bigDealNumber })
    .then(({ cart }: { cart: ShoppingCart }) => cart)
    .catch((error: any) => {
      throw getFetchErrorTransform({
        default: "Error updating, please try again",
      })(error);
    });

export const updateShippingInfo = (customerId: string, shippingInfo: ShippingInfo): Promise<ShoppingCart> =>
  postJson(`/shopping-cart/cart-shipping-info?${queryString.stringify({ customerId })}`, { shippingInfo })
    .then(({ cart }: { cart: ShoppingCart }) => cart)
    .catch((error: any) => {
      throw getFetchErrorTransform({
        default: "Error updating, please try again",
      })(error);
    });

export interface PlaceOrderSCResponse {
  order: Order;
  coolDown: Date;
}

export const placeOrder = (customerId: string): Promise<PlaceOrderSCResponse> =>
  postJson(`/shopping-cart/place-order?${queryString.stringify({ customerId })}`, {})
    .then((result) => {
      result.coolDown = new Date(result.coolDown);
      return result;
    });

export const clearShoppingCart = (customerId: string): Promise<any> =>
  deleteRequest(`/shopping-cart/clear-cart?${queryString.stringify({ customerId })}`);
