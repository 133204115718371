export const TOAST_REGEX = /^toast\//;
export const TOAST_ADD = "toast/add";
export const TOAST_REMOVE = "toast/remove";

export enum ToastType {
  INFO = "informative",
  ERROR = "negative",
  SUCCESS = "positive",
}

export interface ToastMessage {
  id: number;
  toastType: ToastType;
  message: string;
}

export interface ToastAddParams {
  type: typeof TOAST_ADD;
  toastType: ToastType;
  message: string;
}

export interface ToastRemoveParams {
  type: typeof TOAST_REMOVE;
  id: number;
}

export type ToastTypes = ToastAddParams | ToastRemoveParams;

export const addToast = (toastType: ToastType, message: string): ToastTypes => ({
  type: TOAST_ADD,
  toastType,
  message,
});

export const removeToast = (id: number): ToastTypes => ({ type: TOAST_REMOVE, id });
