import React, { useState, useEffect } from "react";
import { SideMenu, SideMenuItem, Icon } from "@veneer/core";
import { withRouter, RouteComponentProps } from "react-router";

import { useAuthIsLoading, useAuthIsLoggedIn, usePermissions } from "redux/auth-hooks";
import logo from "./hp_logo.png";
import { NavItem } from "types/nav-item";
import UserFeedback from "components/user-feedback";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useCurrentCustomer } from "redux/current-customer-hooks";
import useMenuList, { canUseMenuItem } from "../../pages/routes";
import { setIsLoading } from "redux/current-customer-actions";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { RootActionTypes } from "redux/root-action";
import WarningLeavingPage from "components/page-leaving-warning-popup";
import useLeaveWarning from "contexts/leaveWarning-context";
import { useAllFeatures } from "../../hooks/use-all-features";
import useLDFlag from "../../hooks/use-ld-flag";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const UserFeedbackButton = styled.div`
  display: flex;
  width: 260px;
  color: ${props => props.theme.colors.white};
  padding: 20px;
  cursor: pointer;

  > h6 {
    margin-left: 10px;
    margin-top: 5px;
  }
`;

const PrimaryNav = ({ history, location }: RouteComponentProps): JSX.Element => {
  const navData = useMenuList();
  const userPermissions = usePermissions();
  const isLoggedIn = useAuthIsLoggedIn();
  const loading = useAuthIsLoading();
  const features = useAllFeatures();
  const [showUserFeedback, setShowUserFeedback] = useState<any>();
  const [warningPopup, setWarningPopup] = useState(false);
  const [pageChange, setPageChange] = useState(String);
  const { t } = useTranslation();
  const { customer: currentCustomer } = useCurrentCustomer();
  const currentCustomerID = currentCustomer.id;
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();
  const { leaveWarning } = useLeaveWarning();
  const useMedallia = useLDFlag("mssiPsspEnableMedallia20220908");

  const getItemUrl = (item: NavItem) => (
    item.path && item.customerBased
      ? `/${currentCustomerID}${item.path}`
      : item.path
  );

  // override the default veneer behaviour and link into react-router
  const onNavItemClick = (href: string) => (event: MouseEvent) => {
    event.preventDefault();
    setPageChange(href);
    if (leaveWarning) {
      setWarningPopup(true);
    } else {
      history.push(href);
    }
  };

  const selectedPath = `/${location.pathname.split("/")[1]}`;
  const selectedPathCustomer = `/${location.pathname.split("/")[2]}`;

  const checkFeatureFlag = (item: NavItem) => {
    if (!item.featureFlag) {
      return true;
    }

    const [yesFlag, noFlag] = item.featureFlag.split("~");

    if (yesFlag) {
      return !!features[yesFlag];
    }

    if (noFlag) {
      return !features[noFlag];
    }

    return false;
  };

  const items = navData
    .filter((item) => canUseMenuItem(userPermissions, currentCustomer, item))
    .filter((item) => !item.hide)
    .filter((item) => checkFeatureFlag(item))
    .filter((item) => !item.customerBased || !!currentCustomerID)
    .map((item) => (
      <React.Fragment key={item.key}>
        {item.subMenu ? (
          <SideMenuItem label={t(item.label)} icon={item.icon}>
            {item.subMenu
              .filter((subItem) => canUseMenuItem(userPermissions, currentCustomer, subItem))
              .filter((subItem) => !subItem.hide)
              .filter((subItem) => !subItem.featureFlag || !!features[subItem.featureFlag])
              .filter((subItem) => !subItem.customerBased || !!currentCustomerID)
              .map((subItem) => (
                <SideMenuItem
                  label={t(subItem.label)}
                  icon={subItem.icon}
                  url={getItemUrl(subItem)}
                  onClick={subItem.path ? onNavItemClick(getItemUrl(subItem) || "") : () => {}}
                  key={subItem.key}
                  selected={subItem.path === (subItem.customerBased ? selectedPathCustomer : selectedPath)}
                />
              ))}
          </SideMenuItem>
        ) : (
          <SideMenuItem
            label={t(item.label)}
            icon={item.icon}
            url={item.path ? getItemUrl(item) : item.path}
            onClick={item.path ? onNavItemClick(getItemUrl(item) || "") : () => {}}
            selected={item.path === (item.customerBased ? selectedPathCustomer : selectedPath)}
          />
        )}
      </React.Fragment>
    ));

  useEffect(
    () => {
      dispatch(setIsLoading(false));
    },
    [dispatch],
  );

  return (
    <>
      <SideMenu theme="hp" logoImagePath={logo}>
        <Wrapper>
          <div>{!loading && items}</div>
          <div>
            {(process.env.REACT_APP_CONFIG_ENV?.toLowerCase() !== "pro" && (
              <UserFeedbackButton
                onClick={() => {
                  document.cookie = "USE-NEW-APP=1";
                  if (window.location.href === "/") {
                    window.location.reload();
                  } else {
                    window.location.href = "/";
                  }
                }}
              >
                <Icon name="application" size={32} />
                <h6>Use New Site</h6>
              </UserFeedbackButton>
            ))}
            {isLoggedIn && !useMedallia && (
              <UserFeedbackButton onClick={() => setShowUserFeedback(true)}>
                <Icon name="feeling-satisfied" size={32} />
                <h6>{t("feedback.titel")}</h6>
              </UserFeedbackButton>
            )}
          </div>
        </Wrapper>
      </SideMenu>
      <UserFeedback show={showUserFeedback} onClose={() => setShowUserFeedback(false)} />
      <WarningLeavingPage
        values={warningPopup}
        setValue={setWarningPopup}
        pageChange={pageChange}
      />
    </>
  );
};

export default withRouter(PrimaryNav);
