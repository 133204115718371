import { FieldProps, getIn } from "formik";
import { FormField } from "@veneer/core";

import React, { useState } from "react";
import styled from "styled-components";

const StyledTimePicker = styled.div`
  .timePicker {
    width: 100%;
    height: 30px;
    font-family: ${props => props.theme.font.light};
  }
`;

interface TimePickerProps extends FieldProps {
  label?: string;
  required: boolean;
  value: string;
  onChange: (e: string) => void;
}

const TimePicker: React.FC<TimePickerProps> = ({
  label,
  required,
  value,
  onChange,
  form,
  field,
}) => {
  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);
  const hasError = error && touched;
  const [time, setTime] = useState(value);

  const handleChange = (e: any) => {
    setTime(e.target.value);
    onChange(e.target.value);
  };

  return (
    <FormField
      label={label}
      htmlFor={field.name}
      errorMessage={hasError && error}
      required={required}
    >
      <StyledTimePicker>
        <input
          type="time"
          className="timePicker"
          value={time}
          onChange={handleChange}
        />
      </StyledTimePicker>
    </FormField>
  );
};

export default TimePicker;
