import React, { useState } from "react";

interface LeaveWarningContextInterface {
  leaveWarning: boolean;
  updateLeaveWarning(value: boolean): void;
}

// @ts-ignore: Context initializer doesn't play nice with the inteface
const LeaveWarningContext = React.createContext<LeaveWarningContextInterface>({});

export const LeaveWarningProvider: React.FC = props => {
  const [leaveWarning, setLeaveWarning] = useState(false);

  const updateLeaveWarning = (value: boolean) => {
    setLeaveWarning(value);
  };

  const value = {
    leaveWarning,
    updateLeaveWarning,
  };

  return <LeaveWarningContext.Provider value={value} {...props} />;
};

export const useLeaveWarning = (): LeaveWarningContextInterface => {
  const context = React.useContext(LeaveWarningContext) as LeaveWarningContextInterface;

  if (!context) {
    throw new Error("useLeaveWarning must be used within an LeaveWarningProvider");
  }

  return context;
};

export default useLeaveWarning;
