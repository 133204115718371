import React, { useRef } from "react";
import JupiterSatellite from "./jupiter-satellite";
import { useAddError } from "../../redux/toast-hooks";
import { ITSMContactV6 } from "../../types/contact";
import useCustomEvent from "../../hooks/use-custom-event";
import { useTranslation } from "react-i18next";

export interface Props {
  accountId?: string;
  contactId?: string;
  allowCreateOrUpdate: boolean;
  mode?: "button" | "input" | "inputExtended";
  onChange?: (contact?: ITSMContactV6) => void;
}

const JupiterContactSelector: React.FC<Props> = ({
  accountId,
  contactId,
  allowCreateOrUpdate = true,
  mode = "button",
  onChange,
}) => {
  const ref = useRef<HTMLElement>(null);
  const addError = useAddError();
  const { t } = useTranslation();

  useCustomEvent(ref, "contact-selected", (e: CustomEvent) => {
    if (onChange && e.detail) {
      onChange(e.detail.contact as ITSMContactV6 | undefined);
    }
  });

  useCustomEvent(ref, "error", (e: CustomEvent) => {
    if (e.detail) {
      const errorMessage = (typeof e.detail.message === "string" && e.detail.message)
        ? e.detail.message
        : t("error.common");

      addError(errorMessage);
    }
  });

  return (
    <JupiterSatellite
      component="mssi-pssp-contacts"
      version="2.0.0"
      context={{ contactId, accountId, allowCreateOrUpdate, mode }}
      spinnerSize={24}
      satelliteRef={ref}
    />
  );
};

export default JupiterContactSelector;
