import React from "react";
import { Icon, IconButton, Tooltip } from "@veneer/core";
import Panel from "components/panel";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface IconsProps {
  updatedProfilePanel: boolean;
}

const Centered = styled.div`
  text-align: center;
  word-break: break-word;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.p<IconsProps>`
  text-transform: ${props => (props.updatedProfilePanel ? undefined : "uppercase")};
  margin-top: 5px;
`;

const SubTitle = styled.p<IconsProps>`
  margin-top: 5px;
  font-size: ${props => (props.updatedProfilePanel ? "12px" : undefined)};
`;

const Children = styled.div`
  margin: 24px 0;
  & > * {
    margin-top: 24px;
  }
`;

const RedIconButton = styled(IconButton)`
  color: ${(props) => props.theme.colors.red};
  &:hover, &:active, &:focus {
    color: ${(props) => props.theme.colors.red};
  }
`;

const ActionIcons = styled.div`
  display: flex;
  justify-content: center;
`;

interface ProfilePanelProps {
  children: React.ReactNode;
  icon?: string;
  pictureUrl?: string;
  title?: string;
  title2?: string;
  onDelete?(): void;
  onEdit?(): void;
  canEditUser?: boolean;
  updatedProfilePanel?: boolean;
}

const ProfilePanel: React.FC<ProfilePanelProps> = ({ children, icon, pictureUrl, title, title2, onDelete, onEdit, canEditUser = true, updatedProfilePanel = false }) => {
  const { t } = useTranslation();
  return (
    <Panel>
      <Centered>
        <Header>
          {icon && !pictureUrl && <Icon name={icon} color="accent" size={64} />}
          {pictureUrl && <img src={pictureUrl} style={{ maxWidth: "100%" }} alt={title} />}
          {title && <Title updatedProfilePanel={updatedProfilePanel}>{title}</Title>}
          {title2 && <SubTitle data-cy="portalOrder" updatedProfilePanel={updatedProfilePanel}>{title2}</SubTitle>}
        </Header>
        <Children>{children}</Children>
        {(onEdit || onDelete) && <hr />}
      </Centered>
      <ActionIcons>
        {onEdit && canEditUser && (
        <Tooltip position="up" content={t("btn.edit")}>
          <IconButton data-cy="edit" title={t("btn.edit")} icon="edit" onClick={onEdit} />
        </Tooltip>
          )}
        {onDelete && canEditUser && (
        <Tooltip position="up" content={t("btn.delete")}>
          <RedIconButton data-cy="delete" title={t("delete")} icon="delete" onClick={onDelete} />
        </Tooltip>
          )}
      </ActionIcons>
    </Panel>
  );
};

export default ProfilePanel;
