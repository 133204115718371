const welcomeData = {
  "welcome.banner.description":
    "​And we provide the purchasing flexibility you need to make the right decisions quickly and implement the right solutions",
  "welcome.banner.title":
    "HP Services can lighten the load on IT by making sure that ​employees get the right technology and stay productive; IT teams get data and insights to more easily manage device infrastructure and minimize problems before they happen; and all employees are secure - both near and far. ",
  "welcome.copyright": "© Copyright 2019 HP Development Company, L.P.",
  "welcome.daas": "HP PS Services Portal",
  "welcome.daas.presence.devices": "3.6M DEVICES",
  "welcome.daas.presence.terms":
    "1. HP PS Services plans and or included components may vary by region or by Authorized HP PS Services Service Partner. Please contact your local HP Representative or Authorized PS Services Partner for specific details in your location. HP services are governed by the applicable HP terms and conditions of service provided or indicated to Customer at the time of purchase. Customer may have additional statutory rights according to applicable local laws, and such rights are not in any way affected by the HP terms and conditions of service or the HP Limited Warranty provided with your HP Product.",
  "welcome.daas.presence.terms2":
    "​2. This service is available through an Early Adopter Program; please contact your HP representative for more information.​© Copyright 2020 HP Development Company, L.P. The information contained herein is subject to change without notice. The only warranties for HP products and services are set forth in the express warranty statements accompanying such products and services. Nothing herein should be construed as constituting an additional warranty. HP shall not be liable for technical or editorial errors or omissions contained herein.",
  "welcome.daas.presence.title": "Worldwide HP PS Services Presence",
  "welcome.keys.tile1": "Delivering positive outcomes and experiences for customers",
  "welcome.keys.tile2": "Unlocking more value and opportunity for partners",
  "welcome.keys.tile3": "Scaling the capabilities and capacity of our global PS Services offers",
  "welcome.keys.title":
    "2019 will be a notable year as we focus on three key areas of innovation in HP PS Services:",
  "welcome.privacy": "Privacy",
  "welcome.signin": "SIGN IN",
  "welcome.signin.customerIn": "Customer Sign In",
  "welcome.signin.employeeIn": "Employee Sign In",
  "welcome.signin.customer": "Customer",
  "welcome.signin.employee": "Employee",
  "welcome.whats_new.line1":
    "Thanks to you, HP Device as a Service continues to gain momentum. We’re pleased to be adding security capabilities, enhancing service management options, and communicating outcomes and value more clearly to customers and partners. ",
  "welcome.whats_new.line2":
    "As security remains a critical concern for everyone, we recently introduced a new Proactive Security service that makes endpoints more secure, guards against malware, and works with the HP PS Services platform to provide security insights and reports.",
  "welcome.whats_new.line3":
    "HP PS Services has improved our ability to complement our customers’ and partners’ services efforts by integrating more deeply with IT Service Management platforms. With this integration, the capabilities of HP PS Services work with the practices and processes that our stakeholders have established, creating more opportunities to help solve critical issues. ",
  "welcome.whats_new.line4":
    "To make the value of HP PS Services more apparent and help you understand how our joint efforts can and do improve results, HP PS Services and our Value Management Office have extended the depth and reach of the reports and advice we offer. We want you to clearly understand how and where we are making your job easier.",
  "welcome.whats_new.line5": " Stay tuned, as we’re just getting started!",
  "welcome.whats_new.title": "What's New",
  "updated.text": "Learn More",
  "welcome.privacypolicy": "Privacy Policy",
};

export { welcomeData as default };
