import React from "react";
import { Spinner } from "@veneer/core";
import styled from "styled-components";


interface Props {
  padding?: string;
}

const Container = styled.div<Props>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.padding || "0"} 0;
`;

/**
 * A big centered spinner for dialogs and such
 */
const BlockSpinner: React.FC<Props> = ({ padding }) => (
  <Container padding={padding}>
    <Spinner size={64} />
  </Container>
);

export default BlockSpinner;
