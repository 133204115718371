export enum CaseRequestStatus {
  IN_PROGESS = "IN PROGRESS",
  ON_HOLD = "ON HOLD",
  OPEN = "OPEN",
  NEW = "NEW",
  CLOSED = "CLOSED",
  RESOLVED = "RESOLVED",
  DISPATCHED = "DISPATCHED",
  DISPATCH_REQUESTED = "DISPATCH REQUESTED",
  AWAITING_INFO = "AWAITING INFO",
  PENDING_VENDER = "PENDING VENDER",
  PENDING_VENDOR = "PENDING VENDOR",
  PENDING_DEVELOPMENT = "PENDING DEVELOPMENT",
  PENDING_CUSTOMER = "PENDING CUSTOMER",
  WORK_IN_PROGRESS = "WORK IN PROGRESS"
}

export enum ServiceRequestStatus {
  OPEN = "OPEN",
  CLOSED_SKIPPED = "CLOSED SKIPPED",
  CLOSED_COMPLETE = "CLOSED COMPLETE",
  WORK_IN_PROGRESS = "WORK IN PROGRESS"
}

export enum CaseRequestEnum {
  HP_USER = "HP User",
  INTERNAL = "Internal",
  PORTAL = "portal",
  CONTRACT = "contract",
  HARDWARE_FALLBACK = "hardwareFallback",
  ORDER = "hardwareOrderSupport",
  ME = "Me",
  SOMEONE_ELSE = "SomeoneElse"
}
