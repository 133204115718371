import Bloodhound from "corejs-typeahead/dist/bloodhound";

import { MakeBloodhoundParams } from "./types";
import { getCachedRequestHeaders } from "../../../api/oidc-user-manager";

export const TYPEAHEAD_QUERY_STR = "{SEARCH}";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const makeRemote = <T extends any>(remoteUrl?: string): Bloodhound.RemoteOptions<T> | undefined => {
  if (!remoteUrl) {
    return undefined;
  }

  return {
    url: remoteUrl,
    prepare(query: string, settings: any) {
      settings.headers = {
        ...settings.headers,
        ...getCachedRequestHeaders(), // warning: fails if id token is expired!
      };

      settings.url = `${API_ENDPOINT}${settings.url}`.replace(TYPEAHEAD_QUERY_STR, encodeURIComponent(query));

      return settings;
    },
  };
};

export const makeBloodhound = <T extends any>(params: MakeBloodhoundParams<T>): Bloodhound<T> => {
  const { remoteUrl, localData, filterKeysJSON } = params;

  const filterKeys = JSON.parse(filterKeysJSON) as (keyof T)[];

  const queryTokenizer = Bloodhound.tokenizers.whitespace;

  const datumTokenizer = Bloodhound.tokenizers.obj.whitespace(filterKeys as string[]);

  return new Bloodhound<T>({
    remote: makeRemote(remoteUrl),
    local: localData,
    datumTokenizer,
    queryTokenizer,
  });
};
