import React from "react";
import Button from "components/button";
import { caseRequestPreviousStep } from "../../../redux/case-request-actions";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { RootActionTypes } from "../../../redux/root-action";
import { useTranslation } from "react-i18next";
import { useCaseRequestCurrentStep } from "../../../redux/case-request-hooks";

interface Props {
  onReverse?: () => boolean | void;
}

const CaseRequestModalReverseButton: React.FC<Props> = ({ onReverse }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();
  const currentStep = useCaseRequestCurrentStep();

  if (currentStep <= 0) {
    return null;
  }

  const doReverse = () => {
    if (onReverse) {
      onReverse();
    }
    dispatch(caseRequestPreviousStep());
  };

  return (
    <Button
      appearance="secondary"
      onClick={() => doReverse()}
    >
      {t("btn.goback")}
    </Button>
  );
};

export default CaseRequestModalReverseButton;
