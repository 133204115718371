import React, { useEffect, useState } from "react";
import { getIn, FieldProps } from "formik";
import { FormField } from "@veneer/core";
import ReCAPTCHA from "react-google-recaptcha";
import { useConfig } from "../../client-config";

const Captcha: React.FC<FieldProps> = ({ form, field }) => {
  const [ref, setRef] = useState<ReCAPTCHA | null>(null);
  const captchaKey = useConfig("recaptchaPublicKey");
  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);
  const hasError = error && touched;

  useEffect(
    () => {
      if (ref && ref.getValue() && !field.value) {
        ref.reset();
      }
    },
    [ref, field.value],
  );

  const onChange = (value: string | null) => {
    form.setFieldValue(field.name, value);
  };

  const setRefWrapper = (element: ReCAPTCHA | null) => setRef(element);

  return (
    <FormField errorMessage={hasError && error}>
      <ReCAPTCHA ref={setRefWrapper} sitekey={captchaKey} onChange={onChange} />
    </FormField>
  );
};

export default Captcha;
