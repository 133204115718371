import { IconButton, Icon } from "@veneer/core";
import React, { useState } from "react";
import { deleteMessage, addCalendarEvent } from "api/customer-notice-board";
import ExpiryDateMessageModal from "./expirydate-message-model";
import DetailedMessageModal from "./detailed-message-model";
import deviceType from "shared/devices";
import useIsDevice from "shared/isDevice";
import LinkDescription, { MessageLink } from "./link-description";
import NoticeBoard from "types/notice-board";
import dates from "../../../shared/dates";
import { permissions } from "@mssi/pssp-shared";
import styled from "styled-components";
import { useAuth } from "redux/auth-hooks";
import { useTranslation } from "react-i18next";
import { useAddError, useAddSuccess } from "redux/toast-hooks";
import DeleteConfirmationModal from "components/modals/delete/delete-confirmation-modal";
import MessageAttachment from "./message-attachment";
import Button from "components/button";
import EventDetailedModal from "./event-detailed-modal";
import download, { FileType } from "shared/download";
import { formatTime } from "shared/format-time";
import NoticeBoardModalSDM from "./notice-board-modal-sdm";

interface Props {
  message: NoticeBoard;
  setRefresher?: any;
}

const Info = styled.button`
  color: ${props => props.theme.colors.blue};
  font-size: 13px;
  font-family: ${props => props.theme.font.light};
  &:hover {
    cursor: pointer;
  }
`;

const ActionList = styled.div`
  justify-content: flex-end;
  display: flex; 
  margin-top: -6px;
  .clock {
    color: ${props => props.theme.colors.body};
}
  .vn-button {
    padding-right: 10px;
    padding-inline: 0;
  @media only screen and (max-width: 1440px) {
      .vn-icon {
        height: 20px;
        width: 20px;
      }
    }
  }
`;

const DatePanel = styled.div`
  display: flex; 
  flex-direction: row;
  justify-content: space-between;
`;

const DateTime = styled.div`
  font-size: 12px;
  padding-bottom: 2px;
`;

const MainMessage = styled.div`
  width: 80%;
  margin-bottom: 10px;
  font-weight: bold;
  word-break: break-word;
  margin-top: 6px;
  margin: 6px 0 16px 0;
  
`;

const ButtonWrapper = styled.div`
  .vn-button {
    width: 100%;
    text-transform: capitalize;
    margin-top: 10px;
  }
`;

const Component = styled.div`
  display: flex; 
  flex-direction: row;
  justify-content: space-between;
`;

const CalenderIcon = styled.div`
  .vn-icon {
    width: 75px;
    height: 75px;
  }
  justify-content: space-between;
  position: absolute;
  top: 20px;
  right: 0px;
`;

const DateTimeWrapper = styled.div`
  margin: auto 0px;
  p {
    margin-top: 10px;
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const LocationWrapper = styled.div`
  display: flex;
`;

const MessageLinkWrapper = styled.div`
  word-break: break-all;
  padding-top: 0px !important;
  margin-left: -12px;
`;

const MessageTile: React.FC<Props> = ({ message, setRefresher }) => {
  const { t } = useTranslation();
  const addError = useAddError();
  const { authorizedFor } = useAuth();
  const addSuccess = useAddSuccess();
  const [editNoticeBoardModal, setEditNoticeBoardModal] = useState<boolean>(false);
  const [showDetailedMessageModel, setShowDetailedMessageModel] = useState<boolean>(false);
  const [showEventDetailedModal, setShowEventDetailedModal] = useState<boolean>(false);
  const [expireDateMessageModal, showExpireDateMessageModal] = useState<boolean>(false);
  const isMobileTablet = useIsDevice(deviceType.MOBILE, deviceType.MOBILELARGE, deviceType.TABLET);
  const canManageNoticeBoard = authorizedFor([permissions.canManageNoticeBoard]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const [deletingMessage, setDeletingMessage] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = () => {
    if (message.isEvent) {
      setShowEventDetailedModal(true);
    } else {
      setShowDetailedMessageModel(true);
    }
  };

  const item = {
    description: message.eventLocation,
    link: message.eventLocation,
  };

  const handleCalendarEvent = (message: NoticeBoard) => {
    setLoading(true);
    const offsetTime = new Date().getTimezoneOffset();
    addCalendarEvent(message._id, offsetTime)
      .then((event: any) => {
        download.file(event.invite, `invite-${message.mainMessage}.ics`, FileType.ICS);
        setLoading(false);
      })
      .catch((error: any) => {
        addError(error.message);
        setLoading(false);
      });
  };

  const deleteMessageInfo = async (message: NoticeBoard) => {
    setDeletingMessage(true);
    try {
      deleteMessage(message._id).then(() => {
        setRefresher(false);
        setDeletingMessage(false);
        setShowDeleteConfirm(false);
        addSuccess(t("successfully.deleted"));
        setRefresher(true);
      });
    } catch (error: any) {
      setDeletingMessage(false);
      addError(error.message);
    }
  };
  return (
    <>
      <Wrapper>
        <DatePanel>
          <div>
            <DateTime>{t("posted")} {dates.format(message.createdDate ? message.createdDate : "", dates.formats.stringFormat)}
              {t(" ")}{t("at")}{t(" ")}
              {dates.format(message.createdDate ? message.createdDate : "", dates.formats.timeOnly)}
            </DateTime>
            {message.expiryDate &&
              <DateTime>{t("expires")} {dates.format(message.expiryDate ? message.expiryDate : "", dates.formats.stringFormat)}
              </DateTime>}
          </div>
          {canManageNoticeBoard && (
            <ActionList>
              <IconButton data-cy="edit" icon="edit" title={t("contract.edit")} onClick={() => setEditNoticeBoardModal(true)} />
              <IconButton data-cy="delete" icon="delete" title={t("delete")} onClick={() => setShowDeleteConfirm(true)} />
            </ActionList>
          )}
        </DatePanel>
        <MainMessage>{message.mainMessage}</MainMessage>
        {message.isEvent &&
          <Component>
            <DateTimeWrapper>
              {message.eventStartDate &&
                <DateTime>{t("start")}: {dates.format(message.eventStartDate ? message.eventStartDate : "", dates.formats.stringFormat)}
                  { !message.allDayEvent && message.eventStartTime && ` ${t("at")} ${formatTime(message.eventStartTime)}`}
                </DateTime>}
              {message.eventEndDate &&
                <DateTime>{t("end")}: {dates.format(message.eventEndDate ? message.eventEndDate : "", dates.formats.stringFormat)}
                  {!message.allDayEvent && message.eventEndTime && ` ${t("at")} ${formatTime(message.eventEndTime)}`}
                </DateTime>}
              {message.eventLocation && (
                <LocationWrapper>
                  <p>{t("devicesdetail.location")}:</p>{" "}
                  <MessageLinkWrapper>
                    <MessageLink item={item} />
                  </MessageLinkWrapper>
                </LocationWrapper>
              )}
            </DateTimeWrapper>
            <CalenderIcon>
              <Icon name="calendar" />
            </CalenderIcon>
          </Component>}
        <Info data-cy="readmore" onClick={handleClick}>{t("viewmoredetails")}</Info>
        {!!message.messageLinks?.length && <LinkDescription messageLinks={message.messageLinks} />}
        <MessageAttachment message={message} />
        {message.isEvent &&
          <ButtonWrapper>
            <Button loading={loading} onClick={() => handleCalendarEvent(message)}>{t("add.calendar", { postProcess: "upper" })}</Button>
          </ButtonWrapper>}
      </Wrapper>
      <EventDetailedModal message={message} onClose={() => setShowEventDetailedModal(false)} show={showEventDetailedModal} />
      <DetailedMessageModal message={message} onClose={() => setShowDetailedMessageModel(false)} show={showDetailedMessageModel} />
      <NoticeBoardModalSDM initialValues={message} onClose={() => setEditNoticeBoardModal(false)} show={editNoticeBoardModal} event={!!message.isEvent} setRefresher={setRefresher} />
      <DeleteConfirmationModal
        show={showDeleteConfirm}
        onClose={() => { setShowDeleteConfirm(false); }}
        onConfirm={() => deleteMessageInfo(message)}
        loading={deletingMessage}
        header={t("noticeBoard.deleteMessage")}
        title={t("noticeBoard.deleteConfirmation")}
        iconName="incidents"
        message={t("role.deleteMessage")}
      />
      {isMobileTablet &&
        <ExpiryDateMessageModal message={message} onClose={() => showExpireDateMessageModal(false)} show={expireDateMessageModal} />}
    </>
  );
};


export default MessageTile;
