import React, { useMemo } from "react";
import useOrderDetail from "../../../contexts/order-details-context";
import dates from "../../../shared/dates";
import styled from "styled-components";
import { OrderStatus, ShipmentGroup, ShipmentGroupProduct } from "../../../types/order";
import Button from "components/button";
import ShipmentGroupPanel from "./shipment-group-panel";
import { useTranslation } from "react-i18next";
import { GuestOrderStatus } from "../../order-status/types/guest-order-status";

const Container = styled.div`
  margin: 16px;
  border: 1px solid ${(props) => props.theme.colors.border};

  hr {
    margin: 10px 0 16px 0;
    border-bottom: none;
  }
`;

const Header = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  @media ${(props) => props.theme.breakpoints.tablet} {
    flex-direction: row;
    div {
      text-align: center;
      width: 33%;
      &:first-child {
        text-align: left;
      }
      &:last-child {
        text-align: right;
      }
    }
  }
`;

const Clearfix = styled.div`
  clear: both;
`;

const TrackingInfoContainer = styled.div`
  @media ${(props) => props.theme.breakpoints.tablet} {
    float: left;
  }

  > * {
    display: block;
    margin-top: 8px;
  }
`;

const DatesTable = styled.table.attrs(() => ({ border: 0, cellpadding: 0, cellspacing: 0 }))`
  @media ${(props) => props.theme.breakpoints.tablet} {
    float: right;
  }

  > tbody > tr > td {
    border: none; /*1px solid black;*/
    padding: 10px;
  }

  > tbody > tr > td:first-child {
    background-color: ${(props) => props.theme.colors.background};
  }

  > tbody > tr > td + td {
    background-color: ${(props) => props.theme.colors.background5};
  }
  
  th {
    text-align: left;
  }
`;

const DatesTableInner = styled.table.attrs(() => ({ border: 0, cellpadding: 0, cellspacing: 0 }))`
  td {
    text-align: right;
  }

  td + td {
    width: 100px;
    padding-left: 10px;
    text-align: left;
  }
`;

const ProductTable = styled.table.attrs(() => ({ border: 0, cellpadding: 0, cellspacing: 0 }))`
  > tbody > tr > td:first-child {
    @media ${(props) => props.theme.breakpoints.mobileMaximum} {
      width: 75%
    }

    @media ${(props) => props.theme.breakpoints.tablet} {
      width: 40%
    }
  }
  
  .odd, .odd td {
    background-color: ${(props) => props.theme.colors.bgV5};
  }

  b {
    font-size: 1.25em;
  }

  td {
    padding: 1.25em 1em;
  }

  width: 100%;
  margin: 8px 0;
`;

const ProductTableHeader = styled.span`
  display: block;
  padding-bottom: 4px;
  color: ${(props) => props.theme.colors.textColor5};
  text-transform: uppercase;
`;

const ProductTableSub = styled.span`
  display: block;
  color: ${(props) => props.theme.colors.textColor5};
`;

const shippingGroupSortOrder: {[key: string]: number} = {
  [OrderStatus.DELIVERED]: 1,
  [OrderStatus.SHIPPED]: 5,
  [OrderStatus.ACCEPTED]: 10,
  [OrderStatus.APPROVED]: 15,
  [OrderStatus.CANCELLED]: 20,
};

interface ShipmentGroupParams {
  group: ShipmentGroup;
  index?: number;
}

interface ShipmentGroupProductRowParams {
  product: ShipmentGroupProduct;
  index?: number;
}

export const shippingGroupSort = (a: ShipmentGroup, b: ShipmentGroup): number =>
  (shippingGroupSortOrder[a.status.toUpperCase()] || 25) - (shippingGroupSortOrder[b.status.toUpperCase()] || 25);

export const TrackingInfo: React.FC<ShipmentGroupParams> = ({ group }) => {
  const { trackingNumber, trackAndTraceLink } = group;
  const { t } = useTranslation();

  const realTrackingNumber = trackingNumber === "unknown" ? "" : trackingNumber;

  const linkClicked = () => {
    window.open(trackAndTraceLink, "_blank", "noopener,noreferrer");
  };

  if (!realTrackingNumber && !trackAndTraceLink) {
    return null;
  }

  return (
    <TrackingInfoContainer>
      {realTrackingNumber && <>{t("orderstatus.tracking.trackingNumber")}: {realTrackingNumber}</>}
      {trackAndTraceLink && <Button onClick={linkClicked}>{t("orderstatus.tracking.trackShipment")}</Button>}
    </TrackingInfoContainer>
  );
};

export const ShipmentGroupProductRow: React.FC<ShipmentGroupProductRowParams> = ({ product, index = 0 }) => {
  const { order } = useOrderDetail(false);
  const { t } = useTranslation();

  const localizationCode = (order?.itsmOrderItems || [])
    .find((it) => it.correlationId === product.correlationId)
    ?.localizationCode
    ?? "";

  const productDescription = useMemo(
    () => {
      if (product.productDescription === "Unassigned" || !product.productDescription) {
        return t("orderstatus.product.unassigned");
      }

      return product.productDescription;
    },
    [product.productDescription, t],
  );

  const productNumber = useMemo(
    () => {
      if (product.productNumber === "0000" || !product.productNumber) {
        return t("orderstatus.product.sku0000");
      }

      if (localizationCode) {
        return `${product.productNumber}#${localizationCode}`;
      }

      return product.productNumber;
    },
    [product.productNumber, localizationCode, t],
  );

  return (
    <tr className={index % 2 ? "odd" : "even"}>
      <td>
        <ProductTableHeader>{t("orderstatus.table.productItem")}</ProductTableHeader>
        <b>{productDescription}</b>
        <ProductTableSub>
          {t("orderstatus.table.productNumber")}: {productNumber}
        </ProductTableSub>
      </td>
      <td>
        <ProductTableHeader>QTY</ProductTableHeader>
        <b>{product.quantity}</b>
      </td>
    </tr>
  );
};

export const ShipmentGroupRow: React.FC<ShipmentGroupParams> = ({ group, index }) => {
  const { t } = useTranslation();

  return (
    <ShipmentGroupPanel group={group} index={index}>
      <ProductTable>
        <tbody>
          {(group.products || []).map((product, index) => (
            <ShipmentGroupProductRow product={product} key={product.lineItemNumber} index={index} />
          ))}
        </tbody>
      </ProductTable>
      <Clearfix />
      <hr />
      <TrackingInfo group={group} />
      <DatesTable>
        <tbody>
          <tr>
            <th>{t("orderstatus.table.plannedShipDateHeader")}</th>
            <th>{t("orderstatus.table.actualShipDateHeader")}</th>
          </tr>
          <tr>
            <td>
              <DatesTableInner>
                <tbody>
                  <tr>
                    <td>{t("orders.table.ship")}:</td>
                    <td>{group.plannedShipDate ? dates.formatUTC(group.plannedShipDate, "YYYY-MM-DD") : "--"}</td>
                  </tr>
                  <tr>
                    <td>{t("orders.table.delivery")}:</td>
                    <td>{group.plannedDeliveryDate ? dates.formatUTC(group.plannedDeliveryDate, "YYYY-MM-DD") : "--"}</td>
                  </tr>
                </tbody>
              </DatesTableInner>
            </td>
            <td>
              <DatesTableInner>
                <tbody>
                  <tr>
                    <td>{t("orders.table.ship")}:</td>
                    <td>{group.shipDate ? dates.formatUTC(group.shipDate, "YYYY-MM-DD") : "--"}</td>
                  </tr>
                  <tr>
                    <td>{t("orders.table.delivery")}:</td>
                    <td>{group.deliveryDate ? dates.formatUTC(group.deliveryDate, "YYYY-MM-DD") : "--"}</td>
                  </tr>
                </tbody>
              </DatesTableInner>
            </td>
          </tr>
        </tbody>
      </DatesTable>
      <Clearfix />
    </ShipmentGroupPanel>
  );
};

export const ShipmentGroupsTable: React.FC = () => {
  const { order } = useOrderDetail();
  const { t } = useTranslation();

  const groups = useMemo(
    () => [...(order.shipmentGroups || [])].sort(shippingGroupSort),
    [order.shipmentGroups],
  );

  const updatedGroups = useMemo(
    () => {
      const nonCancelledGroups = groups.filter((group) => !/cancel/i.test(group.status));
      return nonCancelledGroups.length ? nonCancelledGroups : groups;
     },
    [groups],
  );

  const orderDate = useMemo(
    () => {
      const date = order.orderStatus.statusDateTime || order.orderOriginationDate;

      return date ? dates.format(date) : "";
    },
    [order],
  );

  const orderQuantity = useMemo(
    () => {
      if (order.shipmentGroups && order.shipmentGroups.length > 0) {
        return order.shipmentGroups
          .filter((it) => !/cancel/i.test(it.status))
          .map((sg) => sg.products)
          .flat()
          .map((product) => +product.quantity)
          .reduce((a, b) => a + b, 0);
      }

      if (order.itsmOrderItems?.length) {
        return order.itsmOrderItems.map((item) => +item.quantity).reduce((a, b) => a + b, 0);
      }

      if (order.items?.length) {
        return order.items.map((item) => +item.quantity).reduce((a, b) => a + b, 0);
      }

      return 0;
    },
    [order],
  );

  return (
    <Container>
      <Header>
        <div><b>{t("orderdetails.date")}:</b> {orderDate}</div>
        <div><b>{t("orderstatus.panel.title")}:</b> {orderQuantity}</div>
        <div><b>{t("order.number")}</b> {order.singleOrderNumber}</div>
      </Header>
      {updatedGroups.map((group, index) => (
        <ShipmentGroupRow group={group} key={group.groupId} index={index} />
      ))}
    </Container>
  );
};

interface GuestTableProps {
  order: GuestOrderStatus;
}

export const GuestOrderItemsTable: React.FC<GuestTableProps> = ({ order }) => {
  const groups: ShipmentGroup[] = useMemo(
    () => order.items
      .map((item): ShipmentGroup => ({
        groupId: item.groupId,
        status: item.status as OrderStatus,
        trackingNumber: item.trackingNumber || "",
        trackAndTraceLink: item.trackAndTraceLink || "",
        plannedShipDate: item.plannedShipDate || "",
        plannedDeliveryDate: item.plannedDeliveryDate || "",
        shipDate: item.shipDate || "",
        deliveryDate: item.deliveryDate || "",
        products: item.products.map((product): ShipmentGroupProduct => ({
          correlationId: "",
          lineItemNumber: "",
          productDescription: product.productDescription,
          productNumber: product.productNumber,
          quantity: `${product.quantity || ""}`,
          serialNumberList: [],
          serialNumbers: [],
        })),
      }))
      .sort(shippingGroupSort),
    [order.items],
  );

  return (
    <Container>
      {groups.map((group, index) => (
        <ShipmentGroupRow group={group} key={group.groupId} index={index} />
      ))}
    </Container>
  );
};

export default ShipmentGroupsTable;
