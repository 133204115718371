import React, { ReactNode, CSSProperties } from "react";
import { Button as VeneerButton } from "@veneer/core";

interface Props {
  appearance?: "secondary" | "critical";
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  expanded?: boolean;
  loading?: boolean;
  name?: string;
  onClick?: (event: any) => void;
  type?: "submit" | "reset" | "button";
  style?: CSSProperties;
}

const Button = (props: Props) => {
  const { children, ...rest } = props;

  return <VeneerButton {...rest}>{children}</VeneerButton>;
};

export default Button;
