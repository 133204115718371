import { ThunkAction } from "redux-thunk";
import { getMe, putAllPreferences, putPreference, putEmailRegionsPreference } from "../api/me";
import { AuthTypes, patchAuthPreferences, setAuthLoading, setAuthLoginError, setAuthMe } from "./auth-actions";
import { RootState } from "redux/redux-state";

export type AuthThunkAction = ThunkAction<void, RootState, void, AuthTypes>;

export const asyncGetMe = (setLoading = true): AuthThunkAction => async (dispatch) => {
  if (setLoading) {
    dispatch(setAuthLoading());
  }

  const me = await getMe()
    .catch((error) => {
      dispatch(setAuthLoading(false));

      if (error.json?.reason) {
        dispatch(setAuthLoginError(error.json.reason, error.json.message, error.json.info));
      }
    });

  if (me) {
    dispatch(setAuthMe(me));
  }
};

export const asyncUpdatePreference = (preference: string, value: any): AuthThunkAction => async (dispatch) => {
  dispatch(patchAuthPreferences({ [preference]: value }));

  if (preference === "emailRegions") {
    await putEmailRegionsPreference(value);
  } else {
    await putPreference(preference, value);
  }
};

export const asyncUpdatePreferences = (preferences: {[key: string]: any}): AuthThunkAction => async (dispatch) => {
  dispatch(patchAuthPreferences(preferences));

  const { emailRegions, ...rest } = preferences;

  await putAllPreferences(rest);

  if (emailRegions) {
    await putEmailRegionsPreference(emailRegions);
  }

  // is this necessary?
  window.location.reload();
};
