import React from "react";
import Sidebar from "components/layouts/sidebar";
import FlexContainer from "components/layouts/flex-container";
import ProfilePanel from "components/profile-panel";
import { useAuth } from "redux/auth-hooks";
import { useTranslation } from "react-i18next";
import ProfilePreferences from "../components/profile-preference/profile-preferences";
import ProfilePanelLabel from "components/profile-panel-label";
import ProfilePanelData from "components/profile-panel-data";
import styled from "styled-components";
import { useCurrentCustomer } from "redux/current-customer-hooks";
import getConfig from "client-config";

interface ListItemProps {
  shaded?: boolean;
}

const ProfileRow = styled.div`
  display: flex;
`;

const ListItem = styled.li<ListItemProps>`
  padding: 10px;
  background-color: ${(props) => (props.shaded ? `${props.theme.colors.bgV1}` : "")};
`;

const ProfileView: React.FC = () => {
  const { currentUser } = useAuth();
  const { firstName, lastName, email, persona } = currentUser;
  const { t } = useTranslation();
  const { customer: contextCustomer } = useCurrentCustomer();
  const editProfileEmployee = getConfig("editProfileEmployee");
  const editProfileNonEmployee = getConfig("editProfileNonEmployee");

  const onUserEdit = () => {
    if (email.includes("@hp.com")) {
      window.open(editProfileEmployee, "_blank", "noopener,noreferrer");
    } else {
      window.open(editProfileNonEmployee, "_blank", "noopener,noreferrer");
    }
  };

  const customerName = contextCustomer.itsmCustomerName || contextCustomer.name;

  return (
    <FlexContainer adjustSize={true}>
      <Sidebar adjustSize={false}>
        <ProfilePanel
          icon="profile"
          title={`${firstName} ${lastName}`}
          title2={`${persona && persona.name}`}
          updatedProfilePanel={true}
          onEdit={onUserEdit}
        >
          <ul data-cy="profile-info__list">
            <ListItem shaded>
              <ProfileRow>
                <ProfilePanelLabel>{t("firstname")}</ProfilePanelLabel>
                <ProfilePanelData>{firstName}</ProfilePanelData>
              </ProfileRow>
            </ListItem>
            <ListItem>
              <ProfileRow>
                <ProfilePanelLabel>{t("lastname")}</ProfilePanelLabel>
                <ProfilePanelData>{lastName}</ProfilePanelData>
              </ProfileRow>
            </ListItem>
            <ListItem shaded>
              <ProfileRow>
                <ProfilePanelLabel>{t("field.email")}</ProfilePanelLabel>
                <ProfilePanelData>
                  {<a href={`mailto:${email}`}>{email}</a>}
                </ProfilePanelData>
              </ProfileRow>
            </ListItem>
            <ListItem>
              <ProfileRow>
                <ProfilePanelLabel>{t("role")}</ProfilePanelLabel>
                <ProfilePanelData>
                  {persona?.isPartner && t("partner")}
                  {persona?.isInternal && t("internal")}
                  {persona?.isPEC && t("pecExternal")}
                  {!persona?.isPartner && !persona?.isInternal && !persona?.isPEC && t("internal")}
                </ProfilePanelData>
              </ProfileRow>
            </ListItem>
            <ListItem shaded>
              <ProfileRow>
                <ProfilePanelLabel>{t("role.roleType")}</ProfilePanelLabel>
                <ProfilePanelData>
                  {persona && persona.roleType}
                </ProfilePanelData>
              </ProfileRow>
            </ListItem>
            {!!customerName && (
              <ListItem>
                <ProfileRow>
                  <ProfilePanelLabel>{t("customer.title")}</ProfilePanelLabel>
                  <ProfilePanelData>{customerName}</ProfilePanelData>
                </ProfileRow>
              </ListItem>
            )}
          </ul>
        </ProfilePanel>
      </Sidebar>
      <ProfilePreferences />
    </FlexContainer>
  );
};

export default ProfileView;
