import queryString from "query-string";
import getJson, { postJson } from "./request";
import { skuLocalizationCodeMap } from "../shared/sku-localization";
import { FetchError } from "@mssi/pssp-shared";

export const uploadCatalog = (id: string, data: any) =>
  postJson(`/contracts/${id}/catalog`, data).catch((error) => {
    if (`${error.status}` === "500") {
      throw new FetchError("File is not parsed properly, check its correct format", 500);
    } else {
      throw error;
    }
  });

export const getUserLocalizationCodes = (): Promise<
  [string, string][] | undefined
> =>
  getJson("/catalog/user-localization-codes")
    .then((codes: string[]) => {
      if (!codes || !Array.isArray(codes)) {
        return undefined;
      }

      const codesList: [string, string][] = codes.map((code: string) => [
        code,
        skuLocalizationCodeMap[code],
      ]);
      codesList.unshift(["", "None"]);

      return codesList;
    })
    .catch((error: Error) => {
      console.error(error);
      return undefined;
    });

export const getCatalogXLSX = (id: string): Promise<any> =>
  getJson(`/catalog/xlsx?${queryString.stringify({ id })}`);
