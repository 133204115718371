import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import classNames from "classnames";

import ComponentTooltip from "./component-tooltip";

interface StyledLinkProps {
  appearance?: string;
}

interface ButtonLinkProps {
  appearance?: "secondary" | "critical";
  to: string;
  children?: React.ReactNode;
  disabled?: boolean;
  tooltip?: string;
  testData?: string;
  onClickFunction?: any;
}

const StyledLink = styled(Link)<StyledLinkProps>`
  display: inline-block;
  line-height: 34px;
  text-decoration: none !important;

  &:hover {
    color: ${props => (props.appearance === "secondary" ? "#666" : "#fff")};
  }
`;

const ButtonLink: React.FC<ButtonLinkProps> = ({ appearance, disabled, tooltip, to, children, testData, onClickFunction }) => {
  const linkClasses = {
    "vn-button": true,
    "vn-button--primary": !appearance,
    "vn-button--secondary": appearance === "secondary",
    "vn-button--critical": appearance === "critical",
    "vn-button--primary--hoverable": !(disabled || appearance),
    "vn-button--secondary--hoverable": !disabled && appearance === "secondary",
    "vn-button--critical--hoverable": !disabled && appearance === "critical",
    "vn-button--disabled": disabled,
  };

  const onLinkClick: React.MouseEventHandler = event => {
    if (disabled) {
      event.preventDefault();
    }
  };

  const link = (
    <div data-cy={testData}>
      <StyledLink
        to={to}
        appearance={appearance}
        className={classNames(linkClasses)}
        onClick={onClickFunction ? onClickFunction() : onLinkClick}
      >
        {children}
      </StyledLink>
    </div>
  );

  return <ComponentTooltip content={tooltip} position="right" component={link} />;
};

export default ButtonLink;
