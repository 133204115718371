import TilePermission, { TileLinkCondition, TilePermissionKey } from "./tile-permission";
import { customerPermissions } from "@mssi/pssp-shared";
import { LoginMethod } from "./user";

export const techPulseLink: TileLinkCondition = ({ stage }) => {
  switch (stage?.toUpperCase()) {
    case "DEV":
      return "https://usdevms.daas.hppipeline.com/services/oauth_handler/hpbp/sign_in";
    case "ITG":
    case "PIE":
      return "https://usstagingms.hpdaas.com/services/oauth_handler/hpbp/sign_in";
    case "PRO":
    default:
      return "https://www.hpdaas.com/services/oauth_handler/hpbp/sign_in";
  }
};

export const hpAEMLink: TileLinkCondition = ({ stage }) => {
  switch (stage?.toUpperCase()) {
    case "DEV":
    case "ITG":
    case "PIE":
      return "https://hptest.service-now.com/hpaem";
    case "PRO":
      return "https://hp.service-now.com/hpaem";
    default:
      return "https://hptest.service-now.com/hpaem";
  }
};

export const hpDRSLink: TileLinkCondition = ({ stage }) => {
  switch (stage?.toUpperCase()) {
    case "DEV":
    case "ITG":
    case "PIE":
      return "https://uat-device-recovery.ext.hp.com/ui/index.html#/prelogin";
    case "PRO":
      return "https://device-recovery.ext.hp.com/ui/index.html";
    default:
      return "https://uat-device-recovery.ext.hp.com/ui/index.html#/prelogin";
  }
};

export const hp2bLink: TileLinkCondition = ({ stage, featuresCapabilities }) => {
  if (featuresCapabilities?.includes(customerPermissions.federal.id)) {
    switch (stage?.toUpperCase()) {
      case "DEV":
      case "ITG":
      case "PIE":
        return "https://itg2.hp2b.hp.com/webapp/wcs/stores/servlet/LogonForm?myAcctMain=1&catalogId=10051&langId=-1&storeId=10153";
      case "PRO":
      default:
        return "https://hp2b.hp.com/webapp/wcs/stores/servlet/HPIDAuthHandler?flow=LOGON_HIT&storeId=10153";
    }
  }

  switch (stage?.toUpperCase()) {
    case "DEV":
    case "ITG":
    case "PIE":
      return "https://itg2.hp2b.hp.com/webapp/wcs/stores/servlet/HPIDAuthHandler?flow=LOGON_HIT&storeId=10151";
    case "PRO":
    default:
      return "https://hp2b.hp.com/webapp/wcs/stores/servlet/HPIDAuthHandler?flow=LOGON_HIT&storeId=10151";
  }
};

export const deviceWiseLink: TileLinkCondition = ({ loginMethod }) => (
  loginMethod === LoginMethod.HP_EMPLOYEE
    ? "https://www.hpsalescentral.com/saml2/signin"
    : "https://www.hpsalescentral.com/hpid/signin"
);

export const dccLink: TileLinkCondition = ({ stage }) => {
  switch (stage?.toUpperCase()) {
    case "DEV":
      return "https://dcc-itg.msit.hpcloud.hp.com";
    case "ITG":
    case "PIE":
      return "https://dcc-e2e.msit.hpcloud.hp.com";
    case "PRO":
    default:
      return "https://dcc.ext.hp.com";
  }
};

// Use nanoid as keys so strings can be modified as needed
const tilePermissionsMap: { [index in TilePermissionKey]: TilePermission } = {
  canAccessTechPulseTile: {
    iconName: "reports",
    title: "tile.hptech",
    description: "tile.hptech.description",
    dynamicLink: techPulseLink,
    theme: "purple",
    rolePermissionId: "G7bNSN6F7ENk87HOZdzKB",
  },
  canAccessVMOTile: {
    iconName: "cash-drawer",
    title: "tile.valuemanagement",
    description: "tile.valuemanagement.description",
    link: "https://www.ecosystems.us/hpdaas/vmo/doclib/#/",
    theme: "green",
    rolePermissionId: "LfaOHYF1jU6wWrJEpdzRc",
  },
  canAccessHP2BTile: {
    iconName: "inventory",
    title: "permissions.customer.canAccessHP2BTile.description",
    description: "tile.hp2b",
    dynamicLink: hp2bLink,
    theme: "blue",
    rolePermissionId: "CQspWwaI26EAc6i5iXKTM",
  },
  canAccessAEMTile: {
    iconName: "software-change",
    title: "tile.aem",
    description: "tile.aem.description",
    dynamicLink: hpAEMLink,
    theme: "orange",
    target: "_blank",
    rolePermissionId: "N-FjPTgjJUwYncaKzyRsc",
  },
  canAccessDCCTile: {
    iconName: "printer",
    title: "tile.deviceControlCenter",
    description: "tile.deviceControlCenter.description",
    dynamicLink: dccLink,
    theme: "violet",
    rolePermissionId: "A6Q9XrFiaoEEPOCpmlMN3",
  },
  canAccessHPWPTTile: {
    iconName: "lock",
    title: "tile.hpWolfProtectAndTrace",
    description: "tile.hpWolfProtectAndTrace.description",
    dynamicLink: techPulseLink,
    theme: "purple",
    rolePermissionId: "HVc8ixfLCGM_Y1LHLnTPt"
  },
  canAccessHPPITile: {
    iconName: "show",
    title: "tile.hpPresenceInsights",
    description: "tile.hpPresenceInsights.description",
    dynamicLink: techPulseLink,
    theme: "blue",
    rolePermissionId: "dUei4z_WiMHPHmemU6hYf"
  },
  canAccessHPPIEMTile: {
    iconName: "user-interface",
    title: "tile.hpPIEM",
    description: "tile.hpPIEM.description",
    dynamicLink: techPulseLink,
    theme: "gold",
    rolePermissionId: "DxeEG1Fs0j3v5fC1TeaA2"
  },
  canAccessHPInstantInsightsTile: {
    iconName: "bar-graph",
    title: "tile.HPInstantInsights",
    description: "tile.HPInstantInsights.description",
    link: "https://insights-ms.hpcloud.hp.com",
    theme: "green",
    rolePermissionId: "LRQ_6X-vbnQnNOFEM2tpz"
  },
  canAccessDeviceRecoveryServiceTile: {
    iconName: "refresh",
    title: "tile.deviceRecoveryService",
    description: "tile.deviceRecoveryService.description",
    dynamicLink: hpDRSLink,
    theme: "green",
    rolePermissionId: "2aY89rKAQDfyfZVMSR2vW"
  },
  canAccessServicesCapabilityInventoryTool: {
    iconName: "shipped",
    title: "tile.servicesCapabilityInventoryTool",
    description: "tile.servicesCapabilityInventoryTool.description",
    link: "https://servicecapabiltyinventory.inc.hpicorp.net/",
    theme: "gold",
    rolePermissionId: "ZrtylxtGSpLyRnlvEe4pp",
  },
  cPA0O5AR9EnjAjlrhYWEs: {
    iconName: "desktop",
    title: "tile.hpdevicewise",
    description: "tile.hpdevicewise.description",
    theme: "blue",
    dynamicLink: deviceWiseLink,
  },
  o93PtOB1evoG1Oo04J3S7: {
    iconName: "currency",
    title: "tile.serviceestimator",
    description: "tile.serviceestimator.description",
    link: "https://daas-foster-ui-pro.mssi.corp.hpicloud.net/home",
    theme: "gold",
  },
  qGxWJoC9aqFBO1857lDra: {
    iconName: "user",
    title: "tile.opportunitymgmt",
    description: "tile.opportunitymgmt.description",
    link: "https://oms-pro.oms-ase-pro.appserviceenvironment.net",
    theme: "blue",
  },
  CNELQbHpQfQSLoMTEfsoo: {
    iconName: "services",
    title: "tile.servicesales",
    description: "tile.servicesales.description",
    link: "https://daas-aqp.ext.hp.com/",
    theme: "orange",
  },
  BmM1JZbIVFcdLlmfbIPFR: {
    iconName: "reports",
    title: "tile.solution",
    description: "tile.solution.description",
    link: "https://services.corp.hpicloud.net/Solutions/ViewID/1043",
    theme: "purple",
  },
  Sxk2OqkUbsh6OKT1XIDn4: {
    iconName: "cash-drawer",
    title: "tile.servicenow",
    description: "tile.servicenow.description",
    link: "https://hp.service-now.com/nav_to.do?uri=%2Fhome.do",
    theme: "green",
  },
  canAccessMyHPSupport: {
    iconName: "chat",
    title: "tile.myHPSupport",
    description: "tile.myHPSupport.description",
    link: "https://support.hp.com/us-en",
    theme: "blue",
    rolePermissionId: "PB7CBy3vkvFnnM4iDAl6M",
  }
};

export const exploreTilePermissionsMap: { [index in TilePermissionKey]: TilePermission } = {
  canAccessLearnMore: {
    iconName: "find",
    title: "tile.learnmore",
    description: "tile.learnmore.description",
    link: "https://www.hp.com/us-en/services.html",
    theme: "green",
    target: "_blank",
  },
  canAccessHPPS: {
    iconName: "company",
    title: "tile.hpps",
    description: "tile.hpps.description",
    link: "https://www.hp.com/us-en/services/professional-services.html",
    theme: "orange",
    target: "_blank",
  },
  canAccessHPWS: {
    iconName: "lock",
    title: "tile.hpws",
    description: "tile.hpws.description",
    link: "https://www.hp.com/us-en/security/endpoint-security-solutions.html",
    theme: "purple",
    target: "_blank",
  },
  canAccessHPPCS: {
    iconName: "support",
    title: "tile.hppcs",
    description: "tile.hppcs.description",
    link: "https://www.hp.com/us-en/services/presence/conference-room-solutions.html",
    theme: "pink",
    target: "_blank",
  },
  canAccessHPAEM: {
    iconName: "software-change",
    title: "tile.hpaem",
    description: "tile.hpaem.description",
    link: "https://www.hp.com/us-en/services/adaptive-endpoint-management.html",
    theme: "orange",
    target: "_blank",
  },
  canAccessHPDAAS: {
    iconName: "desktop",
    title: "tile.hpdaas",
    description: "tile.hpdaas.description",
    link: "https://www.hp.com/us-en/services/daas.html",
    theme: "blue",
    target: "_blank",
  },
  canAccessHPBB: {
    iconName: "line-graph",
    title: "tile.hpbb",
    description: "tile.hpbb.description",
    link: "https://www.hp.com/us-en/solutions/business-boost.html",
    theme: "purple",
    target: "_blank",
  },
  canAccessHPSMS: {
    iconName: "cash-drawer",
    title: "tile.hpsms",
    description: "tile.hpsms.description",
    link: "https://www.hp.com/us-en/services/subscription-management-service.html",
    theme: "green",
    target: "_blank",
  },
  canAccessHPPI: {
    iconName: "request",
    title: "tile.hppi",
    description: "tile.hppi.description",
    link: "https://www.hp.com/us-en/services/proactive-insights.html",
    theme: "orange",
    target: "_blank",
  },
  canAccessHPAnyware: {
    iconName: "online",
    title: "tile.hpAnyware",
    description: "tile.hpAnyware.description",
    link: "https://www.hp.com/us-en/solutions/digital-workspaces.html",
    theme: "pink",
    target: "_blank",
  },
};

export default tilePermissionsMap;
