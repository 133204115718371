import * as React from "react";
import styled from "styled-components";

const StyledSidebar = styled.div<StyledSidebarProps>`
  margin-bottom: 20px;
  width: 100%;
  margin-right: 0px;
  @media ${(props) => (props.adjustSize ? props.theme.breakpoints.laptop : props.theme.breakpoints.tablet)} {
    width: 320px;
    margin-right: ${props => props.theme.gutter};
    flex-shrink: 0;
  }
`;
interface StyledSidebarProps {
  children: any;
  adjustSize?: boolean;
}

const Sidebar = ({ children, adjustSize }: StyledSidebarProps): JSX.Element => {
  return <StyledSidebar adjustSize={adjustSize}>{children}</StyledSidebar>;
};

export default Sidebar;
