import styled from "styled-components";

const CaseRequestModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  > * + * {
    margin-left: 10px;
  }
`;

export default CaseRequestModalButtonWrapper;
