import Site from "../types/site";
import Contract from "types/contract";
import { ShippingInfo } from "types/order";
import CatalogItem from "types/catalog-item";

export const NEW_ORDER_SELECT_COUNTRY = "newOrder/selectCountry";
export const NEW_ORDER_SELECT_CONTRACT = "newOrder/selectContract";
export const NEW_ORDER_SHOW_CONTRACTS = "newOrder/listContracts";
export const NEW_ORDER_SELECT_SITE = "newOrder/selectSite";
export const NEW_ORDER_ADDITIONAL_INFO = "newOrder/additionalInfo";
export const NEW_ORDER_ADD_ITEM = "newOrder/addItem";
export const NEW_ORDER_DELETE_ITEM = "newOrder/deleteItem";
export const NEW_ORDER_ITEM = "newOrder/Item";
export const NEW_ORDER_SET_DEFAULT_LOCALIZATION = "newOrder/setDefaultLocalization";
export const NEW_ORDER_GOTO_NEXT_STEP = "newOrder/nextStep";
export const NEW_ORDER_GOTO_STEP = "newOrder/goToStep";
export const NEW_ORDER_GOTO_PREVIOUS_STEP = "newOrder/previousStep";
export const NEW_ORDER_SUBMIT = "newOrder/submit";
export const NEW_ORDER_SUBMITTED = "newOrder/submitted";
export const NEW_ORDER_SEARCH_CATALOG = "newOrder/searchCatalog";
export const NEW_ORDER_ERROR = "newOrder/submitError";
export const NEW_ORDER_SET_ACCESSORY = "newOrder/setAccessory";
export const NEW_ORDER_SET_HARDWARE = "newOrder/setHardware";

export interface AddItemParams {
  type: typeof NEW_ORDER_ADD_ITEM;
  item: CatalogItem;
  quantity: number;
  localization: string | undefined;
}

export interface DeleteItemParams {
  type: typeof NEW_ORDER_DELETE_ITEM;
  item: CatalogItem;
}

export interface ItemParams {
  type: typeof NEW_ORDER_ITEM;
  item: CatalogItem[];
}

export interface NewOrderCountryParams {
  type: typeof NEW_ORDER_SELECT_COUNTRY;
  country: string;
}

export interface NewOrderContractParams {
  type: typeof NEW_ORDER_SELECT_CONTRACT;
  contractId: any;
}

export interface NewOrderSiteParams {
  type: typeof NEW_ORDER_SELECT_SITE;
  site: Site | undefined;
}

export interface NewOrderDefaultLocalizationParams {
  type: typeof NEW_ORDER_SET_DEFAULT_LOCALIZATION;
  skuCode: string;
}

export interface NewOrderAdditionalInfoParams {
  type: typeof NEW_ORDER_ADDITIONAL_INFO;
  shippingInfo: ShippingInfo;
}

export interface NewOrderNextStepParams {
  type: typeof NEW_ORDER_GOTO_NEXT_STEP;
  submitting?: boolean; // default true
}

export interface NewOrderPreviousStepParams {
  type: typeof NEW_ORDER_GOTO_PREVIOUS_STEP;
}

export interface NewOrderGoToStepParams {
  type: typeof NEW_ORDER_GOTO_STEP;
  stepNumber: number;
}

export interface NewOrderSubmitParams {
  type: typeof NEW_ORDER_SUBMIT;
}

export interface NewOrderSubmittedParams {
  type: typeof NEW_ORDER_SUBMITTED;
}

export interface NewOrderSearchCatalogParams {
  type: typeof NEW_ORDER_SEARCH_CATALOG;
  value: any;
}

export interface NewOrderErrorParams {
  type: typeof NEW_ORDER_ERROR;
  error?: string;
}

export interface NewOrderContractsListParams {
  type: typeof NEW_ORDER_SHOW_CONTRACTS;
  contracts: Contract[];
}

export interface NewOrderHasAccessoryParams {
  type: typeof NEW_ORDER_SET_ACCESSORY;
  hasAccessory: boolean;
}
export interface NewOrderHasHardwareParams {
  type: typeof NEW_ORDER_SET_HARDWARE;
  hasHardware: boolean;
}

export type NewOrderTypes =
  | NewOrderContractParams
  | NewOrderContractsListParams
  | NewOrderCountryParams
  | NewOrderSiteParams
  | NewOrderAdditionalInfoParams
  | NewOrderNextStepParams
  | NewOrderPreviousStepParams
  | NewOrderSubmitParams
  | NewOrderErrorParams
  | NewOrderSearchCatalogParams
  | NewOrderDefaultLocalizationParams
  | AddItemParams
  | ItemParams
  | DeleteItemParams
  | NewOrderGoToStepParams
  | NewOrderSubmittedParams
  | NewOrderHasAccessoryParams
  | NewOrderHasHardwareParams;

export const newOrderSelectContract = (contractId?: string): NewOrderTypes => ({
  type: NEW_ORDER_SELECT_CONTRACT,
  contractId,
});

export const newOrderSelectCountry = (country: string): NewOrderTypes => ({
  type: NEW_ORDER_SELECT_COUNTRY,
  country,
});

export const newOrderSelectSite = (site: Site | undefined): NewOrderTypes => ({
  type: NEW_ORDER_SELECT_SITE,
  site,
});

export const newOrderAdditionalInfo = (shippingInfo: ShippingInfo): NewOrderTypes => ({
  type: NEW_ORDER_ADDITIONAL_INFO,
  shippingInfo,
});

export const newOrderGotoNextStep = (): NewOrderTypes => ({
  type: NEW_ORDER_GOTO_NEXT_STEP,
});

export const newOrderGotoPreviousStep = (): NewOrderTypes => ({
  type: NEW_ORDER_GOTO_PREVIOUS_STEP,
});

export const newOrderGotoStep = (stepNumber: number): NewOrderTypes => ({
  type: NEW_ORDER_GOTO_STEP,
  stepNumber,
});

export const newOrderSubmit = (): NewOrderTypes => ({
  type: NEW_ORDER_SUBMIT,
});

export const newOrderSubmitted = (): NewOrderTypes => ({
  type: NEW_ORDER_SUBMITTED,
});

export const newOrderError = (error?: string): NewOrderTypes => ({
  type: NEW_ORDER_ERROR,
  error,
});

export const newOrderDefaultLocalization = (skuCode: string): NewOrderTypes => ({
  type: NEW_ORDER_SET_DEFAULT_LOCALIZATION,
  skuCode,
});

export const newOrderItem = (item: CatalogItem[]): NewOrderTypes => ({
  type: NEW_ORDER_ITEM,
  item,
});

export const newOrderAddItem = (item: CatalogItem, quantity: number, localization?: string): NewOrderTypes => ({
  type: NEW_ORDER_ADD_ITEM,
  item,
  quantity,
  localization,
});

export const newOrderDeleteItem = (item: CatalogItem): NewOrderTypes => ({
  type: NEW_ORDER_DELETE_ITEM,
  item,
});

export const newOrderHasAccessory = (hasAccessory: boolean): NewOrderTypes => ({
  type: NEW_ORDER_SET_ACCESSORY,
  hasAccessory,
});

export const newOrderHasHardware = (hasHardware: boolean): NewOrderTypes => ({
  type: NEW_ORDER_SET_HARDWARE,
  hasHardware,
});
