import { permissions, customerPermissions } from "@mssi/pssp-shared";
import { AppBar, AppBarLeftSection, AppBarRightSection, DropdownIcon, DropdownIconAction, IconButton, UserProfile } from "@veneer/core";
import React, { Dispatch, useEffect, useState } from "react";
import { closeShoppingCart, openShoppingCart } from "redux/shopping-cart-action";
import { useAuth, useFeature } from "redux/auth-hooks";
import { useCurrentCustomerID } from "redux/current-customer-hooks";
import { useShoppingCartContents, useShoppingCartVisibility } from "redux/shopping-cart-hooks";
import CartIcon from "../pages/orders/components/cart-icon";
import CustomerSelector from "./customer-selector";
import { RootActionTypes } from "redux/root-action";
import ToolsAndEnables from "./tools-and-enabler";
import WarningLeavingPage from "components/page-leaving-warning-popup";
import deviceType from "shared/devices";
import { logout } from "../api/oidc-user-manager";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import useIsDevice from "shared/isDevice";
import useLeaveWarning from "contexts/leaveWarning-context";
import { useTranslation } from "react-i18next";
import { useCurrentCustomerCapability } from "shared/customer-has-capability";
import useToolsEnablersVisibility from "shared/use-tools-enablers-visibility";
import { useHasCurrentCustomerCompletedContractSetup } from "redux/combo-hooks";

const ModifiedAppBar = styled(AppBar)`
  .vn-breadcrumb {
    padding: 10px 20px;
  }
  @media (min-width: 1200px) {
    .vn-appbar__menu--enabled {
      padding-left: 260px;
    }
  }
`;
const SignOut = styled.button`
  color: ${(props) => `${props.theme.colors.blue}`};
  background-color: ${(props) => props.theme.colors.white};
  border-width: 0;
  font-size: 12px;
  cursor: pointer;
`;

const SignOutIcon = styled.div`
  padding-top: 2px;
  margin: 13px 15px 10px 10px;

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 55px;
    height: 25px;
    background-color: white;
    color: black;
    text-align: center;
    padding: 5px 0;
    position: absolute;
    top: 150%;
    left: 50%;
    margin-left: -60px;
    border: 1px solid black;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`;
const UserProfileWrapper = styled.div`
  .vn-user-profile__avatar {
    margin-left: 35px;
  }
  .vn-user-profile__content {
    width: unset;
  }
`;

const CartIconWrapper = styled.div`
  position: relative;
  right: 14px;
`;

const StyleBadge = styled.div`
  @media ${(props) => props.theme.breakpoints.tabletMaximum} {
    .vn-notification__badge {
      background: #0195d6;
      background: var(--blue-v2, #0195d6);
      right: 147px;
      top: 131px;
      z-index: 999;
      min-width: 0px;
      height: 9px;
      width: 9px;
    }
  }
`;
interface HeaderProps {
  menuEnabled?: boolean;
  title?: string;
}

const Header = ({ menuEnabled = true, title = "appname" }: HeaderProps) => {
  const history = useHistory();
  const { currentUser, authorizedFor } = useAuth();
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showBadge, setShowBadge] = useState<boolean>(false);
  const { leaveWarning } = useLeaveWarning();
  const [warningPopup, setWarningPopup] = useState<boolean>(false);
  const [pageChange, setPageChange] = useState(String);
  const canAccessSupportPage = authorizedFor([permissions.canAccessSupportPage]);
  const isLoggedIn = !!currentUser?.id;
  const contractualOrdering = useCurrentCustomerCapability(customerPermissions.contractualOrdering);
  const canCreateOrders = authorizedFor([permissions.canCreateOrders]);
  const canViewOrders = authorizedFor(permissions.canViewOrders, permissions.canViewMyOrders);
  const currentCustomerID = useCurrentCustomerID();
  const isMobileTablet = useIsDevice(deviceType.MOBILE, deviceType.MOBILELARGE, deviceType.TABLET);
  const isShown = useShoppingCartVisibility();
  const items = useShoppingCartContents();
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();
  const showNotificationBadge = items.length > 0;
  const isToolsAndEnablersVisible = useToolsEnablersVisibility();

  const toggle = () => {
    dispatch(isShown ? closeShoppingCart() : openShoppingCart());
  };

  const toggleBadge = () => {
    if (showBadge) {
      setShowBadge(false);
    } else {
      setShowBadge(true);
    }
  };

  const alertUser = (e: any) => {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
   if (leaveWarning) {
      window.addEventListener("beforeunload", alertUser);
    }
    return () => {
      window.removeEventListener("beforeunload", alertUser);
  };
  });

  const onClick = (path: any) => {
    if (leaveWarning) {
      setWarningPopup(true);
      if (path) {
      setPageChange(path);
      }
    } else if (path) {
      history.push(path);
    }
  };

  const hasAnyCatalog = useHasCurrentCustomerCompletedContractSetup();
  const canOrderBasedOnAssignedCategory = authorizedFor([permissions.canOrderBasedOnAssignedCategory]);
  const endUserOrder = useFeature<boolean>("endUserOrder");
  const endUserPlaceOrder = canOrderBasedOnAssignedCategory && currentUser.categoryId && currentUser.site?.addressLine1 && endUserOrder;
  const canPlaceOrder = hasAnyCatalog && contractualOrdering && canViewOrders && (canCreateOrders || endUserPlaceOrder);

  const isOnSupportPage = document.location.pathname.endsWith("/get-support");
  const showGetSupport = canAccessSupportPage && !!currentCustomerID && !isOnSupportPage;

  const dropDownActions = () => {
    const dropDownItemsArr = [];
    let key = 1;
    if (document.location.pathname === "/profile") {
      dropDownItemsArr.push(<DropdownIconAction icon="profile" label={t("profile.logOut")} onClick={() => logout()} key={`${key}_${t("profile.logOut")}`} />);
      key += 1;
    } else {
      dropDownItemsArr.push(<DropdownIconAction icon="profile" label={t("profile.title")} onClick={() => history.push("/profile")} key={`${key}_${t("profile.title")}`} />);
      key += 1;
    }

    if (isToolsAndEnablersVisible) {
      dropDownItemsArr.push(<DropdownIconAction icon="dashboard" label={t("toolsandenablers")} onClick={() => setShowPopup(true)} key={`${key}_${t("toolsandenablers")}`} />);
      key += 1;
    }
    if (canPlaceOrder) {
      dropDownItemsArr.push(<DropdownIconAction icon="bag" label={t("profile.cart")} onClick={toggle} key={`${key}_${t("cart")}`} />);
      key += 1;
    }
    if (showGetSupport) {
      dropDownItemsArr.push(<DropdownIconAction
        className="headerPopupHover"
        icon="help"
        label={t("profile.help")}
        onClick={() => {
          history.push(`/${currentCustomerID}/${"get-support"}`);
        }}
        key={`${key}_${t("help")}`}
      />);
    }

    return dropDownItemsArr;
  };

  return (
    <ModifiedAppBar menuEnabled={menuEnabled} className="headerIcons">
      <>
        <AppBarLeftSection>
          <h4 className="vn-color--primary headerAlignment">{t(title)}</h4>
        </AppBarLeftSection>
        <AppBarRightSection>
          <CustomerSelector />
        </AppBarRightSection>
        {isLoggedIn && isMobileTablet ?
          <AppBarRightSection>
            <div className="headerPopupHover">
              <DropdownIcon icon="more" title="Menu" popoverAlignment="right" onClick={toggleBadge} onClose={() => setShowBadge(false)}>
                { dropDownActions() }
              </DropdownIcon>
            </div>
          </AppBarRightSection> :
          <>
            {
              document.location.pathname === "/profile"
                ? (
                  <AppBarRightSection>
                    <SignOutIcon>
                      <SignOut className="tooltip" onClick={() => logout()}>
                        {t("btn.signout")}
                        <span className="tooltiptext">{t("hover.signout")}</span>
                      </SignOut>
                    </SignOutIcon>
                  </AppBarRightSection>
                )
                : (
                  <AppBarRightSection>
                    <UserProfileWrapper>
                      {isLoggedIn && (
                        <div data-cy="profileicon">
                          <UserProfile
                            email={currentUser.email}
                            name={`${currentUser.firstName} ${currentUser.lastName}`}
                            onEditMyProfileClick={() => {
                            onClick("/profile");
                          }}
                            i18n={{
                            editMyProfile: t("viewprofile"),
                            title: t("profile.myProfile"),
                            signOut: t("profile.logOut"),
                          }}
                            onSignOutClick={() => logout()}
                          />
                        </div>
                      )}
                    </UserProfileWrapper>
                  </AppBarRightSection>
                )
            }
            {isLoggedIn && isToolsAndEnablersVisible && (
              <AppBarRightSection>
                <IconButton
                  data-cy="goToTools"
                  icon="dashboard"
                  title={t("toolsandenablers")}
                  size="48"
                  className="helpIconButton"
                  onClick={() => setShowPopup(true)}
                />
              </AppBarRightSection>
            )}
            {isLoggedIn && canPlaceOrder && (
              <AppBarRightSection>
                <CartIconWrapper>
                  <CartIcon />
                </CartIconWrapper>
              </AppBarRightSection>
            )}
            {showGetSupport &&
            <AppBarRightSection>
              <IconButton
                data-cy="helpIcon"
                icon="help"
                title={t("getSupport.label")}
                size="48"
                className="helpIconButton"
                onClick={() => {
                  history.push(`/${currentCustomerID}/${"get-support"}`);
                }}
              />
            </AppBarRightSection>}
          </>}
        <WarningLeavingPage
          values={warningPopup}
          setValue={setWarningPopup}
          pageChange={pageChange}
        />
        {isLoggedIn && showPopup && <ToolsAndEnables setShowPopup={setShowPopup} />}
        {isLoggedIn && showBadge && showNotificationBadge && isMobileTablet &&
        <StyleBadge><div className="vn-notification__badge" /></StyleBadge>}
      </>
    </ModifiedAppBar>
  );
};

export default Header;
