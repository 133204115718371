import React, { Ref, useState } from "react";
import "@mssi/jupiter-engine";
import { v4 as uuidV4 } from "uuid";
import getConfig from "../../client-config";

export interface Props {
  component: string;
  context: Record<string, unknown>;
  version: string;
  satInfo?: string;

  /** Add API parts to context if needed by component */
  apiEndpointAddition?: string;

  /** Size of spinner (default 60) */
  spinnerSize?: number;

  /** Ref for satellite, e.g. for events */
  satelliteRef?: Ref<HTMLElement>;
}

const JupiterSatellite: React.FC<Props> = ({
  component,
  version,
  context,
  satInfo = undefined,
  spinnerSize = 60,
  satelliteRef = undefined,
}) => {
  const [id] = useState<string>(`S${uuidV4().replace(/-/g, "")}`);
  const stack = getConfig("jupiterStack");
  const locale = "en";

  const cachedContext = JSON.stringify({ ...context });

  return (
    <jupiter-sat
      id={id}
      name={component}
      context={cachedContext}
      version={version}
      hostName="PSSP"
      stack={stack}
      authMode="asset"
      token=""
      locale={locale}
      satInfo={satInfo}
      spinnerSize={spinnerSize}
      ref={satelliteRef}
    />
  );
};

export default JupiterSatellite;
