import Handlebars from "handlebars";
import { MakeDataSourceParams, TypeaheadTemplates } from "./types";

export const spanWrapper = Handlebars.compile("<span class=\"{{className}}\">{{{content}}}</span>");

export const safeRenderTemplate = <T2 extends any>(
  template: Handlebars.TemplateDelegate<T2> | string | undefined,
  obj: T2,
): string => {
  if (template === undefined) {
    return "";
  }

  if (typeof template !== "function") {
    return `${template}`;
  }

  return template(obj);
};

export const makeDataSource = <T extends any>(params: MakeDataSourceParams<T>): Twitter.Typeahead.Dataset<T> => {
  const { fieldKey, templatesJSON, bloodhound } = params;

  const templates = JSON.parse(templatesJSON) as TypeaheadTemplates<T>;

  const compiledTemplates = {
    // Used to wrap elements in a DOM node for Typeahead
    span: Handlebars.compile("<span class=\"{{className}}\">{{{content}}}</span>"),

    // Templates with defaults
    notFound: templates.notFound !== undefined
      ? Handlebars.compile(templates.notFound)
      : Handlebars.compile("No item matches your search."),
    pending: templates.pending !== undefined
      ? Handlebars.compile(templates.pending)
      : Handlebars.compile("Searching..."),
    suggestion: templates.suggestion !== undefined
      ? Handlebars.compile(templates.suggestion)
      : Handlebars.compile(`{{suggestion.${fieldKey}}}`),

    // Templates without defaults
    header: templates.header !== undefined
      ? Handlebars.compile(templates.header)
      : undefined,
    footer: templates.footer !== undefined
      ? Handlebars.compile(templates.footer)
      : undefined,
  };

  return {
    display: `${fieldKey}`,
    source: bloodhound,
    templates: {
      // templates always present, with defaults
      notFound(query: string): string {
        const content = safeRenderTemplate(compiledTemplates.notFound, { query });
        return spanWrapper({ className: "tt-not-found", content });
      },
      pending(query: string): string {
        const content = safeRenderTemplate(compiledTemplates.pending, { query });
        return spanWrapper({ className: "tt-pending", content });
      },
      suggestion(suggestion: T): string {
        const content = safeRenderTemplate(compiledTemplates.suggestion, { suggestion });
        return spanWrapper({ className: "", content });
      },

      // only include header/footer if actually set; don't send empty spans otherwise
      ...(compiledTemplates.header !== undefined && {
        header(query: string, suggestions: T[]): string {
          const content = safeRenderTemplate(compiledTemplates.header, { query, suggestions });
          return spanWrapper({ className: "tt-header", content });
        },
      }),
      ...(compiledTemplates.footer !== undefined && {
        footer(query: string, suggestions: T[]): string {
          const content = safeRenderTemplate(compiledTemplates.footer, { query, suggestions });
          return spanWrapper({ className: "tt-footer", content });
        },
      }),
    },
  };
};
