import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { UsePageContext } from "./types";

/**
 * It validates and set the current page
 */
const validateAndSetPageCallback = (setCurrentPage: Dispatch<SetStateAction<number>>, lastPage: number) =>
  (page: number): void => {
    if (page < 1) {
      setCurrentPage(1);
    } else if (page > lastPage) {
      setCurrentPage(lastPage);
    } else {
      setCurrentPage(page);
    }
  };

/**
 * It validates and set the size of the current page
 */
const validateAndSetPageSizeCallback = (
  currentPage: number,
  setCurrentPage: Dispatch<SetStateAction<number>>,
  setPageSize: Dispatch<SetStateAction<number>>,
  totalItems: number,
  startIndex: number,
) =>
  (size: number): void => {
    const newLastPage = size ? Math.ceil(totalItems / size) : 1;
    const destinationPage = size ? Math.max(1, Math.min(Math.ceil((1 + startIndex) / size), newLastPage)) : 1;

    if (destinationPage < currentPage) {
      setCurrentPage(destinationPage);
    }

    setPageSize(size);

    if (destinationPage > currentPage) {
      setCurrentPage(destinationPage);
    }
  };

export function usePage<T>(
  items: T[],
  startingPage: number = 1,
  startingPageSize: number = 10,
  enabled: boolean = true,
): UsePageContext<T> {
  const [currentPage, setCurrentPage] = useState<number>(startingPage);
  const [pageSize, setPageSize] = useState<number>(startingPageSize);

  const totalItems = items.length;
  const startIndex = enabled && pageSize ? (currentPage - 1) * pageSize : 0;
  const itemsOnThisPage = enabled && pageSize ? Math.min(pageSize, totalItems - startIndex) : totalItems;
  const sliceLength = startIndex + itemsOnThisPage;
  const startItem = startIndex + 1;
  const endItem = startIndex + itemsOnThisPage;

  const result = items.slice(startIndex, sliceLength);
  const lastPage = enabled && pageSize ? Math.ceil(totalItems / pageSize) : 1;

  const validateAndSetPage = useMemo(() => validateAndSetPageCallback(setCurrentPage, lastPage), [lastPage]);

  const validateAndSetPageSize = useMemo(
    () => validateAndSetPageSizeCallback(currentPage, setCurrentPage, setPageSize, totalItems, startIndex),
    [currentPage, totalItems, startIndex],
  );

  const nextPage = useCallback(
    () => validateAndSetPage(currentPage + 1),
    [validateAndSetPage, currentPage],
  );

  const previousPage = useCallback(
    () => validateAndSetPage(currentPage - 1),
    [validateAndSetPage, currentPage],
  );

  const canNext = currentPage < lastPage;
  const canPrevious = currentPage > 1;

  useEffect(
    () => {
      if (currentPage < 1) {
        setCurrentPage(1);
        return;
      }

      if (currentPage > lastPage && currentPage > 1) {
        setCurrentPage(1);
      }
    },
    [currentPage, lastPage],
  );

  return {
    result,
    currentPage,
    lastPage,
    pageSize,
    totalItems,
    startItem,
    endItem,
    setPageSize: validateAndSetPageSize,
    setPage: validateAndSetPage,
    canNext,
    canPrevious,
    nextPage,
    previousPage,
  };
}
