export enum OrderStep {
  SITE_SELECTION = "contract",
  HARDWARE_SELECTION = "browse",
  SHIPPING_INFO = "shipping",
  REVIEW_SUBMIT = "checkout",
}
export enum OrderStepEndUser {
  HARDWARE_SELECTION = 1,
  ACCESSORIES_SELECTION = 2,
  REVIEW_SUBMIT = 3,
  MAX_STEPS = 4,
}
export const orderStepValues: (string & OrderStep)[] = [
  OrderStep.SITE_SELECTION,
  OrderStep.HARDWARE_SELECTION,
  OrderStep.SHIPPING_INFO,
  OrderStep.REVIEW_SUBMIT,
];

export default OrderStep;
