export default {
  UAMlink: "https://accessmanager.austin.hp.com/uam/ProfileRequest.aspx?ProFlag=True&ProfileID=57562&",
  cognitoDomain: "https://pie-daas-portal.auth-fips.us-west-2.amazoncognito.com",
  cognitoRegion: "us-west-2",
  cognitoPoolId: "us-west-2_nNalsRvm6",
  cognitoClientId: "2ru67bh5tj8hum9617gfc8eh19",
  hpidUrl: "https://directory.stg.cd.id.hp.com",
  editProfileEmployee: " https://directoryworks.hpicorp.net",
  editProfileNonEmployee: "https://myaccount.stg.cd.id.hp.com",
  launchDarklyReactKey: "6149527191dc1925ab5ba597",
  jupiterStack: "pie",
  testEmailNote: true,
};
