import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { Column, Property, SearchEntry, SearchType, UseSearchContext } from "./types";
import { getTableProp } from "./get-table-prop";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

/**
 * Break text into search tokens
 * @param text
 */
const tokenize = (text: string): string[] => text
  .split(/\s+/)
  .filter((text) => !!text);

/**
 * Create a safe regex from text
 * @param text
 */
const makeRegex = (text: string) => new RegExp(text.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&"), "i");

const searchCallback = <T extends any>(
  searchEntries: SearchEntry<T>[],
  setSearchEntries: Dispatch<SetStateAction<SearchEntry<T>[]>>,
) =>
  (property?: Property<T>, searchText: string = ""): void => {
    let index = searchEntries.findIndex((entry) => entry.property === property);

    if (index < 0 && !searchText) {
      return;
    }

    if (index < 0) {
      searchEntries.push({ property, searchText: "", searchTokens: [], searchRegExps: [] });
      index = searchEntries.length - 1;
    }

    const entry = searchEntries[index];

    if (entry.searchText === searchText) {
      return;
    }

    entry.searchText = searchText;
    entry.searchTokens = tokenize(entry.searchText);
    entry.searchRegExps = entry.searchTokens.map(makeRegex);

    setSearchEntries([...searchEntries]);
  };

const clearPropSearchCallback = <T extends any>(
  searchEntries: SearchEntry<T>[],
  setSearchEntries: Dispatch<SetStateAction<SearchEntry<T>[]>>,
) =>
  (): void => {
    const filtered = searchEntries.filter((it) => it.property === undefined);

    if (filtered.length === searchEntries.length) {
      return;
    }

    setSearchEntries(filtered);
  };

const searchValueCallback = <T extends any>(searchEntries: SearchEntry<T>[]) => (property: Property<T>) => {
  const entry = searchEntries.find((it) => it.property === property);

  if (!entry) {
    return "";
  }

  return entry.searchText;
};

const isMatch = <T extends any>(
  item: T,
  property?: Property<T>,
  searchRegExps: RegExp[] = [],
  t: TFunction = (v: string): string => v,
) => {
  if (property === undefined) {
    return false;
  }

  let value = getTableProp(item, property, t);

  if (value === null || value === undefined) {
    value = "";
  }

  if (typeof value !== "string") {
    value = `${value}`;
  }

  return searchRegExps.every((regex) => regex.test(value));
};

const filterItems = <T extends any>(
  items: T[],
  searchEntries: SearchEntry<T>[],
  columns: Column<T>[],
  t: TFunction,
) => items
  .filter((item) => {
    for (const { property, searchRegExps } of searchEntries) {
      const matched = property
        ? isMatch(item, property, searchRegExps, t)
        : (!columns.length || columns.some((column) => isMatch(item, column.property, searchRegExps, t)));

      if (!matched) {
        return false;
      }
    }

    return true;
  });

/**
 * Helper method if you want to search something that's not a table
 * Returns a map of columns to search on given a bunch of Property<T>'s
 * @param props
 */
export function getSimpleColumnsForSearch<T>(props: Property<T>[]): Column<T>[] {
  return props.map((property, index) => ({
    property,
    label: `${index}.${property}`,
    searchType: SearchType.Text,
  }));
}

export function useSearch<T>(items: T[], columns: Column<T>[] = []): UseSearchContext<T> {
  const { t } = useTranslation();

  const [searchEntries, setSearchEntries] = useState<SearchEntry<T>[]>([]);

  const search = useMemo(() => searchCallback(searchEntries, setSearchEntries), [searchEntries]);
  const clearPropSearch = useMemo(() => clearPropSearchCallback(searchEntries, setSearchEntries), [searchEntries]);
  const getSearchValue = useMemo(() => searchValueCallback(searchEntries), [searchEntries]);

  const searchableColumns = columns.filter(({ searchType, enabled }) => enabled !== false && searchType !== undefined);

  const result = filterItems(items, searchEntries, searchableColumns, t);

  return {
    result,
    search,
    clearPropSearch,
    getSearchValue,
  };
}
