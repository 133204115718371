import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeCurrentCustomer } from "./current-customer-actions";
import { Dispatch } from "redux";
import { RootActionTypes } from "./root-action";

const SaveCurrentCustomer: React.FC = () => {
  const { customerId } = useParams<{ customerId?: string }>();
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();

  useEffect(
    () => {
      dispatch(changeCurrentCustomer(customerId));
    },
    [dispatch, customerId],
  );

  return null;
};

export default SaveCurrentCustomer;
