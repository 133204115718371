import React from "react";
import styled from "styled-components";

export enum StatusTrackerState {
  Normal,
  Active,
  Pending,
  Cancelled,
}

interface StatusTrackerItemProps {
  state?: StatusTrackerState;
  color?: string;
  border?: string;
  vertical?: boolean;
  statusLength?: number;
}

const makeStyle = (normalColor: string, outline: boolean) =>
  (props: StatusTrackerItemProps): StatusTrackerItemProps => {
    switch (props.state) {
      case StatusTrackerState.Active:
        return { ...props, color: "#0096d6", border: undefined };
      case StatusTrackerState.Pending:
        return outline
          ? { ...props, color: "#ffffff", border: "#f28202" }
          : { ...props, color: "#f28202", border: undefined };
      case StatusTrackerState.Cancelled:
        return { ...props, color: "#cf4030", border: undefined };
      case StatusTrackerState.Normal:
      default:
        return outline
          ? { ...props, color: "#ffffff", border: normalColor }
          : { ...props, color: normalColor, border: undefined };
    }
  };

const Dot = styled.div.attrs<StatusTrackerItemProps, StatusTrackerItemProps>(makeStyle("#cccccc", true))`
  border-radius: 100%;
  height: 20px;
  position: relative;
  width: 20px;
  z-index: 3;
  @media (max-width: 480px) {
    left: ${props => (props.statusLength === 1 ? "4.5%" : "-17.5%")} ;
    top: ${props => (props.statusLength === 1 ? "-4px" : "0")} ;
  }

  ${(props) => (props.color ? `background-color: ${props.color};` : "")};
  ${(props) => (props.border ? `border: 3px solid ${props.border};` : "")};
`;

const Bar = styled.div.attrs<StatusTrackerItemProps, StatusTrackerItemProps>(makeStyle("#cccccc", false))`
  box-sizing: border-box;
  display: inline-block;
  height: 4px;
  margin: 8.5px -20px;
  position: relative;
  z-index: 2;

  ${(props) => (props.color ? `background-color: ${props.color};` : "")};

${(props) => (props.vertical ? `
  height: 5px;
  display: block;
  transform: rotate(90deg)translateY(15px);
  width: 70px !important;
  margin: 14.5px -10px 14.5px 6.3%;

  @media (max-width: 480px) {
    margin-left: 3%;
  }
` : "")}
`;

const Label = styled.div.attrs<StatusTrackerItemProps, StatusTrackerItemProps>(makeStyle("#666666", false))`
  transform: translateX(-50%);
  font-size: 0.875rem;
  font-weight: 300;
  left: 50%;
  margin-top: 10px;
  position: absolute;
  text-align: center;
  width: 125px;
  font-weight: bold;

  @media (min-width: 1024px){
    width: 130px;
  }

  ${(props) => (props.color ? `color: ${props.color};` : "")};

  > span {
    color: ${props => props.theme.colors.body};
    font-weight: normal;
  }

${(props) => (
  props.vertical
    ? `
  position: relative;
  top: 0;
  left: 12px;
  margin-top: -5px;
  transform: none;
  @media (max-width: 480px) {
    left: -12px;
  }
  
  > span {
    position: absolute;
    left: 120px;
    width: 82px;
  }
`
    : ""
)}
`;

interface WrapperProps {
  vertical?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  height: 80px;
  padding: 10px 0 10px 20px;
  position: relative;

${(props) => (props.vertical ? `
  height: auto;
  padding-left: 0;
  
  .vn-status-tracker__step {
    display: flex;
    margin-left: 6.3%;
    left: 10px;

    @media (max-width: 480px) {
      margin-left: 17.5%;
    }
  }
` : "")}
`;

export interface StatusTrackerItem {
  key: string;
  value: any;
  state: StatusTrackerState;
  barState?: StatusTrackerState;
}

export interface Props {
  items: StatusTrackerItem[];
  barWidth?: string;
  vertical?: boolean;
}

const StatusTrackerAdvanced: React.FC<Props> = ({ items, barWidth: overrideBarWidth = undefined, vertical = false }) => (
  <Wrapper vertical={vertical}>
    {
      items.reduce<JSX.Element[]>(
        (acc, item, index, array) => {
          const isNotFirst = index > 0;

          const barCount = array.length - 1;
          // split the 100% width among each bar
          // and also split the 10px from the first dot left half
          // plus the 10px from the last dot right half
          const barWidth = overrideBarWidth || `calc(${100 / barCount}% - ${20 / barCount}px)`;

          if (isNotFirst) {
            acc.push(
              <Bar
                key={`bar-${item.key}`}
                state={item.barState || item.state}
                style={{ width: barWidth }}
                vertical={vertical}
              />,
            );
          }

          acc.push(
            <div key={`step-${item.key}`} className="vn-status-tracker__step">
              <Dot state={item.state} vertical={vertical} statusLength={items.length} />
              <Label state={item.state} vertical={vertical}>{item.value}</Label>
            </div>,
          );

          return acc;
        },
        [],
      )
    }
  </Wrapper>
);

export default StatusTrackerAdvanced;
