export enum FileType {
  CSV = "csv",
  XLSX = "xlsx",
  ICS = "ics",
}

function base64toBlob(b64Data: any, contentType: any, sliceSize: any) {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  let offset;
  let slice;
  let byteNumbers;
  let index;
  let byteArray;
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    slice = byteCharacters.slice(offset, offset + sliceSize);
    byteNumbers = new Array(slice.length);
    for (index = 0; index < slice.length; index++) {
      byteNumbers[index] = slice.charCodeAt(index);
    }
    byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
}

type IENavigator = Navigator & { msSaveBlob?(blob: Blob, filename: string): void };

const downloadBlob = (blob: Blob, filename: string) => {
  const ieNav = window.navigator as IENavigator;
  if (typeof ieNav.msSaveBlob !== "undefined") {
    ieNav.msSaveBlob(blob, filename);
    return;
  }

  const blobURL = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.style.display = "none";
  link.target = "_blank";
  link.href = blobURL;
  link.setAttribute("download", filename);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(blobURL);
};

export default {
  /**
   * Opens the given string data as a file
   */
  file(data: string, filename: string, fileType: FileType) {
    const blob = (fileType === FileType.XLSX) ? base64toBlob(data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", 512) : new Blob([data], { type: `text/${fileType};charset=utf8;` });
    downloadBlob(blob, filename);
  },

  blob: downloadBlob,
};
