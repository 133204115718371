import React from "react";
import useOrder, { OrderSubmissionState } from "api/hooks/use-order";
import Order, { OrderStatusType } from "types/order";
import PageSpinner from "../components/page-spinner";
import useCustomer from "api/hooks/use-customer";
import Customer from "types/customer";

export interface OrderDetailsContextInterface {
  submitting: OrderSubmissionState;
  order: Order;
  customer: Customer;
  refreshOrder(order?: Order | undefined): Promise<void>;
  actions: {
    approve(): Promise<void>;
    reject(msg: string): Promise<void>;
    cancelRequest(msg: string): Promise<void>;
    revertCancelRequest(): Promise<void>;
    approveRequest(msg: string): Promise<void>;
    updateOrderStatus(status: OrderStatusType): Promise<void>;
    patchOrder(partial: Partial<Order>): void;
  };
}

export interface OrderDetailsProviderProps {
  id: string;
  customerId?: string;
}

export const OrderDetailsContext = React.createContext<OrderDetailsContextInterface | undefined>(undefined);

export const OrderDetailsProvider: React.FC<OrderDetailsProviderProps> = ({ id, customerId, ...props }) => {
  const {
    loading,
    submitting,
    order,
    approve,
    reject,
    cancelRequest,
    revertCancelRequest,
    approveRequest,
    refreshOrder,
    updateOrderStatus,
    patchOrder,
    error,
  } = useOrder(id);

  const { customer, loading: customerLoading } = useCustomer(customerId ? customerId : "");

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (loading || customerLoading) {
    return <PageSpinner />;
  }

  if (!order || !customer) {
    return <div>Order not found</div>;
  }

  const value = {
    order,
    customer,
    refreshOrder,
    submitting,
    actions: {
      approve,
      reject,
      cancelRequest,
      revertCancelRequest,
      approveRequest,
      updateOrderStatus,
      patchOrder,
    },
  };

  return <OrderDetailsContext.Provider value={value} {...props} />;
};

export const useOrderDetail = (failIfNotFound: boolean = true): OrderDetailsContextInterface => {
  const context = React.useContext(OrderDetailsContext) as OrderDetailsContextInterface;

  if (!context) {
    if (failIfNotFound) {
      throw new Error("userOrderDetail must be used within an OrderDetailsProvider");
    } else {
      return {} as OrderDetailsContextInterface;
    }
  }

  return context;
};

export default useOrderDetail;
