import { useSelector } from "react-redux";
import { RootState } from "./redux-state";
import deepEqual from "fast-deep-equal";
import Site from "../types/site";
import { CartItem, LocalizationCodeInfo, ShippingInfo, ShoppingCart } from "../types/order";
import OrderStep from "../types/order-step";
import { S3Document } from "../types/document";

interface NewOrderContextState {
  customerId?: string;
  contractId?: string;
  country?: string;
  site?: Site;
  loading: boolean;
  homeAddress: any;
  checked?:boolean;
}

export const useNewOrderContextParams = (): NewOrderContextState => useSelector(
  ({ shoppingCart }: RootState) => ({
    customerId: shoppingCart.customerId,
    contractId: shoppingCart.contractId,
    country: shoppingCart.country,
    site: shoppingCart.site,
    loading: shoppingCart.loading,
    homeAddress: shoppingCart.homeAddress,
    checked: !!(shoppingCart.homeAddress && shoppingCart.homeAddress.homeBuilding !== ""),
  }),
  deepEqual,
);

export const useShoppingCartLoading = (): boolean =>
  useSelector(({ shoppingCart }: RootState) => shoppingCart.loading);

export const useShoppingCartSubmitting = (): boolean =>
  useSelector(({ shoppingCart }: RootState) => shoppingCart.submitting);

export const useNewOrderSite = (): Site | undefined =>
  useSelector(({ shoppingCart }: RootState) => shoppingCart.site);

export const useNewOrderContractId = (): string | undefined =>
  useSelector(({ shoppingCart }: RootState) => shoppingCart.contractId);

export const useShoppingCartContents = (): CartItem[] =>
  useSelector(({ shoppingCart }: RootState) => shoppingCart.items);

export const useShoppingCartDocuments = (): S3Document[] =>
  useSelector(({ shoppingCart }: RootState) => shoppingCart.documents ?? []);

export const useShoppingCartId = (): string | undefined =>
  useSelector(({ shoppingCart }: RootState) => shoppingCart.cartId);

export const useShoppingCartVisibility = (): boolean =>
  useSelector(({ shoppingCart }: RootState) => shoppingCart.showShoppingCart);

export const useDefaultLocalizationCode = (): string | undefined =>
  useSelector(({ shoppingCart }: RootState) => shoppingCart.defaultLocalizationCode);

export interface ShippingInfoParams {
  hasShippingInfo: boolean;
  shippingInfo: ShippingInfo;
  bigDealNumber?: string;
  hpfsNumber?: string;
  site?: Site
}

const isShippingInfoEntered = (shoppingCart: ShoppingCart): boolean => {
  const { shippingInfo, bigDealNumber, hpfsNumber } = shoppingCart;

  return !!(
    shippingInfo
    && shippingInfo.firstName
    && shippingInfo.lastName
    && shippingInfo.email
    && shippingInfo.primaryPhone
    && bigDealNumber
    && hpfsNumber
  );
};

export const useShippingInfo = (): ShippingInfoParams => useSelector(({ shoppingCart }: RootState) => {
  const { shippingInfo, bigDealNumber, hpfsNumber, site } = shoppingCart;
  const hasShippingInfo = isShippingInfoEntered(shoppingCart);
  return { shippingInfo, hasShippingInfo, bigDealNumber, hpfsNumber, site };
});

export const useLocalizationCodeInfo = (): LocalizationCodeInfo | undefined =>
  useSelector(({ shoppingCart }: RootState) => shoppingCart.localizationCodeSelectionInfo);

export interface NextStepBestGuess {
  step: OrderStep;
  caption: string;
}

export const useNewOrderStepNextBestGuess = (preferCartOverCheckout: boolean = false): NextStepBestGuess =>
  useSelector(({ shoppingCart }: RootState) => {
    const hasSite = !!(shoppingCart.contractId && shoppingCart.country && shoppingCart.site);
    const hasItems = shoppingCart.items.length > 0;
    const hasShippingInfo = isShippingInfoEntered(shoppingCart);

    if (hasSite && (!hasItems || preferCartOverCheckout)) {
      return {
        step: OrderStep.HARDWARE_SELECTION,
        caption: "order.placeOrder",
      };
    }

    if (hasSite && hasItems && !hasShippingInfo) {
      return {
        step: OrderStep.SHIPPING_INFO,
        caption: "neworder.btn.reviewandsubmit",
      };
    }

    if (hasSite && hasItems && hasShippingInfo) {
      return {
        step: OrderStep.REVIEW_SUBMIT,
        caption: "neworder.btn.reviewandsubmit",
      };
    }

    return {
      step: OrderStep.SITE_SELECTION,
      caption: "order.placeOrder",
    };
  });

export const useShoppingCartError = (): string | undefined =>
  useSelector(({ shoppingCart }: RootState) => shoppingCart.error);
