import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { Column, Comparator, Property, SortDirection, SortEntry, UseSortContext } from "./types";
import { getTableProp } from "./get-table-prop";
import { useTranslation } from "react-i18next";

const defaultComparator = <T extends any>(a: T, b: T): number => {
  if (typeof a !== typeof b) {
    return 0;
  }

  if (typeof a === "string" && typeof b === "string") {
    return a.localeCompare(b);
  }

  if (typeof a === "number" || typeof a === "boolean") {
    return (+a) - (+b);
  }

  return 0;
};

/**
 * It sorts the filter items
 */
const sortCallback = <T extends any>(
  sortEntries: SortEntry<T>[],
  setSortEntries: Dispatch<SetStateAction<SortEntry<T>[]>>,
) =>
  (property: Property<T>, sortDirection?: SortDirection) => {
    const copy = [...sortEntries];
    const index = copy.findIndex((it) => it.property === property);
    const alreadySorted = index >= 0;

    const currentSortDirection: SortDirection | undefined = alreadySorted
      ? copy[index].direction
      : undefined;

    if (alreadySorted) {
      copy.splice(index, 1);
    }

    let direction = sortDirection;

    if (!direction) {
      if (currentSortDirection === SortDirection.ASC) {
        direction = SortDirection.DESC;
      } else if (currentSortDirection === SortDirection.DESC) {
        direction = undefined;
      } else {
        direction = SortDirection.ASC;
      }
    }

    if (direction) {
      copy.unshift({ property, direction });
    }

    setSortEntries(copy);
  };

const sortValueCallback = <T extends any>(sortEntries: SortEntry<T>[]) =>
  (property: Property<T>): SortDirection | undefined => {
    const entry = sortEntries.find((it) => it.property === property);

    if (entry) {
      return entry.direction;
    }

    return undefined;
  };

export function useSort<T>(items: T[], columns: Column<T>[] = [], initial?: SortEntry<T>): UseSortContext<T> {
  const { t } = useTranslation();

  const [sortEntries, setSortEntries] = useState<SortEntry<T>[]>(initial ? [initial] : []);

  const sort = useMemo(() => sortCallback(sortEntries, setSortEntries), [sortEntries]);
  const getSortDirection = useMemo(() => sortValueCallback(sortEntries), [sortEntries]);

  const result = [...items];

  for (let i = sortEntries.length - 1; i >= 0; i--) {
    const { property, direction } = sortEntries[i];
    const column = columns.find((col) => col.property === property);
    const comparator: Comparator<T> = (column ? column.comparator : undefined) || defaultComparator;

    result.sort((a, b) => (comparator(getTableProp(a, property, t), getTableProp(b, property, t)) * direction));
  }

  return {
    result,
    sort,
    getSortDirection,
  };
}
