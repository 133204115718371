// keep in sync with server/shared/invitation-routes.js

const invitationRoutes = {
  MISSING: "/404",
  EXPIRED: "/expired",
  DEACTIVATED: "/deactivated",
  EMAIL_MISMATCH: "/email-mismatch",
  EXISTING_USER_WRONG_EMAIL: "/user",
  EXISTING_USER_SAME_EMAIL: "/match",
  INTERNAL_ERROR: "/error",
};

export default invitationRoutes;
