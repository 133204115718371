import React from "react";
import { useAuth } from "./auth-hooks";
import { Modal } from "@veneer/core";
import { setAuthLoginError } from "./auth-actions";
import { logout } from "../api/oidc-user-manager";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { RootActionTypes } from "./root-action";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Button from "components/button";

const StyleButton = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const ErrorDetails = styled.div`
  white-space: pre-line;
`;


const AuthError: React.FC = () => {
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();
  const { t } = useTranslation();
  const { errorCode, errorMessage, errorInfo } = useAuth();

  const clearError = () => {
    dispatch(setAuthLoginError());
    logout().catch(() => {});
  };

  return (
    <Modal size="sm" title={t("error.title")} show dismissOnOverlayClick={false} onClose={clearError}>
      <p>{t("error.login")}</p>
      {!!errorMessage && !errorInfo && (
        <ErrorDetails>{t("error.loginDetails", { errorMessage })}</ErrorDetails>
      )}
      {errorInfo && (
        <>
          <ErrorDetails>{t(`error.loginCode.${errorCode || "UNKNOWN"}`)}</ErrorDetails>
          {!!errorInfo.provider && <ErrorDetails>{t("error.loginProvider", errorInfo)}</ErrorDetails>}
          {!!errorInfo.tenant && <ErrorDetails>{t("error.loginTenant", errorInfo)}</ErrorDetails>}
          {!!errorInfo.cognitoIdp && <ErrorDetails>{t("error.loginCognitoIdp", errorInfo)}</ErrorDetails>}
        </>
      )}
      <StyleButton>
        <Button onClick={clearError}>{t("close")}</Button>
      </StyleButton>
    </Modal>
  );
};

export default AuthError;
