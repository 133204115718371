import React, { useMemo } from "react";
import { Panel } from "@veneer/core";
import { OrderStatus, orderStatusMap, ShipmentGroup } from "../../../types/order";
import CollapsibleView from "../../../components/collapsibleView";
import styled from "styled-components";
import dates from "../../../shared/dates";
import { useTranslation } from "react-i18next";

interface Props {
  group: ShipmentGroup;
  index?: number;
}

const Wrapper = styled.div`
  position: relative;
`;

const Header = styled.div`
  position: absolute;
  pointer-events: none;
  background-color: ${(props) => props.theme.colors.background5};
  width: 100%;
  z-index: 19;
  padding: 20px;

  > span.combo {
    display: block;
    font-size: 1em;
    margin-top: 8px;
    color: ${(props) => props.theme.colors.body};

    @media ${(props) => props.theme.breakpoints.laptop} {
      display: none;
    }

    > span {
      position: relative;
      margin-left: 1em;
      padding-left: 1em;
      border-left: 1px solid ${(props) => props.theme.colors.body};
    }
  }

  h4 {
    z-index: 21;
    margin-bottom: 0;

    &.green {
      font-weight: bold;
      color: ${(props) => props.theme.colors.success};
    }

    &.red {
      font-weight: bold;
      color: ${(props) => props.theme.colors.error};
    }
    
    &.blue {
      font-weight: normal;
      color: ${(props) => props.theme.colors.darkBlue};
      font-size: 1.25em;
      margin-top: 8px;
    }

    > span {
      font-size: 16px;
      margin-top: 16px;
      color: ${(props) => props.theme.colors.body};
    }

    > span.info {
      position: absolute;
      left: 40%;

      @media ${(props) => props.theme.breakpoints.tabletMaximum} {
        display: none;
      }
    }

    > span.status {
      float: right;
      margin-right: 56px;

      @media ${(props) => props.theme.breakpoints.tabletMaximum} {
        display: none;
      }
    }
  }
`;

const StyledPanel = styled(Panel)`
  z-index: 20;
  .vn-panel__header {
    border: none;
    height: 97px;

    svg {
      z-index: 22;
      margin-top: -4px;
      margin-right: 16px;
    }

    @media ${(props) => props.theme.breakpoints.tabletMaximum} {
      height: 126px;

      svg {
        margin-top: -32px;
      }

      .vn-panel__title svg {
        top: 0px
      }
    }
  }
`;

interface GroupHeaderParams {
  status: string;
  header?: string;
  date?: string;
  quantity: number;
  index?: number;
}

const GroupHeaderComponent: React.FC<GroupHeaderParams> = ({ status, header = status, date, quantity, index }) => {
  const { t } = useTranslation();

  return (
    <Header>
      <h4 className={/^cancell?ed$/i.test(status) ? "red" : "green"}>
        {header}
        {date ? ` ${dates.formatUTC(date, dates.formats.pretty)}` : null}
        <span className="info">{t("orderstatus.shipmentGroup.totalQty", { quantity, postProcess: "upper" })}</span>
        <span className="status">{status}</span>
      </h4>
      {typeof index === "number" && (
        <h4 className="blue">{t("orderstatus.shipmentGroup.groupNumber", { number: index + 1 })}</h4>
      )}
      <span className="combo">{t("orderstatus.shipmentGroup.totalQtyShort", { quantity, postProcess: "upper" })} <span>{status}</span></span>
    </Header>
  );
};

const isCancelled = (group: ShipmentGroup) => /^cancell?ed$/i.test(group.status || "");
const groupQuantity = (group: ShipmentGroup) => group.products.map((p) => +p.quantity).reduce((a, b) => a + b, 0);

const GroupHeader: React.FC<Props> = ({ group, index }) => {
  const quantity = useMemo(() => groupQuantity(group), [group]);
  const mappedStatus = orderStatusMap[group.status?.toUpperCase() as OrderStatus] ?? group.status;

  if (group.status?.toUpperCase() === OrderStatus.CANCELLED || group.status?.toUpperCase() === "CANCELED") {
    return <GroupHeaderComponent status={mappedStatus} index={index} quantity={quantity} />;
  }

  if (group.status?.toUpperCase() === OrderStatus.DELIVERED) {
    return <GroupHeaderComponent status={mappedStatus} date={group.deliveryDate} index={index} quantity={quantity} />;
  }

  if (group.plannedDeliveryDate) {
    return <GroupHeaderComponent status={mappedStatus} header="Estimated Delivery" date={group.plannedDeliveryDate} index={index} quantity={quantity} />;
  }

  if (group.shipDate) {
    return <GroupHeaderComponent status={mappedStatus} header="Shipped" date={group.shipDate} index={index} quantity={quantity} />;
  }

  if (group.plannedShipDate) {
    return <GroupHeaderComponent status={mappedStatus} header="Planned Shipping" date={group.plannedShipDate} index={index} quantity={quantity} />;
  }

  return <GroupHeaderComponent status={mappedStatus} index={index} quantity={quantity} />;
};

const ShipmentGroupPanel: React.FC<Props> = ({ group, children, index }) => {
  return (
    <Wrapper>
      <GroupHeader group={group} index={index} />
      <CollapsibleView collapsible collapsed={isCancelled(group)} title="" Styled={StyledPanel}>
        {children}
      </CollapsibleView>
    </Wrapper>
  );
};

export default ShipmentGroupPanel;
