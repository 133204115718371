import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

interface TileBoxProps {
  $hoverBorder: string;
}

interface TileWrapperProps {
  homePage?: boolean;
}

const TileWrapper = styled.div<TileWrapperProps>`
  width: 100%;
  a {
    position: relative;
    padding: 0 16px 16px 16px;
    display: block;
    color: ${props => props.theme.colors.body};
    text-decoration: none;
    width: 100%;
    cursor: default;
    &:hover,
    &:active {
      text-decoration: none;
      color: ${props => props.theme.colors.body};
    }
  }
  @media ${(props) => props.theme.breakpoints.tablet} {
    display: flex;
    width: 50%;
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    width: ${props => (props.homePage ? "50%" : "33.333%")};
    a {
      margin-bottom: 16px;
    }
  }
  @media ${(props) => props.theme.breakpoints.desktop} {
    width: ${props => (props.homePage ? "33.333%" : "25%")};
  }
  @media ${(props) => props.theme.breakpoints.mobileMaximum} {
    a {
      padding: 0 8px 8px 8px;
    }
  }
  @media ${(props) => `${props.theme.breakpoints.tablet} and ${props.theme.breakpoints.tabletMaximum}`} {
    width: ${props => (props.homePage ? "100%" : "33.333%")};
    a {
        padding: 10px 10px 10px 10px;
    } 
  }
`;

const TileBox = styled.div<TileBoxProps>`
  display: flex;
  align-items: center;
  padding: 10px 16px;
  border: ${props => `1px solid ${props.theme.colors.border}`};
  border-radius: 4px;
  border-left-color: ${props => props.theme.colors.blue};
  border-left-width: 4px;
  background: ${props => props.theme.colors.white};
  cursor: pointer;
  > .icon {
    height: 60px;
    width: 60px;
    margin: 0px 10px 0px 0px;
  }

  @media ${(props) => props.theme.breakpoints.mobileMaximum} {
    padding: 10px 16px 10px 10px;
    align-items: normal;
    > .icon {
      margin: 0px 20px 0px 0px;
    }
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: 20px;
    width: 100%;
    height: 100%;
    min-height: 280px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    border: 2px solid transparent;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    user-select: none;
    &:hover {
      border-color: ${props => props.theme.colors[props.$hoverBorder]};
    }

    > .icon {
      margin: 0 auto;
    }
  }
`;

const Title = styled.h5`
  position: relative;
  overflow-wrap: break-word;
  font-size: 16px;
  text-align: left;
  @media ${(props) => props.theme.breakpoints.tablet} {
    text-align: center;
    font-size: 20px;
    margin-top: 20px;
  }
`;

const Description = styled.p`
  margin: 20px 0 10px 0px;
  display: block;
  @media ${(props) => props.theme.breakpoints.mobileMaximum} {
    width: 100%;
  }
`;

const TileAndDescription = styled.div`
  display: inline-table;
  width:100%;
  @media ${(props) => props.theme.breakpoints.mobileMaximum} {
    width:88%;
  }
  @media ${(props) => props.theme.breakpoints.mobile640} {
    width:85%;
  }
  @media ${(props) => props.theme.breakpoints.mobile540} {
    width:82%;
  }
  @media ${(props) => props.theme.breakpoints.mobile467} {
    width:79%;
  }
  @media ${(props) => props.theme.breakpoints.mobile385} {
    width:76%;
  }
  @media ${(props) => props.theme.breakpoints.mobile355} {
    width:72%;
  }
`;

interface Props {
  children?: React.ReactNode;
  link?: string;
  to?: string;
  theme?: string;
  target?: string;
  icon?: JSX.Element;
  title: string;
  description?: string;
  homePage?: boolean;
  subDescription?: string;
}

const Tile: React.FC<Props> = ({
  children,
  to,
  link,
  icon,
  title,
  description,
  theme = "blue",
  target = "_blank",
  homePage,
  subDescription,
}) => {
  const content = (
    <TileBox $hoverBorder={theme}>
      {icon}
      <TileAndDescription>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
        {subDescription && <p>{subDescription}</p>}
        {children}
      </TileAndDescription>
    </TileBox>
  );

  return (
    <TileWrapper homePage={homePage}>
      {to && <Link to={to}>{content}</Link>}
      {link && (
        <a href={link} target={target} rel="noopener">
          {content}
        </a>
      )}
    </TileWrapper>
  );
};

export default Tile;
