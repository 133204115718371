import { getJson, putJson } from "./request";
import { Me } from "../types/user";

export const getMe = (): Promise<Me> => getJson(
  "/me",
  (res) => res.json()
    .catch(() => undefined)
    .then((json: any) => {
      if (res.status !== 200) {
        const error = new Error(res.statusText) as any;

        error.status = res.status;
        error.json = json;
        throw error;
      }

      if (json.coolDown) {
        json.coolDown = new Date(json.coolDown);
      }

      return json;
    }),
);

export const putPreference = (preference: string, value: any): Promise<any> =>
  putJson("/me/preference", { preference, value });

export const putAllPreferences = (preferences: { [key: string]: any }): Promise<any> =>
  putJson("/me/allPreferences", { preferences });

export const putEmailRegionsPreference = (emailRegions: string[]): Promise<any> =>
  putJson("/me/updateEmailRegionsPreference", { emailRegions });

export default getMe;
