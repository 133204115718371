import {
  SHOPPING_CART_CLOSE,
  SHOPPING_CART_CONTENTS,
  SHOPPING_CART_ERROR,
  SHOPPING_CART_LOADING,
  SHOPPING_CART_OPEN,
  SHOPPING_CART_PATCH,
  SHOPPING_CART_REGEX,
  SHOPPING_CART_RESET,
  SHOPPING_CART_SELECT_LOCALIZATION_CODE,
  SHOPPING_CART_SET_DEFAULT_LOCALIZATION_CODE,
  SHOPPING_CART_SUBMITTING,
} from "./shopping-cart-action";
import { RootActionTypes } from "./root-action";
import { initialShoppingCartState, ShoppingCartState } from "redux/redux-state";

const shoppingCartReducer = (
  state: ShoppingCartState = initialShoppingCartState,
  action: RootActionTypes,
): ShoppingCartState => {
  if (!SHOPPING_CART_REGEX.test(action.type)) {
    return state;
  }

  switch (action.type) {
    case SHOPPING_CART_OPEN:
      return {
        ...state,
        showShoppingCart: true,
      };

    case SHOPPING_CART_CLOSE:
      return {
        ...state,
        showShoppingCart: false,
      };

    case SHOPPING_CART_ERROR:
      return {
        ...state,
        submitting: false,
        error: action.error,
        loading: false,
      };

    case SHOPPING_CART_CONTENTS:
      return {
        ...state,
        contractId: undefined,
        country: undefined,
        site: undefined,
        bigDealNumber: undefined,
        hpfsNumber: undefined,
       ...action.cart,
        loading: false,
      };

    case SHOPPING_CART_RESET:
      return {
        ...initialShoppingCartState,
      };

    case SHOPPING_CART_PATCH:
      return {
        ...state,
        ...action.value,
      };

    case SHOPPING_CART_LOADING:
      return {
        ...state,
        loading: action.loading === undefined ? true : action.loading,
      };

    case SHOPPING_CART_SUBMITTING:
      return {
        ...state,
        submitting: action.submitting === undefined ? true : action.submitting,
      };

    case SHOPPING_CART_SELECT_LOCALIZATION_CODE:
      return {
        ...state,
        localizationCodeSelectionInfo: action.localizationSkuSelectionInfo,
      };

    case SHOPPING_CART_SET_DEFAULT_LOCALIZATION_CODE:
      return {
        ...state,
        defaultLocalizationCode: action.defaultLocalizationCode,
      };

    default:
      return state;
  }
};

export default shoppingCartReducer;
