import React from "react";
import { Icon } from "@veneer/core";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  width: 100%;
  color: ${props => props.theme.colors.white};
  background: ${(props) => props.theme.colors.darkBlue};
  border-radius: 2px;
  svg {
    flex-shrink: 0;
  }
  @media ${(props) => props.theme.breakpoints.tablet} {
    flex-direction: row;
  }
`;

const HeaderContent = styled.div`
  margin-top: 20px;
  h1 {
    font-size: 24px;
    margin-bottom: 12px;
    line-height: 32px;
  }
  p {
    font-size: 16px;
    max-width: 1000px;
  }
  @media ${(props) => props.theme.breakpoints.tablet} {
    flex-direction: row;
    margin-top: 0;
    margin-left: 20px;
    h1 {
      font-size: 32px;
    }
  }
`;

const HomepageHeader: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Header>
      <Icon name="hp" size={32} />
      <HeaderContent>
        <h1>{t("home.welcometitle")}</h1>
        <p>{t("home.welcomedescription")}</p>
      </HeaderContent>
    </Header>
  );
};

export default HomepageHeader;
