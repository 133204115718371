import styled from "styled-components";

interface PageSectionProps {
  centered?: boolean;
}

const PageSection = styled.div<PageSectionProps>`
  display: flex;
  align-items: center;
  justify-content: ${props => (props.centered ? "center" : "space-between")};
  text-align: ${props => (props.centered ? "center" : "inherit")};
  margin: 30px 0px 0px 0px;
`;

export default PageSection;
