import React, { useState } from "react";
import { ConfirmationModal, LabeledIcon, Spinner } from "@veneer/core";
import { withRouter, RouteComponentProps } from "react-router";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Dispatch } from "redux";
import { clearShoppingCart } from "api/shopping-cart";
import { useCurrentCustomerID } from "redux/current-customer-hooks";
import { asyncGetCart } from "redux/shopping-cart-thunks";
import { RootActionTypes } from "../../../redux/root-action";
import { useDispatch } from "react-redux";
import {
    resetShoppingCart
  } from "redux/shopping-cart-action";

interface StyleModalProps {
    inCart?: boolean;
    disable?: boolean;
}

interface Props extends RouteComponentProps{
  clearCart: boolean;
  setClearCart(value: boolean): void;
  inCart?: boolean;
}

const StyleText = styled.div`
  font-size: 23px;
  font-weight: bold;
  margin-top: -20px;
`;

const StyleSpinner = styled.div`
    margin-top: 30px;
    z-index: 1;
    margin-left: 95px;
`;

const StyleModal = styled.div<StyleModalProps>`
    @media ${(props) => props.theme.breakpoints.tablet} {
        .vn-button {
            width: 45%;
        }
    }
    @media ${(props) => props.theme.breakpoints.laptop} {    
        .vn-icon--size-24 {
            height: 40px;
            width: 40px;
        }

        .vn-modal {
            background-color: ${props => (props.inCart ? "rgba(0, 0, 0, 0)" : "rgba(0, 0, 0, 0.4)")};
            overflow-y: ${props => (props.inCart ? "hidden" : "auto")};
        }
        
        .vn-modal__dialog {
            position: ${props => (props.inCart ? "absolute" : "relative")};
            top: ${props => (props.inCart ? "70px" : "unset")};
            right: ${props => (props.inCart ? "40px" : "unset")};
            width: ${props => (props.inCart ? "35%" : "480px")};
            justify-content: ${props => (props.inCart ? "right" : "unset")};
            height: ${props => (props.inCart ? "auto" : "unset")};
        }

        .vn-modal__content {
            height: ${props => (props.inCart ? "350px" : "unset")};
            opacity: ${props => (props.inCart ? "0.92" : "unset")};
        }
    }
    .vn-button--primary {
        background-color: ${props => (props.theme.colors.white)};
        color: ${props => (props.theme.colors.gray)};
    }

    .vn-button--secondary {
        background-color: ${props => (props.disable ? props.theme.colors.lightGray : props.theme.colors.blue)};
        color: ${props => (props.theme.colors.white)};
    }

    .vn-modal__close {
        display: none;
    }
`;

const ClearCartWarningPage: React.FC<Props> = ({ history, clearCart, setClearCart, inCart }) => {
  const { t } = useTranslation();
  const customerId = useCurrentCustomerID();
  const [customSpinner, showCustomSpinner] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(false);
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();
  const close = () => {
    setClearCart(false);
  };

  const confirm = async () => {
    showCustomSpinner(true);
    setDisable(true);
    await clearShoppingCart(customerId).then(() => {
        dispatch(resetShoppingCart());
        dispatch(asyncGetCart() as any);
        showCustomSpinner(false);
        setClearCart(false);
        setDisable(false);
        if (!inCart) {
          history.push(`/${customerId}/orders/new-order/contract`);
        }
    });
  };

  return (
    <StyleModal inCart={inCart} disable={disable}>
      <ConfirmationModal
        show={clearCart}
        onClose={() => confirm()}
        onConfirm={() => close()}
        size="sm"
        closeButtonLabel={t("order.clearCart.warning.button")}
        confirmButtonLabel={t("btn.cancel")}
      >
        <div className="clearCart">
          <LabeledIcon
            name="incidents"
            size={24}
            color="error"
            labelColor="black"
            label=""
          />
        </div>
        <StyleText><br />{t("order.clearCart.warning")}</StyleText>
        {customSpinner && (<StyleSpinner className="customSpinnerParent"><Spinner id="mySpinner" /></StyleSpinner>)}
      </ConfirmationModal>
    </StyleModal>
  );
};

export default withRouter(ClearCartWarningPage);
