import { addToast, ToastMessage, ToastType } from "./toast-actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux-state";
import { Dispatch } from "redux";
import { RootActionTypes } from "./root-action";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export type AddToastFn = (message: string, translate?: boolean) => void;

export const useToasts = (): ToastMessage[] =>
  useSelector((state: RootState) => state.toast.messages);

export const useAddToast = (toastType: ToastType): AddToastFn => {
  const { t } = useTranslation();
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();

  return useCallback(
    (message?: string, translate: boolean = false) => {
      if (!message) {
        return;
      }

      dispatch(addToast(toastType, translate ? t(message) : message));
    },
    [toastType, dispatch, t],
  );
};

export const useAddError = (): AddToastFn => useAddToast(ToastType.ERROR);
export const useAddSuccess = (): AddToastFn => useAddToast(ToastType.SUCCESS);
export const useAddInfo = (): AddToastFn => useAddToast(ToastType.INFO);
