import { currencies } from "./currencies";
import unique from "./unique";
import numeral from "numeral";

type CurrencyCodeOverrideFunction = (formatted: string) => string;

interface CurrencyCodeOverrideMap {
  [key: string]: CurrencyCodeOverrideFunction;
}

export const currencyFormats = unique(currencies.map(({ currencyCode }) => currencyCode))
  .map((currencyCode) => ({
    currencyCode,
    format: new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currencyCode,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
  }))
  .reduce<{[key: string]: Intl.NumberFormat}>(
    (obj, { currencyCode, format }) => {
      obj[currencyCode] = format;
      return obj;
    },
    {},
  );

const currencyCodeOverrides: CurrencyCodeOverrideMap = {
  USD: (formatted: string) => (/^\$/.test(formatted) ? `US${formatted}` : formatted),
};

export const formatCurrency = (value: number = 0, currencyCode?: string): string => {
  if (!currencyCode) {
    return `$${numeral(value).format("0,0.00")}`;
  }

  const formatter = currencyFormats[currencyCode];

  if (!formatter) {
    return `${currencyCode} ${numeral(value).format("0,0.00")}`;
  }

  let formatted = formatter.format(value);

  if (currencyCodeOverrides[currencyCode]) {
    formatted = currencyCodeOverrides[currencyCode](formatted);
  }

  return formatted;
};
