import React, { useEffect } from "react";
import { Spinner } from "@veneer/core";
import styled from "styled-components";

const FixedPanel = styled.div`
  display: flex;
  height: ${(props) => props.className || 100}vh;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

interface PageSpinnerProps {
  className?: string;
  height?: string;
  size?: number;
}

/**
 * A full screen spinner for page loading
 */
const PageSpinner: React.FC<PageSpinnerProps> = ({ height, size }) => {
  useEffect(() => {
    (window as any).isLoading = true;

    return () => {
      (window as any).isLoading = false;
    };
  });

  return (
    <FixedPanel className={height}>
      <Spinner delay={300} size={size ? size : 64} />
    </FixedPanel>
  );
};

export default PageSpinner;
