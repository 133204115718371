import React, { useMemo, useState } from "react";
import { useCurrentCustomer } from "../../../redux/current-customer-hooks";
import { Field, Formik } from "formik";
import styled from "styled-components";
import Typeahead, { TYPEAHEAD_QUERY_STR } from "../../../components/forms/typeahead";
import { useDispatch } from "react-redux";
import { useHardwareSupportParams } from "../../../redux/case-request-hooks";
import { changeCaseRequestOrderNumber } from "../../../redux/case-request-actions";
import { RootActionTypes } from "../../../redux/root-action";
import { Dispatch } from "redux";
import { useTranslation } from "react-i18next";
import CaseRequestModalForwardButton from "./case-request-modal-forward-button";

const TypeaheadStyle = styled.div`
  .tt-suggestion {
    height: fit-content;
    span {
      position: relative;
      font-size: 12px;
      line-height: 75%;
      top: -4px;
      color: ${(props) => props.theme.colors.gray};
    }
  }
`;

const CaseRequestModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  > * + * {
    margin-left: 10px;
  }
`;

interface CaseRequestHardwareForm {
  country: string;
  category: string;
  orderNumber: string;
  currentStep: number;
}

const CaseRequestHardwareOrderStep: React.FC = () => {
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();
  const { customer } = useCurrentCustomer();
  const { t } = useTranslation();
  const { currentStep, orderNumber } = useHardwareSupportParams();
  const [orderNumberOnPage, setOrderNumberOnPage] = useState<string>("");

  const initialValues: CaseRequestHardwareForm = useMemo(
    () => ({
      category: "",
      orderNumber: orderNumber ?? "",
      country: "",
      currentStep: currentStep ?? "",
    }),
    [currentStep, orderNumber],
  );

  const displayValueChangedFunction = (val:string) => {
    dispatch(changeCaseRequestOrderNumber(val));
  };

  const canContinue = !!(orderNumber && orderNumberOnPage && orderNumber === orderNumberOnPage);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={() => {}}
      >
        {({ values, setFieldValue }) => {
          setOrderNumberOnPage(values.orderNumber);
          return (
            <>
              <TypeaheadStyle>
                <Field
                  label={t("orderstatus.ordernumber")}
                  name="orderNumber"
                  placeholder={t("orderstatus.ordernumber")}
                  component={Typeahead}
                  remoteUrl={`/orders/orderNumber?customerId=${encodeURIComponent(customer.id)}&orderNumber=${TYPEAHEAD_QUERY_STR}`}
                  initialDisplayValue={orderNumber || values.orderNumber || ""}
                  update={setFieldValue}
                  updateMapping={{
                    orderNumber: "singleOrderNumber",
                    country: "country"
                  }}
                  valueChanged={displayValueChangedFunction}
                  fieldKey="singleOrderNumber"
                  filterKeys={["singleOrderNumber"]}
                  templates={{ suggestion: "{{ suggestion.singleOrderNumber}}" }}
                  required
                />
              </TypeaheadStyle>
            </>
          );
        }}
      </Formik>
      <CaseRequestModalButtonWrapper>
        <CaseRequestModalForwardButton canContinue={canContinue} />
      </CaseRequestModalButtonWrapper>
    </>
  );
};

export default CaseRequestHardwareOrderStep;
