import React from "react";

import PageNoMenu from "components/layouts/page-no-menu";
import ProfileView from "../../profile";

const RegistrationProfile: React.FC = () => (
  <PageNoMenu>
    <ProfileView />
  </PageNoMenu>
);

export default RegistrationProfile;
