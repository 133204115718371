import React from "react";
import { Modal } from "@veneer/core";
import { useReportDownloadModalState, useReportDownloadTitle } from "../../../redux/report-download-hooks";
import ReportModalState from "./report-modal-state";
import ReportModalContent from "./report-modal-content";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { RootActionTypes } from "../../../redux/root-action";
import { downloadReportExit } from "../../../redux/report-download-actions";
import Clearfix from "../../clearfix";
import { useTranslation } from "react-i18next";

const ReportModal: React.FC = () => {
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();
  const { t } = useTranslation();
  const modalState = useReportDownloadModalState();
  const heading = useReportDownloadTitle();

  const title = t("reports.modal.title", { reportName: heading });

  const onClose = () => dispatch(downloadReportExit());

  if (modalState === ReportModalState.HIDDEN) {
    return null;
  }

  return (
    <Modal show title={title} size="lg" onClose={onClose} dismissOnOverlayClick={false}>
      <ReportModalContent />
      <Clearfix />
    </Modal>
  );
};

export default ReportModal;
