import { useEffect, useState, useCallback } from "react";
import { getOrderById, approveOrder, rejectOrder, cancelOrderRequest, approveOrderCancelRequest, updateOrderStatusManually, revertCancelOrderRequest } from "api/order";
import Order, { OrderStatusType } from "types/order";
import { FetchError } from "types/fetch-error";
import { useAddError } from "../../redux/toast-hooks";

export enum OrderSubmissionState {
  NONE = 0,
  APPROVE = 1,
  REJECT = 2,
  CANCEL = 3,
  APPROVE_CANCEL = 4,
  UPDATE_STATUS = 5,
}

interface UseOrder {
  error?: FetchError;
  loading: boolean;
  submitting: OrderSubmissionState;
  order?: Order;
  refreshOrder(order?: Order): Promise<void>;
  approve(): Promise<void>;
  reject(msg: string): Promise<void>;
  cancelRequest(msg: string): Promise<void>;
  revertCancelRequest(): Promise<void>;
  approveRequest(msg: string): Promise<void>;
  updateOrderStatus(status: any): Promise<void>;
  patchOrder(partial: Partial<Order>): void;
}

const useOrder = (orderId: string): UseOrder => {
  const [loading, setLoading] = useState(!!orderId);
  const [submitting, setSubmitting] = useState<OrderSubmissionState>(OrderSubmissionState.NONE);
  const [error, setError] = useState();
  const [order, setOrder] = useState<Order>();
  const addError = useAddError();

  const refreshOrder = useCallback(
    async (order?: Order): Promise<void> => {
      if (order) {
        setOrder(order);
        setLoading(false);
        return undefined;
      }

      if (orderId) {
        setLoading(true);

        return getOrderById(orderId)
          .then((order) => {
            setOrder(order);
            setError(undefined);
            setLoading(false);
          })
          .catch((error) => {
            console.error(error);
            setError(error);
            setLoading(false);
          });
      }
    },
    [orderId],
  );

  const approve = useCallback(
    (): Promise<void> => {
      setSubmitting(OrderSubmissionState.APPROVE);

      return approveOrder(orderId)
        .then((order) => {
          setOrder(order);
          setSubmitting(OrderSubmissionState.NONE);
        })
        .catch((error) => {
          addError(error.message, true);
          setSubmitting(OrderSubmissionState.NONE);
        });
    },
    [orderId, addError],
  );

  const reject = useCallback(
    (msg: string): Promise<void> => {
      setSubmitting(OrderSubmissionState.REJECT);

      return rejectOrder(orderId, msg)
        .then((order) => {
          setOrder(order);
          setSubmitting(OrderSubmissionState.NONE);
        })
        .catch((error) => {
          addError(error.message, true);
          setSubmitting(OrderSubmissionState.NONE);
        });
    },
    [orderId, addError],
  );

  const cancelRequest = useCallback(
    (msg: string): Promise<void> => {
      setSubmitting(OrderSubmissionState.CANCEL);

      return cancelOrderRequest(orderId, msg)
        .then((order) => {
          setOrder(order);
          setSubmitting(OrderSubmissionState.NONE);
        })
        .catch((error) => {
          addError(error.message, true);
          setLoading(false);
        });
    },
    [orderId, addError],
  );

  const revertCancelRequest = useCallback((): Promise<void> => {
    setSubmitting(OrderSubmissionState.APPROVE);

    return revertCancelOrderRequest(orderId)
      .then((order) => {
        setOrder(order);
        setSubmitting(OrderSubmissionState.NONE);
      })
      .catch((error) => {
        addError(error.message, true);
        setLoading(false);
      });
  }, [addError, orderId]);

  const approveRequest = useCallback(
    (msg: string): Promise<void> => {
      setSubmitting(OrderSubmissionState.APPROVE_CANCEL);

      return approveOrderCancelRequest(orderId, msg)
        .then((order) => {
          setOrder(order);
          setSubmitting(OrderSubmissionState.NONE);
        })
        .catch((error) => {
          addError(error.message, true);
          setSubmitting(OrderSubmissionState.NONE);
        });
    },
    [orderId, addError],
  );

  const updateOrderStatus = useCallback(
    (status: OrderStatusType): Promise<void> => {
      setSubmitting(OrderSubmissionState.UPDATE_STATUS);

      return updateOrderStatusManually(orderId, status)
        .then((order) => {
          setOrder(order);
          setSubmitting(OrderSubmissionState.NONE);
        })
        .catch((error) => {
          addError(error.message, true);
          setSubmitting(OrderSubmissionState.NONE);
        });
    },
    [orderId, addError],
  );

  const patchOrder = useCallback(
    (partial: Partial<Order>): void => {
      if (order) {
        const replacementOrder = { ...order, ...partial };
        setOrder(replacementOrder);
      }
    },
    [order],
  );

  useEffect(() => { refreshOrder(); }, [refreshOrder]);

  return {
    loading,
    submitting,
    error,
    order,
    approve,
    refreshOrder,
    reject,
    cancelRequest,
    revertCancelRequest,
    approveRequest,
    updateOrderStatus,
    patchOrder,
  };
};

export default useOrder;
