import React from "react";
import styled from "styled-components";
import { Icon } from "@veneer/core";
import { useTranslation } from "react-i18next";
import { useCanPlaceOrder } from "../redux/combo-hooks";

const StyleWarning = styled.div`
  margin-top: 20px;
  display: flex;
  .vn-icon--size-24 {
    height: 30px;
    width: 30px;
  }
  @media ${(props) => props.theme.breakpoints.tabletMaximum} {
    .vn-icon--size-24 {
      height: 35px;
      width: 35px;
    }
  }
`;

const StyleWarningText = styled.div`
  color: ${(props) => props.theme.colors.lightGray};
  margin-left: 10px;
  margin-top: 5px;
`;

const PlaceOrderButtonWarning: React.FC = () => {
  const { t } = useTranslation();
  const { showWarning } = useCanPlaceOrder();

  if (!showWarning) {
    return null;
  }

  return (
    <StyleWarning>
      <Icon name="incidents" color="warning" />
      <StyleWarningText>{t("contract.setup.placeorder.warning")}</StyleWarningText>
    </StyleWarning>
  );
};

export default PlaceOrderButtonWarning;
