import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "@veneer/core";
import NoticeBoard from "types/notice-board";
import dates from "../../../shared/dates";

interface Props {
  message: NoticeBoard;
  onClose(): void;
  show: boolean;
}

const ExpiryDateMessageModal: React.FC<Props> = ({ message, show, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal size="sm" show={show} onClose={onClose}>
      {`${t("expire")} ${dates.format(message.expiryDate ? message.expiryDate : "", dates.formats.standard)}`}
    </Modal>
  );
};

export default ExpiryDateMessageModal;
