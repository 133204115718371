import clone from "clone-deep";
import hasPermission, { OrPermission } from "../shared/has-permission";
import {
  AUTH_PATCH_COOL_DOWN,
  AUTH_PATCH_PREFERENCES,
  AUTH_REGEX,
  AUTH_SET_LOADING,
  AUTH_SET_LOGGING_IN,
  AUTH_SET_LOGIN_ERROR,
  AUTH_SET_ME
} from "./auth-actions";
import { RootActionTypes } from "./root-action";
import { AuthState, initialAuthState } from "redux/redux-state";

const CONSUMERS_TENANT = "9188040d-6c67-4c5b-b112-36a304b66dad";

const authReducer = (
  state: AuthState = initialAuthState,
  action: RootActionTypes,
): AuthState => {
  if (!AUTH_REGEX.test(action.type)) {
    return state;
  }

  const result: AuthState = { ...state };

  switch (action.type) {
    case AUTH_SET_ME:
      result.currentUser = action.me;
      result.loading = action.loading;
      break;
    case AUTH_PATCH_PREFERENCES: {
      const preferences = clone(result.currentUser.preferences);
      const { emailRegions = result.currentUser.emailRegions, ...rest } = action.preferences;

      for (const [k, v] of Object.entries(rest)) {
        if (v === undefined && Object.prototype.hasOwnProperty.call(preferences, k)) {
          delete preferences[k];
        } else if (v !== undefined) {
          preferences[k] = v;
        }
      }

      result.currentUser = { ...result.currentUser, preferences, emailRegions };
      break;
    }
    case AUTH_PATCH_COOL_DOWN: {
      let { coolDown } = action;
      if (!coolDown) {
        result.currentUser = { ...result.currentUser, coolDown: undefined };
        break;
      } else if (typeof coolDown === "string") {
        coolDown = new Date(coolDown);
      }

      result.currentUser = { ...result.currentUser, coolDown };
      break;
    }
    case AUTH_SET_LOADING:
      result.loading = action.loading;
      break;
    case AUTH_SET_LOGGING_IN:
      result.loading = action.loggingIn;
      result.loggingIn = action.loggingIn;
      break;
    case AUTH_SET_LOGIN_ERROR:
      if (action.errorCode === "FEDERATED_LOGIN_UNACCEPTABLE" && action.errorInfo?.provider === "azure" && action.errorInfo?.tenant === CONSUMERS_TENANT) {
        result.errorCode = "FEDERATED_LOGIN_CONSUMERS_UNACCEPTABLE";
        result.errorMessage = action.errorMessage;
        result.errorInfo = action.errorInfo;
      } else if (action.errorCode) {
        result.errorCode = action.errorCode;
        result.errorMessage = action.errorMessage;
        result.errorInfo = action.errorInfo;
      } else {
        result.errorCode = undefined;
        result.errorMessage = undefined;
        result.errorInfo = undefined;
      }
      break;
    default:
      return state;
  }

  result.features = result.currentUser?.features ?? {};
  result.authorizedFor = (...permissions: OrPermission) => hasPermission(...permissions)(result.currentUser);

  return result;
};

export default authReducer;
