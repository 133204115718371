import { useAuth } from "../redux/auth-hooks";
import { useCurrentCustomerID } from "../redux/current-customer-hooks";

interface CustomerCardInfo {
  contracts: number;
  customerUsers: number;
  customerName: string;
  primaryContact: string;
  primaryEmail: string;
  defaultContract: string;
  showCard: boolean;
}

/**
 * It return the data related to header cards
 */
const useCardInfo = (): CustomerCardInfo => {
  const { currentUser } = useAuth();
  const currentCustomerId = useCurrentCustomerID();
  const customers = currentUser.customers || [];
  const customer = customers?.find((it) => it.id === currentCustomerId);
  const isExternal = !(currentUser.persona && currentUser.persona.isInternal);

  if (customer && isExternal) {
    return {
      contracts: customer.contracts || 0,
      customerUsers: customer.users || 0,
      customerName: customer.itsmCustomerName ? customer.itsmCustomerName : customer.name || "",
      primaryContact: customer.primaryContactName || "",
      primaryEmail: customer.primaryContactEmail || "",
      defaultContract: customer.defaultContract || "",
      showCard: true,
    };
  }

  return {
    contracts: 0,
    customerUsers: 0,
    customerName: "",
    primaryContact: "",
    primaryEmail: "",
    defaultContract: "",
    showCard: false,
  };
};

export default useCardInfo;
