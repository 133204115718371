import GuestOrderStatusForm from "../pages/order-status/types/guest-order-status-form";
import { FetchError } from "../types/fetch-error";
import { GuestOrderStatus } from "../pages/order-status/types/guest-order-status";

const endpoint = process.env.REACT_APP_ORDER_STATUS_ENDPOINT;

export const postJson = async <T extends any>(url: string, body: Partial<GuestOrderStatusForm>): Promise<T> =>
  fetch(`${endpoint}/${url}`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json" },
  })
    .then(async (res: Response) => {
      if (res.status === 200) {
        return res.json() as Promise<T>;
      }

      const json = await res.json()
        .catch(() => undefined);

      const error = new Error(json?.message ?? res.statusText) as FetchError;
      error.status = res.status;
      error.details = json?.details;

      throw error;
    });

export const requestOrderStatusOTP = (values: GuestOrderStatusForm): Promise<undefined> => postJson("request-otp", {
  emailAddress: values.emailAddress,
  orderNumber: values.orderNumber,
  captcha: values.captcha,
})
  .then(() => undefined);

export const postOrderStatusOTP = (values: GuestOrderStatusForm): Promise<GuestOrderStatus> => postJson("submit-otp", {
  emailAddress: values.emailAddress,
  orderNumber: values.orderNumber,
  otp: values.otp,
});
