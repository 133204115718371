import React from "react";
import { useReportDownloadModalState } from "../../../redux/report-download-hooks";
import ReportModalState from "./report-modal-state";
import ReportModalError from "./report-modal-error";
import ReportModalDownloading from "./report-modal-downloading";
import ReportModalDone from "./report-modal-done";
import ReportModalOptions from "./report-modal-options";

const ReportModalContent: React.FC = () => {
  const modalState = useReportDownloadModalState();

  switch (modalState) {
    case ReportModalState.ERROR:
      return <ReportModalError />;
    case ReportModalState.PICK_CUSTOMER_ANDOR_TYPE:
      return <ReportModalOptions />;
    case ReportModalState.DOWNLOADING:
      return <ReportModalDownloading />;
    case ReportModalState.DONE:
      return <ReportModalDone />;
    default:
      console.warn(`Unknown modal state ${modalState}`);
      return null;
  }
};

export default ReportModalContent;
