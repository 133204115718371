import React from "react";
import { Panel } from "@veneer/core";
import styled from "styled-components";

const StyledPanelNew = styled(Panel)`
  .vn-panel__header {
    background-color: ${props => props.theme.colors.bgV7};
    padding: 10px;
    display: ${props => (props.link ? "none" : "block")};
  }
  .vn-color--neutral-higher {
    color: ${props => props.theme.colors.white} !important;
  }
  .vn-panel__title svg {
    top: 0px;
  }
  .vn-panel__content {
    padding: 20px;
  }
  .vn-panel__title {
    font-size: 15px;
    padding-left: 10px;
    text-transform: uppercase;
    color: ${props => props.theme.colors.white};
  }
  
  @media ${(props) => props.theme.breakpoints.tablet} {
    width: 100%;
    margin-right: ${props => (props.noticeboard ? "20px" : "none")};
    height: fit-content;
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    width: ${props => (props.noticeboard ? "70%" : "100%")};
    margin-right: ${props => (props.noticeboard ? "20px" : "none")};
  }
  @media ${(props) => props.theme.breakpoints.mobileMaximum} {
    margin-bottom: 10px
  }
`;

interface PanelProps {
  children: React.ReactNode;
  title?: string;
  collapsible?: boolean;
  noticeBoard?: boolean;
}

/**
 * Panel based on the veneer panel but has different styles specific to the homepage
 */
const HomepagePanel: React.FC<PanelProps> = ({ children, collapsible = false, title, noticeBoard }) => {
  return (
    <StyledPanelNew title={title} collapsible={collapsible} noticeboard={noticeBoard?.toString()}>
      {children}
    </StyledPanelNew>
  );
};

export default HomepagePanel;
