export const formatTime = (eventTime: string) => {
    if (eventTime) {
      let time: any[] = eventTime.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [eventTime];

      if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? "AM" : "PM";
        time[0] = (parseInt(time[0]) % 12 || 12).toString();
      }
      return time.join(" ");
    }
  };
