import { LocalizationCodeInfo, ShoppingCart } from "types/order";

export const SHOPPING_CART_REGEX = /^shoppingCart\//;

export const SHOPPING_CART_OPEN = "shoppingCart/open";
export const SHOPPING_CART_CLOSE = "shoppingCart/close";
export const SHOPPING_CART_ERROR = "shoppingCart/error";
export const SHOPPING_CART_CONTENTS = "shoppingCart/cart";
export const SHOPPING_CART_RESET = "shoppingCart/reset";
export const SHOPPING_CART_LOADING = "shoppingCart/loading";
export const SHOPPING_CART_SUBMITTING = "shoppingCart/submitting";
export const SHOPPING_CART_SELECT_LOCALIZATION_CODE = "shoppingCart/selectLocalizationCode";
export const SHOPPING_CART_SET_DEFAULT_LOCALIZATION_CODE = "shoppingCart/setDefaultLocalizationCode";
export const SHOPPING_CART_PATCH = "shoppingCart/patch";
export const SHOPPING_CART_VERSION_INCREMENT = "shoppingCart/versionIncrement";

export interface ShoppingCartContentsParams {
  type: typeof SHOPPING_CART_CONTENTS;
  cart: ShoppingCart;
}

export interface ShoppingCartResetParams {
  type: typeof SHOPPING_CART_RESET;
}

export interface OpenShoppingCartParams {
  type: typeof SHOPPING_CART_OPEN;
}

export interface ShoppingCartLoadingParams {
  type: typeof SHOPPING_CART_LOADING;
  loading?: boolean; // default true
}

export interface ShoppingCartSubmittingParams {
  type: typeof SHOPPING_CART_SUBMITTING;
  submitting?: boolean; // default true
}

export interface CloseShoppingCartParams {
  type: typeof SHOPPING_CART_CLOSE;
}

export interface ShoppingCartErrorParams {
  type: typeof SHOPPING_CART_ERROR;
  error?: string;
}

export interface ShoppingCartSelectLocalizationSkuParams {
  type: typeof SHOPPING_CART_SELECT_LOCALIZATION_CODE;
  localizationSkuSelectionInfo?: LocalizationCodeInfo; // undefined to hide
}

export interface ShoppingCartSetDefaultLocalizationCodeParams {
  type: typeof SHOPPING_CART_SET_DEFAULT_LOCALIZATION_CODE;
  defaultLocalizationCode?: string;
}

export interface PatchShoppingCartParams {
  type: typeof SHOPPING_CART_PATCH;
  value: Partial<ShoppingCart>;
}

export interface ShoppingCartVersionIncrement {
  type: typeof SHOPPING_CART_VERSION_INCREMENT;
}

export type ShoppingCartTypes = (
  OpenShoppingCartParams
  | CloseShoppingCartParams
  | ShoppingCartResetParams
  | ShoppingCartErrorParams
  | ShoppingCartContentsParams
  | ShoppingCartLoadingParams
  | ShoppingCartSubmittingParams
  | ShoppingCartSelectLocalizationSkuParams
  | ShoppingCartSetDefaultLocalizationCodeParams
  | PatchShoppingCartParams
  | ShoppingCartVersionIncrement
);

export const openShoppingCart = (): ShoppingCartTypes => ({
  type: SHOPPING_CART_OPEN,
});

export const closeShoppingCart = (): ShoppingCartTypes => ({
  type: SHOPPING_CART_CLOSE,
});

export const shoppingCartLoading = (loading: boolean = true): ShoppingCartTypes => ({
  type: SHOPPING_CART_LOADING,
  loading,
});

export const shoppingCartSubmitting = (submitting: boolean = true): ShoppingCartTypes => ({
  type: SHOPPING_CART_SUBMITTING,
  submitting,
});

export const shoppingCartError = (error?: string): ShoppingCartTypes => ({
  type: SHOPPING_CART_ERROR,
  error,
});

export const setShoppingCart = (cart: ShoppingCart): ShoppingCartTypes => ({
  type: SHOPPING_CART_CONTENTS,
  cart,
});

export const resetShoppingCart = (): ShoppingCartTypes => ({ type: SHOPPING_CART_RESET });

export const showLocalizationSkuDialog = (localizationSkuSelectionInfo: LocalizationCodeInfo): ShoppingCartTypes => ({
  type: SHOPPING_CART_SELECT_LOCALIZATION_CODE,
  localizationSkuSelectionInfo,
});

export const hideLocalizationSkuDialog = (): ShoppingCartTypes => ({
  type: SHOPPING_CART_SELECT_LOCALIZATION_CODE,
  localizationSkuSelectionInfo: undefined,
});

export const setDefaultLocalizationCode = (defaultLocalizationCode?: string): ShoppingCartTypes => ({
  type: SHOPPING_CART_SET_DEFAULT_LOCALIZATION_CODE,
  defaultLocalizationCode,
});

export const clearDefaultLocalizationCode = (): ShoppingCartTypes => ({
  type: SHOPPING_CART_SET_DEFAULT_LOCALIZATION_CODE,
  defaultLocalizationCode: undefined,
});

export const patchShoppingCart = (value: Partial<ShoppingCart> = {}): ShoppingCartTypes => ({
  type: SHOPPING_CART_PATCH,
  value,
});

export const incrementShoppingCartVersion = (): ShoppingCartTypes => ({ type: SHOPPING_CART_VERSION_INCREMENT });
