import { useSelector } from "react-redux";
import deepEqual from "fast-deep-equal";
import { RootState } from "./redux-state";

export const useReportDownloadModalState = () =>
  useSelector((state: RootState) => state.reportDownload.modalState);

export const useReportDownloadError = () =>
  useSelector((state: RootState) => state.reportDownload.error);

export const useReportDownloadTitle = () =>
  useSelector((state: RootState) => state.reportDownload.reportTitle);

export const useReportDownloadState = () =>
  useSelector(({ reportDownload }: RootState) => reportDownload, deepEqual);
