import React, { useState } from "react";
import { Icon } from "@veneer/core";
import styled from "styled-components";
import { CustomerSelectorModal } from "./customer-selector-modal";
import { useAuth } from "../redux/auth-hooks";
import { useCurrentCustomer } from "../redux/current-customer-hooks";
import useLeaveWarning from "contexts/leaveWarning-context";
import WarningLeavingPage from "components/page-leaving-warning-popup";

const CustomerNameButton = styled.button`
  cursor: pointer;
  display: flex;
  background: none;
  border: none;
  padding: 12px;
  flex-direction: row;
  align-items: center;
  font-family: "HP Simplified Light", Arial, sans-serif;

  > span {
    color: ${(props) => props.theme.colors.body};
    margin-right: 6px;
  }
  @media ${(props) => props.theme.breakpoints.mobileMaximum} {
    word-break: break-all;
  }
`;

const CustomerNameIndicator = styled.span`
  display: flex;
  padding: 12px;
  flex-direction: row;
  align-items: center;
`;

const CustomerSelector: React.FC = () => {
  const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false);
  const { currentUser } = useAuth();
  const { customer: currentCustomer } = useCurrentCustomer();
  const { leaveWarning } = useLeaveWarning();
  const [warningPopup, setWarningPopup] = useState<boolean>(false);
  const authCustomers = (currentUser && currentUser.customers) || [];

  const showCustomerSelectionModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (leaveWarning) {
      setWarningPopup(true);
    } else {
      e.preventDefault();
      setShowCustomerModal(true);
    }
  };

  const closeCustomerSelectionModal = () => {
    setShowCustomerModal(false);
  };

  return (
    <>
      {authCustomers.length > 1 ? (
        <CustomerNameButton type="button" onClick={showCustomerSelectionModal}>
          <span>{currentCustomer.itsmCustomerName || currentCustomer.name}</span>
          <Icon name="chevron" size={16} color="black-60" />
        </CustomerNameButton>
      ) : (
        <CustomerNameIndicator>
          {currentCustomer.itsmCustomerName || currentCustomer.name}
        </CustomerNameIndicator>
      )}
      <CustomerSelectorModal show={showCustomerModal} onClose={closeCustomerSelectionModal} />
      <WarningLeavingPage
        values={warningPopup}
        setValue={setWarningPopup}
        screenChange={() => {
          setShowCustomerModal(true);
        }}
      />
    </>
  );
};

export default CustomerSelector;
