import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const TileWrapper = styled.div`
  width: 100%;
  a {
    position: relative;
    padding: 0px;
    display: block;
    color: ${props => props.theme.colors.body};
    text-decoration: none;    
    width: 100%;
    cursor: default;
    &:hover,
    &:active {
      text-decoration: none;
      color: ${props => props.theme.colors.body};
    }
  }
  @media ${(props) => props.theme.breakpoints.mobile} {
    display: flex;
    width: 31%;
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 31%;
    a {
      margin-bottom: 16px;
    }
  }
  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 31%;
  }
  @media ${(props) => props.theme.breakpoints.tabletMaximum} {
    width: 31%;
    a {
        padding: 10px;
    }
  } 
`;

const TileBox = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 16px;
  border: ${props => `1px solid ${props.theme.colors.border}`};
  border-radius: 4px;
  border-left-color: ${props => props.theme.colors.blue};
  border-left-width: 4px;
  background: ${props => props.theme.colors.white};
  cursor: pointer;
  > .icon {
    height: 35px;
    width: 32px;
    margin: 0px 10px 0px 0px;
  }

  .vn-icon--size-32 {
    height: 20px;
    width: 20px;
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    padding: 0px;
    width: 100%;
    height: 100%;
    min-height: 0px;
    box-shadow: undefined;
    border: 0px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    user-select: none;

    > .icon {
      margin: 0 auto;
    }
  }
`;

const Title = styled.h5`
  position: relative;
  overflow-wrap: break-word;
  font-size: 15px;
  text-align: left;
  @media ${(props) => props.theme.breakpoints.mobile} {
    text-align: center;
    font-size: 12px;
    margin-top: 15px;
  }
`;

const Description = styled.p`
  margin: 20px 0 10px 0px;
  display: block;
`;

const TileAndDescription = styled.div`
  display: inline-table;
  width:100%;
  height: 67%;
`;

interface Props {
  children?: React.ReactNode;
  link?: string;
  to?: string;
  target?: string;
  icon?: JSX.Element;
  title: string;
  description?: string;
}

const TilePopup: React.FC<Props> = ({
  children,
  to,
  link,
  icon,
  title,
  description,
  target = "_blank",
}) => {
  const content = (
    <TileBox>
      {icon}
      <TileAndDescription>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
        {children}
      </TileAndDescription>
    </TileBox>
  );

  return (
    <TileWrapper>
      {to && <Link to={to}>{content}</Link>}
      {link && (
        <a href={link} target={target} rel="noopener">
          {content}
        </a>
      )}
    </TileWrapper>
  );
};

export default TilePopup;
