import React, { useState } from "react";
import data from "./welcome-page-data";
import { Icon, Footer, Panel } from "@veneer/core";
import "./welcome-container.css";
import styled from "styled-components";
import { logIn } from "../../api/oidc-user-manager";
import Button from "components/button";
import { RegistrationState } from "../../types/registration-state";
import { useAuth } from "../../redux/auth-hooks";
import { useHistory } from "react-router";

const Anchor = styled.a`
  font-weight: normal;
  cursor: pointer;
  user-select: none;
  margin-left: 20px;
`;

const copyText = `© Copyright ${new Date().getFullYear()} HP Development Company, L.P.`;
const WelcomeContainer: React.FC = () => {
  const { currentUser } = useAuth();
  const history = useHistory();

  const isMobileView = () => {
    return window.innerWidth <= 510 ? true : false;
  };

  const [showMobileView, setShowMobileView] = useState<boolean>(isMobileView());

  const onOrientationChange = () => {
    setShowMobileView(isMobileView());
  };

  window.addEventListener("resize", onOrientationChange);

  if (currentUser?.registrationState === RegistrationState.INVITATION_EXPIRED) {
    history.replace("/invitation/expired");
  }

  if (currentUser?.registrationState === RegistrationState.DEACTIVATED) {
    history.replace("/invitation/deactivated");
  }

  return (
    <div className="dui-welcome">
      <div className="dui-welcome-header">
        <div className="row">
          <div className="content-wrapper">
            <div className="row headerRow">
              <div className="col leftCol">
                <Icon className="items-space" multicolor color="blue" name="hp" size={64} />
              </div>
              <div className="col rightCol">
                {showMobileView && <span className="mobileSignIn">{data["welcome.signin"]}</span> }
                <span>
                  <Button
                    className="welcome-signin-button vn-button"
                    appearance="secondary"
                    onClick={() => logIn("HPEmployee")}
                  >
                    {showMobileView ? data["welcome.signin.employee"] : data["welcome.signin.employeeIn"]}
                  </Button>
                </span>
                <span>
                  <Button
                    className="welcome-signin-button vn-button secondBtn"
                    onClick={() => logIn("HPID")}
                  >
                    {showMobileView ? data["welcome.signin.customer"] : data["welcome.signin.customerIn"]}
                  </Button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dui-main-container">
        <div className="dui-banner-container">
          <div className="dui-banner-row" />
          <div className="hp-daas-title">
            <h5 className="dui-welocome-text-white">{data["welcome.daas"]}</h5>
          </div>
          <div className="disContainer">
            <div className="dui-padding-0 col-sm-12 banner-text text-center">
              <div className="smart-simplified-co">
                <h3>{data["welcome.banner.title"]}</h3>
              </div>
              <div className="hp-daas-desc">
                <h6>{data["welcome.banner.description"]}</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="dui-whats-new-background">
          <div className="key-areas-title">
            <a
              className="learn-more"
              href="https://www8.hp.com/us/en/services/overview.html?jumpid=va_6jy9i1bg3f"
            >
              {data["updated.text"]}
            </a>
          </div>
        </div>

        { showMobileView ?
          <Panel collapsed={false} collapsible={true} title="TERMS">
            <div className="mobileTerms">
              <p>{data["welcome.daas.presence.terms"]}</p>
              <p className="terms2">{data["welcome.daas.presence.terms2"]}</p>
            </div>
          </Panel>
          :
          <div className="tnc-background">
            <div className="termsContainer">
              <div className="tnc-note row">
                <div className="col-xl-12">
                  <p>{data["welcome.daas.presence.terms"]}</p>
                  <p className="terms2">{data["welcome.daas.presence.terms2"]}</p>
                </div>
              </div>
            </div>
          </div> }
      </div>

      <Footer copyrightText={copyText}>
        <Anchor
          href="https://ssl.www8.hp.com/us/en/privacy/privacy.html"
          target="_blank"
          rel="noopener"
        >
          {data["welcome.privacypolicy"]}
        </Anchor>
      </Footer>
    </div>
  );
};

export default WelcomeContainer;
