import React from "react";

import { withRouter, Switch, Route } from "react-router-dom";

import { useAuth } from "redux/auth-hooks";
import PageSpinner from "components/page-spinner";
import Profile from "./components/profile";
import DefaultPage from "./components/default-page";

enum RegistrationRoutes {
  REGISTRATION_FORM = "/register",
  REGISTERED = "/registered",
  PROFILE = "/profile",
}

const Registration: React.FC = () => {
  const { loading, currentUser } = useAuth();

  if (loading || !currentUser) {
    return <PageSpinner />;
  }

  return (
    <Switch>
      <Route path={RegistrationRoutes.PROFILE}>
        <Profile />
      </Route>
      <Route path="/">
        <DefaultPage />
      </Route>
    </Switch>
  );
};

export default withRouter(Registration);
