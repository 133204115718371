import styled from "styled-components";

import { TypeaheadWrapperProps } from "./types";

export const TypeaheadWrapper = styled.div.attrs(
  ({ displayValue, isOpen, className }: TypeaheadWrapperProps): TypeaheadWrapperProps => ({
    className: [(displayValue && isOpen ? "typeahead-open" : ""), className]
      .filter((str) => !!str)
      .join(" "),
  }),
)`
  .twitter-typeahead {
    width: 100%;
  }
  
  &.typeahead-open .twitter-typeahead > input {
    border-color: ${props => props.theme.colors.white};
    border-style: solid;
    box-shadow: 0 0 3px 0 rgba(0,0,0,.15)
  }

  .tt-menu {
    background-color: ${props => props.theme.colors.white};
    width: 100%;
    box-sizing: border-box;
    border-top: 1px;
    border-top-color: ${props => props.theme.colors.border};
    border-top-style: solid;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.15);
    white-space: nowrap;
  }
  
  .tt-not-found, .tt-pending, .tt-suggestion, .tt-header, .tt-footer {
    font-family: "HP Simplified Light", Arial, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5;
    cursor: default;
    display: block;
    height: 34px;
    padding: 5px 11px;
    text-decoration: none;
  }

  .tt-header {
    border-bottom: 1px solid ${props => props.theme.colors.border};
  }

  .tt-footer {
    border-top: 1px solid ${props => props.theme.colors.border};
  }

  .tt-suggestion:hover {
    background-color: ${props => props.theme.colors.border};
  }
`;
