import React from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";

const Wrapper = styled.div`
  font-size: 0.9em;
  margin: 8px;
  padding: 8px;
  border: 1px solid ${(props) => props.theme.colors.border};
  background: ${(props) => props.theme.colors.background};
  a {
    word-break: break-word;
  }
`;

const PRIVACY_URL = "https://www8.hp.com/us/en/privacy/privacy.html";

const PrivacyNote: React.FC = () => (
  <Wrapper>
    <Trans
      i18nKey="privacyNote"
      components={{
        br: <br />,
        a: <a href={PRIVACY_URL} target="_blank" rel="noreferrer noopener"> </a>,
      }}
      values={{ url: PRIVACY_URL }}
    />
  </Wrapper>
);

export default PrivacyNote;
