import styled from "styled-components";
import { Spinner } from "@veneer/core";
import NoticeBoard from "../../../types/notice-board";
import React, { useState } from "react";
import { useAddError } from "../../../redux/toast-hooks";
import { downloadSdmDocument } from "../../../api/upload";

const AttachmentSection = styled.div`
  background-color: ${props => props.theme.colors.background};
  padding: 10px;
  margin: 10px 0;
  position: relative;
`;

const Info = styled.button`
  word-break: break-all;
  color: ${props => props.theme.colors.blue};
  font-size: 13px;
  font-family: ${props => props.theme.font.light};
  position: relative;
  &:hover {
    cursor: pointer;
  }
`;

const SpinnerWrapper = styled.div`
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  background: rgba(100, 100, 100, 0.25);
  text-align: center;
  > svg {
    margin-top: 8px;
  }
`;

export interface Props {
  message: NoticeBoard;
}

const MessageAttachment: React.FC<Props> = ({ message }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const addError = useAddError();

  const downloadAttachment = (message: any) => {
    if (message.sdmDocument) {
      setLoading(true);

      const downloadFunction = downloadSdmDocument;

      downloadFunction(message.sdmDocument)
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          addError(error.message);
        });
    }
  };

  if (!message.fileName) {
    return null;
  }

  return (
    <AttachmentSection>
      <Info data-cy="downloadAttachment" onClick={() => downloadAttachment(message)}>{message.fileName}</Info>
      {loading && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
    </AttachmentSection>
  );
};

export default MessageAttachment;
