import { FetchError } from "../types/fetch-error";
import queryString from "query-string";
import { getRequestHeaders } from "./oidc-user-manager";

export interface Query {
  email: string;
  orderId?: string;
}

const endpoint = process.env.REACT_APP_EMAIL_UNSUBSCRIBE_ENDPOINT;

export const postJson = async <T extends any>(url: string, body: any): Promise<T> =>
  fetch(`${endpoint}/${url}`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json" },
  })
    .then(async (res: Response) => {
      if (res.status === 200) {
        return res.json();
      }
      const json = await res.json()
        .catch(() => undefined);

      const error = new Error(json?.message ?? res.statusText) as FetchError;
      error.status = res.status;
      error.details = json?.details;

      throw error;
    });

export const getJson = async <T extends any>(url: string): Promise<T> =>
  fetch(`${endpoint}/${url}`, {
      method: "GET",
      headers: await getRequestHeaders(),
  }).then(async (res: Response) => {
      if (res.status === 200) {
        return res.json();
      }
  });

export const setEmailPreferences = (email: string, emailData: String[], orderId?: string): Promise<any> =>
  postJson("set-email-preferences", { email, emailData, orderId }).then(() => {});

export const getEmailPreferences = (query: Query): Promise<any> =>
  getJson(`get-email-preferences?${queryString.stringify(query)}`);
