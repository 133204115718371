import React, { useEffect } from "react";
import { Field, Form, useFormikContext } from "formik";
import TextBox from "../../../components/forms/text-box";
import { useTranslation } from "react-i18next";
import Button from "components/button";
import GuestOrderStatusForm, { GuestOrderStatusFormStep } from "../types/guest-order-status-form";
import styled from "styled-components";

const UserMessage = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
`;

const SubmitOTPForm: React.FC = () => {
  const { t } = useTranslation();
  const { setFieldValue, values, isSubmitting } = useFormikContext<GuestOrderStatusForm>();

  useEffect(
    () => {
      setFieldValue("step", GuestOrderStatusFormStep.SubmitOTP);
    },
    [setFieldValue],
  );

  return (
    <Form>
      <UserMessage>{t("order.caseSensitiveUserMessage")}</UserMessage>
      <Field
        label={t("contactemail")}
        name="emailAddress"
        placeholder={t("contactemail")}
        component={TextBox}
        value={values.emailAddress}
        required
        readOnly={isSubmitting || values.fieldsLocked}
      />
      <Field
        label={t("orderstatus.orderNumberOrRef")}
        name="orderNumber"
        placeholder={t("orderstatus.orderNumberOrRef")}
        component={TextBox}
        value={values.orderNumber}
        required
        readOnly={isSubmitting || values.fieldsLocked}
      />
      <Field
        label={t("orderstatus.otp")}
        name="otp"
        placeholder={t("orderstatus.otp")}
        component={TextBox}
        value={values.otp}
        required
        readOnly={isSubmitting}
      />
      <Button type="submit" loading={isSubmitting}>{t("btn.submit")}</Button>
    </Form>
  );
};

export default SubmitOTPForm;
