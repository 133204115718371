import moment from "moment";

const formats = {
  standard: "M/D/YYYY",
  pretty: "MMM D, YYYY",
  international: "YYYY-MM-DD",
  internationalTime: "YYYY-MM-DD hh:mm:ss A",
  stringFormat: "MMMM DD, YYYY",
  timeOnly: "hh:mm A",
  friendlyTime: "h:mm A",
};

/**
 * It convert into required date formate
 */
export default {
  formats,
  format(date: string | number, format: string = formats.international) {
    return moment
      .utc(date)
      .local()
      .format(format);
  },
  formatUTC(date: string | number, format: string = formats.international) {
    return moment
      .utc(date)
      .format(format);
  },
  formatDateWithKnownOffset(date: string | number, offsetHours: number, format: string = formats.international) {
    return moment(date)
      .add(offsetHours, "hours")
      .format(format);
  },

};
