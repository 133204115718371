import React, { useEffect } from "react";
import "@mssi/jupiter-engine";
import { Field, Formik } from "formik";
import { countries } from "@mssi/pssp-shared";
import AutoSubmit from "../../../components/forms/auto-submit";
import { useDispatch } from "react-redux";
import {
  changCaseRequestCountry,
  changeCaseRequestSelectedFor,
  setCaseRequestContact,
  setCaseRequestAlternateContact,
} from "../../../redux/case-request-actions";
import { useCaseContactInfoParams, useCaseDetailParams } from "../../../redux/case-request-hooks";
import { Dispatch } from "redux";
import { RootActionTypes } from "../../../redux/root-action";
import CaseRequestModalButtonWrapper from "./case-request-modal-button-wrapper";
import CaseRequestModalReverseButton from "./case-request-modal-reverse-button";
import CaseRequestModalForwardButton from "./case-request-modal-forward-button";
import { CaseRequestEnum } from "types/case-request";
import { ITSMContactV6 } from "../../../types/contact";
import { useCurrentCustomerITSMID } from "../../../redux/current-customer-hooks";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Select from "../../../components/forms/select";
import TestEmailNote from "../../../components/test-email-note";
import JupiterContactSelector from "../../../components/jupiter/jupiter-contact-selector";

type FormValues = { country?: string };

const ContactSelectorWrapper = styled.div`
  margin-bottom: 1em;
`;

const HPContactNote = styled.div`
  font-size: 0.9em;
  margin: 8px;
  padding: 8px;
  border: 1px solid ${(props) => props.theme.colors.border};
  background: ${(props) => props.theme.colors.background};
`;

const CaseContactInfo: React.FC = () => {
  const itsmCustomerId = useCurrentCustomerITSMID();
  const { contact, country, alternateContact } = useCaseContactInfoParams();
  const { selectedFor, category } = useCaseDetailParams();
  const { t } = useTranslation();
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();

  useEffect(
    () => {
      const contactCountry = contact?.langCountryId;
      if (contactCountry && countries.some(({ id }) => id === contactCountry)) {
        dispatch(changCaseRequestCountry(contactCountry));
      }
    },
    [contact, dispatch],
  );

  const initialValues: FormValues = { country };
  const canSearchContact = (category === CaseRequestEnum.PORTAL) && !!itsmCustomerId;
  const isAnyHardwareType = category === CaseRequestEnum.HARDWARE_FALLBACK;

  const countryEntered = !!country;
  const contactInfoEntered = !!(contact?.emailAddress && contact?.firstName && contact?.lastName && contact?.phoneNumber);
  const contactIdEntered = !!contact?.contactId || !canSearchContact;
  const canContinue = countryEntered && contactInfoEntered && contactIdEntered;

  return (
    <>
      <div className="displayFlex">
        <span>{t("support.caseRequest.contactPerson.primaryContact")}</span>
        <div className="customAstrik">*</div>
      </div>

      <ContactSelectorWrapper data-cy="primaryContact">
        <JupiterContactSelector
          contactId={contact?.contactId ?? ""}
          accountId={canSearchContact ? (itsmCustomerId ?? "") : ""}
          allowCreateOrUpdate
          mode="inputExtended"
          onChange={(result?: ITSMContactV6) => {
            if (result) {
              dispatch(setCaseRequestContact({
                contactId: result.contactId,
                emailAddress: result.emailAddress,
                firstName: result.firstName,
                lastName: result.lastName,
                phoneNumber: result.phoneNumber,
                langCountryId: result.langCountryId,
              }));

              const newSelectedFor = canSearchContact && /@hp\.com$/i.test(result.emailAddress || "") && !isAnyHardwareType
                ? CaseRequestEnum.HP_USER
                : CaseRequestEnum.SOMEONE_ELSE;

              dispatch(changeCaseRequestSelectedFor(newSelectedFor));
            } else {
              dispatch(setCaseRequestContact(undefined));
              dispatch(changeCaseRequestSelectedFor(CaseRequestEnum.SOMEONE_ELSE));
            }
          }}
        />
      </ContactSelectorWrapper>

      <div className="displayFlex">
        <span>{t("support.caseRequest.contactPerson.alternateContact")}</span>
      </div>

      <ContactSelectorWrapper data-cy="alternateContact">
        <JupiterContactSelector
          contactId={alternateContact?.contactId ?? ""}
          accountId={canSearchContact ? (itsmCustomerId ?? "") : ""}
          allowCreateOrUpdate
          mode="inputExtended"
          onChange={(result?: ITSMContactV6) => {
            if (result) {
              dispatch(setCaseRequestAlternateContact({
                contactId: result.contactId,
                emailAddress: result.emailAddress,
                firstName: result.firstName,
                lastName: result.lastName,
                phoneNumber: result.phoneNumber,
                langCountryId: result.langCountryId,
              }));
            } else {
              dispatch(setCaseRequestAlternateContact(undefined));
            }
          }}
        />
      </ContactSelectorWrapper>

      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          if (country !== values.country) {
            dispatch(changCaseRequestCountry(values.country));
          }
        }}
      >
        {({ values, submitForm }) => (
          <>
            <AutoSubmit values={values} submitForm={submitForm} />

            <Field
              label={t("orderdetails.country")}
              name="country"
              values={values.country ? [values.country] : []}
              options={countries.map(({ id, country }) => ({
                value: id,
                label: country,
              }))}
              component={Select}
              required
              showInputSearch={true}
              placeholder={t("orderdetails.country")}
            />
          </>
        )}
      </Formik>

      {selectedFor === CaseRequestEnum.HP_USER && (<HPContactNote>{t("support.hpContactNote")}</HPContactNote>)}
      <TestEmailNote />

      <CaseRequestModalButtonWrapper>
        <CaseRequestModalReverseButton />
        <CaseRequestModalForwardButton canContinue={canContinue} />
      </CaseRequestModalButtonWrapper>
    </>
  );
};

export default CaseContactInfo;
