import * as React from "react";
import { Footer } from "@veneer/core";
import styled from "styled-components";

const Anchor = styled.a`
  font-weight: normal;
  cursor: pointer;
  user-select: none;
  margin-left: 20px;
`;

const copyText = `© Copyright ${new Date().getFullYear()} HP Development Company, L.P.`;

const FooterContainer = (): JSX.Element => {
  return (
    <Footer copyrightText={copyText}>
      <Anchor
        href="https://www8.hp.com/us/en/terms-of-use.html"
        target="_blank"
        rel="noopener"
      >
        Terms of Use
      </Anchor>
      <Anchor
        href="https://ssl.www8.hp.com/us/en/privacy/privacy.html"
        target="_blank"
        rel="noopener"
      >
        Privacy Policy
      </Anchor>
    </Footer>
  );
};

export default FooterContainer;
