import React from "react";
import { Tooltip } from "@veneer/core";

interface Props {
  content?: string;
  component: any;
  position?: string;
}

/**
 * Conditionally wrap a component with a tooltip
 */
const ComponentTooltip: React.FC<Props> = ({ content, component, position = "down" }) => {
  if (content) {
    const placedContent = <div style={{ width: "200px" }}>{content}</div>;
    return (
      <Tooltip content={placedContent} position={position}>
        {component}
      </Tooltip>
    );
  }

  return component;
};

export default ComponentTooltip;
