export interface SubCategoryType {
  subCategory: string;
  subTitle?: boolean;
  subTypes: string[];
}

export interface SubCategoryMapping {
  [category: string]: SubCategoryType[];
}

export const subCategoryMapping: SubCategoryMapping = {
  hardware: [
    { subCategory: "hardware", subTypes: ["hardware"] },
  ],
  contract: [
    { subCategory: "contract", subTypes: ["general"] },
    {
      subCategory: "catalog",
      subTypes: [
        "usersRoles",
        "addressSiteList",
        "productsBundlesPricing",
      ],
    },
  ],
};
