import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media ${(props) => props.theme.breakpoints.mobileMaximum} {
    margin: 1% -1% -1%;
  }
`;

const QuickActionButtonGroup: React.FC = ({ children }) => (
  <Wrapper>{children}</Wrapper>
);

export default QuickActionButtonGroup;
