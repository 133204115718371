export default {
  UAMlink: "https://accessmanager.austin.hp.com/uam/ProfileRequest.aspx?ProFlag=True&ProfileID=57562&",
  cognitoDomain: "https://dev-daas-portal.auth-fips.us-west-2.amazoncognito.com",
  cognitoRegion: "us-west-2",
  cognitoPoolId: "us-west-2_8KYGqj3Hi",
  cognitoClientId: "8uppl38ids3nofnjbon9uso9g",
  hpidUrl: "https://directory.stg.cd.id.hp.com",
  editProfileEmployee: " https://directoryworks.hpicorp.net",
  editProfileNonEmployee: "https://myaccount.stg.cd.id.hp.com",
  launchDarklyReactKey: "61495270a3f8b125cd27b5f4",
  allowLocalStorageFlagOverrides: true,
  jupiterStack: "dev",
  testEmailNote: true,
};
