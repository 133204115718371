import { useSelector } from "react-redux";
import { AuthState, RootState } from "redux/redux-state";
import deepEqual from "fast-deep-equal";
import { RegistrationState } from "../types/registration-state";
import { OrPermission } from "../shared/has-permission";
import useLDFlag from "../hooks/use-ld-flag";

export const useAuth = (): AuthState =>
  useSelector((state: RootState) => state.auth, deepEqual);

export const usePermissions = (): string[] =>
  useSelector((state: RootState) => state.auth.currentUser?.persona?.permissions ?? [], deepEqual);

export const useHasPermission = (): (...permissions: OrPermission) => boolean => {
  const userPermissions = usePermissions();

  return (...permissions: OrPermission) => {
    for (const entry of permissions) {
      const andPermissions = Array.isArray(entry) ? entry : [entry];
      if (andPermissions.every(({ id }) => userPermissions.includes(id))) {
        return true;
      }
    }

    return false;
  };
};

export const useAuthIsLoading = (): boolean =>
  useSelector((state: RootState) => !!(state.auth?.loading || state.auth?.loggingIn));

export const useAuthShouldSetPreferences = (): boolean => {
  const enableEmailRegions = useLDFlag("mssiPsspEmailRegionsPrefTemp20221027");

  return useSelector((state: RootState) => {
    const { currentUser } = state.auth;

    return !!(
      currentUser?.preferences?.sendEmails === undefined
      || (enableEmailRegions && currentUser?.persona?.isInternal && currentUser?.emailRegions === null)
    );
  });
};

export const useAuthIsLoggedIn = (): boolean =>
  useSelector((state: RootState) => !!(state.auth.currentUser?.id && state.auth.currentUser?.persona?.id));

export const useAuthIsApproved = (): boolean =>
  useSelector((state: RootState) => state.auth.currentUser?.registrationState === RegistrationState.APPROVED);

export const useFeature = <T extends any>(feature: string): T =>
  useSelector((state: RootState) => state.auth.features[feature] as T);

export const useUserPreference = (preference: string): any =>
  useSelector((state: RootState) => ((state.auth.currentUser || {}).preferences || {})[preference]);

export const useCurrentUserIsInternal = (): boolean =>
  useSelector((state: RootState) => !!state.auth.currentUser?.persona?.isInternal);
