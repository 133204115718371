import React, { Dispatch } from "react";
import styled from "styled-components";
import { Icon } from "@veneer/core";
import { useDispatch } from "react-redux";
import { RootActionTypes } from "redux/root-action";
import { useShoppingCartContents, useShoppingCartVisibility } from "../../../redux/shopping-cart-hooks";
import { closeShoppingCart, openShoppingCart } from "../../../redux/shopping-cart-action";

interface WrapperProps {
  active: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  top: 61px;
  width: 30px;
  height: 27px;
  text-align: center;
  cursor: pointer;
  background-color: ${(props) => (props.active ? `${props.theme.colors.background3}` : "transparent")};

  .vn-notification__badge {
    width: 12px;
    height: 12px;
    min-width: 0;
    top: 2px;
    right: 2px;
  }
`;

const CartIcon: React.FC = () => {
  const items = useShoppingCartContents();
  const isShown = useShoppingCartVisibility();
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();
  const showNotificationBadge = items.length > 0;

  const toggle = () => {
    dispatch(isShown ? closeShoppingCart() : openShoppingCart());
  };

  return (
    <Wrapper
      active={isShown}
      role="button"
      onClick={toggle}
      onKeyPress={(event: { keyCode: number }) => {
        if (event.keyCode === 10 || event.keyCode === 13) {
          toggle();
        }
      }}
      tabIndex={0}
    >
      <Icon name="bag" data-cy="cartIcon" />
      {showNotificationBadge && <div className="vn-notification__badge" />}
    </Wrapper>
  );
};

export default CartIcon;
