export default {
  UAMlink:
    "https://accessmanager.austin.hp.com/uam/ProfileRequest.aspx?ProFlag=True&ProfileID=57562&",
  cognitoDomain:
    "https://pro-daas-portal.auth-fips.us-west-2.amazoncognito.com",
  cognitoRegion: "us-west-2",
  cognitoPoolId: "us-west-2_3beQDRFy5",
  cognitoClientId: "df06d5mkakqv9a043tugt0prp",
  hpidUrl: "https://directory.id.hp.com",
  dccLink: "https://dcc.ext.hp.com/#/",
  editProfileEmployee: " https://directoryworks.hpicorp.net",
  editProfileNonEmployee: "https://myaccount.id.hp.com",
  aemLink: "https://hp.service-now.com/hpaem",
  hpPresenceSupportCase: "https://www.hpdaas.com/ui/view/support",
  hpPresenceIncidentDashboards: "https://www.hpdaas.com/ui/view/incidents",
  hpPresenceKnowledge: "https://hp.service-now.com/techpulse_kb",
  hpProactiveInsightsSupportCase: "https://www.hpdaas.com/ui/view/support",
  hpProactiveInsightsIncidentDashboards: "https://www.hpdaas.com/ui/view/incidents",
  hpProactiveInsightsKnowledge: "https://hp.service-now.com/techpulse_kb",
  supportCase: "https://help.teradici.com/s/contactsupport",
  knowledge: "https://help.teradici.com/s/knowledge",
  hpAdaptiveEndpointManagementSupportCase: "https://hp.service-now.com/hpaem?id=aem_cat_item&sys_id=5430cec3db0590d0b17124684b9619a8",
  hpAdaptiveEndpointManagementServiceRequest: "https://hp.service-now.com/hpaem?id=aem_sc_category",
  hpAdaptiveEndpointManagementKnowledge: "https://hp.service-now.com/hpaem?id=aem_kb_home",
  nextThinkSupportCase: "https://support.nexthink.com/",
  learningResources: "https://docs.nexthink.com/resources/index.html",
  library: "https://www.nexthink.com/library/",
  hpSupportCase: "https://enterprisesecurity.hp.com/s/contactsupport#overlay-scesupportonly",
  topArticle: "https://enterprisesecurity.hp.com/s/contactsupport#overlay-scesupportonly",
  hpFaq: "https://enterprisesecurity.hp.com/s/frequently-asked-questions",
  documentation: "https://enterprisesecurity.hp.com/s/documentation",
  serviceRequestLock: "https://www.hpdaas.com/ui/view/devices/",
  serviceRequestTrace: "https://www.hpdaas.com/ui/view/devices/",
  serviceRequestCompany: "https://www.hpdaas.com/ui/view/settings/company-preferences",
  wolfSupportCase: "https://www.hpdaas.com/ui/view/support",
  wolfFaq: "https://support.hp.com/us-en/document/ish_5029006-5029050-16",
  articles: "https://hp.service-now.com/nav_to.do?uri=%2Fkb_knowledge_list.do%3Fsysparm_first_row%3D1%26sysparm_query%3Dauthor%253d38bfaafd1b3228d8be6a8622604bcbd1%255esys_created_onBETWEENjavascript%253ags.beginningOfLast12Months()%2540javascript%253ags.endOfToday()%255eworkflow_state%253ddraft%255eGOTOshort_descriptionLIKEtrace%26sysparm_query_encoded%3Dauthor%253d38bfaafd1b3228d8be6a8622604bcbd1%255esys_created_onBETWEENjavascript%253ags.beginningOfLast12Months()%2540javascript%253ags.endOfToday()%255eworkflow_state%253ddraft%255eGOTOshort_descriptionLIKEtrace%26sysparm_view%3D",
  jupiterStack: "prod",
  testEmailNote: false,
};
