import { useSelector } from "react-redux";
import { CaseRequestState, RootState } from "redux/redux-state";
import deepEqual from "fast-deep-equal";

type CaseRequestModalProps = "category" | "country" | "currentStep" | "caseNumber";
export type CaseRequestModalParams = Pick<CaseRequestState, CaseRequestModalProps>;

export const useCaseRequestModalParams = (): CaseRequestModalParams => useSelector(
  ({ caseRequest }: RootState) => ({
    category: caseRequest.category,
    currentStep: caseRequest.currentStep,
    caseNumber: caseRequest.caseNumber,
  }),
  deepEqual,
);

export const useCaseRequestCurrentStep = (): number => useSelector(
  ({ caseRequest }: RootState) => caseRequest.currentStep,
);

type CaseContactInfoProps = "contact" | "country" | "contactLoaded" | "alternateContact";
export type CaseContactInfoParams = Pick<CaseRequestState, CaseContactInfoProps>;

export const useCaseContactInfoParams = (): CaseContactInfoParams => useSelector(
  ({ caseRequest }: RootState) => ({
    contact: caseRequest.contact,
    alternateContact: caseRequest.alternateContact,
    contactLoaded: caseRequest.contactLoaded,
    country: caseRequest.country,
  }),
  deepEqual,
);

type CaseDetailProps = (
  "shortDescription"
  | "detailedDescription"
  | "attachedFiles"
  | "sdmAttachments"
  | "customerTicketNumber"
  | "submitting"
  | "category"
  | "orderNumber"
  | "subCategory"
  | "subType"
  | "country"
  | "contact"
  | "alternateContact"
  | "serviceLocation"
  | "newServiceLocation"
  | "serialNumber"
  | "productNumber"
  | "error"
  | "selectedFor"
  | "submittedUserType"
  | "deviceState"
  | "troubleshootingDescription"
  | "osAndVersion"
  | "issueType"
  | "contactsList"
);

export type CaseDetailParams = Pick<CaseRequestState, CaseDetailProps>;

export const useCaseDetailParams = (): CaseDetailParams => useSelector(
  ({ caseRequest }: RootState) => ({
    shortDescription: caseRequest.shortDescription,
    detailedDescription: caseRequest.detailedDescription,
    attachedFiles: caseRequest.attachedFiles,
    sdmAttachments: caseRequest.sdmAttachments,
    customerTicketNumber: caseRequest.customerTicketNumber,
    submitting: caseRequest.submitting,
    category: caseRequest.category,
    subCategory: caseRequest.subCategory,
    subType: caseRequest.subType,
    country: caseRequest.country,
    orderNumber: caseRequest.orderNumber,
    contact: caseRequest.contact,
    alternateContact: caseRequest.alternateContact,
    serviceLocation: caseRequest.serviceLocation,
    newServiceLocation: caseRequest.newServiceLocation,
    serialNumber: caseRequest.serialNumber,
    productNumber: caseRequest.productNumber,
    error: caseRequest.error,
    selectedFor: caseRequest.selectedFor,
    submittedUserType: caseRequest.submittedUserType,
    deviceState: caseRequest.deviceState,
    troubleshootingDescription: caseRequest.troubleshootingDescription,
    osAndVersion: caseRequest.osAndVersion,
    issueType: caseRequest.issueType,
    locationsListForModal: caseRequest.locationsListForModal,
    contactsList: caseRequest.contactsList
  }),
  deepEqual,
);

type CaseHardwareProps = "deviceId" | "serialNumber" | "productNumber" | "contact" | "serviceLocation" | "newServiceLocation" | "deviceState" | "osAndVersion" | "locationsListForModal";
export type CaseHardwareParams = Pick<CaseRequestState, CaseHardwareProps>;

export const useCaseHardwareParams = (): CaseHardwareParams => useSelector(
  ({ caseRequest }: RootState) => ({
    deviceId: caseRequest.deviceId,
    serialNumber: caseRequest.serialNumber,
    productNumber: caseRequest.productNumber,
    contact: caseRequest.contact,
    serviceLocation: caseRequest.serviceLocation,
    newServiceLocation: caseRequest.newServiceLocation,
    deviceState: caseRequest.deviceState,
    osAndVersion: caseRequest.osAndVersion,
    locationsListForModal: caseRequest.locationsListForModal
  }),
  deepEqual,
);

type issueType = "issueType";
export type CaseRequestIssueType = Pick<CaseRequestState, issueType>;

export const useIssueType = (): CaseRequestIssueType => useSelector(
  ({ caseRequest }: RootState) => ({
    issueType: caseRequest.issueType,
  }),
  deepEqual,
);
type NewCaseInfoProps = "caseNumber" | "category";
export type NewCaseInfoParams = Pick<CaseRequestState, NewCaseInfoProps>;

export const useNewCaseInfo = (): NewCaseInfoParams => useSelector(
  ({ caseRequest }: RootState) => ({
    caseNumber: caseRequest.caseNumber,
    category: caseRequest.category,
  }),
  deepEqual,
);

type HardwareSupportProps = "category" | "currentStep" | "orderNumber";
export type HardwareCaseRequestParams = Pick<CaseRequestState, HardwareSupportProps>;

export const useHardwareSupportParams = (): HardwareCaseRequestParams => useSelector(
  ({ caseRequest }: RootState) => ({
    category: caseRequest.category,
    orderNumber: caseRequest.orderNumber,
    currentStep: caseRequest.currentStep,
  }),
  deepEqual,
);
