import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Panel, LabeledIcon, IconButton, Checkbox } from "@veneer/core";
import styled from "styled-components";
import FormActions from "components/forms/form-actions";
import { Formik, Form, FieldArray, FormikHelpers as FormikActions, Field } from "formik";
import { FetchError } from "types/fetch-error";
import NoticeBoard from "types/notice-board";
import { addMessage, editMessage } from "api/customer-notice-board";
import { useCurrentCustomerID } from "redux/current-customer-hooks";
import Textarea from "components/forms/text-area";
import DatePicker from "components/forms/date-picker";
import CollapsibleView from "components/collapsibleView";
import QuickActionButton from "../../../components/quick-actions/quick-action-button";
import PageSection from "components/layouts/page-section";
import Textbox from "components/forms/text-box";
import getFormikErrors, { useQuickMakeMessage } from "../../../shared/formik-errors";
import { useAddError } from "../../../redux/toast-hooks";
import AcceptedFileTypes from "../../../types/accepted-file";
import FilePickerSDM from "../../../components/forms/file-picker-sdm";
import Button from "components/button";
import TimePicker from "components/forms/time-picker";
import { noticeBoardValidations } from "shared/validation";

interface Props {
  onClose(): void;
  show: boolean;
  event: boolean;
  initialValues?: any;
  setRefresher: any;
}
interface Errors {
  dateError: boolean;
  timeError: boolean;
  urlError: boolean;
}
interface MessageLink {
  link: string,
  description: string,
}

const DatePickerWrapper = styled.span`
  .vn-datepicker {
    width: auto;
  }
`;

const TimePickerWrapper = styled.div`
  input {
    width: auto;
  }
`;

const BoxStyle = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.bgV5};
  padding: 10px;
  margin-top: 10px;
  height: 180px;
  @media ${(props) => props.theme.breakpoints.mobileMaximum} {
    width: 100%;
    display: inline-table;
  }
`;

const DeleteIconStyle = styled.div`
  margin-top: -105px;
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
  margin-right: -45px;
  @media ${(props) => props.theme.breakpoints.mobileMaximum} {
    display: flex;
    margin-top: 0px;
    margin-right: 0px;
    width: 100%;
    justify-content: space-evenly;
  }
`;

const StyledPanel = styled(Panel)`
  .vn-panel__header {
    border-bottom-color: transparent;
    background-color: transparent;
    display: ${(props) => (props.link ? "none" : "block")};
  }
  .vn-panel__content {
    padding: 0px 0px 0px;
  }
  .vn-panel__toggle-icon {
    right: -14px;
  }
  .vn-panel__title {
    color: ${(props) => props.theme.colors.gray};
    margin-left: -20px;
    text-transform: capitalize;
    padding-bottom: 10px;
    font-family: ${(props) => props.theme.font.light};
    font-size: 14px;
  }
  
  .vn-color--neutral-higher {
    color: ${(props) => props.theme.colors.gray} !important;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .vn-panel__title {
      padding-left: 20px;
    }
    .vn-panel__collapsible-header {

      margin-top: 0px;
      margin-left: -10px;
      
  }
  ${(props) => (props.borderatbottomofpanel ? `border-bottom: 1px solid ${props.theme.colors.border};` : "")};
}
`;

const Wrapper = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.background};
  border-bottom: 1px solid ${(props) => props.theme.colors.background};
`;

const EventWrapper = styled.div`
  border-top: 13px solid ${(props) => props.theme.colors.background};
  border-bottom: 13px solid ${(props) => props.theme.colors.background};
`;

const FormActionWrapper = styled.div`
  .wrapper {
    display: flex;
    flex-direction: row-reverse;
  }
`;

const CheckboxStyle = styled.div`
  padding-bottom: 15px;
`;

const Title = styled.div`
  display: flex;
  margin-bottom: -33px;
`;

const ButtonStyle = styled.div`
  width: 100%;
  margin: 30px 0px 20px 10px;
  display: flex;
  justify-content: flex-end;
  @media ${(props) => props.theme.breakpoints.mobileMaximum} {
    margin-left: -3px;
  }
  .vn-button {
    width: auto;
    float: right;
  }
`;

const NewContactWarning = styled.div`
  background-color: ${(props) => props.theme.colors.warningBackground};
  padding: 3px 5px;
  margin: 15px 0px 10px 0px;
`;

const NoticeBoardModalSDM: React.FC<Props> = ({ initialValues, onClose, show, setRefresher, event }) => {
  const { t } = useTranslation();
  const customerId = useCurrentCustomerID();
  const quickMakeMessage = useQuickMakeMessage();
  const addError = useAddError();
  const [allDayEvent, setAllDayEvent] = useState<boolean>(!!initialValues?.allDayEvent);
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const initialErrors = { dateError: false, timeError: false, urlError: false };
  const [eventErrors, setEventErrors] = useState<Errors>(initialErrors);

  const onChangeStartTime = (time: string) => {
    setStartTime(time);
  };

  const onChangeEndTime = (time: string) => {
    setEndTime(time);
    setEventErrors({ ...eventErrors, timeError: false });
  };

  // Submit Notice Board Message
  const onSubmit = (values: NoticeBoard, actions: FormikActions<any>) => {
    values.customerId = customerId;
    values.allDayEvent = allDayEvent;
    values.eventStartTime = startTime || values.eventStartTime;
    values.eventEndTime = endTime || values.eventEndTime;
    values.isEvent = event;

    const errors: Errors = noticeBoardValidations(values, eventErrors);
    if (errors && (errors.dateError || errors.timeError || errors.urlError)) {
      setEventErrors(errors);
      actions.setSubmitting(false);
      return;
    }

    setRefresher(false);
    (values._id ? editMessage(values) : addMessage(values))
      .then(async () => {
        actions.setSubmitting(false);
        actions.resetForm();
        onClose();
      })
      .catch((error: FetchError) => {
        actions.setErrors(getFormikErrors(error, quickMakeMessage));

        if (error?.message && !error.details) {
          addError(error.message);
        }

        values.messageLinks.forEach((messageLink: MessageLink, index: number) => {
          if (messageLink.description === "") {
            actions.setFieldError(`messageLinks[${index}].description`, t("description.required"));
          }
          if (messageLink.link === "") {
            actions.setFieldError(`messageLinks[${index}].link`, t("link.required"));
          }
        });

        actions.setSubmitting(false);
      });
    setRefresher(true);
  };

  const onCloseModel = () => {
    setEndTime("");
    setStartTime("");
    setEventErrors(initialErrors);
    setAllDayEvent(!!initialValues?.allDayEvent);
    onClose();
  };

  const canContinue = (values: NoticeBoard) => {
    const check = values && values.mainMessage && values.detailedMessage &&
      (!event || (values.eventStartDate && values.eventEndDate &&
        (allDayEvent || ((startTime || values.eventStartTime) && (endTime || values.eventEndTime)))));
    return !check;
  };

  const data = initialValues || {
    mainMessage: "",
    detailedMessage: "",
    messageLinks: []
  };

  return (
    <Modal
      size="lg"
      title={initialValues ? t("edit.message") : t("new.message")}
      show={show}
      event={event}
      onClose={onCloseModel}
      dismissOnOverlayClick={false}
    >
      <Formik
        initialValues={data}
        onSubmit={onSubmit}
        onReset={onCloseModel}
      >
        {({ values, isSubmitting }) => {
          return (
            <Form>
              <Field
                label={(event || values.isEvent) ? t("eventTitle") : t("mainmessage.title")}
                value={values.mainMessage}
                name="mainMessage"
                component={Textarea}
                height="80px"
                placeholder={t("mainmessage")}
                required
              />
              <Field
                label={t("detailedmessage.title")}
                value={values.detailedMessage}
                name="detailedMessage"
                component={Textarea}
                placeholder={t("detailedmessagee")}
                required
              />
              <DatePickerWrapper>
                <Field
                  label={t("messageexpiration.title")}
                  value={values.expiryDate}
                  name="expiryDate"
                  component={DatePicker}
                  placeholder={t("messageexpiration")}
                />
              </DatePickerWrapper>
              {(event || values.isEvent) && (
                <EventWrapper>
                  <Title>{t("event.add")}</Title>
                  <CollapsibleView
                    Styled={StyledPanel}
                    title=""
                    collapsible={true}
                    collapsed={false}
                  >
                    <CheckboxStyle>
                      <Checkbox
                        name="allDayEvent"
                        label={t("checkbox.title")}
                        checked={allDayEvent}
                        onChange={() => setAllDayEvent(!allDayEvent)}
                      />
                    </CheckboxStyle>
                    <DatePickerWrapper>
                      <Field
                        label={t("contracts.StartDate")}
                        value={values.eventStartDate}
                        name="eventStartDate"
                        component={DatePicker}
                        placeholder={t("startdate.title")}
                        required
                      />
                    </DatePickerWrapper>
                    {!allDayEvent && (
                      <TimePickerWrapper>
                        <Field
                          label={t("starttime")}
                          value={values.eventStartTime || startTime}
                          name="eventStartTime"
                          component={TimePicker}
                          onChange={onChangeStartTime}
                          placeholder={t("starttime.title")}
                          required
                        />
                      </TimePickerWrapper>
                    )}
                    <DatePickerWrapper>
                      <Field
                        label={t("enddate")}
                        value={values.eventEndDate}
                        name="eventEndDate"
                        component={DatePicker}
                        placeholder={t("enddate.title")}
                        onChange={() => setEventErrors({ ...eventErrors, dateError: false })}
                        required
                      />
                    </DatePickerWrapper>
                    {eventErrors.dateError && (
                      <NewContactWarning>
                        {t("date.errormessage")}
                      </NewContactWarning>
                    )}
                    {!allDayEvent && (
                      <TimePickerWrapper>
                        <Field
                          label={t("endtime")}
                          value={values.eventEndTime || endTime}
                          name="eventEndTime"
                          component={TimePicker}
                          onChange={onChangeEndTime}
                          placeholder={t("starttime.title")}
                          required
                        />
                        {eventErrors.timeError && (
                          <NewContactWarning>
                            {t("time.errormessage")}
                          </NewContactWarning>
                        )}
                      </TimePickerWrapper>
                    )}
                    <Field
                      label={t("location.title")}
                      value={values.eventLocation}
                      name="eventLocation"
                      component={Textbox}
                      placeholder={t("location")}
                      onChange={() => setEventErrors({ ...eventErrors, urlError: false })}
                    />
                    {eventErrors.urlError && (
                      <NewContactWarning>
                        {t("error.urlformat")}
                      </NewContactWarning>
                    )}
                  </CollapsibleView>
                </EventWrapper>
              )}

              <FieldArray
                name="messageLinks"
                render={({ remove, push }) => (
                  <Wrapper>
                    <Title>{t("links.add")}</Title>
                    <CollapsibleView Styled={StyledPanel} title="" collapsible={true} collapsed={false}>
                      {values.messageLinks.map((messageLink: MessageLink, index: number) => (
                        <BoxStyle key={index}>
                          <Field
                            label={t("links.description")}
                            data-cy="descriptionlink"
                            name={`messageLinks[${index}].description`}
                            placeholder={t("link.enter.link")}
                            value={messageLink?.description}
                            component={Textbox}
                          />
                          <Field
                            label={t("links.url")}
                            data-cy="urlLink"
                            name={`messageLinks[${index}].link`}
                            placeholder={t("link.enter.url")}
                            value={messageLink?.link}
                            component={Textbox}
                          />
                          <DeleteIconStyle>
                            <IconButton
                              data-cy="deleteLink"
                              icon="delete"
                              title={t("delete")}
                              onClick={() => remove(index)}
                            />
                          </DeleteIconStyle>
                        </BoxStyle>
                      ))}
                      <ButtonStyle>
                        <QuickActionButton text={t("link", { postProcess: "upper" })} icon="add">
                          <Button
                            data-cy="LinkButton"
                            style={{ height: "90%" }}
                            onClick={() => push({
                              link: "",
                              description: "",
                            })}
                          />
                        </QuickActionButton>
                      </ButtonStyle>
                    </CollapsibleView>
                  </Wrapper>
                )}
              />

              <CollapsibleView Styled={StyledPanel} title={t("attachfile")} collapsible={true} collapsed={false}>
                <Field
                  name="sdmDocument"
                  placeholder="File"
                  label="File"
                  value={values.sdmDocument}
                  accept={AcceptedFileTypes}
                  component={FilePickerSDM}
                  fileNameField="fileName"
                />
              </CollapsibleView>
              <PageSection>
                {(values.fileName) && (
                  <LabeledIcon
                    name="incidents"
                    size={24}
                    label={t("uploadmodal.file.warning")}
                    color="error"
                    labelColor="error"
                  />
                )}
              </PageSection>
              <FormActionWrapper>
                <FormActions
                  disabled={!!canContinue(values)}
                  submitButtonText={t("post")}
                  isSubmitting={isSubmitting}
                  onCancel={onCloseModel}
                />
              </FormActionWrapper>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default NoticeBoardModalSDM;
