import React from "react";
import styled from "styled-components";
import dates from "shared/dates";
import { useIsCoolDown, useStorageCoolDown } from "./init-cooldown";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  color: ${(props) => props.theme.colors.red};
  text-align: right;
  padding-top: 10px;
`;

const CoolDownDisplay: React.FC = () => {
  const isCoolDown = useIsCoolDown();
  const coolDown = useStorageCoolDown();
  const { t } = useTranslation();

  if (!isCoolDown || !coolDown) {
    return null;
  }

  const time = dates.format(coolDown.valueOf(), dates.formats.friendlyTime);

  return (
    <Wrapper>{t("error.coolDownWarning", { time })}</Wrapper>
  );
};

export default CoolDownDisplay;
