import skuLocalizationData from "./sku-localization-data.json";

export interface SkuLocalizationCode {
  code: string;
  countryregion: string;
}

export interface SkuLocalizationCountry {
  country: string;
  countryCode: SkuLocalizationCode[];
}

export const skuLocalization: SkuLocalizationCountry[] = Object.freeze(skuLocalizationData) as SkuLocalizationCountry[];

export const skuLocalizationByCountry = skuLocalization.reduce<{[key: string]: SkuLocalizationCode[]}>(
  (result, { country, countryCode }) => {
    result[country] = countryCode;
    return result;
  },
  {},
);

export const skuLocalizationCodeMap: {[key: string]: string} = skuLocalization.reduce<{[key: string]: string}>(
  (result, { countryCode }) => {
    for (const { code, countryregion } of countryCode) {
      result[code] = countryregion;
    }

    return result;
  },
  {},
);
