import {
  MakeTypeaheadChangeEventParams,
  MakeTypeaheadSelectionEventParams,
  TypeaheadChangedEvent,
  TypeaheadSelectionEvent,
  TypeaheadUpdateMapping,
} from "./types";

export const makeTypeaheadSelectionEvent = <T extends any>(
  params: MakeTypeaheadSelectionEventParams<T>,
): TypeaheadSelectionEvent<T> => {
  const { updateMappingJSON, update, fieldKey, setDisplayValue, setSelectedValue } = params;

  const updateMapping = JSON.parse(updateMappingJSON) as TypeaheadUpdateMapping<T>;

  return (_event?: any, selection?: T) => {
    if (!selection) {
      setSelectedValue("");

      for (const key of Object.keys(updateMapping)) {
        update(key, "");
      }

      return;
    }

    for (const key of Object.keys(updateMapping)) {
      if (updateMapping[key]) {
        update(key, `${selection[updateMapping[key]]}`);
      } else {
        update(key, "");
      }
    }

    setDisplayValue(`${selection[fieldKey]}`);
    setSelectedValue(`${selection[fieldKey]}`);
  };
};

export const makeTypeaheadChangeEvent = <T extends any>(
  params: MakeTypeaheadChangeEventParams<T>,
): TypeaheadChangedEvent => {
  const { displayValue, selectedValue, matchKey, bloodhound, handleSelection } = params;

  if (!displayValue) {
    return () => handleSelection();
  }

  if (displayValue !== selectedValue) {
    return () => {
      let didSelect = false;

      /**
       * Handle results from bloodhound.search
       * Expects to get called twice, hence the declaration in scope of "didSelect"
       * @param {T[]} results
       * @return {void}
       */
      const process = (results: T[]) => {
        if (didSelect) {
          return;
        }

        const match = results.find((result: T) => `${result[matchKey]}` === displayValue);

        if (match !== undefined) {
          didSelect = true;
        }

        handleSelection(undefined, match);
      };

      bloodhound.search(displayValue, process, process);
    };
  }

  // select runs before change - this means result is already correct
  return () => {};
};
