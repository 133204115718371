import { getJson, postJson, putJson, deleteRequest } from "./request";

import NoticeBoard from "../types/notice-board";

export const getMessages = (customerId: string): Promise<NoticeBoard[]> => getJson(`/messages/${customerId}`);

export const addMessage = (message: NoticeBoard): Promise<NoticeBoard> => postJson(`/message`, message);

export const editMessage = (message: NoticeBoard): Promise<NoticeBoard> => putJson(`/message`, message);

export const deleteMessage = (messageId: string) => deleteRequest(`/message/${messageId}`);

export const addCalendarEvent = (messageId: string, offsetTime: number): Promise<any> => postJson(`/message/send-invite`, { messageId, offsetTime });
