import { useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

const ScrollToTop = ({ history }: RouteComponentProps) => {
  const pathname = history.location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default withRouter(ScrollToTop);
