import moment from "moment";
import { PostProcessorModule } from "i18next";

export const format = (value: any, format?: string): string => {
  if (format === "uppercase") {
    return `${value || ""}`.toUpperCase();
  }

  if (value instanceof Date) {
    const [, showInUTC = false, dateFormat = "M/D/YYYY"] = (format && format.match(/^(UTC)?\s*(.+)$/)) || [];

    let date = moment.utc(value);

    if (!showInUTC) {
      date = date.local();
    }

    return date.format(dateFormat);
  }

  if (value instanceof Error) {
    return value.message;
  }

  return `${value || ""}`;
};

export const processUpper = (): PostProcessorModule => ({
  type: "postProcessor",
  name: "upper",
  process: (value: string) => value.toLocaleUpperCase(),
});
