import React from "react";
import styled from "styled-components";

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px -16px;
`;

interface Props {
  children: React.ReactNode;
}

const TileGrid: React.FC<Props> = ({ children }) => {
  return <Grid>{children}</Grid>;
};

export default TileGrid;
