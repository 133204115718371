import React from "react";
import { ConfirmationModal, LabeledIcon } from "@veneer/core";
import { withRouter, RouteComponentProps } from "react-router";
import useLeaveWarning from "../contexts/leaveWarning-context";
import { useTranslation } from "react-i18next";

interface Props extends RouteComponentProps{
  values: boolean;
  setValue(value: boolean): void;
  pageChange?: string;
  screenChange?(): void;
}

const WarningLeavingPage: React.FC<Props> = ({ history, values, setValue, pageChange, screenChange }) => {
  const { updateLeaveWarning } = useLeaveWarning();
  const { t } = useTranslation();
  const close = () => {
    setValue(false);
  };

  const confirm = () => {
    updateLeaveWarning(false);
    setValue(false);
    if (pageChange) {
        history.push(pageChange);
    }

   if (screenChange) {
      screenChange();
    }
  };

  return (
    <>
      <ConfirmationModal
        show={values}
        onClose={() => close()}
        onConfirm={() => confirm()}
        size="sm"
        closeButtonLabel={t("btn.cancel")}
        confirmButtonLabel={t("btn.proceed")}
      >
        <div className="leavingWarning">
          <LabeledIcon
            name="incidents"
            size={24}
            label={t("leavepage.warning")}
            color="error"
            labelColor="black"
          />
        </div>
        <p><br />{t("leavepage.warning.error")}</p>
      </ConfirmationModal>
    </>
  );
};

export default withRouter(WarningLeavingPage);
