import { useSelector } from "react-redux";
import { deepEqual } from "@mssi/pssp-shared";
import { RootState } from "../redux/redux-state";
import { useLDFlags } from "./use-ld-flag";

export const useAllFeatures = (): Record<string, any> => {
  const authFeatures = useSelector((state: RootState) => state.auth.features ?? {}, deepEqual);
  const ldFeatures = useLDFlags();

  return { ...authFeatures, ...ldFeatures };
};
