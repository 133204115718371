import React, { Children, PropsWithChildren, ReactElement } from "react";
import { Icon } from "@veneer/core";
import styled from "styled-components";
import { VeneerIcon } from "../../shared/veneer-icons";

interface Props extends PropsWithChildren<{}> {
  text: string;
  icon?: VeneerIcon;
  children: ReactElement<PropsWithChildren<any>>;
  testData?: string;
  textWordNoWrap?: any;
}
interface StyleProps{
  noWrap: any;
}

const Text = styled.span<StyleProps>`
  margin: 2px 10px 0 8px;
  white-space: ${(props) => (props.noWrap ? "nowrap" : "break-spaces")};
  line-height: normal;
  text-align: left;
`;

const StyledIcon = styled(Icon).attrs(() => ({ color: "white", size: 32 }))`
  display: block;
  margin: 0;
`;

const Wrapper = styled.div`
  margin: 10px 10px 0px 0px;
  @media ${(props) => props.theme.breakpoints.tabletMaximum} {
    margin-top: 8px;
  }

  @media ${(props) => props.theme.breakpoints.mobileMaximum} {
    flex: 0 48%;
    margin: 1%;
  }
  @media ${(props) => props.theme.breakpoints.mobile355} {
    flex: auto;
    width: 100%;
  }

  .vn-button {
    width: 100%;
    line-height: 34px;
    display: flex;
    flex-direction: row;
    padding: 0 8px;
    text-transform: inherit !important;
    align-items: center;
    height: 52px;
  }
`;

const QuickActionButton = ({ text, icon, children, testData, textWordNoWrap }: Props): ReactElement => {
  const button = Children.only(children);

  const cloned = React.cloneElement(
    button,
    {},
    <>
      {icon && <StyledIcon name={icon} />}
      <Text data-cy={testData} noWrap={textWordNoWrap}>{text}</Text>
      {(button && button.props && button.props.children) || null}
    </>
  );

  return <Wrapper>{cloned}</Wrapper>;
};

export default QuickActionButton;
