import { FieldProps, getIn } from "formik";
import { FormField, TextArea } from "@veneer/core";

import React from "react";

interface TextAreaProps extends FieldProps {
  label?: string;
  placeholder?: string;
  height?: string;
  value: string;
  readOnly?: boolean;
  required: boolean;
}

const cleanDataAttribute = (value: string) => value.replace(/[[\].]+/g, "");

const Textarea: React.FC<TextAreaProps> = ({
  label,
  placeholder,
  value,
  form,
  field,
  readOnly = false,
  required,
  height = "120px",
}) => {
  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);
  const hasError = error && touched;

  return (
    <FormField
      label={label}
      htmlFor={field.name}
      errorMessage={hasError && error}
      required={required}
    >
      <TextArea
        name={field.name}
        placeholder={placeholder}
        onChange={(value: string) => form.setFieldValue(field.name, value)}
        onBlur={field.onBlur}
        type="text"
        value={value}
        error={hasError}
        resize={false}
        height={height}
        readOnly={readOnly}
        data-cy={cleanDataAttribute(field.name)}
      />
    </FormField>
  );
};

export default Textarea;
