import * as React from "react";
import styled from "styled-components";

const StyledButton = styled.button<StyledButtonProps>`
  display: inline-block;
  border: none;
  background-color: transparent;
  padding: 0;
  font-size: ${props => (props.size ? `${props.size}px` : "14px")};
  cursor: pointer;
  font-family: ${props => props.theme.font.light};
  color: ${props => (props.appearance === "secondary" ? props.theme.colors.body : props.theme.colors.blue)};
  transition: ${props => props.theme.globalTransition};

  &:hover {
    color: ${props => (props.appearance === "secondary" ? props.theme.colors.bodyDark : props.theme.colors.darkBlue)};
  }
`;

const Children = styled.div`
  display: flex;
  align-items: center;
  > svg {
    margin-right: 4px;
  }
`;

interface StyledButtonProps {
  appearance?: string;
  size?: number;
}

interface TextButtonProps {
  onClick?(event: React.MouseEvent): void;
  children?: React.ReactNode;
  appearance?: string;
  size?: number;
  testData?: string;
}

const TextButton = ({ onClick, children, appearance, size, testData }: TextButtonProps): JSX.Element => {
  return (
    <StyledButton appearance={appearance} onClick={onClick} size={size} data-cy={testData}>
      <Children>{children}</Children>
    </StyledButton>
  );
};

export default TextButton;
