import React, { useEffect, useState } from "react";
import { useAuth } from "redux/auth-hooks";
import { Select, Icon, Tooltip } from "@veneer/core";
import styled from "styled-components";
import { PreferenceLookupType } from "./preference-lookup-type";
import { useDispatch } from "react-redux";
import { RootActionTypes } from "../../redux/root-action";
import { Dispatch } from "redux";
import { asyncUpdatePreference } from "../../redux/auth-thunks";
import useIsDevice from "shared/isDevice";
import deviceType from "shared/devices";

export interface Option {
  value: string;
  label: string;
}

export interface Props {
  label: string;
  placeholder?: string;
  preference: string;
  choices: PreferenceLookupType<any>;
  disabled?: boolean;
  tooltipContent?: string;
}

const Container = styled.div`
  margin-bottom: 20px;
  position: relative;

  .vn-options-list {
    overflow-y: auto;
    max-height: 180px !important;
  }

  svg.vn-spinner {
    position: absolute;
    bottom: 5px;
    left: 50%;
    margin-left: -16px;
  }
`;

const Wrapper = styled.div`
    display: flex;
    .vn-tooltip {
      position: relative !important;
      bottom: 2px;
      left: 4px;
    }
    .vn-tooltip__content {
      width: 185px;
    }
`;

const SelectContainer = styled.div`
    display: flex;
`;

const SelectWrapper = styled.div`
    width: 90%;
`;

const ProfilePreferenceSelect: React.FC<Props> = ({
  label,
  tooltipContent,
  placeholder = label,
  preference,
  choices,
  disabled: disabledByParent = false,
}) => {
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();
  const { currentUser } = useAuth();
  const { preferences } = currentUser;
  const [disabled, setDisabled] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);

  const isMobileOrTablet = useIsDevice(deviceType.MOBILE, deviceType.MOBILELARGE, deviceType.TABLET);

  useEffect(
    () => {
      setDisabled(false);
    },
    [currentUser],
  );

  const currentValue = preferences[preference] || undefined;

  const options: Option[] = choices.map(([value, label]) => ({ value, label }));

  const currentChoice: PreferenceLookupType<any> | null = (
    currentValue ?
      choices.find((choice) => choice[0] === currentValue)
      : undefined
  ) || null;

  const onChange = (option?: Option) => {
    const value = (option && option.value) || "";

    setDisabled(true);
    setChanged(true);
    dispatch(asyncUpdatePreference(preference, value) as any);
    setTimeout(() => {
      setChanged(false);
    }, 20000);
  };

  return (
    <Container>
      <Wrapper data-cy="localization">
        <label htmlFor={`pref_${preference}`}>{label}</label>
        {tooltipContent && !isMobileOrTablet &&
          <Tooltip
            content={tooltipContent}
            contentHideDelayOnHover={0}
            position="left"
          >
            <Icon name="information" />
          </Tooltip>}
      </Wrapper>
      <SelectContainer>
        <SelectWrapper>
          <Select
            id={`pref_${preference}`}
            disabled={disabled || disabledByParent}
            clearIcon={false}
            onChange={onChange}
            placeholder={placeholder}
            options={options}
            value={currentChoice && currentChoice[0] ? [currentChoice[0]] : [""]}
          />
        </SelectWrapper>
        {!disabled && !disabledByParent && changed && <Icon name="enabled" size={32} color="green" />}
      </SelectContainer>
    </Container>
  );
};

export default ProfilePreferenceSelect;
