import { useSelector } from "react-redux";
import { CurrentCustomerState, RootState } from "redux/redux-state";
import deepEqual from "fast-deep-equal";
import { CustomerPermission } from "@mssi/pssp-shared";

export const useCurrentCustomerID = (): string => useSelector((state: RootState) => state.currentCustomer.customer.id);

export const useCurrentCustomerITSMID = (): string | undefined => useSelector((state: RootState) => state.currentCustomer.customer?.itsmCustomerId);

export const useCurrentCustomer = (): CurrentCustomerState => useSelector(
  (state: RootState) => ({
    customer: state.currentCustomer.customer,
    initialized: state.currentCustomer.initialized,
    loading: state.currentCustomer.loading,
    contracts: state.currentCustomer.contracts,
    contractId: state.currentCustomer.contractId
  }),
  deepEqual,
);

export const useShouldSelectCustomer = (): boolean => useSelector((state: RootState) => (
  state.currentCustomer.initialized
  && !state.currentCustomer.customer.id
  && (state.auth.currentUser.customers || []).length > 0
));

export const useCustomerCapability = (capability: CustomerPermission): boolean => useSelector((state: RootState) => !!(
  state.currentCustomer.customer
  && state.currentCustomer.customer.featureCapabilities
  && state.currentCustomer.customer.featureCapabilities.includes(capability.id)
));
