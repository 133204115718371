import React, { useEffect } from "react";
import { Modal, Stepper } from "@veneer/core";
import styled from "styled-components";
import useIsDevice from "shared/isDevice";
import deviceType from "shared/devices";
import { useTranslation } from "react-i18next";
import CaseContactInfo from "./case-contact-info";
import CaseDetailsV2 from "./case-details-v2";
import { useDispatch } from "react-redux";
import {
  closeCaseRequest,
  setCaseRequestContact,
} from "../../../redux/case-request-actions";
import { Dispatch } from "redux";
import { RootActionTypes } from "../../../redux/root-action";
import { useCaseRequestModalParams } from "../../../redux/case-request-hooks";
import CaseRequestStatusStep from "./case-request-status-step";
import { CaseRequestEnum } from "types/case-request";
import CaseRequestHardwareFallbackStep from "./case-request-hardware-fallback-step";
import CaseRequestHardwareOrderStep from "./case-request-hardware-order";
import PrivacyNote from "../../../components/privacy-note";
import CaseRequestContractSelection from "./contract-case-type";

const StyledModal = styled.div`
  .vn-stepper {
    width: 45%;
  }

  > .vn-modal > .vn-modal__dialog > .vn-modal__content > .vn-modal__body {
    display: flex;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    > .vn-modal > .vn-modal__dialog > .vn-modal__content{
      left: 140px;
    }
  }
  .vn-textbox:disabled {
    color: ${(props) => props.theme.colors.black};
  }

  .vn-select__title-text--disabled {
    color: ${(props) => props.theme.colors.black};
  }

  @media ${(props) => props.theme.breakpoints.tabletMaximum} {
    > .vn-modal > .vn-modal__dialog > .vn-modal__content{
      margin: 20px;
      left: 0;
    }
  }
`;

const StyledPrivacyWrapper = styled.div`
  > div {
    margin: 8px 0 0;
  }
`;

const RightPanel = styled.div`
  width: 50%;
  .vn-spinner {
    margin-left: 10px;
  }
  @media ${(props) => props.theme.breakpoints.mobileMaximum} {
    width: 100%;
  }
`;

const Border = styled.div`
  border: ${(props) => `1px solid ${props.theme.colors.border}`};
  margin-right: 40px;
  margin-left: 5px;
`;

const MobileBorder = styled.div`
  border: ${(props) => `1px solid ${props.theme.colors.border}`};
  margin-top: 30px;
  margin-bottom: 10px;
`;

const CaseRequestModal: React.FC = () => {
  const { t } = useTranslation();
  const { category, currentStep, caseNumber } = useCaseRequestModalParams();
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();
  const isMobile = useIsDevice(deviceType.MOBILE, deviceType.MOBILELARGE);

  useEffect(
    () => {
      dispatch(setCaseRequestContact({}));
    },
    [dispatch],
  );

  const stepMapping: { [key: string]: string[] } = {
    [CaseRequestEnum.CONTRACT]: ["issueType", "contactInformation", "details"],
    [CaseRequestEnum.PORTAL]: ["contactInformation", "details"],
    [CaseRequestEnum.HARDWARE_FALLBACK]: ["deviceInformationFallback", "contactInformation", "details"],
    [CaseRequestEnum.ORDER]: ["orderNumber", "contactInformation", "details"],
  };

  const steps = (stepMapping[category || ""] || [])
    .map((step, index) => ({
      step: `${index + 1}`,
      key: step,
      title: t(`caserequest.step.${step}`),
      status: (
        (currentStep > index)
          ? "complete"
          : (
            (currentStep < index)
              ? "incomplete"
              : "start"
          )
      ),
    }));

  const currentStepKey = steps[currentStep] ? steps[currentStep].key : undefined;

  if (!category || caseNumber !== undefined || !steps || !steps.length) {
    return null;
  }

  if (isMobile) {
    return (
      <StyledModal>
        <Modal
          show
          onClose={() => {
            dispatch(closeCaseRequest());
          }}
          dismissOnOverlayClick={false}
          size="xl"
          title={category === "contract" ? t(`contractchange.${category}.title`) : t(`caserequest.${category}.title`)}
        >
          <RightPanel>
            {steps.map((step, index) => (
              <React.Fragment key={step.key}>
                <CaseRequestStatusStep
                  title={step.title}
                  step={index}
                >
                  {step.key === "issueType" && <CaseRequestContractSelection />}
                  {step.key === "deviceInformationFallback" && <CaseRequestHardwareFallbackStep />}
                  {step.key === "contactInformation" && <CaseContactInfo />}
                  {step.key === "details" && <CaseDetailsV2 />}
                  {step.key === "orderNumber" && <CaseRequestHardwareOrderStep />}
                </CaseRequestStatusStep>
                <MobileBorder />
              </React.Fragment>
            ))}
            <StyledPrivacyWrapper>
              <PrivacyNote />
            </StyledPrivacyWrapper>
          </RightPanel>
        </Modal>
      </StyledModal>
    );
  } else {
    return (
      <StyledModal>
        <Modal
          show
          onClose={() => {
            dispatch(closeCaseRequest());
          }}
          dismissOnOverlayClick={false}
          size="xl"
          title={category === "contract" ? t(`contractchange.${category}.title`) : t(`caserequest.${category}.title`)}
        >
          <>
            {!!steps?.length && <Stepper steps={steps} />}
            <Border />
            <RightPanel>
              {currentStepKey === "issueType" && <CaseRequestContractSelection />}
              {currentStepKey === "deviceInformationFallback" && <CaseRequestHardwareFallbackStep />}
              {currentStepKey === "contactInformation" && <CaseContactInfo />}
              {currentStepKey === "details" && <CaseDetailsV2 />}
              {currentStepKey === "orderNumber" && <CaseRequestHardwareOrderStep />}
              <StyledPrivacyWrapper>
                <PrivacyNote />
              </StyledPrivacyWrapper>
            </RightPanel>
          </>
        </Modal>
      </StyledModal>
    );
  }
};

export default CaseRequestModal;
