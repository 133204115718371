import * as React from "react";
import { Icon } from "@veneer/core";
import styled from "styled-components";

interface IconProps {
  iconName?: string;
  theme?: string;
  size?: number;
}

interface WrapperProps {
  theme: string;
  backgroundGradient: string;
  size?: number;
}

interface SOWIconProps {
  size?: number;
}

const iconGradients: Record<string, string> = {
  blue: "linear-gradient(143.35deg, #23bdff 0%, #9d7fd3 100%)",
  green: "linear-gradient(143.35deg, #fee970 0%, #77c667 100%)",
  orange: "linear-gradient(143.35deg, #ffa63f 0%, #ee5c95 100%)",
  purple: "linear-gradient(143.35deg, rgb(251, 116, 90) 0%, #e46f86 50%, rgb(197, 105, 197) 100%)",
  pink: "linear-gradient(143.35deg,#d06cb6 29%,rgb(144, 90, 216) 77%)",
  gold: "linear-gradient(143.35deg, #fff93f 0%, #bd3d6f 100%)",
  violet: "linear-gradient(143.35deg, #DE7CDB 10%, #7F3DAD 90%)",
};

const IconWrapper = styled.div<WrapperProps>`
  height: ${props => `${props.size}px`};
  width: ${props => `${props.size}px`};
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  background: ${props => iconGradients[props.backgroundGradient]};
`;

const SOWIcon = styled.div<SOWIconProps>`
  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 24px;
  }
  height: ${props => `${props.size}px`};
  width: ${props => `${props.size}px`};
  color: ${props => props.theme.colors.white};
  font-size: 13px;
  font-weight: 300;
  line-height: 28px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconDisplay = ({ iconName, theme = "blue", size = 60 }: IconProps): JSX.Element => {
  return (
    <IconWrapper backgroundGradient={theme} size={size} className="icon">
      {iconName === "SOW" ? (
        <SOWIcon size={size}>SOW</SOWIcon>
      ) : (
        <Icon name={iconName} color="white" size={32} style={{ position: "relative" }} />
      )}
    </IconWrapper>
  );
};

export default IconDisplay;
