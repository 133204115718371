import { useMemo } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import overrides from "../shared/feature-overrides.json";
import useStorage from "./use-storage";
import { tryParseJson } from "@mssi/pssp-shared";
import { useConfig } from "../client-config";

export const useLDFlags = () => {
  const flags = useFlags();
  const jsonOverrides = overrides as any;
  const canUseLsOverrides = useConfig("allowLocalStorageFlagOverrides");
  const lsOverrides = useStorage(localStorage, "flagOverrides", "{}") as any;

  return useMemo(
    () => {
      const flagsCopy = { ...flags };
      const parsedLsOverrides = tryParseJson(lsOverrides) as any;

      if (jsonOverrides) {
        for (const [k, v] of Object.entries(jsonOverrides)) {
          flagsCopy[k] = v;
        }
      }

      if (canUseLsOverrides && parsedLsOverrides) {
        for (const [k, v] of Object.entries(parsedLsOverrides)) {
          flagsCopy[k] = v;
        }
      }

      return flagsCopy;
    },
    [flags, lsOverrides, jsonOverrides, canUseLsOverrides]
  );
};

const useLDFlag = <T extends any = boolean>(key: string): T => {
  const flags = useLDFlags();

  const modifiedKey = useMemo(
    () => key?.replace(/-([a-z])/g, (_str, char) => char?.toLocaleUpperCase()),
    [key],
  );

  if (!Object.prototype.hasOwnProperty.call(flags, modifiedKey)) {
    console.warn(`Feature flag ${key} / ${modifiedKey} not found`);
  }

  return flags[modifiedKey];
};

export default useLDFlag;
