import React from "react";
import permissionsMap, { exploreTilePermissionsMap } from "types/tile-permissions";
import Tile from "./tile";
import Icon from "./icon";
import TileGrid from "./tile-grid";
import { useAuth } from "redux/auth-hooks";
import { useTranslation } from "react-i18next";
import { useCurrentCustomer } from "../redux/current-customer-hooks";

interface DaasTileProps {
  permissions: string[];
  homePage?: boolean;
  isExplore?: boolean;
}

const DaasTiles: React.FC<DaasTileProps> = ({
  permissions,
  homePage = false,
  isExplore,
}) => {
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const { customer } = useCurrentCustomer();
  const tilesPermissions = isExplore ? customer.explorePermissions : customer.toolsEnablerPermissions;
  const tilesPermissionsMap = isExplore ? exploreTilePermissionsMap : permissionsMap;

  return (
    <TileGrid>
      {permissions?.map?.((item: string): JSX.Element | null => {
        if (!tilesPermissionsMap[item]) {
          return null;
        }

        const {
          iconName,
          title,
          description,
          link,
          dynamicLink = () => link,
          theme,
        } = tilesPermissionsMap[item];

        const calculatedLink = dynamicLink({
          featuresCapabilities: customer?.featureCapabilities,
          persona: currentUser?.persona,
          loginMethod: currentUser?.userLoginMethod,
          isEmployee: /@hp\.com$/.test(currentUser?.email || ""),
          stage: process.env.REACT_APP_CONFIG_ENV,
        });

        if (!calculatedLink) {
          return null;
        }

        return (
          <Tile
            icon={<Icon iconName={iconName} theme={theme} />}
            title={t(title)}
            description={description ? t(description) : ""}
            link={calculatedLink}
            theme={theme}
            key={title}
            homePage={homePage}
          />
        );
      })}

      {tilesPermissions?.map?.(
        (item: string): JSX.Element | null => {
          const tilePermission = tilesPermissionsMap[item];

          if (!tilePermission) {
            return null;
          }

          const {
            rolePermissionId,
            iconName,
            title,
            description,
            link,
            dynamicLink = () => link,
            theme,
            subDescription,
            target,
          } = tilePermission;

          if (!isExplore && !(rolePermissionId && permissions.includes(rolePermissionId))) {
            return null;
          }

          const calculatedLink = dynamicLink({
            featuresCapabilities: customer?.featureCapabilities,
            persona: currentUser?.persona,
            loginMethod: currentUser?.userLoginMethod,
            isEmployee: /@hp\.com$/.test(currentUser?.email || ""),
            stage: process.env.REACT_APP_CONFIG_ENV,
          });

          if (!calculatedLink) {
            return null;
          }

          return (
            <Tile
              icon={<Icon iconName={iconName} theme={theme} />}
              title={t(title)}
              description={description ? t(description) : ""}
              link={calculatedLink}
              theme={theme}
              key={title}
              homePage={homePage}
              subDescription={subDescription ? t(subDescription) : ""}
              target={target}
            />
          );
        }
      )}
    </TileGrid>
  );
};

export default DaasTiles;
