import React from "react";
import Button from "components/button";
import { caseRequestNextStep } from "../../../redux/case-request-actions";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { RootActionTypes } from "../../../redux/root-action";
import { useTranslation } from "react-i18next";

interface Props {
  canContinue: boolean;
  onContinue?: () => any;
  caption?: string;
  loading?: boolean;
}

const CaseRequestModalForwardButton: React.FC<Props> = ({ canContinue, onContinue, caption, loading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();

  const doContinue = () => {
    const result = onContinue ? onContinue() : undefined;

    if (result === true || result === undefined) {
      dispatch(caseRequestNextStep());
    }
  };

  return (
    <Button
      disabled={!canContinue}
      onClick={() => doContinue()}
      loading={!!loading}
    >
      {caption || t("btn.continue")}
    </Button>
  );
};

export default CaseRequestModalForwardButton;
