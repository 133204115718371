import Customer, { CustomerBasicInfo, CustomerEditInfo, CustomerListItem, DemoCustomerInfo, FederatedLoginTenant, OrderDeploymentContacts, AccountInfo } from "../types/customer";
import { deleteRequest, getJson, postJson, putJson } from "./request";
import { TFunction } from "i18next";
import { Me } from "../types/user";
import queryString from "query-string";
import { BackendTableBaseParams, BackendTableBaseResult } from "types/table-backend-types";
import { ServiceRequest } from "types/service-request-enum";

const getError = (error: any, t: TFunction) => {
  let parsedError = { };
  if (error) {
    if (error.details) {
      parsedError = error.details;
    } else {
      parsedError = t("dashboard.unexpected.error");
    }
    return parsedError;
  }
};

interface ServiceRequestType {
  serviceRequestPermissions: string[];
}

export type CustomerListResponse = BackendTableBaseResult<CustomerListItem>;

export type AccountContactResponse = BackendTableBaseResult<AccountInfo>;

export interface CustomerAccountTeam extends BackendTableBaseParams {
  customerId: string;
}

export const queryCustomerList = (params: BackendTableBaseParams): Promise<CustomerListResponse> =>
  getJson(`/customer/list?${queryString.stringify(params)}`);

export const getAllCustomers = (): Promise<Customer[]> => getJson(`/customers/`);

export const getCustomerById = (id: string): Promise<Customer> => getJson(`/customers/${id}`);

export const updateCustomer = (id: string, customerInfo: Customer): Promise<Customer> => {
  if (customerInfo.name) {
    delete customerInfo.name;
  }
  return putJson(`/customers/${id}`, customerInfo);
};

export const createOrUpdateCustomer = (customerInfo: CustomerEditInfo): Promise<Customer> => {
  const payload = {
    itsmCustomerId: customerInfo.itsmCustomerId,
    itsmCustomerName: customerInfo.itsmCustomerName,
    accountId: customerInfo.accountId,
    primaryContactName: customerInfo.primaryContactName,
    primaryContactEmail: customerInfo.primaryContactEmail,
  };

  return customerInfo.id
    ? putJson(`/customers/${customerInfo.id}`, { ...payload, id: customerInfo.id })
    : postJson(`/customers`, payload);
};

export const createOrUpdateDemoCustomer = (customerInfo: DemoCustomerInfo, t: TFunction): Promise<Customer> => {
  const payload = {
    itsmCustomerId: customerInfo.itsmCustomerId,
    itsmCustomerName: customerInfo.itsmCustomerName,
  };

  return customerInfo.id
    ? putJson(`/customers/${customerInfo.id}`, { ...payload, id: customerInfo.id }).catch((error) => {
      throw getError(error, t);
    })
    : postJson(`/demo/customers`, customerInfo).catch((error) => {
        throw getError(error, t);
      });
};

export const updateCustomerFederatedLoginTenant = (id: string, federatedLoginTenants?: FederatedLoginTenant[]): Promise<Customer> =>
  putJson(`/customers/${id}/updateCustomerFederatedLoginTenant`, { federatedLoginTenants });

export const updateCustomerMaxOrderQuantity = (id: string, maximumOrderQuantity?: number): Promise<Customer> =>
  putJson(`/updateCustomerMaxOrderQuantity/${id}`, { maximumOrderQuantity });

export const updateCustomerEmailRestrictions = (id: string, emailRestrictions: string): Promise<Customer> =>
  putJson(`/updateCustomerEmailRestrictions/${id}`, { emailRestrictions });

export const updateCustomerSupportEmail = (id: string, supportEmail: string): Promise<Customer> =>
  putJson(`/updateCustomerSupportEmail/${id}`, { supportEmail });

export const updateCustomerHardwareSupportEmail = (id: string, hardwareSupportEmail: string): Promise<Customer> =>
  putJson(`/updateCustomerHardwareSupportEmail/${id}`, { hardwareSupportEmail });

export const deleteCustomer = (id: string) => deleteRequest(`/customers/${id}`);

export const updateCustomerPreferencePermission = (customerId: string, id: string, value: boolean, type: string): Promise<String[]> => {
  if (type === "toolsEnablerPermissions") {
    return putJson(`/customers/toolsEnablerPermissions/${customerId}`, { id, value })
    .then((response: any) => {
      return response.toolsEnablerPermissions || [];
    });
  } else if (type === "explorePermissions") {
    return putJson(`/customers/explorePermissions/${customerId}`, { id, value })
    .then((response: any) => {
      return response.explorePermissions || [];
    });
  } else if (type === "ordersPermissions") {
    return putJson(`/customers/ordersPermissions/${customerId}`, { id, value })
    .then((response: any) => {
      return response.ordersPermissions || [];
    });
  } else if (type === "serviceCatalogPermissions") {
    return putJson(`/customers/serviceCatalogPermissions/${customerId}`, { id, value })
    .then((response: any) => {
      return response.serviceCatalogPermissions || [];
    });
  } else if (type === "hardwareBreakFixPermissions") {
    return putJson(`/customers/hardwareBreakFixPermissions/${customerId}`, { id, value })
    .then((response: any) => {
      return response.hardwareBreakFixPermissions || [];
    });
  } else if (type === "softwareSupportPermissions") {
    return putJson(`/customers/softwareSupportPermissions/${customerId}`, { id, value })
    .then((response: any) => {
      return response.softwareSupportPermissions || [];
    });
  } else if (type === ServiceRequest.SERVICE_REQUEST_PERMISSIONS) {
    return putJson(`/customers/serviceRequestPermissions/${customerId}`, {
      id,
      value,
    }).then((response: ServiceRequestType) => {
      return response.serviceRequestPermissions || [];
    });
  } else {
    return putJson(`/customers/capabilities/${customerId}`, { id, value })
    .then((response: any) => {
      return response.featureCapabilities || [];
    });
  }
};

export const getSelfAssignableCustomers = (): Promise<CustomerBasicInfo[]> =>
  getJson("/customers/self-assignable-customers");

export const assignSelfToCustomers = (customerIds?: string[]): Promise<Me> =>
  postJson("/customers/self-assign", { customerIds });

export const unAssignSelfFromCustomer = (customerId?: string): Promise<Me> =>
  postJson("/customers/self-un-assign", { customerId });

export const putCustomerSetupSteps = (customerId: string, ...steps: string[]): Promise<Customer> =>
  putJson(`/customers/${customerId}/setup`, { steps });

export const updateOrderDeploymentContacts = (
  id: string,
  orderDeploymentContacts: OrderDeploymentContacts[]
): Promise<Customer> =>
  postJson(`/customers/${id}/orderDeploymentContacts`, { orderDeploymentContacts });

export const getAccountContacts = (): Promise<any> =>
  getJson("/account-info/accounts");

export const addAccountContact = (
  customerId: string,
  accountContactInfo: AccountInfo[]
): Promise<Customer> =>
  postJson(`/customers/${customerId}/accountInfo`, accountContactInfo);

export const updateAccountContact = (
  customerId: string,
  accountContactInfo: AccountInfo,
): Promise<Customer> =>
  putJson(`/customers/${customerId}/accountInfo`, accountContactInfo);

export const updateAccountRearrange = (
  customerId: string,
  accountContactInfo: AccountInfo,
  arrangementType: string,
): Promise<Customer> =>
  putJson(`/customers/${customerId}/rearrange-accountTeam?${queryString.stringify({ arrangementType })}`, accountContactInfo);

export const deleteAccountContact = (customerId: string, id: string) =>
  deleteRequest(`/customers/${customerId}/accountInfo/${id}`);

export const getCustomerAccounts = (
  params: CustomerAccountTeam
): Promise<AccountContactResponse> =>
  getJson(`/customers/getCustomerAccounts?${queryString.stringify(params)}`);
