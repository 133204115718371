import React from "react";
import styled from "styled-components";
import { Icon, Tooltip } from "@veneer/core";
import useIsDevice from "shared/isDevice";
import deviceType from "shared/devices";

const Tile = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const Title = styled.div`
  width: 130px;
`;
const TooltipContent = styled.div`
  width: 150px;
`;
interface OrderValueTileProps {
  title: string;
  tooltipcontent: string;
}
const OrderValueTile: React.FC<OrderValueTileProps> = ({
  title,
  tooltipcontent,
}) => {
  const isMobileTablet = useIsDevice(
    deviceType.MOBILE,
    deviceType.MOBILELARGE,
    deviceType.TABLET,
  );
  return (
    <Tile>
      <Title> {title}</Title>
      <Tooltip
        content={<TooltipContent>{tooltipcontent}</TooltipContent>}
        contentHideDelayOnHover={0}
        placement="auto"
      >
        { !isMobileTablet && <Icon name="information" />}
      </Tooltip>
    </Tile>
  );
};

export default OrderValueTile;
