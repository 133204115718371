// this is much faster than [...new Set(...items)]
export default function unique<T>(items: T[]) {
  const set: Set<T> = new Set();
  const list: T[] = [];

  for (const item of items) {
    if (!set.has(item)) {
      set.add(item);
      list.push(item);
    }
  }

  return list;
}
