export enum ReportStatus {
  PREPARING = "preparing",
  READY = "ready",
  ERROR = "error",
}

export interface DocumentInit {
  id?: string;
  error?: string;
}

export interface DocumentResult {
  status: ReportStatus;
  url?: string;
  error?: string;
}

export interface ReportParams {
  [key: string]: any;
}

export interface S3Document {
  _id: string;
  customerId: string;
  entity: string;
  entityId: string;
  documentType: string;
  s3Key?: string;
  sdmId?: string;
  uploaded: boolean;
  fileName: string;
  contentType?: string;
  createdAt: string; // ISO Date
}
