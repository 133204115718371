import { useCallback, useEffect, useState } from "react";
import { queryContractsForCustomerPage } from "api/contract";
import { ContractForCustomerPage } from "types/contract";
import log from "shared/log";
import { FetchError } from "types/fetch-error";

interface Props {
  customerId?: string;
}

interface UseContracts {
  error?: FetchError;
  loading: boolean;
  contracts?: ContractForCustomerPage[];
  refreshContracts(): void;
}

const useContracts = ({ customerId }: Props): UseContracts => {
  const [loading, setLoading] = useState(!!customerId);
  const [error, setError] = useState();
  const [contracts, setContracts] = useState<ContractForCustomerPage[]>();

  const refreshContracts = useCallback(
    () => {
      if (customerId) {
        setLoading(true);
        queryContractsForCustomerPage(customerId)
          .then(contracts => {
            setContracts(contracts);
            setError(undefined);
            setLoading(false);
          })
          .catch(error => {
            log.error(error);
            setError(error);
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    },
    [customerId],
  );

  useEffect(refreshContracts, [refreshContracts]);

  return { loading, error, contracts, refreshContracts };
};

export default useContracts;
