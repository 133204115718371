import React from "react";
import { useHistory } from "react-router-dom";
import { AlertModal } from "@veneer/core";
import { useTranslation } from "react-i18next";
import { useNewCaseInfo } from "../../../redux/case-request-hooks";
import { useDispatch } from "react-redux";
import { RootActionTypes } from "../../../redux/root-action";
import { Dispatch } from "redux";
import { closeCaseRequest } from "../../../redux/case-request-actions";
import styled from "styled-components";
import { useCurrentCustomerID } from "redux/current-customer-hooks";
import Button from "components/button";

const ButtonWrapper = styled.div`
  margin-top: 10px;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

const CaseRequestSubmittedModal: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const customerId = useCurrentCustomerID();
  const { caseNumber, category } = useNewCaseInfo();
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();

  const onPopupClosed = () => {
    dispatch(closeCaseRequest());
  };

  const redirectToCaseDetail = () => {
    history.push(`/${customerId}/support-tickets/case/${caseNumber}`);
    dispatch(closeCaseRequest());
  };

  if (caseNumber === undefined || !category) {
    return null;
  }

  return (
    <div className={caseNumber ? undefined : "caseAlertModal"}>
      <AlertModal
        size="sm"
        show
        title={t("caserequest.submitted")}
        onClose={onPopupClosed}
        dismissOnOverlayClick={false}
      >
        {caseNumber ? (
          <p>{t("caserequest.submitted.caseNumber", { caseNumber })}</p>
        ) : (
          <p>
            {
              category === "contract"
                ? t(`contractchange.submitted.${category}`)
                : t(`caserequest.submitted.${category}`)
            }
          </p>
        )}
        {
          caseNumber
            ? (
              <ButtonWrapper>
                <StyledButton onClick={onPopupClosed}>{t("ok")}</StyledButton>
                <Button onClick={redirectToCaseDetail}>{t("casedetails")}</Button>
              </ButtonWrapper>
            )
            : (
              <ButtonWrapper>
                <Button onClick={onPopupClosed}>{t("ok")}</Button>
              </ButtonWrapper>
            )
        }
      </AlertModal>
    </div>
  );
};

export default CaseRequestSubmittedModal;
