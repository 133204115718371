export enum GuestOrderStatusFormStep {
  RequestOTP = 0,
  SubmitOTP = 1,
}

export default interface GuestOrderStatusForm {
  emailAddress: string;
  orderNumber: string;
  fieldsLocked: boolean;
  captcha: string;
  otp: string;
  step: GuestOrderStatusFormStep;
  orderId?: string;
  unsubscribe?: boolean;
}
