import Customer from "../types/customer";
import { useCurrentCustomer } from "../redux/current-customer-hooks";
import { serviceRequestPermissions, CustomerPermission } from "@mssi/pssp-shared";

const customerHasCapability = (capabilities: CustomerPermission[]) => (customer?: Customer) => {
  if (!customer) {
    return false;
  }

  if (capabilities.every(({ id }) => customer.featureCapabilities && customer.featureCapabilities.includes(id))) {
    return true;
  }

  if (capabilities.every(({ id }) => customer.toolsEnablerPermissions && customer.toolsEnablerPermissions.includes(id))) {
    return true;
  }

  if (capabilities.every(({ id }) => customer.ordersPermissions && customer.ordersPermissions.includes(id))) {
    return true;
  }

  if (capabilities.every(({ id }) => customer.explorePermissions && customer.explorePermissions.includes(id))) {
    return true;
  }
};


export const useCustomerHasServiceRequestCapability = (): boolean => {
  const { customer } = useCurrentCustomer();

  if (!customer) {
    return false;
  }

  const capabilities: CustomerPermission[] = [
    serviceRequestPermissions.locationChangeOfAsset,
    serviceRequestPermissions.buyoutOfLeasedAsset,
    serviceRequestPermissions.leaseEarlyTerminationAssetReturn,
    serviceRequestPermissions.leaseEarlyTerminationAssetReturnFleetFlex,
    serviceRequestPermissions.lossTolerance,
    serviceRequestPermissions.installServiceChangeOfAsset,
  ];

  return capabilities.some(({ id }) => customer.serviceRequestPermissions?.includes(id));
};

export const useCurrentCustomerCapability = (capability: CustomerPermission) => {
  const { customer } = useCurrentCustomer();

  if (!customer?.featureCapabilities) {
    return false;
  }

  return customer.featureCapabilities.includes(capability.id);
};

// match at least one
export const useCurrentCustomerToolsAndEnablers = (...capabilities: CustomerPermission[]) => {
  const { customer } = useCurrentCustomer();

  if (!customer.toolsEnablerPermissions) {
    return false;
  }

  for (const capability of capabilities) {
    if (customer.toolsEnablerPermissions.includes(capability.id)) {
      return true;
    }
  }

  return false;
};

export const useCurrentCustomerExplore = (...capabilities: CustomerPermission[]) => {
  const { customer } = useCurrentCustomer();

  if (!customer.explorePermissions) {
    return false;
  }

  for (const capability of capabilities) {
    if (customer.explorePermissions.includes(capability.id)) {
      return true;
    }
  }

  return false;
};

export const useCurrentCustomerServiceRequestPermissions = (serviceRequestPermissions: CustomerPermission) => {
  const { customer } = useCurrentCustomer();

  if (!customer.serviceRequestPermissions) {
    return false;
  }

  return customer.serviceRequestPermissions.includes(serviceRequestPermissions.id);
};

export const useCurrentCustomerOrders = (capability: CustomerPermission) => {
  const { customer } = useCurrentCustomer();

  if (!customer.ordersPermissions) {
    return false;
  }

  return customer.ordersPermissions.includes(capability.id);
};

export default customerHasCapability;
