import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import styled from "styled-components";

import PrimaryNav from "../components/primary-nav";
import {
  useAuthIsApproved,
  useAuthIsLoading,
  useAuthIsLoggedIn,
  useAuthShouldSetPreferences
} from "redux/auth-hooks";
import PageSpinner from "components/page-spinner";
import RegistrationPage from "./registration";
import WelcomePage from "./welcome";
import InvitationError from "./invitation-error";
import AuthenticatedRoutes from "components/authenticated-routes";
import ScrollToTop from "components/scroll-to-top";
import SaveCurrentCustomer from "../redux/save-current-customer";
import { CustomerSelectorModal } from "../components/customer-selector-modal";
import UserPreferences from "./users/components/user-preferences-alert";
import { useShouldSelectCustomer } from "../redux/current-customer-hooks";
import ShoppingCartMobile from "./orders/components/shopping-cart-mobile";
import ReportModal from "../components/modals/reports";
import GuestOrderStatusPage from "./order-status";
import CaseRequestSubmittedModal from "./support-tickets/components/case-request-submitted-modal";
import DeepLinkHelper from "./deep-link-helper";

const AppContainer = styled.div`
  height: 100%;
  min-height: 100%;
`;

const PageContent = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flow-root;
  min-height: 100%;
  padding-left: 260px;

  @media (max-width: 1199px) {
    padding-left: 0;
  }
`;

const Pages = (): JSX.Element => {
  const loading = useAuthIsLoading();
  const shouldSelectCustomer = useShouldSelectCustomer();
  const shouldSelectPreferences = useAuthShouldSetPreferences();
  const isLoggedIn = useAuthIsLoggedIn();
  const isApproved = useAuthIsApproved();

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <Router>
      <Switch>
        <Route path="/invitation">
          <InvitationError />
        </Route>
        <Route path="/my-order">
          <AppContainer>
            <PrimaryNav />
            <PageContent>
              <GuestOrderStatusPage />
            </PageContent>
          </AppContainer>
        </Route>
        <Route path="/email-preferences">
          <AppContainer>
            <PrimaryNav />
            <PageContent>
              <GuestOrderStatusPage />
            </PageContent>
          </AppContainer>
        </Route>
        <Route path="/email-preferences/order/:orderNumber" exact>
          <AppContainer>
            <PrimaryNav />
            <PageContent>
              <GuestOrderStatusPage />
            </PageContent>
          </AppContainer>
        </Route>
        {!isLoggedIn && (
          <Route path="*">
            <WelcomePage />
          </Route>
        )}
        {isLoggedIn && !isApproved && (
          <Route path="*">
            <RegistrationPage />
          </Route>
        )}
        {isLoggedIn && isApproved && (
          <Route path="/:customerId?">
            <SaveCurrentCustomer />
            <CustomerSelectorModal show={shouldSelectCustomer} />
            <ReportModal />
            {!shouldSelectCustomer && shouldSelectPreferences && <UserPreferences />}
            {!shouldSelectCustomer && !shouldSelectPreferences && (
              <AppContainer>
                <PrimaryNav />
                <PageContent>
                  <AuthenticatedRoutes />
                  <DeepLinkHelper />
                </PageContent>
              </AppContainer>
            )}
          </Route>
        )}
      </Switch>
      {isLoggedIn && (
      <Route path="*">
        <CaseRequestSubmittedModal />
      </Route>
        )}
      <ScrollToTop />
      <ShoppingCartMobile />
    </Router>
  );
};

export default Pages;
