import React from "react";
import styled from "styled-components";

import Header from "../header";
import Footer from "../footer";

const StyledPage = styled.div`
  background: ${props => props.theme.colors.background};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
  padding-top: 60px;
`;

const Container = styled.div`
  min-height: calc(100vh - 110px);
  padding: 16px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: 20px;
  }
`;

interface Props {
  children: React.ReactNode;
  menuEnabled?: boolean;
  title?: string;
}

const Page: React.FC<Props> = ({ title, menuEnabled, children }) => {
  return (
    <StyledPage>
      <Header title={title} menuEnabled={menuEnabled} />
      <Container>{children}</Container>
      <Footer />
    </StyledPage>
  );
};

export default Page;
