import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { initCurrentCustomer } from "./current-customer-actions";
import { Dispatch } from "redux";
import { RootActionTypes } from "./root-action";
import { useAuth } from "./auth-hooks";
import { useCurrentCustomer } from "./current-customer-hooks";

const InitCurrentCustomer: React.FC = () => {
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();
  const { currentUser } = useAuth();
  const { initialized } = useCurrentCustomer();

  useEffect(
    () => {
      if (!currentUser || initialized) {
        return;
      }

      dispatch(initCurrentCustomer());
    },
    [dispatch, currentUser, initialized],
  );

  return null;
};

export default InitCurrentCustomer;
