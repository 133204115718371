import queryString from "query-string";

import DocumentTypes from "shared/contract-document-types";
import Contract, {
  ContractCreateInfo,
  ContractUpdateInfo,
  ContractDocument,
  ContractAdditionalInfo,
  BasicContractInfo,
  ContractForCustomerPage,
  ContractDocumentUpdate,
} from "types/contract";
import { getJson, postJson, putJson } from "./request";
import { BackendTableBaseParams, BackendTableBaseResult } from "../types/table-backend-types";

export const getContractById = (customerId: string, contractId: string): Promise<Contract> =>
  getJson(`/contracts/${customerId}/${contractId}`);

export const createContract = (
  contractInfo: ContractCreateInfo
): Promise<Contract> => postJson("/contracts", contractInfo);

export const updateContract = (
  id: string,
  contractInfo: ContractUpdateInfo
): Promise<Contract> => putJson(`/contracts/${encodeURIComponent(id)}`, contractInfo);

export const addAdditionalInfo = (
  id: string,
  additionalInfo: ContractAdditionalInfo
): Promise<Contract> => postJson(`/contracts/${encodeURIComponent(id)}/addAdditionalInfo`, additionalInfo);

export const updateAdditionalInfo = (
  id: string,
  additionalInfo: ContractAdditionalInfo
): Promise<Contract> => putJson(`/contracts/${encodeURIComponent(id)}/updateAdditionalInfo`, additionalInfo);

export const removeAdditionalInfo = (
  id: string,
  additionalInfo: ContractAdditionalInfo,
): Promise<Contract> => putJson(`/contracts/${encodeURIComponent(id)}/removeAdditionalInfo`, additionalInfo);

export const queryContractsForCustomerPage = (customerId: string): Promise<ContractForCustomerPage[]> =>
  getJson(`/contracts?${queryString.stringify({ customerId })}`);

export interface ContractListRequest extends BackendTableBaseParams {
  customerId: string;
  name?: string;
  contractId?: string;
}

export type ContractListResponse = BackendTableBaseResult<BasicContractInfo>;

export const listContracts = (query: ContractListRequest): Promise<ContractListResponse> =>
  getJson(`/contracts/list?${queryString.stringify(query)}`);

export const getContractDocuments = (id: string): Promise<ContractDocument[]> =>
  getJson(`/contracts/${encodeURIComponent(id)}/documents`);

export const deleteContractDocumentSDM = (contractId: string, id: string): Promise<ContractDocument[]> =>
  postJson(`/contracts/${encodeURIComponent(contractId)}/remove-document`, { id });

export const saveContractDocumentSDM = (contractId: string, id: string, documentType: DocumentTypes, documentName: string, folder?: string): Promise<ContractDocument[]> =>
  postJson(`/contracts/${encodeURIComponent(contractId)}/save-document`, { id, documentType, documentName, folder });

export const updateContractDocument = (contractId: string, document: ContractDocumentUpdate): Promise<ContractDocument[]> =>
  putJson(`/contracts/${contractId}/update-contract-document`, { document });
