import { applyMiddleware, createStore } from "redux";
import caseRequestReducer from "./case-request-reducer";
import currentCustomerReducer from "./current-customer-reducer";
import shoppingCartReducer from "./shopping-cart-reducer";
import authReducer from "./auth-reducer";
import orderEndUserReducer from "./order-endUser-reducer";
import thunk from "redux-thunk";
import { initialRootState, RootState } from "redux/redux-state";
import { RootActionTypes } from "./root-action";
import reportDownloadReducer from "./report-download-reducer";
import reportDownloadMiddleware from "./report-download-middleware";
import toastReducer from "./toast-reducer";

const rootReducer = (state: RootState = initialRootState, action: RootActionTypes): RootState => ({
  auth: authReducer(state.auth, action),
  toast: toastReducer(state.toast, action),
  currentCustomer: currentCustomerReducer(state.currentCustomer, action, state),
  caseRequest: caseRequestReducer(state.caseRequest, action),
  shoppingCart: shoppingCartReducer(state.shoppingCart, action),
  orderEndUser: orderEndUserReducer(state.orderEndUser, action),
  reportDownload: reportDownloadReducer(state.reportDownload, action, state),
});

const store = createStore(rootReducer, applyMiddleware(thunk, reportDownloadMiddleware));

export default store;
