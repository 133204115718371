import React from "react";
import { Icon, Button } from "@veneer/core";
import { RegistrationState } from "types/registration-state";
import PageNoMenu from "components/layouts/page-no-menu";
import ScreenPanel from "components/layouts/screen-panel";
import { useAuth } from "redux/auth-hooks";
import { logout } from "api/oidc-user-manager";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledButton = styled(Button)`
    min-width: 140px;
`;

const DefaultRegistrationPage: React.FC = () => {
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const isNewHpAccount = currentUser.registrationState === RegistrationState.NEW_ACCOUNT && !!currentUser.persona?.isInternal;

  const copyMap: Record<string, string> = {
    [RegistrationState.INVITATION_EXPIRED]: t("portalaccess.invitation.expried"),
    [RegistrationState.INVITED]: t("portalaccess.invited"),
    [RegistrationState.DEACTIVATED]: t("portalaccess.deactivated"),
    default: t("portalaccess.default"),
    defaultOld: t("portalaccess.default.old"),
  };

  const defaultHp = (
    <>
      {t("portalaccess.defaulthp")} <a href="https://services.corp.hpicloud.net/Solutions/ViewID/1043">{t("tile.solution")}.</a>
    </>
  );

  const copy = (copyMap[currentUser.registrationState]) || (copyMap.default);

  const isDeactivatedUser = currentUser.registrationState === RegistrationState.DEACTIVATED;

  return (
    <PageNoMenu>
      <ScreenPanel>
        <Icon name="hp" size={64} />
        <h3>{isDeactivatedUser ? t("portalaccess.deactivated.title") : (t("portalaccess.title"))}</h3>
        <h5>{copy}</h5>
        <>
          {isNewHpAccount && (
          <><br /><h5>{defaultHp}</h5></>
            )}
          <br /><br />
          <StyledButton onClick={() => logout()}>{t("portalaccess.backbutton")}</StyledButton>
        </>
      </ScreenPanel>
    </PageNoMenu>
  );
};

export default DefaultRegistrationPage;
