import * as React from "react";

import permissionsMap from "types/tile-permissions";
import TilePopup from "./tile-popup";
import Icon from "./icon";
import TileGridPopup from "./tile-grid-popup";
import { useAuth } from "redux/auth-hooks";
import { useTranslation } from "react-i18next";
import { useCurrentCustomer } from "../redux/current-customer-hooks";

interface DaasTileProps {
  permissions: string[];
}

const DaasTilesPopup: React.FC<DaasTileProps> = ({ permissions }) => {
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const { customer } = useCurrentCustomer();

  return (
    <TileGridPopup>
      {permissions?.map?.((item: string): JSX.Element | null => {
        if (!permissionsMap[item]) {
          return null;
        }

        const {
          iconName,
          title,
          link,
          dynamicLink = () => link,
          theme,
        } = permissionsMap[item];

        const calculatedLink = dynamicLink({
          featuresCapabilities: customer?.featureCapabilities,
          persona: currentUser?.persona,
          loginMethod: currentUser?.userLoginMethod,
          isEmployee: /@hp\.com$/.test(currentUser?.email || ""),
          stage: process.env.REACT_APP_CONFIG_ENV,
        });

        if (!calculatedLink) {
          return null;
        }

        return (
          <TilePopup
            icon={<Icon iconName={iconName} theme={theme} />}
            title={t(title)}
            link={calculatedLink}
            key={title}
          />
        );
      })}

      {customer.toolsEnablerPermissions?.map?.(
        (item: string): JSX.Element | null => {
          const toolsEnablersPermission = permissionsMap[item];

          if (!toolsEnablersPermission) {
            return null;
          }

          const {
            rolePermissionId,
            iconName,
            title,
            link,
            dynamicLink = () => link,
            theme,
            target,
          } = toolsEnablersPermission;

          if (!(rolePermissionId && permissions.includes(rolePermissionId))) {
            return null;
          }

          const calculatedLink = dynamicLink({
            featuresCapabilities: customer?.featureCapabilities,
            persona: currentUser?.persona,
            loginMethod: currentUser?.userLoginMethod,
            isEmployee: /@hp\.com$/.test(currentUser?.email || ""),
            stage: process.env.REACT_APP_CONFIG_ENV,
          });

          if (!calculatedLink) {
            return null;
          }

          return (
            <TilePopup
              icon={<Icon iconName={iconName} theme={theme} />}
              title={t(title)}
              link={calculatedLink}
              key={title}
              target={target}
            />
          );
        }
      )}
    </TileGridPopup>
  );
};

export default DaasTilesPopup;
