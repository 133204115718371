export enum RegistrationState {
  INVITED = "INVITED",
  INVITATION_EXPIRED = "INVITATION_EXPIRED",
  NEW_ACCOUNT = "NEW_ACCOUNT",
  REGISTERED = "REGISTERED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  DEACTIVATED = "DEACTIVATED",
}

export const registrationTranslationMap = {
  [RegistrationState.INVITED]: "user.state.invited",
  [RegistrationState.INVITATION_EXPIRED]: "user.state.invitationExpired",
  [RegistrationState.NEW_ACCOUNT]: "user.state.newAccount",
  [RegistrationState.REGISTERED]: "user.state.registered",
  [RegistrationState.APPROVED]: "user.state.approved",
  [RegistrationState.REJECTED]: "user.state.rejected",
  [RegistrationState.DEACTIVATED]: "user.state.deactivated",
};
