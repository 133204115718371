import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, useHistory } from "react-router-dom";
import { Formik } from "formik";
import RequestOTPForm from "./components/request-otp-form";
import SubmitOTPForm from "./components/submit-otp-form";
import GuestOrderStatusForm, {
  GuestOrderStatusFormStep,
} from "./types/guest-order-status-form";
import {
  postOrderStatusOTP,
  requestOrderStatusOTP,
} from "../../api/order-status";
import { FormikHelpers } from "formik/dist/types";
import { GuestOrderStatus } from "./types/guest-order-status";
import GuestOrderStatusDisplay from "./components/guest-order-status-display";
import Page from "../../components/layouts/page";
import { useFeature, useAuthIsLoggedIn } from "../../redux/auth-hooks";
import { FetchError } from "../../types/fetch-error";
import getFormikErrors, {
  useQuickMakeMessage,
} from "../../shared/formik-errors";
import { useAddError } from "../../redux/toast-hooks";
import EmailUnsubscribe from "../emailUnsubscribe";
import EmailSubmitForm from "../email-submit-form";

const GuestOrderStatusPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const enable = useFeature<boolean>("guestOrderStatus");
  const addError = useAddError();
  const quickMakeMessage = useQuickMakeMessage();
  const isLoggedIn = useAuthIsLoggedIn();

  const [orderStatus, setOrderStatus] = useState<GuestOrderStatus | undefined>();

  const initialValues: GuestOrderStatusForm = {
    emailAddress: "",
    orderNumber: "",
    fieldsLocked: false,
    captcha: "",
    orderId: "",
    otp: "",
    unsubscribe: false,
    step: GuestOrderStatusFormStep.RequestOTP,
  };

  const onSubmit = (
    values: GuestOrderStatusForm,
    formikHelpers: FormikHelpers<GuestOrderStatusForm>
  ) => {
    const { setFieldValue, setSubmitting, setErrors } = formikHelpers;
    setSubmitting(true);
    setErrors({});

    switch (values.step) {
      case GuestOrderStatusFormStep.RequestOTP:
        requestOrderStatusOTP(values).then(
          () => {
            history.push("/my-order/otp");
            setFieldValue("captcha", "");
            setFieldValue("fieldsLocked", true);
            setSubmitting(false);
          },
          (error: FetchError) => {
            setSubmitting(false);
            setErrors(getFormikErrors(error, quickMakeMessage));
            if (!error.details) {
              addError(error.message);
            }
          }
        );
        break;
      case GuestOrderStatusFormStep.SubmitOTP:
        postOrderStatusOTP(values).then(
          (value: GuestOrderStatus) => {
            setOrderStatus(value);
            history.replace("/my-order/status");
            setFieldValue("otp", "");
            setFieldValue("captcha", "");
            setSubmitting(false);
          },
          (error) => {
            setSubmitting(false);
            setErrors(getFormikErrors(error, quickMakeMessage));
            if (!error.details) {
              addError(error.message);
            }
          }
        );
        break;
      default:
        addError("An unexpected error occurred.");
        setSubmitting(false);
        break;
    }
  };

  if (enable === false) {
    return null;
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {() => (
        <Switch>
          <Route path="/my-order/status">
            <Page title={t("orderstatus.title")}>
              <GuestOrderStatusDisplay orderStatus={orderStatus} />
            </Page>
          </Route>
          <Route path="/my-order/otp">
            <Page title={t("orderstatus.title")}>
              <SubmitOTPForm />
            </Page>
          </Route>
          <Route path="/my-order">
            <Page title={t("orderstatus.title")}>
              <RequestOTPForm />
            </Page>
          </Route>
          {!isLoggedIn && (
            <Route path={`/email-preferences/order/:orderNumber`} exact>
              <Page title={t("emailUnsubscribe.unsubscribe")}>
                <EmailSubmitForm />
              </Page>
            </Route>
          )}
          {!isLoggedIn && (
            <Route path={"/email-preferences"} exact>
              <Page title={t("emailUnsubscribe.unsubscribe")}>
                <EmailSubmitForm />
              </Page>
            </Route>
          )}
          {isLoggedIn && (
            <Route path={"/email-preferences"} exact>
              <Page title={t("emailUnsubscribe.unsubscribe")}>
                <EmailUnsubscribe />
              </Page>
            </Route>
          )}
          {isLoggedIn && (
            <Route path={`/email-preferences/order/:orderNumber`}>
              <Page title={t("emailUnsubscribe.unsubscribe")}>
                <EmailUnsubscribe />
              </Page>
            </Route>
          )}
        </Switch>
      )}
    </Formik>
  );
};

export default GuestOrderStatusPage;
