import React from "react";
import { TFunction } from "i18next";

/**
 * Type for getting a nested value or other string-compatible value from an object.
 * Not intended to be combined with things like app state.
 * DANGER: if this is a FUNCTION, it MUST be defined OUTSIDE any React rendering scope or other generator function!
 * The included hooks use properties as an index, which means STRICT EQUALITY is considered.
 * If your function is "remade" as a result of a re-render, but the hooks are kept in state, the hooks will fail.
 */
export type Getter<T> = ((obj: T) => any) | ((obj: T, t: TFunction) => any);

/**
 * Simple type for a property.
 * Properties are expected to be strings in this usage.
 */
export type Key<T> = (keyof T & string);

export type Property<T> = Getter<T> | Key<T>;

export type Comparator<T> = (a: T, b: T) => number;

// Can use TableBackendV3Context in TableBackend3
export type CellFC<T> = React.FC<{ item: T, index: number }>;

// Can use TableBackendV3Context in TableBackend3
export type ExpandedCellFC<T, V = T> = React.FC<{ item: T, detail?: V, index: number }>;

export enum SearchType {
  /**
   * used by useFilter
   * also used by useSearch - only for "global" search
   */
  Filter,

  /**
   * used by useSearch only
   */
  Text,

  /**
   * used by useSearch
   */
  Date,

  /**
   * used by useSearch
   */
  DateRange,
}

export enum SortDirection {
  ASC = 1,
  DESC = -1,
  NONE = 0,
}

export type VeneerSortDirection = "ASC" | "DESC";

export enum ColumnAlign {
  Left = "left",
  Center = "center",
  Right = "right",
}

/**
 * T should be the type of data you expect for the table
 * U is by default the same as T, but can be set as a parent type of T if needed for CellFC compatibility
 * Do not set U as anything other than T or a parent type of T
 */
export interface Column<T, U = T> {
  /**
   * Property for value of Column from item.
   * DANGER: if this is a FUNCTION, it MUST be defined OUTSIDE any React rendering scope or other generator function!
   * The result getTableProp(item, property) is passed to search, sort, etc functions and shown in tables.
   * If you retire anything other than text to be shown in the table, also see the "render" property below.
   * See note for Getter<T> for more info.
   */
  property: Property<T>;

  /**
   * Label for filters & table header
   * Can be empty string / blank, but only include ONE blank item per table
   */
  label: string;

  /**
   * Info tag
   */
  info?: string;

  /**
   * Whether column is "enabled" for display as well as search/filter/etc.
   * Default true.
   */
  enabled?: boolean;

  /**
   * Whether column is "hidden" from display.
   * The column will still be searchable/filterable.
   * Default false.
   */
  hidden?: boolean;

  /**
   * Whether column is "hidden" from display on mobile.
   * The column will still be searchable/filterable.
   * Default false.
   */
  mobileHidden?: boolean;

  /**
   * Column alignment
   * Default ColumnAlign.Left.
   */
  align?: ColumnAlign;

  /**
   * Mobile column alignment
   * Default ColumnAlign.Left.
   */
  mobileAlign?: ColumnAlign;

  /**
   * Optional renderer for an item, if the string from getTableProp(item, property) isn't sufficient
   * Necessary if you want to display a link, image, etc in a table
   * May be used with either function-based or string-based Column.property
   */
  render?: CellFC<T> | CellFC<U>;

  /**
   * Allow sorting (default true)
   */
  sortable?: boolean;

  /**
   * Optional search type.
   * If a column has none, no search will be performed.
   */
  searchType?: SearchType;

  /**
   * Optional comparison logic
   */
  comparator?: Comparator<T>;

  /**
   * For backend tables - method to translate filter choices
   * @param value
   */
  translateFilterChoice?: (value: string | undefined, t: TFunction) => string;

  /**
   * For making the column required while configuring the column visibility
   * @default false
   */
  required?: boolean;

  /**
   * For Veneer table - whether visible by default
   * @default true
   */
  visibleByDefault?: boolean;

  /**
   * For Veneer table - initial column width
   * @default "120px"
   */
  width?: string;
}

export interface BackendColumn<T, U = T> extends Column<T, U> {
  property: Key<T>;
}
