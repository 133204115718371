import React, { useMemo } from "react";
import { GuestOrderStatus } from "../types/guest-order-status";
import OrderStatusTrackerGuestView from "../../orders/components/order-status-tracker-guest-view";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { GuestOrderItemsTable } from "../../orders/components/shipment-groups-table";
import dates from "../../../shared/dates";

const TabHeader = styled.div`
  height: 40px;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  padding-left: 20px;
  background-color: ${props => props.theme.colors.bgV7};
  color: ${props => props.theme.colors.white};
  font-weight: bold;
`;

const TableWrapper = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  margin-bottom: 20px;
  padding: 0 0 1px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex: 1;
  }
`;

const Header = styled.div`
  padding: 10px 20px;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  font-weight: bold;
`;

const Left = styled.div`
  text-align: left;
  flex: 1;
`;

const Middle = styled.div`
  text-align: center;
  flex: 1;
`;

const Right = styled.div`
  text-align: right;
  flex: 1;
`;

interface Props {
  orderStatus?: GuestOrderStatus;
}

const GuestOrderStatusDisplay: React.FC<Props> = ({ orderStatus }) => {
  const { t } = useTranslation();

  const quantity = useMemo(
    () => orderStatus?.items
      .map((item) => item.products)
      .flat()
      .map((product) => +(product?.quantity ?? 0))
      .reduce((a, b) => a + b, 0),
    [orderStatus],
  );

  const onSiteDate = orderStatus && orderStatus.onSiteDate && dates.format(orderStatus.onSiteDate, dates.formats.international);
  const estimatedDeliveryDate = orderStatus && orderStatus.plannedDeliveryDate && dates.format(orderStatus.plannedDeliveryDate, dates.formats.international);
  const customerOrderNumber = orderStatus?.portalCustomerOrderNumber !== undefined ? orderStatus?.portalCustomerOrderNumber : orderStatus?.customerOrderNumber;
  if (!orderStatus?.singleOrderNumber) {
    return null;
  }

  return (
    <TableWrapper>
      <TabHeader>{t("orderstatus.title")}</TabHeader>
      <Header>
        <Left>
          {t("orderstatus.ordernumber")}: {orderStatus.singleOrderNumber}
          <br />
          {!!customerOrderNumber && (<>{t("order.shipinfo.CON")}:{" "}{customerOrderNumber}</>)}
        </Left>
        <Middle>{t("orderstatus.orderitems", { quantity, count: quantity })}</Middle>
        <div>
          {onSiteDate && <Right>{t("order.shipinfo.onsite")}: {onSiteDate}</Right>}
          {estimatedDeliveryDate && <Right>{t("orderdetails.estdelivery")}: {estimatedDeliveryDate}</Right>}
        </div>
        <br />
      </Header>
      <OrderStatusTrackerGuestView order={orderStatus} />
      <GuestOrderItemsTable order={orderStatus} />
    </TableWrapper>
  );
};

export default GuestOrderStatusDisplay;
