import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Modal } from "@veneer/core";
import Button from "components/button";

const BoxStyle = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.background};
  border-bottom: 1px solid ${(props) => props.theme.colors.background};
  margin-bottom: 10px;
  margin-top: 10px;
`;

const LinkStyle = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
`;

const Info = styled.button`
  color: ${(props) => props.theme.colors.blue};
  &:hover {
    cursor: pointer;
  }
`;

const ContentContainer = styled.div`
  word-wrap: break-word;
`;

interface Props {
  messageLinks: any;
}

interface LinkProps {
  item: any;
}

export const MessageLink: React.FC<LinkProps> = ({ item }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);

  const validateLink = (link: string) => {
    const regex = /\.hp\.com$/i;
    if (regex.test(link)) {
      window.open(link, "_blank");
    } else {
      setShow(true);
    }
  };

  return (
    <>
      <LinkStyle>
        <Info data-cy="messageLink" onClick={() => validateLink(item.link)}>
          {item.description}
        </Info>
      </LinkStyle>
      <Modal
        size="sm"
        show={show}
        onClose={() => setShow(false)}
        dismissOnOverlayClick={false}
      >
        <ContentContainer>
          {t("noticeBoard.externallink.warning", { link: item.link })}
          <br />
          <br />
          {t("noticeBoard.externallink.clickOk")}
          <br />
          <br />
          <Button
            onClick={() => {
              window.open(item.link, "_blank");
              setShow(false);
            }}
          >
            {t("ok")}
          </Button>
        </ContentContainer>
      </Modal>
    </>
  );
};

const LinkDescription: React.FC<Props> = ({ messageLinks }) => {
  return (
    <BoxStyle>
      {messageLinks.map((item: any, index: number) => {
        return <MessageLink key={index} item={item} />;
      })}
    </BoxStyle>
  );
};

export default LinkDescription;
