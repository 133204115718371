import React from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import Button from "components/button";
import { RootActionTypes } from "../../../redux/root-action";
import { downloadReportExit } from "../../../redux/report-download-actions";
import { useTranslation } from "react-i18next";

const ButtonWrapper = styled.div`
  > * {
    margin-top: 20px;
    float: right;
  }
`;

const ReportModalDone: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();
  const close = () => dispatch(downloadReportExit());

  return (
    <div>
      {t("reports.modal.done")}
      <ButtonWrapper>
        <Button onClick={close} data-cy="closeButton">{t("close")}</Button>
      </ButtonWrapper>
    </div>
  );
};

export default ReportModalDone;
