import { ThunkAction } from "redux-thunk";
import { RootState } from "redux/redux-state";
import {
  hideLocalizationSkuDialog,
  incrementShoppingCartVersion,
  patchShoppingCart,
  resetShoppingCart,
  setShoppingCart,
  shoppingCartError,
  shoppingCartLoading,
  shoppingCartSubmitting,
  showLocalizationSkuDialog,
} from "./shopping-cart-action";
import {
  getCart,
  placeOrder,
  setCartContract,
  setCartCountry,
  setCartSite,
  setCartHomeAddress,
  updateCart,
  updateHPFSAndBigDealNumbers,
  updateShippingInfo,
} from "../api/shopping-cart";
import Site from "../types/site";
import CatalogItem from "../types/catalog-item";
import { skuLocalizationByCountry } from "../shared/sku-localization";
import { CartItem, ShippingInfo } from "../types/order";
import { History } from "history";
import { patchAuthCoolDown } from "./auth-actions";
import { RootActionTypes } from "./root-action";

export type ShoppingCartThunkAction = ThunkAction<
  void,
  RootState,
  void,
  RootActionTypes
>;

const getShoppingCartVersion = (state: RootState) => state.shoppingCart.version;

export const asyncGetCart =
  (): ShoppingCartThunkAction => async (dispatch, getState) => {
    const {
      currentCustomer: {
        customer: { id: customerId },
      },
    } = getState();

    if (!customerId) {
      return;
    }

    const cart = await getCart(customerId).catch((error) => {
      dispatch(shoppingCartError(error));
    });

    if (cart) {
      dispatch(setShoppingCart(cart));
    }
  };

export const asyncUpdateCart =
  (
    bundleId: any,
    localizationCode: string,
    quantity: number,
    solutionId: any
  ): ShoppingCartThunkAction =>
  async (dispatch, getState) => {
    const {
      currentCustomer: {
        customer: { id: customerId },
      },
      shoppingCart: { items = [] },
    } = getState();
    const existingItems = [...items];
    const match = existingItems.findIndex(
      (it) =>
        it.bundleId === bundleId && it.localizationCode === localizationCode
    );

    if (match >= 0) {
      existingItems[match] = { ...existingItems[match], quantity };
      dispatch(patchShoppingCart({ items: existingItems }));
    }

    dispatch(shoppingCartLoading());
    dispatch(hideLocalizationSkuDialog());

    dispatch(incrementShoppingCartVersion());
    const actionId = getShoppingCartVersion(getState());

    const cart = await updateCart(
      customerId,
      bundleId,
      localizationCode,
      quantity,
      solutionId
    ).catch((error) => {
      dispatch(shoppingCartError(error));
    });

    if (getShoppingCartVersion(getState()) > actionId) {
      return;
    }

    if (cart) {
      dispatch(setShoppingCart(cart));
    }
  };

export const asyncSelectContract = (contractId?: string): ShoppingCartThunkAction => async (dispatch, getState) => {
    const { currentCustomer: { customer: { id: customerId }, }, } = getState();

    dispatch(shoppingCartLoading());
    const cart = await setCartContract(customerId, contractId).catch(
      (error) => {
        dispatch(shoppingCartError(error));
      }
    );

    if (cart) {
      dispatch(setShoppingCart(cart));
    }
  };

export const asyncSelectHomeAddress =
  (homeAddress?: any): ShoppingCartThunkAction =>
  async (dispatch, getState) => {
    const {
      currentCustomer: {
        customer: { id: customerId },
      },
    } = getState();

    dispatch(shoppingCartLoading());

    const cart = await setCartHomeAddress(customerId, homeAddress).catch(
      (error) => {
        dispatch(shoppingCartError(error));
      }
    );

    if (cart) {
      dispatch(setShoppingCart(cart));
    }
  };

export const asyncSelectCountry =
  (countryId?: string): ShoppingCartThunkAction =>
  async (dispatch, getState) => {
    const { currentCustomer: { customer: { id: customerId } } } = getState();

    dispatch(shoppingCartLoading());

    const cart = await setCartCountry(customerId, countryId).catch(
      (error) => {
        dispatch(shoppingCartError(error));
      }
    );

    if (cart) {
      dispatch(setShoppingCart(cart));
    }
  };

export const asyncSelectSite =
  (site?: Site): ShoppingCartThunkAction =>
  async (dispatch, getState) => {
    const {
      currentCustomer: {
        customer: { id: customerId },
      },
    } = getState();

    dispatch(shoppingCartLoading());

    const cart = await setCartSite(customerId, site).catch((error) => {
      dispatch(shoppingCartError(error));
    });

    if (cart) {
      dispatch(setShoppingCart(cart));
    }
  };

export const getNewQuantity = (
  items: CartItem[],
  bundleId: string,
  localizationCode: string,
  quantity: number
) => {
  const match = items.find(
    (it) => it.bundleId === bundleId && it.localizationCode === localizationCode
  );
  return match ? match.quantity + quantity : quantity;
};

export const asyncAddToCart = (item: CatalogItem, quantity: number, localizationCode?: string): ShoppingCartThunkAction =>
  async (dispatch, getState) => {
    const {
      shoppingCart: { defaultLocalizationCode, items },
    } = getState();
    const allowed = skuLocalizationByCountry[item.country || ""] || [];
    const allowedCodes = allowed.map((it) => it.code);
    if (localizationCode) {
      const newQuantity = getNewQuantity(
        items,
        item.bundleId,
        localizationCode,
        quantity
      );
      dispatch(
        asyncUpdateCart(
          item.bundleId,
          localizationCode,
          newQuantity,
          item.solutionId
        )
      );
      return;
    }

    if (
      defaultLocalizationCode &&
      allowedCodes.includes(defaultLocalizationCode)
    ) {
      const newQuantity = getNewQuantity(
        items,
        item.bundleId,
        defaultLocalizationCode,
        quantity
      );
      dispatch(
        asyncUpdateCart(
          item.bundleId,
          defaultLocalizationCode,
          newQuantity,
          item.solutionId
        )
      );
      return;
    }

    dispatch(showLocalizationSkuDialog({ item, quantity }));
  };

export const asyncSetHPFSAndBigDealNumbers =
  (hpfsNumber?: string, bigDealNumber?: string): ShoppingCartThunkAction =>
  async (dispatch, getState) => {
    const {
      currentCustomer: {
        customer: { id: customerId },
      },
    } = getState();

    const cart = await updateHPFSAndBigDealNumbers(
      customerId,
      hpfsNumber || "",
      bigDealNumber || ""
    );

    if (cart) {
      dispatch(setShoppingCart(cart));
    }
  };

export const asyncSetShippingInfo =
  (shippingInfo: ShippingInfo): ShoppingCartThunkAction =>
  async (dispatch, getState) => {
    const {
      currentCustomer: {
        customer: { id: customerId },
      },
    } = getState();

    const cart = await updateShippingInfo(customerId, shippingInfo);

    if (cart) {
      dispatch(setShoppingCart(cart));
    }
  };

export const asyncPlaceOrder = (history: History): ShoppingCartThunkAction => async (dispatch, getState) => {
  const { currentCustomer: { customer: { id: customerId } } } = getState();

  dispatch(shoppingCartSubmitting(true));

  const { order, coolDown } = await placeOrder(customerId)
    .catch((error) => {
      console.error(error);
      dispatch(shoppingCartSubmitting(false));
      dispatch(shoppingCartError(error.message));
      return {
        order: undefined,
        coolDown: undefined
      };
    });

  const coolDownValue = coolDown
    ? coolDown.valueOf().toString(10)
    : "";

  localStorage.setItem("coolDown", coolDownValue);
  dispatch(patchAuthCoolDown(coolDown));

  if (order) {
    dispatch(resetShoppingCart());
    dispatch(shoppingCartSubmitting(false));
    history.push(`/${customerId}/orders`);
  }

  dispatch(asyncGetCart());
};
