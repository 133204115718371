import React, { useCallback, useEffect, useState } from "react";
import useStorage from "../hooks/use-storage";
import { useAuth } from "./auth-hooks";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { RootActionTypes } from "./root-action";
import { patchAuthCoolDown } from "./auth-actions";

export const useSetCoolDown = () => {
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();

  return useCallback(
    (coolDown?: Date): void => {
      const value = coolDown
        ? coolDown.valueOf().toString(10)
        : "";

      localStorage.setItem("coolDown", value);
      dispatch(patchAuthCoolDown(coolDown));
    },
    [dispatch],
  );
};

export const useStorageCoolDown = (): Date | undefined => {
  const storageCoolDown = useStorage(localStorage, "coolDown", "") as string;

  if (storageCoolDown && /^[0-9]+$/.test(storageCoolDown)) {
    return new Date(+storageCoolDown);
  }

  return undefined;
};

export const useIsCoolDown = (): boolean => {
  const value = useStorageCoolDown();
  const [result, setResult] = useState<boolean>(!!value && value >= new Date());

  useEffect(
    () => {
      const t = setInterval(() => {
        setResult(!!value && value >= new Date());
      }, 1000);

      return () => {
        clearInterval(t);
      };
    },
    [value],
  );

  return result;
};

const InitCoolDown: React.FC = () => {
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();
  const { currentUser: { coolDown } } = useAuth();
  const storageCoolDown = useStorageCoolDown();

  useEffect(
    () => {
      if (coolDown?.valueOf() !== storageCoolDown?.valueOf()) {
        dispatch(patchAuthCoolDown(storageCoolDown));
      }
    },
    [dispatch, coolDown, storageCoolDown],
  );

  return null;
};

export default InitCoolDown;
