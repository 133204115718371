import { getJson, putJson } from "./request";
import Feature from "../types/feature";
import queryString from "query-string";

export const getAllFeatureFlags = (): Promise<Feature[]> => getJson("/features/");

export const updateFeatureFlag = (id: string, value?: any): Promise<any> => putJson(`/features/${id}`, {
  value: (value !== null && value !== undefined ? JSON.stringify(value) : undefined),
});

export const getRedirectionDataByRoute = (route: string, requestData: string): Promise<{ redirectParam: string, customerId: string }> => getJson(`/${route}/by-key?${queryString.stringify({ requestData })}`);
