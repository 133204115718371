import { Me } from "../types/user";

export const AUTH_REGEX = /^auth\//;
export const AUTH_SET_ME = "auth/setMe";
export const AUTH_SET_LOADING = "auth/setLoading";
export const AUTH_SET_LOGGING_IN = "auth/setLoggingIn";
export const AUTH_SET_LOGIN_ERROR = "auth/setLoginError";
export const AUTH_PATCH_PREFERENCES = "auth/patchPreferences";
export const AUTH_PATCH_COOL_DOWN = "auth/patchCoolDown";

export interface SetAuthMeParams {
  type: typeof AUTH_SET_ME;
  me: Me;
  loading: boolean;
}

export interface SetAuthLoadingParams {
  type: typeof AUTH_SET_LOADING;
  loading: boolean;
}

export interface SetAuthLoggingInParams {
  type: typeof AUTH_SET_LOGGING_IN;
  loggingIn: boolean;
}

export interface SetAuthLoginErrorParams {
  type: typeof AUTH_SET_LOGIN_ERROR;
  errorCode?: string;
  errorMessage?: string;
  errorInfo?: any;
}

export interface PatchAuthPreferencesParams {
  type: typeof AUTH_PATCH_PREFERENCES;
  preferences: Record<string, any>;
}

export interface PatchAuthCoolDownParams {
  type: typeof AUTH_PATCH_COOL_DOWN;
  coolDown?: Date | string;
}

export type AuthTypes = (
  SetAuthMeParams
  | SetAuthLoadingParams
  | SetAuthLoggingInParams
  | SetAuthLoginErrorParams
  | PatchAuthPreferencesParams
  | PatchAuthCoolDownParams
);

export const setAuthMe = (me: Me, loading: boolean = false): AuthTypes => ({
  type: AUTH_SET_ME,
  me,
  loading,
});

export const setAuthLoading = (loading: boolean = true): AuthTypes => ({ type: AUTH_SET_LOADING, loading });

export const setAuthLoggingIn = (loggingIn: boolean = true): AuthTypes => ({ type: AUTH_SET_LOGGING_IN, loggingIn });

export const setAuthLoginError = (errorCode?: string, errorMessage?: string, errorInfo?: any): AuthTypes => ({
  type: AUTH_SET_LOGIN_ERROR,
  errorCode,
  errorMessage,
  errorInfo,
});

export const patchAuthPreferences = (preferences: Record<string, any>): AuthTypes => ({
  type: AUTH_PATCH_PREFERENCES,
  preferences,
});

export const patchAuthCoolDown = (coolDown?: string | Date): AuthTypes => ({
  type: AUTH_PATCH_COOL_DOWN,
  coolDown,
});
