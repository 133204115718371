import { RootActionTypes } from "./root-action";
import { ToastState, initialToastState } from "./redux-state";
import { TOAST_ADD, TOAST_REGEX, TOAST_REMOVE } from "./toast-actions";

const toastReducer = (
  state: ToastState = initialToastState,
  action: RootActionTypes,
): ToastState => {
  if (!TOAST_REGEX.test(action.type)) {
    return state;
  }

  switch (action.type) {
    case TOAST_ADD:
      return {
        id: state.id + 1,
        messages: [
          ...state.messages,
          { id: state.id + 1, toastType: action.toastType, message: action.message },
        ],
      };
    case TOAST_REMOVE:
      return {
        id: state.id,
        messages: state.messages.filter((it) => it.id !== action.id),
      };
  }

  return state;
};

export default toastReducer;
