import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Icon } from "@veneer/core";
import styled from "styled-components";
import NoticeBoard from "types/notice-board";
import dates from "../../../shared/dates";
import MessageAttachment from "./message-attachment";
import Button from "components/button";
import { addCalendarEvent } from "api/customer-notice-board";
import { useAddError } from "redux/toast-hooks";
import download, { FileType } from "shared/download";
import { formatTime } from "shared/format-time";
import LinkDescription, { MessageLink } from "./link-description";

interface Props {
  message: NoticeBoard;
  onClose(): void;
  show: boolean;
}

const DateTime = styled.div`
  font-size: ${(props) => props.theme.sizes.size12};
  padding-bottom: 2px;
`;

const MainMessage = styled.div`
  font-size: ${(props) => props.theme.sizes.size18};
  margin-bottom: 10px;
  font-weight: bold;
  padding-top: 10px;
`;

const DetailedMessage = styled.div`
  margin-bottom: 10px;
  white-space: pre-wrap;
  @media ${(props) => props.theme.breakpoints.tablet} {
    max-height: calc(100vh - 430px);
    overflow-y: auto;
  }
`;

const Component = styled.div`
  display: flex; 
  flex-direction: column;
  justify-content: space-between;
`;

const CalenderIcon = styled.div`
  float: right;
  .vn-icon {
    width: 75px;
    height: 75px;
  }
  justify-content: space-between;
`;

const StyledButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  .vn-button {
    margin: 10px;
  }
`;

const DateTimeWrapper = styled.div`
  margin: auto 0px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DetailedMessageWrapper = styled.div`
  margin-top: 15px;
`;

const StyledLocation = styled.div`
  padding-bottom: 5px;
  display: flex;
  p {
    margin-top: 10px;
  }
`;

const MessageLinkWrapper = styled.div`
  word-break: break-all;
  padding-top: 0px !important;
  margin-left: -12px;
`;

const EventDetailedModal: React.FC<Props> = ({ message, show, onClose }) => {
  const { t } = useTranslation();
  const addError = useAddError();
  const [loading, setLoading] = useState<boolean>(false);

  const handleCalendarEvent = (message: NoticeBoard) => {
    setLoading(true);
    const offsetTime = new Date().getTimezoneOffset();
    addCalendarEvent(message._id, offsetTime)
      .then((event: any) => {
        download.file(event.invite, `invite-${message.mainMessage}.ics`, FileType.ICS);
        setLoading(false);
      })
      .catch((error: any) => {
        addError(error.message);
        setLoading(false);
      });
  };

  const item = {
    description: message.eventLocation,
    link: message.eventLocation,
  };

  return (
    <Modal
      size="lg"
      show={show}
      onClose={onClose}
      dismissOnOverlayClick={false}
    >
      <Component>
        <Wrapper>
          <DateTimeWrapper>
            <DateTime>
              {t("posted")}{" "}
              {dates.format(
                message.createdDate ? message.createdDate : "",
                dates.formats.stringFormat
              )}
              {t(" ")}
              {t("at")}
              {t(" ")}
              {dates.format(
                message.createdDate ? message.createdDate : "",
                dates.formats.timeOnly
              )}
            </DateTime>
            {message.expiryDate && (
              <DateTime>
                {t("expires")}{" "}
                {dates.format(
                  message.expiryDate ? message.expiryDate : "",
                  dates.formats.stringFormat
                )}
              </DateTime>
            )}
            <MainMessage>{message.mainMessage}</MainMessage>
          </DateTimeWrapper>
          <CalenderIcon>
            <Icon name="calendar" />
          </CalenderIcon>
        </Wrapper>
        <div>
          {message.eventStartDate && (
            <DateTime>
              {t("start")}:{" "}
              {dates.format(
                message.eventStartDate ? message.eventStartDate : "",
                dates.formats.stringFormat
              )}
              {!message.allDayEvent && message.eventStartTime && ` ${t("at")} ${formatTime(message.eventStartTime)}`}
            </DateTime>
          )}
          {message.eventEndDate && (
            <DateTime>
              {t("end")}:{" "}
              {dates.format(
                message.eventEndDate ? message.eventEndDate : "",
                dates.formats.stringFormat
              )}
              {!message.allDayEvent && message.eventEndTime && ` ${t("at")} ${formatTime(message.eventEndTime)}`}
            </DateTime>
          )}
        </div>
      </Component>
      <DetailedMessageWrapper>
        {message.eventLocation && (
          <StyledLocation>
            <p>{t("location.title")}:</p>
            <MessageLinkWrapper>
              <MessageLink item={item} />
            </MessageLinkWrapper>
          </StyledLocation>
        )}
        <DetailedMessage>{message.detailedMessage}</DetailedMessage>
        {!!message.messageLinks?.length && <LinkDescription messageLinks={message.messageLinks} />}
        <MessageAttachment message={message} />
        <StyledButtons>
          <Button
            loading={loading}
            onClick={() => handleCalendarEvent(message)}
          >
            {t("add.calendar", { postProcess: "upper" })}
          </Button>
          <Button appearance="secondary" onClick={onClose}>
            {t("customer.setup.close.btn")}
          </Button>
        </StyledButtons>
      </DetailedMessageWrapper>
    </Modal>
  );
};

export default EventDetailedModal;
