import React, { Dispatch, useState } from "react";
import { ConfirmationModal, RadioButtons, RadioButton } from "@veneer/core";
import { useCurrentCustomer } from "redux/current-customer-hooks";
import QuickActionButton from "components/quick-actions/quick-action-button";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { asyncSelectContract } from "redux/shopping-cart-thunks";
import { useDispatch } from "react-redux";
import { RootActionTypes } from "redux/root-action";
import { resetShoppingCart } from "redux/shopping-cart-action";
import { useNewOrderContractId } from "redux/shopping-cart-hooks";
import { useHistory } from "react-router-dom";
import Button from "components/button";
import { useCanPlaceOrder } from "../../redux/combo-hooks";
import { hp2bLink } from "types/tile-permissions";
import { permissions, customerPermissions } from "@mssi/pssp-shared";
import { useCurrentCustomerCapability } from "../../shared/customer-has-capability";
import { useHasPermission } from "../../redux/auth-hooks";

interface Props {
  contractId?: string;
}

const StyleModal = styled.div`
  .vn-modal__footer {
    margin-top: 10px;
  }
`;

const PlaceOrderButton: React.FC<Props> = ({ contractId }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const shoppingCartContractId = useNewOrderContractId();
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();
  const [showOrderSelectionModal, setShowOrderSelectionModal] = useState<boolean>(false);
  const [orderType, setOrderType] = useState<number>(1); // 0 = self, 1 = other
  const { customer } = useCurrentCustomer();
  const hasPermission = useHasPermission();

  const canAccessHP2B = hasPermission(permissions.canAccessHP2B);
  const canCurrentCustomerAccessHP2B = useCurrentCustomerCapability(customerPermissions.canEnableHP2B);
  const useHP2B = canAccessHP2B && canCurrentCustomerAccessHP2B;

  const {
    canPlaceOrder,
    canPlaceSomeOrder,
    canPlaceBothOrderTypes,
  } = useCanPlaceOrder();

  const setOrderContract = () => {
    if (contractId && contractId !== shoppingCartContractId) {
      dispatch(resetShoppingCart());
      dispatch(asyncSelectContract(contractId) as any);
    }
  };

  const goToOrderForm = () => {
    setOrderContract();
    history.push(`/${customer.id}/orders/new-order`);
  };

  const goToSelfOrderForm = () => {
    setOrderContract();
    history.push(`/${customer.id}/orders/new-order-user`);
  };

  const onSelectConfirm = () => {
    setShowOrderSelectionModal(false);

    if (orderType === 0) {
      goToSelfOrderForm();
    } else {
      goToOrderForm();
    }
  };

  const navigateToPlaceOrder = () => {
    if (canPlaceOrder) {
      goToOrderForm();
    } else {
      goToSelfOrderForm();
    }
  };

  const handlePlaceOrder = () => {
    if (canPlaceBothOrderTypes) {
      setShowOrderSelectionModal(true);
    } else {
      navigateToPlaceOrder();
    }
  };

  if (useHP2B) {
    const link = hp2bLink({
      featuresCapabilities: customer?.featureCapabilities,
      stage: process.env.REACT_APP_CONFIG_ENV,
    });

    return (
      <QuickActionButton
        text={t("order.placeOrderHP2B", { postProcess: "upper" })}
        icon="bag"
      >
        <Button
          data-cy="placeAnOrder"
          onClick={() => {
            window.open(link, "_blank");
          }}
        />
      </QuickActionButton>
    );
  }

  if (!canPlaceSomeOrder) {
    return null;
  }

  return (
    <>
      <QuickActionButton
        text={t("order.placeOrder", { postProcess: "upper" })}
        icon="bag"
      >
        <Button
          data-cy="placeAnOrder"
          onClick={() => handlePlaceOrder()}
        />
      </QuickActionButton>
      {showOrderSelectionModal && (
        <StyleModal>
          <ConfirmationModal
            title={t("order.popup")}
            size="sm"
            show={showOrderSelectionModal}
            onConfirm={onSelectConfirm}
            closeButtonLabel={t("btn.cancel")}
            confirmButtonLabel={t("btn.next")}
            onClose={() => {
              setShowOrderSelectionModal(false);
              setOrderType(1);
            }}
            align="left"
            dismissOnOverlayClick={false}
          >
            <RadioButtons
              name="radioButton"
              selectedIndex={orderType}
              onChange={(value: any) => setOrderType(value)}
            >
              <RadioButton index={0} label={t("self")} />
              <RadioButton index={1} label={t("other")} />
            </RadioButtons>
          </ConfirmationModal>
        </StyleModal>
      )}
    </>
  );
};

export default PlaceOrderButton;
