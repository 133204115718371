import React from "react";
import styled from "styled-components";
import { OrderStatus, orderStatusMap } from "../../../types/order";
import useIsDevice from "../../../shared/isDevice";
import deviceType from "../../../shared/devices";

const DateContainer = styled.span`
  font-weight: normal;
  color: ${(props) => props.theme.colors.black};
`;

export interface Props {
  status: OrderStatus;
  date?: string;
}

const OrderStatusTrackerItem: React.FC<Props> = ({ status, date }) => {
  const isMobile = useIsDevice(deviceType.MOBILE, deviceType.MOBILELARGE, deviceType.TABLET);
  const statusText = orderStatusMap[status] ? orderStatusMap[status] : "UNKNOWN";

  return (
    <>
      {statusText}
      {date && (
        <>
          {isMobile ? " " : <br />}
          <DateContainer>{date}</DateContainer>
        </>
      )}
    </>
  );
};

export default OrderStatusTrackerItem;
