import React from "react";
import { Icon } from "@veneer/core";
import theme from "styles/theme";
import styled from "styled-components";
import TextButton from "components/text-button";
import { useCaseRequestCurrentStep } from "../../../redux/case-request-hooks";
import { useDispatch } from "react-redux";
import { RootActionTypes } from "../../../redux/root-action";
import { Dispatch } from "redux";
import { caseRequestGotoStep } from "../../../redux/case-request-actions";

export enum StatusStepTypes {
  COMPLETE = "Complete",
  INCOMPLETE = "Inactive",
  START = "Start",
}

interface Props {
  title: string;
  step: number;
}

interface WrapperProps {
  status: string;
  centered: boolean;
}

interface StatusCircleProps {
  statusColor: string;
  statusBGColor: string;
  status: string;
}

const Header = styled.div<WrapperProps>`
  display: inline-flex;
  width: 100%;
  align-items: ${props => (props.centered ? "center" : "flex-start")};
  opacity: ${props => (props.status === StatusStepTypes.INCOMPLETE ? ".5" : "1")};
`;

const StatusCircle = styled.div<StatusCircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
  margin-right: 20px;
  flex-shrink: 0;
  border-radius: 50%;
  font-size: 20px;
  font-family: ${(props) => props.theme.font.regular};
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => (props.status === StatusStepTypes.COMPLETE ? `${props.theme.colors.borderColor}` : "transparent")};
  color: ${(props) => props.statusColor};
  background-color: ${(props) => props.statusBGColor};

  svg {
    ${(props) => (props.status === StatusStepTypes.START ? "margin: 8px 0 0 4px;" : "")};
  }
`;

const Wrapper = styled.div`
  width: 100%;
`;

const Children = styled.div`
  margin-top: 20px;
`;

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;

const StyleSearch = styled.div`
  @media ${(props) => props.theme.breakpoints.mobileMaximum} {
    margin-top: 20px;
  }
`;

const RotateIcon = styled.div`
        .vn-icon--size-32 {
            transform: rotate(270deg);
        }
`;

const statusCircleColorMap: { [key in StatusStepTypes]: string } = {
  [StatusStepTypes.COMPLETE]: theme.colors.body,
  [StatusStepTypes.INCOMPLETE]: theme.colors.body,
  [StatusStepTypes.START]: theme.colors.white,
};

const statusCircleBGColorMap: { [key in StatusStepTypes]: string } = {
  [StatusStepTypes.COMPLETE]: theme.colors.white,
  [StatusStepTypes.INCOMPLETE]: theme.colors.border,
  [StatusStepTypes.START]: theme.colors.blue,
};

const getStatus = (currentStep: number, step: number) => (
  (currentStep > step)
    ? StatusStepTypes.COMPLETE
    : (
      (currentStep < step)
        ? StatusStepTypes.INCOMPLETE
        : StatusStepTypes.START
    )
);

const CaseRequestStatusStep: React.FC<Props> = ({
  title,
  step,
  children,
}) => {
  const currentStep = useCaseRequestCurrentStep();
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();
  const status = getStatus(currentStep, step);
  const statusColor = statusCircleColorMap[status];
  const statusBGColor = statusCircleBGColorMap[status];

  const jumpToThisStep = () => {
    dispatch(caseRequestGotoStep(step));
  };

  return (
    <Wrapper>
      <Header status={status} centered={!children}>
        <StatusCircle statusColor={statusColor} statusBGColor={statusBGColor} status={status}>
          {
            status === StatusStepTypes.START
              ? (
                <RotateIcon><Icon className="rotateIcon" name="chevron" size={32} /> </RotateIcon>
              )
              : (
                status === StatusStepTypes.COMPLETE
                  ? <Icon name="checkmark" size={32} />
                  : step + 1
              )
          }
        </StatusCircle>
        <TitleWrapper>
          <h5>{title}</h5>
          {status === StatusStepTypes.COMPLETE && (
            <TextButton onClick={() => jumpToThisStep()}>Edit</TextButton>
          )}
        </TitleWrapper>
      </Header>
      <StyleSearch>
        {children && status === StatusStepTypes.START && (
          <Children>{children}</Children>
        )}
      </StyleSearch>
    </Wrapper>
  );
};

export default CaseRequestStatusStep;
