import styled from "styled-components";

const PanelsContainer = styled.div`
  width: 100%;
  & > * + * {
    margin-top: ${props => props.theme.gutter};
  }
`;

export default PanelsContainer;
