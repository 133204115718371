import { ITSMContact } from "../types/contact";
import { ITSMLocation } from "../types/location";
import { IssueType } from "./redux-state";

export const CASE_REQUEST_REGEX = /^caseRequest\//;
export const CASE_REQUEST_SHOW_MODAL = "caseRequest/show";
export const CASE_REQUEST_CLOSE_MODAL = "caseRequest/close";
export const CASE_REQUEST_CHANGE_SELECTED_FOR = "caseRequest/selectedFor";
export const CASE_REQUEST_CHANGE_COUNTRY = "caseRequest/changeCountry";
export const CASE_REQUEST_CHANGE_ORDER_NUMBER = "caseRequest/chnageOrderNumber";
export const CASE_REQUEST_CHANGE_CONTACT = "caseRequest/changeContact";
export const CASE_REQUEST_CHANGE_ALTERNATE_CONTACT = "caseRequest/changeAlternateContact";
export const CASE_REQUEST_CHANGE_SERVICE_LOCATION = "caseRequest/changeServiceLocation";
export const CASE_REQUEST_CHANGE_SHORT_DESCRIPTION = "caseRequest/changeShortDescription";
export const CASE_REQUEST_CHANGE_DETAILED_DESCRIPTION = "caseRequest/changeDetailedDescription";
export const CASE_REQUEST_CHANGE_SDM_ATTACHMENTS = "caseRequest/changeSDMAttachments";
export const CASE_REQUEST_CHANGE_CUSTOMER_TICKET_NUMBER = "caseRequest/changeCustomerTicketNumber";
export const CASE_REQUEST_CHANGE_DEVICE = "caseRequest/changeDevice";
export const CASE_REQUEST_NEXT_STEP = "caseRequest/nextStep";
export const CASE_REQUEST_PREVIOUS_STEP = "caseRequest/previousStep";
export const CASE_REQUEST_GOTO_STEP = "caseRequest/gotoStep";
export const CASE_REQUEST_SUBMIT = "caseRequest/submit";
export const CASE_REQUEST_ERROR = "caseRequest/submitError";
export const CASE_REQUEST_SUBMIT_COMPLETE = "caseRequest/submitComplete";
export const CASE_REQUEST_CHANGE_TROUBLESHOOTING_DESCRIPTION = "caseRequest/changeTroubleshootingDescription";
export const CASE_REQUEST_CONTRACT_ISSUE_TYPE = "caseRequest/contractChangeissueType";

export interface ShowCaseRequestParams {
  type: typeof CASE_REQUEST_SHOW_MODAL;
  submittedUserType?: string;
  category: string;
  subCategory?: string;
  orderNumber?: string;
}

export interface ChangeCaseRequestDeviceParams {
  type: typeof CASE_REQUEST_CHANGE_DEVICE;
  deviceId?: string,
  productNumber?: string;
  serialNumber?: string;
  osAndVersion?: string;
}

export interface ChangeCaseRequestSelectedForParams {
  type: typeof CASE_REQUEST_CHANGE_SELECTED_FOR;
  selectedFor: string;
}

export interface CloseCaseRequestParams {
  type: typeof CASE_REQUEST_CLOSE_MODAL;
}

export interface ChangeCaseRequestCountryParams {
  type: typeof CASE_REQUEST_CHANGE_COUNTRY;
  country?: string;
}

export interface ChangeCaseRequestOrderNumberParams {
  type: typeof CASE_REQUEST_CHANGE_ORDER_NUMBER;
  orderNumber?: string;
}

export interface SetCaseRequestContactParams {
  type: typeof CASE_REQUEST_CHANGE_CONTACT;
  contact?: ITSMContact;
}

export interface SetCaseRequestAlternateContactParams {
  type: typeof CASE_REQUEST_CHANGE_ALTERNATE_CONTACT;
  alternateContact?: ITSMContact;
}

export interface SetCaseRequestServiceLocationParams {
  type: typeof CASE_REQUEST_CHANGE_SERVICE_LOCATION;
  serviceLocation?: ITSMLocation;
}

export interface ChangeCaseRequestShortDescriptionParams {
  type: typeof CASE_REQUEST_CHANGE_SHORT_DESCRIPTION;
  shortDescription?: string;
}

export interface ChangeCaseRequestDetailedDescriptionParams {
  type: typeof CASE_REQUEST_CHANGE_DETAILED_DESCRIPTION;
  detailedDescription?: string;
}

export interface ChangeCaseRequestSDMAttachmentsParams {
  type: typeof CASE_REQUEST_CHANGE_SDM_ATTACHMENTS;
  sdmAttachments?: { id: string, [key: string]: string }[];
}

export interface ChangeCaseRequestCustomerTicketNumberParams {
  type: typeof CASE_REQUEST_CHANGE_CUSTOMER_TICKET_NUMBER;
  customerTicketNumber?: string;
}

export interface CaseRequestNextStepParams {
  type: typeof CASE_REQUEST_NEXT_STEP;
}

export interface CaseRequestPreviousStepParams {
  type: typeof CASE_REQUEST_PREVIOUS_STEP;
}

export interface CaseRequestGotoStepParams {
  type: typeof CASE_REQUEST_GOTO_STEP;
  stepNumber: number;
}

export interface CaseRequestSubmitParams {
  type: typeof CASE_REQUEST_SUBMIT;
}

export interface CaseRequestErrorParams {
  type: typeof CASE_REQUEST_ERROR;
  error?: string;
}

export interface CaseRequestSubmitCompleteParams {
  type: typeof CASE_REQUEST_SUBMIT_COMPLETE;
  caseNumber: string;
}

export interface ChangeCaseRequestTroubleshootingDescriptionParams {
  type: typeof CASE_REQUEST_CHANGE_TROUBLESHOOTING_DESCRIPTION;
  troubleshootingDescription?: string;
}

export interface ChangeContractIssueType {
  type: typeof CASE_REQUEST_CONTRACT_ISSUE_TYPE;
  issueType: IssueType;
}

export type CaseRequestTypes =
  | ShowCaseRequestParams
  | ChangeCaseRequestSelectedForParams
  | CloseCaseRequestParams
  | ChangeCaseRequestCountryParams
  | SetCaseRequestContactParams
  | SetCaseRequestAlternateContactParams
  | SetCaseRequestServiceLocationParams
  | ChangeCaseRequestShortDescriptionParams
  | ChangeCaseRequestDetailedDescriptionParams
  | ChangeCaseRequestSDMAttachmentsParams
  | ChangeCaseRequestOrderNumberParams
  | ChangeCaseRequestCustomerTicketNumberParams
  | CaseRequestNextStepParams
  | CaseRequestPreviousStepParams
  | CaseRequestGotoStepParams
  | ChangeCaseRequestDeviceParams
  | CaseRequestSubmitParams
  | CaseRequestErrorParams
  | CaseRequestSubmitCompleteParams
  | ChangeCaseRequestTroubleshootingDescriptionParams
  | ChangeContractIssueType;

export interface ShowCaseRequestOptionalProps {
  subCategory?: string;
  submittedUserType?: string;
  orderNumber?: string;
}

export const showCaseRequest = (category: string, props: ShowCaseRequestOptionalProps = {}): CaseRequestTypes => ({
  type: CASE_REQUEST_SHOW_MODAL,
  category,
  subCategory: props.subCategory,
  submittedUserType: props.submittedUserType,
  orderNumber: props.orderNumber,
});

export const closeCaseRequest = (): CaseRequestTypes => ({ type: CASE_REQUEST_CLOSE_MODAL });

export const changeCaseRequestSelectedFor = (selectedFor: string): CaseRequestTypes => ({
  type: CASE_REQUEST_CHANGE_SELECTED_FOR,
  selectedFor,
});

export const changCaseRequestCountry = (country?: string): CaseRequestTypes => ({
  type: CASE_REQUEST_CHANGE_COUNTRY,
  country,
});

export const changeCaseRequestOrderNumber = (orderNumber?: string): ChangeCaseRequestOrderNumberParams => ({
  type: CASE_REQUEST_CHANGE_ORDER_NUMBER,
  orderNumber,
});

export const setCaseRequestContact = (contact?: ITSMContact): CaseRequestTypes => ({
  type: CASE_REQUEST_CHANGE_CONTACT,
  contact,
});

export const setCaseRequestAlternateContact = (alternateContact?: ITSMContact): CaseRequestTypes => ({
  type: CASE_REQUEST_CHANGE_ALTERNATE_CONTACT,
  alternateContact,
});

export const setCaseRequestServiceLocation = (serviceLocation?: ITSMLocation): CaseRequestTypes => ({
  type: CASE_REQUEST_CHANGE_SERVICE_LOCATION,
  serviceLocation,
});

export const changeCaseRequestShortDescription = (shortDescription?: string): CaseRequestTypes => ({
  type: CASE_REQUEST_CHANGE_SHORT_DESCRIPTION,
  shortDescription,
});

export const changeCaseRequestDetailedDescription = (detailedDescription?: string): CaseRequestTypes => ({
  type: CASE_REQUEST_CHANGE_DETAILED_DESCRIPTION,
  detailedDescription,
});

export const changeCaseRequestSDMAttachments = (sdmAttachments?: { id: string, [key: string]: string }[]): CaseRequestTypes => ({
  type: CASE_REQUEST_CHANGE_SDM_ATTACHMENTS,
  sdmAttachments,
});

export const changeCaseRequestCustomerTicketNumber = (customerTicketNumber?: string): CaseRequestTypes => ({
  type: CASE_REQUEST_CHANGE_CUSTOMER_TICKET_NUMBER,
  customerTicketNumber,
});

export const changeCaseRequestDevice = (
  deviceId?: string,
  serialNumber?: string,
  productNumber?: string,
  osAndVersion?: string
): ChangeCaseRequestDeviceParams => ({
  type: CASE_REQUEST_CHANGE_DEVICE,
  deviceId,
  productNumber,
  serialNumber,
  osAndVersion
});

export const caseRequestNextStep = (): CaseRequestTypes => ({ type: CASE_REQUEST_NEXT_STEP });

export const caseRequestPreviousStep = (): CaseRequestTypes => ({ type: CASE_REQUEST_PREVIOUS_STEP });

export const caseRequestGotoStep = (stepNumber: number = 0): CaseRequestTypes => ({
  type: CASE_REQUEST_GOTO_STEP,
  stepNumber,
});

export const caseRequestSubmit = (): CaseRequestTypes => ({ type: CASE_REQUEST_SUBMIT });

export const caseRequestError = (error?: string): CaseRequestTypes => ({
  type: CASE_REQUEST_ERROR,
  error,
});

export const caseRequestSubmitComplete = (caseNumber: string): CaseRequestTypes => ({
  type: CASE_REQUEST_SUBMIT_COMPLETE,
  caseNumber,
});

export const changeCaseRequestTroubleshootingDescription = (troubleshootingDescription?: string): CaseRequestTypes => ({
  type: CASE_REQUEST_CHANGE_TROUBLESHOOTING_DESCRIPTION,
  troubleshootingDescription,
});

// @deprecated - use sub-category instead
export const changeContractIssueType = (issueType: IssueType): CaseRequestTypes => ({
  type: CASE_REQUEST_CONTRACT_ISSUE_TYPE,
  issueType,
});

