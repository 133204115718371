import React from "react";
import DaasTiles from "components/daas-tiles";
import { useAuth } from "redux/auth-hooks";
import PanelsContainer from "components/layouts/panels-container";
import HomepageHeader from "./components/homepage-header";
import HomepagePanel from "./components/homepage-panel";
import { useTranslation } from "react-i18next";
import ListCardHeader from "../../components/quick-actions/list-card-header";
import { permissions } from "@mssi/pssp-shared";
import ButtonLink from "../../components/button-link";
import useCardInfo from "../../shared/card-info";
import QuickActionButton from "../../components/quick-actions/quick-action-button";
import { useCurrentCustomer, useCurrentCustomerID } from "redux/current-customer-hooks";
import PlaceOrderButton from "pages/orders/place-order-button";
import styled from "styled-components";
import HomepageNoticeBoard from "./components/homepage-noticeboard";
import PageSpinner from "../../components/page-spinner";
import PlaceOrderButtonWarning from "components/place-order-button-warning";
import useToolsEnablersVisibility from "shared/use-tools-enablers-visibility";
import AccountTeam from "./components/account-team";
import useExploreVisibility from "shared/use-explore-visibility";

const Component = styled.div`
  display: flex;
  flex-direction: row;
  @media ${(props) => props.theme.breakpoints.mobileMaximum} {
  flex-direction: column-reverse;
}
`;

const StyledAccount = styled.div`
  margin: 25px 0px;
  .vn-panel__content {
    padding: 0px;
    margin-bottom: 0px;
  }
`;

const StyledNoticeBoard = styled.div`
  .vn-panel__content {
    margin-bottom: 45px;
  }
`;

const SidePanelWrapper = styled.div`
  flex: 2 0 auto;
  width: 40%;
  @media ${(props) => props.theme.breakpoints.mobileMaximum} {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  .vn-panel {
    width: 96%;
    @media ${(props) => props.theme.breakpoints.mobileMaximum} {
      width: 100%;
    }
    margin-bottom: 30px;
  }
`;

const Home: React.FC = () => {
  const { currentUser, authorizedFor } = useAuth();
  const currentCustomerID = useCurrentCustomerID();
  const { customer: currentCustomer, loading } = useCurrentCustomer();
  const { defaultContract } = useCardInfo();
  const { t } = useTranslation();
  const isToolsAndEnablersVisible = useToolsEnablersVisibility();
  const isExploreVisible = useExploreVisibility();
  const isExternal = !(currentUser.persona && currentUser.persona.isInternal);
  const accountData = currentCustomer.accountInfo?.filter((data) => data.highlight);

  if (loading && currentCustomerID) {
    return <PageSpinner />;
  }

  return (
    <PanelsContainer>
      <HomepageHeader />
      <ListCardHeader title={t("home.welcome", { name: currentUser.firstName })}>
        {authorizedFor([permissions.canViewCustomers]) && (
        <QuickActionButton icon="company" text={t("home.managecustomers", { postProcess: "upper" })}>
          <ButtonLink to="/customers" />
        </QuickActionButton>
        )}
        {(authorizedFor(permissions.canEditContracts, permissions.canCreateContracts)) && currentCustomerID && (
          <QuickActionButton icon="review-order" text={t("home.managecontracts", { postProcess: "upper" })}>
            <ButtonLink to={defaultContract ? `/${currentCustomerID}/contracts/${defaultContract}` : `/${currentCustomerID}/contracts`} />
          </QuickActionButton>
        )}
        <PlaceOrderButton />
        {authorizedFor(permissions.canListDevices, permissions.canListMyDevices) && currentCustomerID && (
          <QuickActionButton icon="desktop" text={t("home.devices", { postProcess: "upper" })}>
            <ButtonLink to={`/${currentCustomerID}/devices`} />
          </QuickActionButton>
        )}
        {authorizedFor([permissions.canAccessSupportPage]) && !!currentCustomerID && (
        <QuickActionButton icon="request" text={t("home.myrequests", { postProcess: "upper" })}>
          <ButtonLink to={currentCustomerID ? `/${currentCustomerID}/${"support-tickets/my-requests"}` : "/support-tickets#/my-requests"} />
        </QuickActionButton>
        )}
      </ListCardHeader>
      <PlaceOrderButtonWarning />

      {currentUser.persona && (
      <Component>
        <Wrapper>
          {isToolsAndEnablersVisible && (
          <HomepagePanel
            title={t("toolsandenablers")}
            collapsible={true}
            noticeBoard={!!currentCustomer.id}
          >
            <DaasTiles
              permissions={currentUser.persona.permissions}
              homePage={!!currentCustomer.id}
              isExplore={false}
            />
          </HomepagePanel>
          )}
          {isExploreVisible && (
          <HomepagePanel
            title={t("explore")}
            collapsible={true}
            noticeBoard={!!currentCustomer.id}
          >
            <DaasTiles
              permissions={currentUser.persona.permissions}
              homePage={!!currentCustomer.id}
              isExplore={true}
            />
          </HomepagePanel>
          )}
        </Wrapper>
        <SidePanelWrapper>
          {!!currentCustomer.id &&
          <StyledNoticeBoard>
            <HomepagePanel title={t("noticeboard")} collapsible={true}>
              <HomepageNoticeBoard />
            </HomepagePanel>
          </StyledNoticeBoard>}
          {!!(isExternal && accountData?.length) && (
            <StyledAccount>
              <HomepagePanel title={t("accountTeam")} collapsible={true}>
                <AccountTeam />
              </HomepagePanel>
            </StyledAccount>
          )}
        </SidePanelWrapper>
      </Component>
      )}
    </PanelsContainer>
  );
};

export default Home;
