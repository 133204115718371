export default {
  UAMlink:
    "https://accessmanager.austin.hp.com/uam/ProfileRequest.aspx?ProFlag=False&ProfileID=57852&",
  recaptchaPublicKey: "6Lf938oaAAAAAMaYXcARysCOtl_04m4F3JUapP_Z",
  hpCustomerId: "01022ba3dbe18810242e9eb6db96194d",
  dccLink: "https://dcc-e2e.msit.hpcloud.hp.com/#/",
  editProfileEmployee: " https://directoryworks.hpicorp.net",
  editProfileNonEmployee: "https://myaccount.stg.cd.id.hp.com",
  launchDarklyReactKey: "614952710df9a125b42f266a",
  allowLocalStorageFlagOverrides: false,
  aemLink: "https://hptest.service-now.com/hpaem",
  hpPresenceSupportCase: "https://usstagingms.hpdaas.com/ui/view/support",
  hpProactiveInsightsSupportCase: "https://usstagingms.hpdaas.com/ui/view/support",
  hpProactiveInsightsIncidentDashboards: "https://usstagingms.hpdaas.com/ui/view/incidents",
  hpProactiveInsightsKnowledge: "https://hptest.service-now.com/techpulse_kb",
  hpAdaptiveEndpointManagementSupportCase: "https://hptest.service-now.com/hpaem?id=aem_cat_item&sys_id=5430cec3db0590d0b17124684b9619a8",
  hpAdaptiveEndpointManagementServiceRequest: "https://hptest.service-now.com/hpaem?id=aem_sc_category",
  hpAdaptiveEndpointManagementKnowledge: "https://hptest.service-now.com/hpaem?id=aem_kb_home",
  hpPresenceIncidentDashboards: "https://www.hpdaas.com/ui/view/incidents",
  hpPresenceKnowledge: "https://hp.service-now.com/techpulse_kb",
  supportCase: "https://help.teradici.com/s/contactsupport",
  knowledge: "https://help.teradici.com/s/knowledge",
  serviceRequestLock: "https://usstagingms.hpdaas.com/ui/view/devices/",
  serviceRequestTrace: "https://usstagingms.hpdaas.com/ui/view/devices/",
  serviceRequestCompany: "https://usstagingms.hpdaas.com/ui/view/settings/company-preferences",
  wolfSupportCase: "https://usstagingms.hpdaas.com/ui/view/support",
  jupiterStack: "prod",
  testEmailNote: false,
};
