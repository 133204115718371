import React, { useMemo, useState } from "react";
import { getIn, FieldProps } from "formik";
import { FormField, Select as VeneerSelect } from "@veneer/core";
import { makeRegExp } from "@mssi/pssp-shared";
import { SelectOption } from "shared/map";

interface TextboxProps extends FieldProps {
  name: string;
  label?: string;
  placeholder?: string;
  options: SelectOption[];
  values: string[];
  multiple: boolean;
  returnArrayValue: boolean;
  required: boolean;
  suppressClearButton?: boolean;
  disable?: boolean;
  showInputSearch?: boolean;
  onChange?: (option: SelectOption) => void;
  onClear?: () => void;
  optionsOrientation?: ("up" | "down" | "left" | "right")[];
}

const cleanDataAttribute = (value: string) => value.replace(/[[\].]+/g, "");

const Select: React.FC<TextboxProps> = ({
  label,
  placeholder,
  options,
  values,
  form,
  field,
  multiple,
  returnArrayValue,
  required,
  suppressClearButton,
  disable = false,
  showInputSearch = false,
  onChange,
  onClear,
  optionsOrientation = ["right", "down"]
}) => {
  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);
  const hasError = error && touched;
  const [dropdownSearch, setDropdownSearch] = useState<string>("");

  const dropdownOptions = useMemo(() => {
    if (!options?.length || options === undefined) {
      return [];
    }

    const search = dropdownSearch ? makeRegExp(dropdownSearch) : undefined;

    return options.filter((option) => !search || search.test(option.label));
  }, [options, dropdownSearch]);

  const onSelectionChanged = (option: SelectOption) => {
    if (multiple) {
      const updatedValues = [...values];
      const isOptionSelected = updatedValues.includes(option.value);
      if (isOptionSelected) {
        const index = updatedValues.indexOf(option.value);
        updatedValues.splice(index, 1);
      } else {
        updatedValues.push(option.value);
      }
      form.setFieldValue(field.name, updatedValues);
    } else {
      form.setFieldValue(field.name, returnArrayValue ? [option.value] : option.value);
    }

    if (onChange) {
      onChange(option);
    }
  };

  const handleClear = () => {
    form.setFieldValue(field.name, "");
  };

  return (
    <FormField
      label={label}
      htmlFor={field.name}
      errorMessage={hasError && error}
      required={required}
      disabled={disable}
      data-cy={cleanDataAttribute(field.name)}
    >
      <VeneerSelect
        name={field.name}
        placeholder={placeholder}
        options={dropdownOptions}
        onChange={onSelectionChanged}
        onSearch={(e: string) => setDropdownSearch(e)}
        onBlur={field.onBlur}
        showSearch={showInputSearch}
        value={values}
        onClear={onClear || handleClear}
        error={!!hasError}
        multiple={multiple}
        clearIcon={!suppressClearButton}
        optionsOrientation={optionsOrientation}
      />
    </FormField>
  );
};

export default Select;
