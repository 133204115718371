import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { RootActionTypes } from "./root-action";
import { asyncGetMe } from "./auth-thunks";
import { useTranslation } from "react-i18next";
import { get, tryParseJson } from "@mssi/pssp-shared";
import { useAuth } from "./auth-hooks";
import queryString from "query-string";
import userManager, {
  CognitoProvider,
  logIn,
  logoutCognito,
  processInvite,
  redirectPath,
} from "../api/oidc-user-manager";
import { setAuthLoggingIn, setAuthLoginError, setAuthMe } from "./auth-actions";
import getMe from "../api/me";
import PageSpinner from "../components/page-spinner";
import AuthError from "./auth-error";
import { RegistrationState } from "../types/registration-state";
import { useCurrentCustomerID } from "./current-customer-hooks";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { LDUser } from "launchdarkly-js-client-sdk";

const InitAuth: React.FC = () => {
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();
  const { t } = useTranslation();
  const { currentUser, loggingIn, errorCode } = useAuth();
  const { i18n } = useTranslation();
  const [search, setSearch] = useState(window.location.search);
  const currentCustomerId = useCurrentCustomerID();
  const ldClient = useLDClient();

  const {
    provider,
    code,
    state,
    error: errorCodeParam,
    error_description: errorMessageParam,
  } = useMemo(() => queryString.parse(search), [search]);

  const pushUrl = (search: any, pathname?: string) => {
    const url = new URL(window.location.toString());
    url.search = typeof search === "string" ? search : queryString.stringify(search);

    if (pathname) {
      url.pathname = pathname;
    }

    setSearch(url.search);
    window.history.pushState({}, "", url.toString());
  };

  const currentLanguage = get(currentUser, "preferences.language");

  useEffect(
    () => {
      if (loggingIn) {
        return;
      }

      dispatch(asyncGetMe() as any);
    },
    [dispatch, loggingIn],
  );

  useEffect(
    () => {
      if (currentLanguage !== i18n.language) {
        i18n.changeLanguage(currentLanguage)
          .catch((error) => {
            console.error(error);
          });
      }
    },
    [currentLanguage, i18n],
  );

  useEffect(
    () => {
      if (!state || !errorCodeParam) {
        return;
      }

      pushUrl({});
      dispatch(setAuthLoginError(`${errorCodeParam}`, `${errorMessageParam || errorCodeParam}`));
    },
    [dispatch, state, errorCodeParam, errorMessageParam],
  );

  useEffect(
    () => {
      if (!provider) {
        return;
      }

      logIn(provider as CognitoProvider)
        .catch((error) => {
          console.error(error);
        });
    },
    [provider],
  );

  useEffect(
    () => {
      if (!code) {
        return;
      }

      dispatch(setAuthLoggingIn());

      userManager.signinCallback()
        .then(async (user) => {
          const { path, token } = tryParseJson(user.state) as any;

          if (token) {
            await processInvite(`${token}`, user);
          }

          const me = await getMe()
            .catch((error) => {
              console.error(error);
            });

          pushUrl({}, redirectPath(path));

          if (me) {
            dispatch(setAuthMe(me, true));
          }

          dispatch(setAuthLoggingIn(false));

          if (me?.registrationState !== RegistrationState.APPROVED) {
            const errorMessage = t(`error.loginRegistration.${me?.registrationState ?? RegistrationState.NEW_ACCOUNT}`);
            dispatch(setAuthLoginError("ACCOUNT", errorMessage));
          }
        })
        .catch((error) => {
          if (/no matching/i.test(error.message)) {
            return;
          }

          dispatch(setAuthLoggingIn(false));

          console.error(error);
          let errorMessage = `${error.message || "Error"}`;
          let errorCode = "OIDC";

          const iatMatcher = /iat is in the future:\s*([0-9]+)/;
          const [, iat] = errorMessage.match(iatMatcher) || [];

          if (iat) {
            errorCode = "OIDC-IAT";
            const offset = Math.round((new Date(+iat * 1000).valueOf() - new Date().valueOf()) / 60000);
            errorMessage = t("error.loginIat", { offset, errorMessage });
          }

          dispatch(setAuthLoginError(errorCode, errorMessage));
          pushUrl({}, "/");
        });
    },
    [code, dispatch, t],
  );

  const ldUser = useMemo(
    (): LDUser | undefined => {
      if (!currentUser?.id) {
        return undefined;
      }

      const custom: LDUser["custom"] = {};

      if (currentCustomerId) {
        custom.customerId = currentCustomerId;
      }

      return {
        key: currentUser.id || "",
        anonymous: true,
        custom,
      };
    },
    [currentCustomerId, currentUser?.id],
  );

  useEffect(
    () => {
      if (!ldClient || !ldUser) {
        return;
      }

      ldClient.identify(ldUser)
        .catch((error) => {
          console.log(error);
        });
    },
    [ldClient, ldUser],
  );

  if (errorCode) {
    return <AuthError />;
  }

  if (window.location.pathname === "/logout") {
    logoutCognito()
      .catch((error) => {
        console.error(error);
      });

    return <PageSpinner />;
  }

  if (provider || code) {
    return <PageSpinner />;
  }

  return null;
};

export default InitAuth;
