import Customer from "../types/customer";
import { Me } from "../types/user";
import { OrPermission } from "../shared/has-permission";
import { ITSMContact } from "../types/contact";
import { ITSMLocation } from "../types/location";
import { LocalizationCodeInfo, ShippingInfo, ShoppingCart } from "../types/order";
import { SkuLocalizationCode } from "../shared/sku-localization";
import Site from "types/site";
import CatalogItem from "types/catalog-item";
import ReportModalState from "../components/modals/reports/report-modal-state";
import { ToastMessage } from "./toast-actions";
import Contract from "types/contract";

export interface AuthState {
  loading: boolean;
  loggingIn: boolean;
  currentUser: Me;
  features: AuthFeatures;
  /**
   * @deprecated
   * use useHasPermission from auth-hooks instead
   */
  authorizedFor: (...permissions: OrPermission) => boolean;
  errorCode?: string;
  errorMessage?: string;
  errorInfo?: any;
}

export interface ToastState {
  id: number;
  messages: ToastMessage[];
}

export interface CurrentCustomerState {
  customer: Customer;
  initialized: boolean;
  loading: boolean;
  contracts?: Contract[];
  contractId?: string;
}

export interface AuthFeatures {
  [key: string]: any;
}

export interface IssueType {
  index: number,
  type: string,
}

export interface CaseRequestState {
  submitting: boolean;
  error?: string;
  caseNumber?: string;
  orderNumber?: string;
  category?: string;
  subCategory?: string;
  subType?: string;
  contact?: ITSMContact;
  alternateContact?: ITSMContact;
  serviceLocation?: ITSMLocation;
  newServiceLocation?: ITSMLocation;
  country?: string;
  customerTicketNumber?: string;
  shortDescription?: string;
  detailedDescription?: string;
  attachedFiles?: any[],
  sdmAttachments?: { id: string, [key: string]: string }[],
  deviceId?: string,
  productNumber?: string;
  serialNumber?: string;
  currentStep: number;
  contactLoaded: boolean;
  selectedFor?: string;
  submittedUserType?: string;
  deviceState?: string;
  troubleshootingDescription?: string;
  contactSelectorState: string;
  osAndVersion?: string;
  issueType?: IssueType;
  locationsListForModal?: any;
  contactsList?: ITSMContact[];
}

export interface ShoppingCartState extends ShoppingCart {
  loading: boolean;
  showShoppingCart: boolean;
  localizationCodeSelectionInfo?: LocalizationCodeInfo; // undefined = hide dialog
  defaultLocalizationCode?: string;
  submitting: boolean;
  error?: string;
}

export interface SkuLocalizationInfo {
  item: CatalogItem;
  quantity: number;
}

export interface LocalizationInfo {
  item: CatalogItem;
  quantity: number;
  localization?: string;
}
export interface OrderEndUserState {
  version: number;
  loading: boolean;
  loadNewOrderPage: boolean;
  localizationCodeSelectionInfo?: LocalizationCodeInfo; // undefined = hide dialog
  defaultLocalizationCode: string;
  submitting: boolean;
  submitted: boolean;
  error?: string;
  catalogitems: LocalizationInfo[];
  item: CatalogItem[];
  contractId?: string;
  hasCatalog: boolean;
  searchCatalog?: string;
  country?: string;

  localizationCodes: SkuLocalizationCode[];
  localizationCodeInfo: SkuLocalizationInfo | undefined;
  site?: Site | undefined;
  currentStep: number;

  shippingInfo: ShippingInfo;
  hasAccessory: boolean;
  hasHardware: boolean;
  bigDealNumber?: string;
  hpfsNumber?: string;
}

export interface ReportDownloadState {
  error?: string;
  modalState: ReportModalState;
  internal: boolean;
  customerIds: string[];
  reportFileName?: string;
  reportTitle?: string;
  reportUrl?: string;
  allowMultipleCustomers: boolean;
  // whether the report supports choices
  reportHasCustomerChoice: boolean;
  reportHasInternalExternalChoice: boolean;
  // whether the user has choices
  userHasCustomerChoice: boolean;
  userHasInternalExternalChoice: boolean;
  passwordChoice: boolean;
  password: string;
}

export interface RootState {
  auth: AuthState;
  toast: ToastState;
  currentCustomer: CurrentCustomerState;
  caseRequest: CaseRequestState;
  shoppingCart: ShoppingCartState;
  orderEndUser: OrderEndUserState;
  reportDownload: ReportDownloadState;
}

export const initialAuthState: AuthState = Object.freeze({
  loading: true,
  loggingIn: false,
  // eslint-disable-next-line
  currentUser: {} as Me,
  features: {},
  authorizedFor: () => false,
});

export const initialToastState: ToastState = Object.freeze({
  id: 0,
  messages: [],
});

export const initialCurrentCustomerState: CurrentCustomerState = Object.freeze({
  customer: {
    id: "",
    name: "",
    accountId: "",
    itsmCustomerId: "",
    itsmCustomerName: "",
    preferenceEmailsCountry: [],
    orderDeploymentContacts: [],
  },
  initialized: false,
  loading: true,
});

export const initialCaseRequestState: CaseRequestState = Object.freeze({
  submitting: false,
  currentStep: 0,
  contactLoaded: false,
  detailedDescription: "",
  troubleshootingDescription: "",
  contactSelectorState: "",
  country: "",
  orderNumber: "",
  issueType: {
    index: 6,
    type: "",
  },
  contactsList: []
});

export const initialShoppingCartState: ShoppingCartState = Object.freeze({
  version: 0,

  loading: true,
  showShoppingCart: false,
  submitting: false,
  error: undefined,

  cartId: undefined,
  contractId: undefined,
  country: undefined,
  site: undefined,

  items: [],

  shippingInfo: {
    firstName: "",
    lastName: "",
    priorityRequest: "",
    shipComplete: "Yes",
    primaryPhone: "",
    onSiteDate: "",
    poNumber: "",
    orderReference: "",
    customerOrderNumber: "",
    email: "",
    deliveryInstructions: "",
    contacts: [],
  },
  hasShippingInfo: false,
  bigDealNumber: undefined,
  hpfsNumber: undefined,
});

export const initialOrderEndUserState: OrderEndUserState = Object.freeze({
  version: 0,

  loading: true,
  loadNewOrderPage: false,
  error: undefined,
  submitted: false,
  submitting: false,
  contractId: undefined,
  country: undefined,
  site: undefined,
  hasCatalog: false,
  localizationCodes: [],
  localizationCodeInfo: undefined,
  defaultLocalizationCode: "",
  currentStep: 1,
  catalogitems: [],
  item: [],

  shippingInfo: {
    firstName: "",
    lastName: "",
    priorityRequest: "No",
    shipComplete: "Yes",
    primaryPhone: "",
    onSiteDate: "",
    poNumber: "",
    orderReference: "",
    customerOrderNumber: "",
    email: "",
    deliveryInstructions: "",
    contacts: [],
  },
  hasAccessory: false,
  hasHardware: false,
  bigDealNumber: undefined,
  hpfsNumber: undefined,
});

export const initialReportDownloadState: ReportDownloadState = Object.freeze({
  customerIds: [],
  modalState: ReportModalState.HIDDEN,
  internal: false,
  error: undefined,
  reportType: undefined,
  reportTitle: undefined,
  reportFileName: undefined,
  reportUrl: undefined,
  allowMultipleCustomers: false,
  reportHasInternalExternalChoice: false,
  reportHasCustomerChoice: false,
  userHasInternalExternalChoice: false,
  userHasCustomerChoice: false,
  passwordChoice: false,
  password: "",
});

export const initialRootState: RootState = Object.freeze({
  auth: initialAuthState,
  toast: initialToastState,
  currentCustomer: initialCurrentCustomerState,
  caseRequest: initialCaseRequestState,
  shoppingCart: initialShoppingCartState,
  orderEndUser: initialOrderEndUserState,
  reportDownload: initialReportDownloadState,
});
