import { Column, SearchType, SortDirection, usePage, useSearch, useSort } from "../hooks/table-utils";
import {
  List,
  ListItem,
  Modal,
  Pagination,
  Search,
} from "@veneer/core";

import { AuthCustomer } from "../types/user";
import { CustomerSelection } from "./customerSelection";
import React from "react";
import styled from "styled-components";
import { useAuth } from "redux/auth-hooks";
import { useCurrentCustomer } from "../redux/current-customer-hooks";
import { useTranslation } from "react-i18next";

const CustomerListItem = styled(ListItem)`
  .vn-list-item__content--left,
  .vn-list-item__content--right {
    padding: 4px 12px;
  }
  @media ${(props) => props.theme.breakpoints.mobileMaximum} {
    .vn-list-item__content--left {
      flex: auto;
    }
    .vn-list-item__content--left,
    .vn-list-item__content--right {
      padding: 4px 4px;
      margin-right: 0px;
    }
  }
`;

const HeadingWrapper = styled.h6`
  padding-bottom: 10px;
`;

interface Props {
  show: boolean;
  onClose?: () => void;
  onSelect?: (id: string) => void;
}

const customerName = (customer: AuthCustomer) => (customer.itsmCustomerName ?
  `${customer.itsmCustomerName}` :
  `${customer.name}`);

export const CustomerSelectorModal: React.FC<Props> = ({ show, onClose, onSelect }) => {
  const { currentUser } = useAuth();
  const { t } = useTranslation();

  const authCustomers = (currentUser && currentUser.customers) || [];

  const columns: Column<AuthCustomer>[] = [
    {
      property: customerName,
      label: "Customer Name",
      searchType: SearchType.Text,
    },
  ];

  const {
    result: sortedCustomers,
  } = useSort(authCustomers, columns, { property: customerName, direction: SortDirection.ASC });

  const {
    result: searchedCustomers,
    getSearchValue,
    search,
  } = useSearch(sortedCustomers, columns);

  const {
    result: displayCustomers,
    pageSize,
    currentPage,
    setPageSize,
    setPage,
    totalItems,
  } = usePage(searchedCustomers, 1, 5);

  const { customer } = useCurrentCustomer();

  const currentCustomerName = customer
    ? (customer.itsmCustomerName || customer.name)
    : t("home.welcometitle");

  return (
    <Modal size="lg" title={currentCustomerName} show={show} onClose={onClose ? () => onClose() : () => {}} dismissOnOverlayClick={false}>
      <HeadingWrapper>
        {t("customer.change")}
      </HeadingWrapper>
      <Search
        placeholder="Search"
        aria-label="search"
        delay={300}
        value={getSearchValue()}
        name="searchCustomer"
        onChange={(value: string) => { search(undefined, value); }}
      />
      <List spaceBetweenItems={4} style={{ padding: "6px 0" }}>
        {displayCustomers.map((customer) => (
          <CustomerListItem key={customer.id}>
            <CustomerSelection customer={customer} onSelect={onSelect} onClose={onClose} />
          </CustomerListItem>
        ))}
      </List>
      <Pagination
        currentPage={currentPage}
        pageSize={pageSize}
        pageSizeOptions={[pageSize]}
        onChangePageSize={setPageSize}
        onPaginate={setPage}
        totalItems={totalItems}
      />
    </Modal>
  );
};
