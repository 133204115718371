import { usePermissions } from "redux/auth-hooks";
import { permissions, toolsAndEnablers } from "@mssi/pssp-shared";
import { useCurrentCustomerToolsAndEnablers } from "shared/customer-has-capability";
import { useMemo } from "react";

const tilePermissions = [
  permissions.canAccessDeviceWise,
  permissions.canAccessServicesEstimator,
  permissions.canAccessOpportunityManagementSystem,
  permissions.canAccessServicesSalesPlatform,
  permissions.canAccessSolutionSource,
  permissions.canAccessServiceNow,
  permissions.canAccessServicesCapabilityInventoryTool,
  permissions.canAccessHPInstantInsightsTile,
  permissions.canAccessHPWolfProtectAndTrace,
  permissions.canAccessDeviceRecoveryServiceTile
];

const useToolsEnablersVisibility = (): boolean => {
  const userPermissions = usePermissions();

  const permissionsIndex = useMemo(
    () => userPermissions.reduce((obj, str) => {
      obj[str] = true;
      return obj;
    }, {} as Record<string, boolean>),
    [userPermissions],
  );

  const canAccessBasicTiles = tilePermissions.some(({ id }) => permissionsIndex[id]);

  const canCurrentCustomerAccessHPTechPulse = useCurrentCustomerToolsAndEnablers(toolsAndEnablers.canAccessTechPulseTile);
  const canCurrentCustomerAccessVMO = useCurrentCustomerToolsAndEnablers(toolsAndEnablers.canAccessVMOTile);
  const canCurrentCustomerAccessHP2B = useCurrentCustomerToolsAndEnablers(toolsAndEnablers.canAccessHP2BTile);
  const canCurrentCustomerAccessAEM = useCurrentCustomerToolsAndEnablers(toolsAndEnablers.canAccessAEMTile);
  const canCurrentCustomerAccessDCC = useCurrentCustomerToolsAndEnablers(toolsAndEnablers.canAccessDCCTile);
  const canCurrentCustomerAccessWPT = useCurrentCustomerToolsAndEnablers(toolsAndEnablers.canAccessHPWPTTile);
  const canCurrentCustomerAccessHPPresenceInsights = useCurrentCustomerToolsAndEnablers(toolsAndEnablers.canAccessHPPITile);
  const canCurrentCustomerAccessHPPIEM = useCurrentCustomerToolsAndEnablers(toolsAndEnablers.canAccessHPPIEMTile);
  const canCurrentCustomerAccessHPInstantInsights = useCurrentCustomerToolsAndEnablers(toolsAndEnablers.canAccessHPInstantInsightsTile);
  const canCurrentCustomerAccessDRS = useCurrentCustomerToolsAndEnablers(toolsAndEnablers.canAccessDeviceRecoveryServiceTile);
  const canCurrentCustomerAccessServicesCapabilityInventoryTool = useCurrentCustomerToolsAndEnablers(toolsAndEnablers.canAccessServicesCapabilityInventoryTool);
  const canCurrentCustomerAccessMyHPSupport = useCurrentCustomerToolsAndEnablers(toolsAndEnablers.canAccessMyHPSupport);

  return (
    canAccessBasicTiles
    || (permissionsIndex[permissions.canAccessHPTechpulse.id] && canCurrentCustomerAccessHPTechPulse)
    || (permissionsIndex[permissions.canAccessValueManagementOffice.id] && canCurrentCustomerAccessVMO)
    || (permissionsIndex[permissions.canAccessHP2B.id] && canCurrentCustomerAccessHP2B)
    || (permissionsIndex[permissions.canAccessAEM.id] && canCurrentCustomerAccessAEM)
    || (permissionsIndex[permissions.canAccessDeviceControlCenter.id] && canCurrentCustomerAccessDCC)
    || (permissionsIndex[permissions.canAccessHPWolfProtectAndTrace.id] && canCurrentCustomerAccessWPT)
    || (permissionsIndex[permissions.canAccessHPPresenceInsights.id] && canCurrentCustomerAccessHPPresenceInsights)
    || (permissionsIndex[permissions.canAccessHPProactiveInsightsExperienceManagement.id] && canCurrentCustomerAccessHPPIEM)
    || (permissionsIndex[permissions.canAccessHPInstantInsightsTile.id] && canCurrentCustomerAccessHPInstantInsights)
    || (permissionsIndex[permissions.canAccessDeviceRecoveryServiceTile.id] && canCurrentCustomerAccessDRS)
    || (permissionsIndex[permissions.canAccessServicesCapabilityInventoryTool.id] && canCurrentCustomerAccessServicesCapabilityInventoryTool)
    || (permissionsIndex[permissions.canAccessMyHPSupport.id] && canCurrentCustomerAccessMyHPSupport)
  );
};

export default useToolsEnablersVisibility;
