import { ITSMCaseRequest } from "types/support";
import { postJson } from "./request";
import { getFetchErrorTransform } from "types/validation-error";
import { TFunction } from "i18next";

export interface CaseCreateResult {
  caseNumber: string;
  coolDown: Date;
}

const errorDetailsMapping: Record<string, string> = {
  CUSTOMER_TICKET_ERROR: "caserequest.submit.customerticket.error",
  DUPLICATE_TICKET_ERROR: "case.error.duplicateTicket",
  CBA_MIGRATION_FREEZE: "case.error.freeze",
  DEFAULT: "caserequest.submit.error",
};

const getError = (error: any, t: TFunction) => {
  if (error) {
    return t(errorDetailsMapping[error.details] || errorDetailsMapping.DEFAULT);
  }

  throw getFetchErrorTransform({ default: `${t("caserequest.submit.error")}` })(error);
};

export const createCase = (caseRequestInfo: ITSMCaseRequest, t: TFunction): Promise<CaseCreateResult> =>
  postJson(`/caserequest`, caseRequestInfo)
    .then((result) => {
      result.coolDown = new Date(result.coolDown);
      return result;
    })
    .catch((error: any) => {
      throw getError(error, t);
    });
