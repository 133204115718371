import React from "react";
import Button from "components/button";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px auto;
`;

interface FormActionsProps {
  isSubmitting?: boolean;
  submitButtonText?: string;
  cancelButtonText?: string;
  onCancel?(event: React.MouseEvent): void;
  onSubmit?(event: React.MouseEvent): void;
  childrenPosition?: "left" | "right";
  cancelButton?: boolean;
  Styled?: any;
  disabled?: boolean;
  onBeforeSubmit?(): void;
  dataCyPrefix?: string;
}

const FormActions: React.FC<FormActionsProps> = ({
  isSubmitting,
  submitButtonText = undefined, // default in UI
  cancelButtonText = undefined,
  onCancel,
  onSubmit,
  children,
  childrenPosition = "left",
  cancelButton = true,
  Styled = Wrapper,
  disabled = false,
  onBeforeSubmit,
  dataCyPrefix = "",
}) => {
  const { t } = useTranslation();
  const onCancelClick = (event: React.MouseEvent) => {
    if (onCancel) {
      event.preventDefault();
      onCancel(event);
    }
  };

  const onSubmitClick = (event: React.MouseEvent) => {
    if (onBeforeSubmit) {
      onBeforeSubmit();
    }
    if (onSubmit) {
      event.preventDefault();
      onSubmit(event);
    }
  };

  return (
    <Styled className="wrapper">
      {childrenPosition === "left" ? children : null}
      <Button name="submit" type="submit" loading={isSubmitting} onClick={onSubmitClick} disabled={disabled} data-cy={`${dataCyPrefix}submit`}>
        {submitButtonText || t("btn.submit")}
      </Button>
      &nbsp;&nbsp;&nbsp;
      {cancelButton && (
        <Button name="reset" type="reset" appearance="secondary" disabled={isSubmitting} onClick={onCancelClick} data-cy={`${dataCyPrefix}reset`}>
          {cancelButtonText || t("btn.cancel")}
        </Button>
      )}
      {childrenPosition === "right" ? children : null}
    </Styled>
  );
};

export default FormActions;
