import { useEffect, RefObject } from "react";

export type CustomEventCallback = (event: CustomEvent) => void;

/**
 *
 * @param {RefObject} ref - element ref for event listener
 * @param {string} type - event name
 * @param {function} callback - recommend use of useCallback if you expect frequent re-renders
 */
const useCustomEvent = (ref: RefObject<HTMLElement>, type: string, callback: CustomEventCallback) => {
  useEffect(
    () => {
      const element = ref.current;

      if (!element) {
        return () => undefined;
      }

      const eventListener = (event: Event) => {
        if (event instanceof CustomEvent) {
          callback(event);
        }
      };

      element.addEventListener(type, eventListener);

      return () => {
        try {
          element.removeEventListener(type, eventListener);
        } catch {
          // do nothing
        }
      };
    },
    [ref, type, callback],
  );
};

export default useCustomEvent;
