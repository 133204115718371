import { explorePermissions } from "@mssi/pssp-shared";
import { useCurrentCustomerExplore } from "./customer-has-capability";

const useExploreVisibilty = (): boolean => {
  const canCurrentCustomerAccessAEM = useCurrentCustomerExplore(explorePermissions.canAccessHPAEM);
  const canCurrentCustomerAccessWPT = useCurrentCustomerExplore(explorePermissions.canAccessHPWS);
  const canCurrentCustomerAccessHPProactiveInsights = useCurrentCustomerExplore(explorePermissions.canAccessHPPI);
  const canCurrentCustomerAccessLearnMore = useCurrentCustomerExplore(explorePermissions.canAccessLearnMore);
  const canCurrentCustomerAccessHPPS = useCurrentCustomerExplore(explorePermissions.canAccessHPPS);
  const canCurrentCustomerAccessHPPCS = useCurrentCustomerExplore(explorePermissions.canAccessHPPCS);
  const canCurrentCustomerAccessHPDAAS = useCurrentCustomerExplore(explorePermissions.canAccessHPDAAS);
  const canCurrentCustomerAccessHPBB = useCurrentCustomerExplore(explorePermissions.canAccessHPBB);
  const canCurrentCustomerAccessSMS = useCurrentCustomerExplore(explorePermissions.canAccessHPSMS);
  const canCurrentCustomerAccessHPAnyware = useCurrentCustomerExplore(explorePermissions.canAccessHPAnyware);

  return (
  (canCurrentCustomerAccessAEM)
    || (canCurrentCustomerAccessWPT)
    || (canCurrentCustomerAccessHPProactiveInsights)
    || (canCurrentCustomerAccessLearnMore)
    || (canCurrentCustomerAccessHPPS)
    || (canCurrentCustomerAccessHPPCS)
    || (canCurrentCustomerAccessHPDAAS)
    || (canCurrentCustomerAccessHPBB)
    || (canCurrentCustomerAccessSMS)
    || (canCurrentCustomerAccessHPAnyware)
  );
};

export default useExploreVisibilty;
