import React from "react";
import { Modal, LabeledIcon } from "@veneer/core";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Button from "components/button";

const Header = styled.div`
  display: flex;
  justify-content: center;
  font-size: 24px;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const ButtonWrapper = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-evenly;
`;

const WarningWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const ErrorWarpper = styled.div`
    display: flex;
    justify-content: space-evenly;
    color: ${props => props.theme.colors.error};
`;

export interface Props {
    show: boolean,
    onClose(): void;
    modalSize?: string;
    header?: string;
    title?: string;
    iconName?: string;
    iconColorType?: string;
    iconSize?: string;
    message?: string;
    messageColorType?: string;
    onConfirm(): void;
    loading?: boolean;
    errorMessage?: string;
    showError?: boolean;
    confirmButtonText?: string;
    dataCy?: string;
    subTitle?: string;
}


const DeleteConfirmationModal: React.FC<Props> = ({
    onClose,
    show,
    modalSize = "sm",
    header,
    title,
    iconName,
    iconColorType = "error",
    iconSize = 24,
    message,
    messageColorType = "error",
    onConfirm,
    loading,
    errorMessage,
    showError,
    confirmButtonText = "",
    dataCy = "deleteButton",
    subTitle
}) => {
 const { t } = useTranslation();
  return (
    <Modal
      id="regular-modal"
      onClose={() => onClose()}
      show={show}
      dismissOnOverlayClick={false}
      size={modalSize}
    >
      {header && <Header>{header}</Header>}
      {title && <Title>{title}</Title>}
      {subTitle && <Title>{subTitle}</Title>}
      <WarningWrapper>
        <LabeledIcon name={iconName} size={iconSize} label={message} color={iconColorType} labelColor={messageColorType} />
      </WarningWrapper>
      <ButtonWrapper>
        <Button data-cy={dataCy} onClick={onConfirm} loading={loading}>
          { confirmButtonText ? confirmButtonText : t("delete")}
        </Button>
        <Button data-cy="closeButton" appearance="secondary" disabled={loading} onClick={() => onClose()}>
          {t("cancel")}
        </Button>
      </ButtonWrapper>
      {showError && errorMessage && <ErrorWarpper>{errorMessage}</ErrorWarpper>}
    </Modal>
  );
};

export default DeleteConfirmationModal;
