import React, { Dispatch, useState } from "react";
import { IconButton, TextBox } from "@veneer/core";
import CatalogIcon from "components/catalog-icon";
import styled from "styled-components";
import CatalogImage from "../../../components/catalog-image";
import { CartItem } from "../../../types/order";
import { skuLocalizationCodeMap } from "../../../shared/sku-localization";
import { useDispatch } from "react-redux";
import { RootActionTypes } from "redux/root-action";
import { useTranslation } from "react-i18next";
import { asyncUpdateCart } from "../../../redux/shopping-cart-thunks";
import { calculateTotalPerMonth, calculateTotalPerTerm } from "./shopping-cart-prices";
import { permissions } from "@mssi/pssp-shared";
import { useAuth } from "../../../redux/auth-hooks";

interface ShoppingCartItemProps {
  orderItem: CartItem;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 6px 6px 6px;
`;

const ItemInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 100%;
  width: 100%;
`;

const Width18 = styled.div`
  min-width: 18%;
  width: 18%;
`;

const Width22 = styled.div`
  min-width: 22%;
  width: 22%;
  padding-left: 5%;
`;

const Width10 = styled.div`
  min-width: 10%;
  width: 10%;
  margin: 5px;
`;

const ItemInfo = styled.div`
  margin-left: 25px;
  min-width: 45%;
  width: 45%;
  margin-right: 3px;
`;

const Price = styled.span`
  font-size: 12px;
`;

const StyledTextBox = styled(TextBox)`
  max-width: 70px;
  margin-left: 10px;
  text-align: center;
`;
const ItemName = styled.div`
  font-size: 13px;
`;
const CartValue = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 2%;
`;
const PerMonth = styled.div`
  font-size: 10px;
`;
const Border = styled.div`
  border: ${props => `1px solid ${props.theme.colors.border}`};
  margin-right: 5px;
  margin-left: 15px;
`;
const PerTerm = styled.div`
  font-size: 10px;
`;
const ShoppingCartItem: React.FC<ShoppingCartItemProps> = ({
  orderItem,
}) => {
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();
  const { t } = useTranslation();
  const { authorizedFor } = useAuth();
  const totalPricePerMonth = calculateTotalPerMonth([orderItem]);
  const totalPricePerTerm = calculateTotalPerTerm([orderItem]);
  const canViewBundlePricing = authorizedFor([permissions.canViewBundlePricing]);

  const [qty, setQty] = useState<string>(`${orderItem.quantity || 0}`);

  return (
    <Wrapper>
      <ItemInfoWrapper>
        <Width18>
          {orderItem.mainHardwareImageUrl ? (
            <CatalogImage url={orderItem.mainHardwareImageUrl} size="100%" />
          ) : (
            <CatalogIcon name={orderItem.mainHardwareCategory} size={48} />
          )}
        </Width18>

        <ItemInfo>
          <ItemName>
            {orderItem.bundleName}
            {skuLocalizationCodeMap[orderItem.localizationCode] && (
              <>
                <br />
                {skuLocalizationCodeMap[orderItem.localizationCode]}
              </>
            )}
          </ItemName>
          {(canViewBundlePricing) && (
            <CartValue>
              <PerMonth>
                <div>{t("shoppingcart.totalpermonth")}</div>
                <Price>{totalPricePerMonth}</Price>
              </PerMonth>
              <Border />
              <PerTerm>
                <div>{t("shoppingcart.totalperterm")}</div>
                <Price>
                  {totalPricePerTerm}
                </Price>
              </PerTerm>
            </CartValue>
          )}
        </ItemInfo>

        <Width22>
          <StyledTextBox
            type="number"
            name="qty"
            min={1}
            max={99999}
            value={qty}
            onChange={(quantity: string) => {
              setQty(quantity);
            }}
            onBlur={() => {
              let quantityParsed = parseInt(qty, 10);

              if (Number.isNaN(quantityParsed)) {
                quantityParsed = 0;
              }

              dispatch(asyncUpdateCart(orderItem.bundleId, orderItem.localizationCode, quantityParsed, orderItem.solutionId) as any);
            }}
          />
        </Width22>

        <Width10>
          <IconButton
            onClick={() => {
              setQty("0");
              dispatch(asyncUpdateCart(orderItem.bundleId, orderItem.localizationCode, 0, orderItem.solutionId) as any);
            }}
            icon="close"
            className="vn-color--red"
          />
        </Width10>
      </ItemInfoWrapper>
    </Wrapper>
  );
};

export default ShoppingCartItem;
