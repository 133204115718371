import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "@veneer/core";
import styled from "styled-components";
import NoticeBoard from "types/notice-board";
import { getMessages } from "api/customer-notice-board";
import { permissions } from "@mssi/pssp-shared";
import { useAuth, useFeature } from "redux/auth-hooks";
import { useCurrentCustomerID } from "redux/current-customer-hooks";
import log from "shared/log";
import MessageTile from "./message-tile";
import NoticeBoardModalSDM from "./notice-board-modal-sdm";
import Button from "components/button";

const BorderStyle = styled.div`
  border: 1px solid ${(props) => props.theme.colors.background};
  margin-top: 10px;
  padding: 15px 5px 15px 15px;
`;

const ButtonWrapper = styled.div`
  .vn-button {
    width: 100%;
    text-transform: capitalize;
    margin-top: 10px;
  }
`;

const Scroll = styled.div`
  margin-bottom: -45px;
`;

const NoMessageAvailable = styled.div`
  padding-top: 5%;
  margin-bottom: -45px;
  text-align: center;
`;

const HomepageNoticeBoard: React.FC = () => {
  const { t } = useTranslation();
  const [showNoticeBoardModal, setShowNoticeBoardModal] = useState<boolean>(false);
  const [messages, setMessages] = useState<NoticeBoard[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [refresher, setRefresher] = useState<boolean>(false);
  const customerId = useCurrentCustomerID();
  const { authorizedFor } = useAuth();
  const canManageNoticeBoard = authorizedFor([permissions.canManageNoticeBoard]);
  const canCreateNewEvent = useFeature<boolean>("canCreateNewEvent");
  const [isEvent, setIsEvent] = useState<boolean>(false);

  // Load Message list
  useEffect(() => {
    if (customerId) {
      setLoading(true);
      getMessages(customerId)
        .then((messages: NoticeBoard[]) => {
          setMessages(messages);
          setLoading(false);
        })
        .catch((error) => {
          log.error(error);
          setMessages([]);
          setLoading(false);
        });
    }
  }, [customerId, refresher]);

  return (
    <>
      {(loading) && (canManageNoticeBoard ? <div className="customSpinnerParent topPosition"><Spinner id="mySpinner" /> </div> :
      <div className="customSpinnerParent">
        <Spinner id="mySpinner" />
      </div>)}
      {canManageNoticeBoard && (
        <>
          <p>{t("noticeboard.info")}</p>
          <ButtonWrapper>
            {canCreateNewEvent &&
            <Button onClick={() => { setShowNoticeBoardModal(true); setIsEvent(true); }}>{t("new.event", { postProcess: "upper" })}</Button>}
            <Button onClick={() => { setShowNoticeBoardModal(true); setIsEvent(false); }}>{t("new.message", { postProcess: "upper" })}</Button>
          </ButtonWrapper>
        </>
      )}
      {!messages.length && !loading && <NoMessageAvailable>{t("nomessage")}</NoMessageAvailable>}
      {messages.length > 0 && (
        <Scroll>
          {messages.map((message: NoticeBoard) => {
            return (
              <BorderStyle key={message._id}>
                <MessageTile message={message} setRefresher={setRefresher} />
              </BorderStyle>
            );
          })}
        </Scroll>
      )}
      <NoticeBoardModalSDM
        onClose={() => { setShowNoticeBoardModal(false); setIsEvent(false); }}
        show={showNoticeBoardModal}
        setRefresher={setRefresher}
        event={isEvent}
      />
    </>
  );
};

export default HomepageNoticeBoard;
