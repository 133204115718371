import React, { Suspense } from "react";

import { NavItem } from "types/nav-item";
import Page from "./layouts/page";
import PageSpinner from "./page-spinner";

export default (routeInfo: NavItem): React.FC<any> => {
  const Component = routeInfo.component;

  const LayoutPage: React.FC = (props: any) => {
    return (
      <Page title={routeInfo.label}>
        <Suspense fallback={<PageSpinner />}>
          {Component && <Component {...props} />}
        </Suspense>
      </Page>
    );
  };

  return LayoutPage;
};
