import React, { useMemo, useState } from "react";
import { Modal } from "@veneer/core";
import * as Yup from "yup";
import { Form, Formik, Field, FormikHelpers as FormikActions } from "formik";
import PageSection from "components/layouts/page-section";
import styled from "styled-components";
import FormActions from "components/forms/form-actions";
import Select from "components/forms/select";
import TextArea from "components/forms/text-area";
import TextBox from "components/forms/text-box";
import { createUserFeedback } from "api/user-feedback";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import Button from "components/button";

interface Props {
  onClose(): void;
  show: boolean;
}

interface AlertProps {
  children: React.ReactNode;
}

const StyledPageSection = styled(PageSection)<AlertProps>`
  margin: 30px 0px 20px 0px;
`;

const getValidationSchema = (t: TFunction) => Yup.object().shape({
  category: Yup.string().required(t("category.required")),
  summary: Yup.string().required(t("summary.required")).matches(/^[^+|\-|@|=].*/, t("formErrors.csv.injection")),
  description: Yup.string()
    .max(500, t("contractchange.categorylengthvalidation"))
    .required(t("feedback.required"))
    .matches(/^[^+|\-|@|=].*/, t("formErrors.csv.injection")),
});

const initialValues = {
  category: "",
  summary: "",
  description: "",
};

const UserFeedback: React.FC<Props> = ({ onClose, show }) => {
  const [successState, setSuccessState] = useState<boolean>();
  const { t } = useTranslation();

  const categories = [
    {
      value: 1,
      label: t("generalfeedback"),
    },
    {
      value: 3,
      label: t("featurerequest"),
    },
    {
      value: 4,
      label: t("caserequest.type.portal.other"),
    },
  ];

  const onSubmit = (values: any, actions: FormikActions<any>) => {
    const categoryObj = categories.find(x => x.value === values.category);
    const category = categoryObj && categoryObj.label;
    if (category) {
      const feedbackValues = {
        category,
        summary: values.summary,
        description: values.description,
      };
      createUserFeedback(feedbackValues)
        .then(() => {
          actions.setSubmitting(false);
          setSuccessState(true);
        })
        .catch(() => {
          actions.setSubmitting(false);
        });
    }
  };

  const closeSuccessModal = () => {
    setSuccessState(false);
    onClose();
  };

  const validationSchema = useMemo(() => getValidationSchema(t), [t]);

  if (successState) {
    return (
      <Modal size="sm" title={t("thankyou!")} show={show} onClose={closeSuccessModal} dismissOnOverlayClick={false}>
        <StyledPageSection>
          {t("feedback.modal.message")}
        </StyledPageSection>
        <Button onClick={closeSuccessModal}>Close</Button>
      </Modal>
    );
  }

  return (
    <Modal size="sm" title={t("shareyourfeedback")} show={show} onClose={onClose} dismissOnOverlayClick={false}>
      <StyledPageSection>{t("feedback.message")}</StyledPageSection>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <Field
              name="category"
              label={t("feedbackform.select")}
              placeholder={t("select")}
              values={[values.category]}
              options={categories}
              component={Select}
              showInputSearch={true}
              required
            />
            <Field
              label={t("summary")}
              name="summary"
              placeholder={t("summary")}
              value={values.summary}
              component={TextBox}
              required
            />
            <Field
              label={t("description")}
              name="description"
              placeholder={t("feedbackform.message")}
              value={values.description}
              component={TextArea}
              required
            />
            <FormActions isSubmitting={isSubmitting} disabled={!values.summary || !values.description || !values.category} onCancel={() => onClose()} />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default UserFeedback;
