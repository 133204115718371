import Site from "./site";
import { PersonaRoleInfoOnly } from "./persona";
import User from "./user";
import CatalogItem from "./catalog-item";
import { BackendTableBaseParams } from "./table-backend-types";
import { S3Document } from "./document";

type OrderId = string;

export enum OrderStatus {
  SUBMITTED = "SUBMITTED",
  AWAITING_APPROVAL = "AWAITING APPROVAL",
  APPROVED = "APPROVED",
  AWAITING_CANCELLATION = "AWAITING CANCELLATION",
  REJECTED = "REJECTED",
  ACCEPTED = "ACCEPTED",
  SHIPPED = "SHIPPED",
  DELIVERED = "DELIVERED",
  CANCELLED = "CANCELLED",
}

export const orderStatusMap = {
  [OrderStatus.SUBMITTED]: "Entered",
  [OrderStatus.AWAITING_APPROVAL]: "Awaiting Approval",
  [OrderStatus.APPROVED]: "Approved",
  [OrderStatus.AWAITING_CANCELLATION]: "Awaiting Cancellation",
  [OrderStatus.REJECTED]: "Rejected",
  [OrderStatus.ACCEPTED]: "In Progress",
  [OrderStatus.SHIPPED]: "Shipped",
  [OrderStatus.DELIVERED]: "Delivered",
  [OrderStatus.CANCELLED]: "Cancelled",
};

export interface OrderStatusHistory {
  rejectionReason?: string;
  cancellationReason?: string;
  status: OrderStatus;
  statusDateTime: string;
  estimatedDeliveryDate: string;
  user: Pick<User, "id" | "firstName" | "lastName" | "email">;
  userRoleInfo: PersonaRoleInfoOnly;
}

export interface CatalogOrderItem {
  bundleId: any;
  bundleName: string;
  bundleCurrency?: string;
  bundleCurrencyOrdered?: string;
  bundlePricePerMonth: number;
  bundlePricePerMonthOrdered?: number;
  bundleTermLength: number;
  mainHardwareSKU: string;
  mainHardwareCategory: string;
  mainHardwareImageUrl: string;
  bundleBigDealPrice: number;
  bundleBigDealPriceOrdered?: number;
  skuLocalizationCode: string;
  quantityAvailable?: any;
  quantityFromContract?: any;
  quantityConsumed?: any;
  accessories: [
    {
      sku: string;
      name: string;
      category: string;
      description: string;
      imageUrl: string;
      bigDealPrice: string;
      bigDealPriceOrdered?: string;
    },
  ];
  displays: [
    {
      displayId: string;
      sku: string;
      name: string;
      category: string;
      nativeResolution: string;
      contractRatio: string;
      signalInputConnectors: string;
      imageUrl: string;
      bigDealPrice: string;
      bigDealPriceOrdered?: string;
    },
  ];
  services: [
    {
      parentId: any;
      parentType: string;
      sku: string;
      serviceOffer: string;
      description1: string;
      description2: string;
      description3: string;
      description4: string;
    },
  ];
}

export interface OrderItem {
  item: CatalogOrderItem;
  quantity: number;
}

export interface CartItemBase {
  bundleId: string;
  solutionId: string;
  localizationCode: string;
  quantity: number;
  quantityAvailable?: any;
  quantityFromContract?: any;
  quantityConsumed?: any;
}

export interface CartItem extends CartItemBase {
  mainHardwareSKU: string;
  mainHardwareCategory: string;
  mainHardwareImageUrl: string;
  bundleName: string;
  bundlePricePerMonth: number;
  bundlePricePerMonthOrdered?: number;
  bundleTermLength: number;
  bundleCurrency?: string;
  bundleCurrencyOrdered?: string;
}

export interface ShoppingCart {
  version: number;
  cartId?: string; // becomes Order ID
  customerId?: string;
  contractId?: string;
  country?: string;
  site?: Site;
  items: CartItem[];
  shippingInfo: ShippingInfo;
  bigDealNumber?: string;
  hpfsNumber?: string;
  homeAddress?: any;
  documents?: S3Document[];
}

export interface LocalizationCodeInfo {
  item: CatalogItem;
  quantity: number;
}

export interface ShippingInfo {
  firstName: string;
  lastName: string;
  email: string;
  /** @deprecated */
  additionalEmail?: string;
   /** @deprecated */
  getEmail?: boolean;
  /** @deprecated */
  getAdditionalEmail?: boolean;
  primaryPhone: string;
  deliveryInstructions?: string;
  poNumber?: string;
  orderReference?: string;
  customerOrderNumber?: string;
  priorityRequest: string;
  shipComplete: string;
  onSiteDate: string;
  contacts?: OrderContact[];
  portalCustomerOrderNumber?: string;
}

export interface OrderContact {
  email: string;
  notify: boolean;
}

export interface OrderAdditionalContacts {
  contacts: OrderContact[];
  additionalEmail: string;
  email: string;
}

export interface SkuLocalizationInfo {
  item: CatalogItem;
  quantity: number;
}

export interface HomeAddress {
  homeBuilding?: string;
  homeCity?: string;
  homeState?: string;
  homeZip?: string;
  homeCountry?: string;
}

export default interface Order {
  id: OrderId;
  customerId: string;
  contractId: string;
  customerContractId: string;
  orderNumber: string;
  hpOrderNumber: string;
  singleOrderNumber: string;
  orderStatus: OrderStatusHistory;
  orderStatusHistory: OrderStatusHistory[];
  site?: Site;
  shipTo?: ShippingInfo;
  items?: OrderItem[];
  catalogItems?: SkuLocalizationInfo[];
  documents?: S3Document[];
  customerName?: string;
  contractName?: string;
  shipmentGroups?: ShipmentGroup[];
  itsmOrderItems?: ITSMOrderItem[];
  additionalInfo: {
    bigDealNumber?: string;
    hpfsNumber?: string;
  };
  isITSMOrder: boolean;
  homeAddress?: HomeAddress;
  contacts?: OrderContact[];
  submittedDate?: string;
  orderOriginationDate?: string;
  shipDate?: string;
  estimatedShipDate?: string;
  deliveryDate?: string;
  estimatedDeliveryDate?: string;
}

export interface OrderListItem {
  id: string;
  customerId: string;
  orderNumber?: string;
  hpOrderNumber?: string;
  siteText: string;
  singleOrderNumber?: string;
  site: Site;
  siteName: string;
  shipTo?: ShippingInfo;
  shipToText?: string;
  orderReference?: string;
  shipToContact?: string;
  quantity: string;
  submittedDate: string;
  estimatedDeliveryDate: string;
  deliveryDate: string;
  status: string;
  homeAddress?: HomeAddress;
  orderStatus?: OrderStatusHistory;
}

export interface OrderListRequest extends BackendTableBaseParams {
  customerId?: string;
  pageNumber: number;
  pageLength: number;
  orderNumber?: string;
  hpOrderNumber?: string;
}

export interface OrderCSV {
  csv: string;
  xlsx: string;
}

export interface ShipmentGroup {
  groupId: string;
  status: OrderStatus;
  trackingNumber: string;
  trackAndTraceLink: string;
  plannedShipDate: string;
  shipDate: string;
  plannedDeliveryDate: string;
  deliveryDate: string;
  products: ShipmentGroupProduct[];
}

export interface ITSMOrderItem {
  correlationId: string; // lines up with ShipmentGroupProduct.correlationId
  solutionId: string; // in DSID format - may match some items[].item.bundleId but may not be 1:1
  localizationCode: string;
  quantity: number;
}

export interface ShipmentGroupProduct {
  quantity: string;
  lineItemNumber: string;
  correlationId: string; // lines up with ITSMOrderItem.correlationId
  productNumber: string;
  productDescription: string;
  serialNumbers: string[];
  serialNumberList: ShipmentGroupProductDetail[];
}

export interface ShipmentGroupProductDetail {
  serialNumber: string;
  deviceId?: string;
}


export interface OrderStatusType {
  status: string;
  statusDateAndTime: string;
}

export type OrderCreateInfo = Pick<
  Order,
  | "customerId"
  | "contractId"
  | "customerContractId"
  | "site"
  | "shipTo"
  | "catalogItems"
  | "additionalInfo"
>;
