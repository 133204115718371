import { useEffect, useState, useCallback } from "react";

import { getCustomerById } from "api/customer";
import Customer from "types/customer";
import log from "shared/log";
import { FetchError } from "types/fetch-error";

interface UseCustomer {
  error?: FetchError;
  loading: boolean;
  customer?: Customer;
  getOnlySites?: boolean;
  refreshCustomer(customer?: Customer, quiet?: boolean): void;
  patchCustomer(values: Partial<Customer>): void;
}

const useCustomer = (customerId: string, getOnlySites?: boolean): UseCustomer => {
  const [loading, setLoading] = useState(!!customerId);
  const [error, setError] = useState();
  const [customer, setCustomer] = useState<Customer | undefined>();

  const refreshCustomer = useCallback(
    (customer?: Customer, quiet: boolean = false) => {
      if (customer) {
        setCustomer(customer);
        return customer;
      }

      if (customerId && !getOnlySites) {
        if (!quiet) {
          setLoading(true);
        }

        return getCustomerById(customerId)
          .then(customer => {
            setCustomer(customer);
            setError(undefined);
            setLoading(false);
          })
          .catch(error => {
            log.error(error);
            setError(error);
            setLoading(false);
          });
      }
    },
    [customerId, getOnlySites],
  );

  const patchCustomer = useCallback(
    (values: Partial<Customer>) => {
      if (!customer) {
        return;
      }

      setCustomer({ ...customer, ...values });
    },
    [customer],
  );

  useEffect(() => {
    refreshCustomer();
  }, [customerId, refreshCustomer]);

  return { loading, error, customer, refreshCustomer, patchCustomer };
};

export default useCustomer;
