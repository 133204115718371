import React from "react";
import { getIn, FieldProps } from "formik";
import { FormField, TextBox } from "@veneer/core";

interface TextboxProps extends FieldProps {
  label?: string;
  placeholder?: string;
  value: string | number;
  required: boolean;
  onFocus?(event: React.FocusEvent<any>): void;
  disabled?: boolean;
  readOnly?: boolean;
  type?: "file" | "password" | "search" | "text" | "number-only";
  maxLength?: number;
  onReturn?: () => void;
  onChange?: (value: string) => void;
}

const cleanDataAttribute = (value: string) => value.replace(/[[\].]+/g, "");

const Textbox: React.FC<TextboxProps> = ({
  label,
  placeholder,
  value,
  form,
  field,
  required,
  onFocus,
  disabled = false,
  readOnly = false,
  type = "text",
  maxLength,
  onReturn,
  onChange,
}) => {
  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);
  const hasError = error && touched;

  // In order to make input field to accept only numbers accross all the browsers.
  const onChangeInput = async (value: any) => {
    if (type === "number-only") {
      const valueCode = String(value.split("")[value.length - 1]).charCodeAt(0);
      value = value.trim();
      if ((valueCode < 48 || valueCode > 57)) {
        value = value.slice(0, -1);
      }
    }
    if (onChange) {
      onChange(value);
    }
    form.setFieldValue(field.name, value);
  };

  const onKeyPress = (event: any) => {
    if (!onReturn) {
      return;
    }

    if (event?.key === "Enter") {
      onReturn();
    }
  };

  return (
    <FormField
      label={label}
      htmlFor={field.name}
      errorMessage={hasError && error}
      required={required}
    >
      <TextBox
        name={field.name}
        placeholder={placeholder}
        onChange={(value: string) => onChangeInput(value)}
        onFocus={onFocus}
        onKeyPress={onKeyPress}
        type={type === "number-only" ? "text" : type}
        value={value}
        error={hasError}
        disabled={disabled}
        readOnly={readOnly}
        className={readOnly ? "disableBg" : ""}
        maxLength={maxLength}
        data-cy={cleanDataAttribute(field.name)}
      />
    </FormField>
  );
};

export default Textbox;
