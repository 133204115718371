import React from "react";
import { Icon, Button } from "@veneer/core";
import styled from "styled-components";

import { RegistrationState } from "types/registration-state";
import PageNoMenu from "components/layouts/page-no-menu";
import ScreenPanel from "components/layouts/screen-panel";
import { useAuth } from "redux/auth-hooks";
import { logout } from "../../../api/oidc-user-manager";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

const BodyContent = styled.h6`
  text-align: left;
  margin-bottom: 40px;
`;

const BlockButton = styled(Button)`
  display: block;
  margin: auto;
  width: 260px;
  margin-bottom: 40px;
`;

interface ErrorPageProps {
  title: string;
}

const InvitationErrorPage: React.FC<ErrorPageProps> = ({ title, children }) => {
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const history = useHistory();

  let showContinueButton = false;

  if (currentUser.registrationState === RegistrationState.APPROVED) {
    showContinueButton = true;
  }

  return (
    <PageNoMenu>
      <ScreenPanel>
        <Icon name="hp" size={64} />
        <h3>{title}</h3>
        <BodyContent>{children}</BodyContent>
        <BlockButton onClick={logout}>{t("log.out")}</BlockButton>
        {
          showContinueButton && (
            <BlockButton
              onClick={() => { history.replace("/"); }}
              appearance="secondary"
            >
              {t("continue.portal")}
            </BlockButton>
          )
        }
      </ScreenPanel>
    </PageNoMenu>
  );
};

export default InvitationErrorPage;
