import React from "react";
import { Panel } from "@veneer/core";
import styled from "styled-components";

const StyledPanel = styled(Panel)`
  .vn-panel__header {
    border-bottom-color: transparent;
    display: ${props => (props.link ? "none" : "block")};
    ${(props) => (!props.borderatbottomofpanel ? `border-bottom: 5px solid ${props.theme.colors.white};` : "padding-bottom: 0;")};
  }
  .vn-panel__content {
    padding: 1px 8px 8px;
  }
  .vn-panel__toggle-icon {
    right: -14px;
  }
  .vn-panel__title svg {
    top: -4px;
}
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .vn-panel__title {
      padding-left: 20px;
    }
 }
  ${(props) => (props.borderatbottomofpanel ? `border-bottom: 5px solid ${props.theme.colors.white};` : "")};
`;

interface PanelProps {
  children: React.ReactNode;
  title?: any;
  collapsible?: boolean;
  collapsed?: boolean;
  Styled?: any;
  borderatbottomofpanel?: boolean;
}

/**
 * Panel based on the veneer panel but has different styles specific to the homepage
 */
const CollapsibleView: React.FC<PanelProps> = ({
  children,
  collapsible = false,
  title,
  collapsed = true,
  Styled = StyledPanel,
  borderatbottomofpanel: borderatbottomOfpanel = false,
}) => (
  <Styled title={title} collapsible={collapsible} collapsed={collapsed} borderatbottomofpanel={borderatbottomOfpanel}>
    {children}
  </Styled>
);

export default CollapsibleView;
