import { useCurrentCustomerID } from "./current-customer-hooks";
import { useSelector } from "react-redux";
import { RootState } from "./redux-state";
import { deepEqual, permissions, customerPermissions } from "@mssi/pssp-shared";
import { useMemo } from "react";
import { useAuth, useFeature, useHasPermission } from "./auth-hooks";
import { useCurrentCustomerCapability } from "../shared/customer-has-capability";

export const useHasCurrentCustomerCompletedContractSetup = (): boolean => {
  const customerId = useCurrentCustomerID();
  const authCustomers = useSelector((state: RootState) => state.auth?.currentUser?.customers ?? [], deepEqual);

  return useMemo(
    () => {
      const match = authCustomers.find((it) => it.id === customerId);
      return !!match?.catalogSetupComplete;
    },
    [authCustomers, customerId],
  );
};

export const useCanPlaceOrder = () => {
  const contractSetupComplete = useHasCurrentCustomerCompletedContractSetup();
  const hasPermission = useHasPermission();
  const { currentUser } = useAuth();
  const selfOrderFeatureFlag = useFeature<boolean>("endUserOrder");
  const contractualOrderingEnabled = useCurrentCustomerCapability(customerPermissions.contractualOrdering);

  const canViewOrders = hasPermission(permissions.canViewOrders, permissions.canViewMyOrders);
  const hasCreateOrderPermission = hasPermission(permissions.canCreateOrders);
  const hasCreateSelfOrderPermission = hasPermission(permissions.canOrderBasedOnAssignedCategory);
  const canFixContracts = hasPermission([permissions.canCreateContracts, permissions.canEditContracts]);

  const selfOrderConfigOK = !!(selfOrderFeatureFlag && currentUser.categoryId && currentUser.site?.addressLine1);

  const prerequisites = contractualOrderingEnabled && canViewOrders && contractSetupComplete;
  const canPlaceOrder = prerequisites && hasCreateOrderPermission;
  const canPlaceSelfOrder = prerequisites && hasCreateSelfOrderPermission && selfOrderConfigOK;
  const canPlaceBothOrderTypes = canPlaceSelfOrder && canPlaceOrder;
  const canPlaceSomeOrder = canPlaceSelfOrder || canPlaceOrder;

  const showWarning = canFixContracts && contractualOrderingEnabled && !contractSetupComplete;

  return {
    canFixContracts, // whether user can fix contracts
    showWarning, // whether to show contract setup warning
    contractSetupComplete, // whether customer has a contract
    canViewOrders, // permission to see orders (required to place)
    contractualOrderingEnabled, // customer capability
    prerequisites, // baseline met for this customer to place order (does not include user role check)
    canPlaceOrder, // user can place regular order
    canPlaceSelfOrder, // user can place order for self
    canPlaceSomeOrder, // user can place regular order, or self order, or both (logical OR)
    canPlaceBothOrderTypes, // user can place regular and self order, should be prompted (logical AND)
  };
};

export const useCustomerHasContracts = (): boolean => {
  const customerId = useCurrentCustomerID();
  const authCustomers = useSelector((state: RootState) => state.auth?.currentUser?.customers ?? [], deepEqual);

  return useMemo(
    () => {
      const match = authCustomers.find((it) => it.id === customerId);
      return !!match?.contracts;
    },
    [authCustomers, customerId],
  );
};

export const useCurrentCustomerHasDevices = (): boolean => {
  const customerId = useCurrentCustomerID();
  const authCustomers = useSelector((state: RootState) => state.auth?.currentUser?.customers ?? [], deepEqual);

  return useMemo(
    () => {
      const match = authCustomers.find((it) => it.id === customerId);
      return !!match?.devices;
    },
    [authCustomers, customerId],
  );
};

export const useCurrentCustomerHasDevicesInUse = (): boolean => {
  const customerId = useCurrentCustomerID();
  const authCustomers = useSelector((state: RootState) => state.auth?.currentUser?.customers ?? [], deepEqual);

  return useMemo(
    () => {
      const match = authCustomers.find((it) => it.id === customerId);
      return !!match?.devicesInUse;
    },
    [authCustomers, customerId],
  );
};
