import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { RootActionTypes } from "./root-action";
import { useCurrentCustomerID } from "./current-customer-hooks";
import { asyncGetCart } from "./shopping-cart-thunks";

const InitShoppingCart: React.FC = () => {
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();
  const customerId = useCurrentCustomerID();

  useEffect(
    () => {
      if (!customerId) {
        return;
      }

      dispatch(asyncGetCart() as any);
    },
    [dispatch, customerId],
  );

  return null;
};

export default InitShoppingCart;
