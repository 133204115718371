export default {
  UAMlink: "https://accessmanager.austin.hp.com/uam/ProfileRequest.aspx?ProFlag=True&ProfileID=57562&",
  cognitoDomain: "https://localdev-daas-portal.auth-fips.us-west-2.amazoncognito.com",
  cognitoRegion: "us-west-2",
  cognitoPoolId: "us-west-2_GP4CoVNpG",
  cognitoClientId: "65h9vp94kvcand0citvsf9j7p6",
  hpidUrl: "https://directory.stg.cd.id.hp.com",
  editProfileEmployee: " https://directoryworks.hpicorp.net",
  editProfileNonEmployee: "https://myaccount.stg.cd.id.hp.com",
  allowLocalStorageFlagOverrides: true,
  jupiterStack: "dev",
  testEmailNote: true,
};
