import React from "react";

import Page from "components/layouts/page";

interface Props {
  title?: string;
}

const PageNoMenu: React.FC<Props> = ({ children, title }) => <Page title={title} menuEnabled={false}>{children}</Page>;

export default PageNoMenu;
