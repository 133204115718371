import queryString from "query-string";
import { getJson, postJson, putJson } from "./request";
import Order, { OrderCSV, OrderCreateInfo, OrderListItem, OrderListRequest, HomeAddress, OrderStatusType, OrderContact } from "../types/order";
import { BackendTableBaseResult } from "../types/table-backend-types";
import { S3Document } from "../types/document";

export const getOrderById = (id: string): Promise<Order> => getJson(`/orders/${id}`);

export const queryOrdersList = (query: OrderListRequest): Promise<BackendTableBaseResult<OrderListItem>> =>
  getJson(`/orders/list?${queryString.stringify(query)}`);

export interface CreateOrderResponse {
  order: Order;
  coolDown: Date;
}

export const createOrder = (orderInfo: OrderCreateInfo): Promise<CreateOrderResponse> => postJson(`/orders`, orderInfo)
  .then((result) => {
    result.coolDown = new Date(result.coolDown);
    return result;
  });

export const approveOrder = (id: string): Promise<Order> => postJson(`/orders/${id}/approve`, { id });

export const rejectOrder = (id: string, rejectionReason: string): Promise<Order> =>
  postJson(`/orders/${id}/reject`, { rejectionReason });

export const cancelOrderRequest = (id: string, cancellationReason: string): Promise<Order> =>
  postJson(`/orders/${id}/cancelRequest`, { cancellationReason });

export const revertCancelOrderRequest = (id: string): Promise<Order> =>
  postJson(`/orders/revertCancelRequest`, { id });

export const approveOrderCancelRequest = (id: string, cancellationReason: string): Promise<Order> =>
  postJson(`/orders/${id}/approveRequest`, { cancellationReason });

export const updateOrderStatusManually = (id: string, orderStatus: OrderStatusType): Promise<Order> =>
 postJson(`/orders/${id}/update-status-manually`, orderStatus);

export const updateS4OrderNumber = (id: string, hpOrderNumber: string): Promise<Order> =>
  putJson(`/orders/${id}`, { hpOrderNumber });

export const updateOrderHomeAddress = (id: string, homeAddress: HomeAddress): Promise<Order> =>
  putJson(`/orders/${id}/homeAddress`, { homeAddress });

export const updateOrderAdditionalContact = (id: string, contacts: OrderContact[]): Promise<Order> =>
  putJson(`/orders/${id}/additionalContacts`, { contacts });

export const combineOrderForS4OrderNumber = (orderIdToDelete: string, hpOrderNumber: string, orderIdToUpdate: string): Promise<Order> =>
  putJson(`/orders/combine`, { orderIdToDelete, hpOrderNumber, orderIdToUpdate });

export const updateCustomerOrderNumber = (id: string, portalCustomerOrderNumber: string): Promise<Order> =>
  putJson(`/orders/${id}/customerOrderNumber`, { portalCustomerOrderNumber });

export const getOrderCSV = (id: string): Promise<OrderCSV> => getJson(`/orders/${id}/csv`);

export const getOrderXLSX = (id: string): Promise<OrderCSV> => getJson(`/orders/${id}/xlsx`);

export const saveSDMPurchaseOrder = async (orderId: string, id: string): Promise<S3Document[]> => {
  const { documents }: { documents: S3Document[] } = await postJson("/orders/save-purchase-order-sdm", { orderId, id });
  return documents;
};

export const deleteDocument = async (_id: string): Promise<S3Document[]> => {
  const { documents }: { documents: S3Document[] } = await getJson(`/orders/remove-document?${queryString.stringify({ _id })}`);

  return documents;
};
