export const CURRENT_CUSTOMER_REGEX = /^currentCustomer\//;
export const CURRENT_CUSTOMER_INIT = "currentCustomer/init";
export const CURRENT_CUSTOMER_CHANGE = "currentCustomer/change";
export const CURRENT_CUSTOMER_LOADING = "currentCustomer/loading";
export const CURRENT_CONTRACT_FOR_ORDER = "currentCustomer/contractId";

export interface ChangeCurrentCustomerParams {
  type: typeof CURRENT_CUSTOMER_CHANGE;
  customerId?: string;
  reloadPage: boolean;
}

export interface InitCurrentCustomerParams {
  type: typeof CURRENT_CUSTOMER_INIT;
}

export interface SetCurrentContract {
  type: typeof CURRENT_CONTRACT_FOR_ORDER;
  contractId: string;
}

export interface SetIsLoading {
  type: typeof CURRENT_CUSTOMER_LOADING;
  loading: boolean;
}

export type CurrentCustomerTypes = (
  ChangeCurrentCustomerParams
  | InitCurrentCustomerParams
  | SetIsLoading
  | SetCurrentContract
);

export const changeCurrentCustomer = (customerId?: string, reloadPage: boolean = false): CurrentCustomerTypes => ({
  type: CURRENT_CUSTOMER_CHANGE,
  customerId,
  reloadPage,
});

export const initCurrentCustomer = (): CurrentCustomerTypes => ({ type: CURRENT_CUSTOMER_INIT });

export const setIsLoading = (loading: boolean): CurrentCustomerTypes => ({ type: CURRENT_CUSTOMER_LOADING, loading });

export const setCurrentContractForOrder = (contractId: string): CurrentCustomerTypes => ({ type: CURRENT_CONTRACT_FOR_ORDER, contractId });

