import { ReportDownloadState, RootState } from "./redux-state";
import { permissions } from "@mssi/pssp-shared";

type ReportDownloadPermissions = Pick<ReportDownloadState, (
  "userHasInternalExternalChoice"
  | "userHasCustomerChoice"
  | "internal"
  | "customerIds"
)>;

export const getReportDownloadPermissions = (
  state: RootState,
  reportHasCustomerChoice: boolean,
  reportHasInternalExternalChoice: boolean,
): ReportDownloadPermissions => {
  const { auth, currentCustomer } = state;

  const canViewInternalReports = auth.authorizedFor(permissions.canViewInternalReports);
  const canViewExternalReports = auth.authorizedFor(permissions.canViewExternalReports);

  const userHasInternalExternalChoice = reportHasInternalExternalChoice
    && canViewInternalReports
    && canViewExternalReports;

  const userHasCustomerChoice = reportHasCustomerChoice
    && (auth.currentUser?.customers?.length ?? 0) > 1;

  const internal = canViewInternalReports;
  const firstCustomer = (auth.currentUser?.customers ?? [undefined])[0];
  const customerId = currentCustomer?.customer?.id ?? firstCustomer?.id;
  const customerIds = customerId ? [customerId] : [];

  return {
    userHasInternalExternalChoice,
    userHasCustomerChoice,
    internal,
    customerIds,
  };
};
