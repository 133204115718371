import { LanguageType } from "../i18n-languages";
import { PreferenceLookupType } from "../components/profile-preference/preference-lookup-type";

const lookupLanguages: PreferenceLookupType<LanguageType> = [
  ["", "Automatic"],
  ["zh", "Chinese (Simplified)"],
  ["da", "Danish"],
  ["nl", "Dutch"],
  ["en", "English"],
  ["fi", "Finnish"],
  ["fr", "French"],
  ["de", "German"],
  ["it", "Italian"],
  ["ja", "Japanese"],
  ["nb-NO", "Norway"],
  ["pt-BR", "Portuguese (Brazil)"],
  ["pt-PT", "Portuguese (Portugal)"],
  ["es", "Spanish"],
  ["sv", "Swedish"],
];

export default lookupLanguages;
