import React, { useCallback, useEffect, useRef, useState } from "react";
import { FilePicker, Modal, Spinner } from "@veneer/core";
import { getSdmDocumentStatus, getSdmUploadUrl, uploadSdmDocument } from "../../api/upload";
import { useAddError } from "../../redux/toast-hooks";
import { SdmUploadStatus } from "../../types/upload";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { SDMStatus } from "@mssi/pssp-database";
import Button from "components/button";

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  > * + * {
    margin-left: 10px;
  }
  @media ${(props) => props.theme.breakpoints.mobileMaximum} {
    margin-top: 32px;
  }
`;

const ModalWrapper = styled.div`
  display: block;
  position: absolute;
`;

const Wrapper = styled.div`
  position: relative;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  text-align: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  > svg {
    margin-top: 5px;
  }
`;

interface Props {
  onFileSelected(state: SdmUploadStatus): void;
  allowEdit?: boolean;
  accept?: string;
}

const FilePickerSDMModal: React.FC<Props> = ({
  onFileSelected,
  allowEdit = true,
  accept,
}) => {
  const { t } = useTranslation();
  const [tempValue, setTempValue] = useState<string | undefined>();
  const addError = useAddError();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [state, setState] = useState<SdmUploadStatus | undefined>();
  const fileRef = useRef<any>(null);

  const isUploading = state?.status === SDMStatus.uploading || state?.status === SDMStatus.pending;

  const clearFile = useCallback(
    () => {
      setFile(null);

      if (!fileRef.current) {
        return;
      }

      fileRef.current.value = null as unknown as string;

      if (!fileRef.current.fileInput) {
        return;
      }

      fileRef.current.fileInput.value = null;
      const fileNameElement = fileRef.current.fileInput.parentElement?.querySelector(".vn-filepicker__file-name");

      if (fileNameElement) {
        fileNameElement.innerHTML = "";
      }
    },
    [fileRef],
  );

  const onChange = async (_event: React.ChangeEvent<HTMLInputElement>, files: FileList) => {
    if (files.length >= 1) {
      setFile(files.item(0) || null);
    } else {
      clearFile();
    }
  };

  useEffect(
    () => {
      if (file) {
        setState({
          id: "",
          fileName: file.name,
          status: SDMStatus.uploading,
        });

        getSdmUploadUrl(file)
          .then(async ({ signedUrl, id }) => {
            await uploadSdmDocument(signedUrl, file);
            setTempValue(id);
          })
          .catch((error) => {
            addError(error.message);

            setState(undefined);
            setTempValue(undefined);
            clearFile();
          });
      }
    },
    [file, addError, clearFile],
  );

  useEffect(
    () => {
      if (!showModal) {
        return undefined;
      }

      if (tempValue) {
        let done = false;
        let interval: number = 0;

        const fn = () => {
          getSdmDocumentStatus(tempValue)
            .then((result) => {
              if (!done) {
                if (result.status === SDMStatus.invalid) {
                  addError("Your attachment was not accepted.  Please try a different file.");
                  setTempValue(undefined);
                  clearFile();
                }

                setState(result);
              }

              if (interval) {
                clearInterval(interval);
                interval = 0;
                done = true;
              }
            })
            .catch((error) => {
              addError(error.message);
              setState((s) => {
                if (s?.status === SDMStatus.valid) {
                  return s;
                }

                return undefined;
              });
            });
        };

        // Note: TS is confused and thinks this is returning a Node timeout instead of a DOM timeout.
        // DOM timeout return type is number, not some fancy object.
        interval = setInterval(fn, 5000) as unknown as number;
        fn();

        // When component unmounts, clear interval & set done to true so there's no stray state updates
        return () => {
          if (interval) {
            clearInterval(interval);
            interval = 0;
            done = true;
          }
        };
      }

      return () => {};
    },
    [addError, tempValue, showModal, clearFile],
  );

  const doClose = () => {
    setShowModal(false);
    setTempValue(undefined);
    setState(undefined);
    setFile(null);
  };

  useEffect(
    () => {
      if (state?.status === SDMStatus.valid && state?.id && state?.fileName) {
        onFileSelected(state);
        doClose();
      }
    },
    [onFileSelected, state],
  );

  return (
    <>
      {allowEdit && <button onClick={() => setShowModal(true)} type="button" className="vn-button vn-button--primary vn-button--primary--hoverable">BROWSE</button>}
      <ModalWrapper>
        <Modal
          size="sm"
          show={showModal}
          onClose={() => doClose()}
          dismissOnOverlayClick={false}
          title={t("chooseFile")}
        >
          <Wrapper>
            <FilePicker
              name="_fp_modal"
              placeholder="Select a File"
              onChange={onChange}
              value={file}
              accept={accept}
              ref={fileRef}
            />
            <ButtonWrapper>
              <Button onClick={() => doClose()} appearance="secondary">{t("btn.cancel", { postProcess: "upper" })}</Button>
            </ButtonWrapper>
            {isUploading && (
              <SpinnerWrapper>
                <Spinner />
              </SpinnerWrapper>
            )}
          </Wrapper>
        </Modal>
      </ModalWrapper>
    </>
  );
};

export default FilePickerSDMModal;
