import React, { Dispatch, useState } from "react";
import { Modal, Icon } from "@veneer/core";
import ShoppingCartMobileItem from "./shopping-cart-item-mobile";
import styled from "styled-components";
import OrderValueTile from "./order-value-tile";
import CollapsibleView from "components/collapsibleView";
import useIsDevice from "shared/isDevice";
import deviceType from "shared/devices";
import ShoppingCartItem from "./shopping-cart-item";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { RootActionTypes } from "redux/root-action";
import { closeShoppingCart } from "redux/shopping-cart-action";
import { permissions } from "@mssi/pssp-shared";
import { useAuth } from "../../../redux/auth-hooks";
import {
  useNewOrderStepNextBestGuess,
  useShoppingCartContents,
  useShoppingCartVisibility,
} from "../../../redux/shopping-cart-hooks";
import { useHistory } from "react-router-dom";
import { calculateTotalPerMonth, calculateTotalPerTerm } from "./shopping-cart-prices";
import { useCurrentCustomerID } from "../../../redux/current-customer-hooks";
import useLeaveWarning from "contexts/leaveWarning-context";
import WarningLeavingPage from "components/page-leaving-warning-popup";
import ClearCartWarningPage from "./clear-cart-warning-popup";
import PlaceOrderButtonWarning from "components/place-order-button-warning";
import Button from "components/button";
import { useHasCurrentCustomerCompletedContractSetup } from "../../../redux/combo-hooks";

const Center = styled.div`
  text-align: center;
  margin-top: 70px;
`;

const ModalWrapper = styled.div`
.vn-modal {
  background-color: ${(props) => props.theme.colors.transparent};
  overflow-y: hidden;
}

.vn-modal__dialog {
  position: absolute;
  top: 30px;
  right: 40px;
  width: 35%;
  justify-content: right;
  height: auto;
}

.vn-modal__content {
  box-shadow: 0 5px 15px rgba(102, 102, 102, 0.5);
  padding: 0;
  margin: 0;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  align-items: stretch;
  
  > div {
    width: 100%;
  }
}

.vn-modal__header {
  h4 {
    font-weight: bold;
    font-size: 0.9rem;
    color: ${(props) => props.theme.colors.textColor3};
    padding: 0.75rem 1rem;
  }

  background-color: ${(props) => props.theme.colors.background3};
  margin-bottom: 0;
  min-height: 41px;
  height: 41px;
}

.vn-modal__body {
  padding: 10px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: initial;
  }
  flex: 2 auto;
}

.vn-modal__footer {
  margin-top: 0;
  flex: 1 auto;
}

.vn-panel__content {
  padding: 0 20px 15px 7px !important;
}

.vn-panel__header {
  border-bottom: none;
}

@media (max-width: 767px) {
  .vn-modal__dialog {
    top: 92px;
    right: 20px;
    width: 45%;
    height: 70%;
  }
}

@media (max-width: 636px) {
  .vn-modal__dialog {
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  
  .vn-modal__content {
    height: 100%;
  }
  
  .vn-modal__body {
    height: 100%;
  }
}
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.white};
  width: 100%;
  overflow-y: overlay;
  overflow-x: hidden;
  height: 250px;
  flex-shrink: 0;
  position: sticky;
  @media (max-width: 636px) {
    height: auto;
  }
`;

const Price = styled.span`
  font-family: ${(props) => props.theme.font.regular};
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
  min-width: 20%;
  width: auto;
  text-align: right;
`;

const OrderValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  @media ${(props) => props.theme.breakpoints.tabletMaximum} {
    justify-content: space-between;
  }
`;

const CartValue = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  padding-bottom: 1%;
`;

const Footer = styled.div`
  padding: 10px;

  button {
    width: 100%;
  }
`;

const ClearButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const ShoppingCartMobile: React.FC = () => {
  const shoppingCart = useShoppingCartContents();
  const showShoppingCart = useShoppingCartVisibility();
  const customerId = useCurrentCustomerID();
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();
  const { t } = useTranslation();
  const { authorizedFor } = useAuth();
  const history = useHistory();
  const canPlaceOrder = authorizedFor([permissions.canCreateOrders]);
  const totalPricePerMonth = calculateTotalPerMonth(shoppingCart);
  const totalPricePerContract = calculateTotalPerTerm(shoppingCart);
  const { leaveWarning } = useLeaveWarning();
  const [warningPopup, setWarningPopup] = useState<boolean>(false);
  const isMobileTablet = useIsDevice(deviceType.MOBILE, deviceType.MOBILELARGE, deviceType.TABLET);
  const canViewBundlePricing = authorizedFor([permissions.canViewBundlePricing]);
  const hasAnyCatalog = useHasCurrentCustomerCompletedContractSetup();

  const [clearCart, setClearCart] = useState<boolean>(false);
  const { step: currentStep, caption } = useNewOrderStepNextBestGuess();

  const openOrderForm = () => {
    history.push(`/${customerId}/orders/new-order/${currentStep}`);

    dispatch(closeShoppingCart());
  };


  if (!canPlaceOrder) {
    return null;
  }

  return (
    <>
      {showShoppingCart && (
        <ModalWrapper>
          <Modal
            align="left"
            size="sm"
            title={t("shoppingcart.title")}
            footer={<div />}
            show
            onClose={() => {
              setClearCart(false);
              dispatch(closeShoppingCart());
            }}
            dismissOnOverlayClick={true}
          >
            {!!shoppingCart && shoppingCart.length > 0 && (
              <ClearButton>
                <Button appearance="secondary" onClick={() => setClearCart(true)}>
                  {t("order.button.allClearCart")}
                </Button>
              </ClearButton>
            )}
            <ListWrapper>
              {!!shoppingCart && shoppingCart.length > 0 && (
                <div className="cartItem">
                  {shoppingCart.map((orderItem, i) => (
                    <div className="mainCart" key={i}>
                      {isMobileTablet && <ShoppingCartMobileItem orderItem={orderItem} key={orderItem.bundleId} />}
                      {!isMobileTablet && <ShoppingCartItem orderItem={orderItem} key={orderItem.bundleId} />}
                    </div>
                  ))}
                </div>
              )}
              {(!shoppingCart || !shoppingCart.length) && <Center>{t("shoppingcart.empty")}</Center>}
            </ListWrapper>
            <Footer>
              {shoppingCart.length > 0 && (
                <>
                  <hr className="hrWidth" />
                  {canViewBundlePricing && (
                    <CartValue>
                      <OrderValue data-cy="totalPerMonth">
                        <OrderValueTile title={t("grandtotalpermonth")} tooltipcontent={t("grandtotalpermonth.tooltip")} />
                        <Price>{totalPricePerMonth}</Price>
                      </OrderValue>
                      <OrderValue data-cy="totalPerTerm">
                        <OrderValueTile title={t("grandtotalperterm")} tooltipcontent={t("grandtotalperterm.tooltip")} />
                        <Price>{totalPricePerContract}</Price>
                      </OrderValue>
                    </CartValue>
                  )}
                </>
              )}
              {
                hasAnyCatalog &&
                <Button
                  onClick={() => {
                  if (leaveWarning) {
                    setWarningPopup(true);
                  } else {
                    openOrderForm();
                  }
                }}
                >
                  {t(caption)}
                </Button>
              }
              <PlaceOrderButtonWarning />
              {shoppingCart.length > 0 && isMobileTablet && (
                <>
                  <hr className="hrWidth" />
                  <div className="posRelative">
                    <div className="cartInfoIcon">
                      <Icon name="information" />
                    </div>
                    <div className="displayFlex">
                      <CollapsibleView title={t("shoppingcart.moreinfo")} collapsible={true} collapsed={true}>
                        <p>{t("grandtotalpermonth.tooltip")}</p>
                        <p>{t("grandtotalperterm.tooltip")}</p>
                      </CollapsibleView>
                    </div>
                  </div>
                </>
              )}
            </Footer>
          </Modal>
        </ModalWrapper>
      )}
      <WarningLeavingPage values={warningPopup} setValue={setWarningPopup} screenChange={openOrderForm} />
      <ClearCartWarningPage clearCart={clearCart} setClearCart={setClearCart} inCart={true} />
    </>
  );
};

export default ShoppingCartMobile;
