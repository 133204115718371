// we support intl numbers, so this regex is as loose as possible, per HP
const phoneNumber = /^[\d()\-+()x\s]+$/i;


// notice board event validations
const noticeBoardValidations = (values: any, errors: any) => {
  if (
    Date.parse(values.eventEndDate || "") <
    Date.parse(values.eventStartDate || "")
  ) {
    errors.dateError = true;
  }

  if (
    Date.parse(values.eventEndDate || "") ===
    Date.parse(values.eventStartDate || "")
  ) {
    if ((values.eventEndTime || "") < (values.eventStartTime || "")) {
      errors.timeError = true;
    }
  }
  if (values.eventLocation && (!values.eventLocation.match(/^https:\/\//) || /\s/.test(values.eventLocation))) {
    errors.urlError = true;
  }
  return errors;
};


export {
  phoneNumber, noticeBoardValidations
};
