import React, { useState, useMemo } from "react";
import { Icon, Select, Toggle, Tooltip } from "@veneer/core";
import styled from "styled-components";
import lookupLanguages from "shared/language-select";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { RootActionTypes } from "../../../redux/root-action";
import { Dispatch } from "redux";
import { asyncUpdatePreferences } from "../../../redux/auth-thunks";
import { useAuth, useFeature } from "../../../redux/auth-hooks";
import useIsDevice from "shared/isDevice";
import deviceType from "shared/devices";
import useLocalizationCodeList from "../../../hooks/use-localization-code-list";
import PageSpinner from "../../../components/page-spinner";
import PrivacyNote from "../../../components/privacy-note";
import Button from "components/button";
import { SelectOption } from "shared/map";
import { makeRegExp, geoRegions, countries } from "@mssi/pssp-shared";

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.background};
`;

const Header = styled.div`
  display: flex;
  padding: 20px;
  background-color: ${(props) => props.theme.colors.white};
  h3 {
    margin-top: 13px;
    margin-left: 25px;
    color: ${(props) => props.theme.colors.grey};
  }
  @media ${(props) => props.theme.breakpoints.mobileMaximum} {
    h3 {
      font-size: 30px;
    }
  }
`;

const PreferenceSelectionSection = styled.div`
    margin-bottom: 0px;
    background-color: ${(props) => props.theme.colors.white};
    padding-bottom: 50px;
    h2 {
        padding: 20px;
        text-align: center;
        color: ${(props) => props.theme.colors.blue2};
    }
    > p {
        font-size: 25px;
        text-align: center;
    }
    @media ${(props) => props.theme.breakpoints.mobileMaximum} {
      h2 {
        font-size: 35px;
      }
      p {
        font-size: 20px;
    }
  }
`;

const Selection = styled.div`
  margin-left: 350px;
  margin-right: 350px;
  svg.vn-spinner {
    position: absolute;
    left: 50%;
  }
  @media ${(props) => props.theme.breakpoints.tabletMaximum} {
    margin-left: 100px;
    margin-right: 100px;
  }
  @media ${(props) => props.theme.breakpoints.mobileMaximum} {
    margin-left: 15px;
    margin-right: 15px;
    }
`;

const Title = styled.div`
  margin-top: 30px;
  font-size: 15px;
`;

const ToggleWrapper = styled.div`
  display: flex;
  margin-top: 50px;
`;

const ToggleTitle = styled.div`
  margin-left: 25px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 78%;
  margin-top: 50px;
  padding-bottom: 50px;
  .vn-button--primary {
    width: 200px;
  }
  @media ${(props) => props.theme.breakpoints.tabletMaximum} {
    width: 85%;
  }
  @media ${(props) => props.theme.breakpoints.mobileMaximum} {
    width: 95%;
    .vn-button--primary {
      width: 150px;
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  .vn-tooltip {
    position: relative !important;
    top: 29px;
    left: 2px;
  }
  .vn-tooltip__content {
    width: 195px;
  }
`;

const EmailWrapper = styled.div`
    display: flex;
    .vn-tooltip {
      position: relative !important;
      bottom: 2px;
      left: 4px;
    }
    .vn-tooltip__content {
      width: 195px;
    }
`;

const Error = styled.div`
  color: ${(props) => props.theme.colors.astrik};
  margin: 10px 0;
`;


export interface Option {
  value: string;
  label: string;
}
interface ErrorProps {
  errorMessage: string
}

const EmailRegionsDropDown = styled.div`
  .vn-options-list {
    min-height: 240px;
    max-height: 240px !important;
  }

  .vn-option {
    height: fit-content;
  }

  .vn-option__multiple a {
    padding-left: unset;
  }
`;

const UserPreferences: React.FC = () => {
  const { currentUser, loading: submitting } = useAuth();
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();
  const { t } = useTranslation();
  const [language, setLanguage] = useState<string>(currentUser.preferences.language);
  const [localization, setLocalization] = useState<string>(currentUser.preferences.defaultLocalizationCode);
  const [sendEmails, setSendEmails] = useState<boolean>(true);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const languagesOptions: Option[] = lookupLanguages.map(([value, label]) => ({ value, label }));
  const isMobileOrTablet = useIsDevice(deviceType.MOBILE, deviceType.MOBILELARGE, deviceType.TABLET);
  const { loading, canSetLocalizationCode, localizationCodeList } = useLocalizationCodeList();
  const localizationOptions: Option[] = localizationCodeList.map(([value, label]) => ({ value, label }));
  const canSelectLanguage = useFeature<boolean>("languagePreferenceUserProfile");
  const [error, setError] = useState<ErrorProps>({ errorMessage: "" });
  const [emailRegionsDropdownSearch, setEmailRegionsDropdownSearch] = useState<string>("");
  const [emailRegions, setEmailRegions] = useState<string[]>(currentUser.emailRegions || []);

  const emailRegionsOptions: SelectOption[] = useMemo(() => {
    if (!geoRegions.length || !countries.length) {
      return [];
    }

    const geoRegionsOptions = geoRegions.map((geoRegion) => ({
      label: geoRegion,
      value: geoRegion,
    }));

    const countriesOptions = countries.map(({ country }) => ({
      label: country,
      value: country,
    }));

    const search = emailRegionsDropdownSearch ? makeRegExp(emailRegionsDropdownSearch) : undefined;

    return [...geoRegionsOptions, ...countriesOptions].filter((option) => !search || search.test(option.label));
  }, [emailRegionsDropdownSearch]);

  if (loading || submitting) {
    return <PageSpinner />;
  }

  const updatePreference = async () => {
    try {
      setButtonLoading(true);

      const preferences: any = {
        language,
        defaultLocalizationCode: localization,
        sendEmails,
      };

      if (currentUser.persona?.isInternal) {
        preferences.emailRegions = emailRegions;
      }

      dispatch(asyncUpdatePreferences(preferences) as any);
    } catch {
      setButtonLoading(false);
      setError({
        errorMessage: t("preferences.error.message"),
      });
    }
  };

  return (
    <Wrapper>
      <Header>
        <Icon name="hp" color="blue-tint-05" size={64} />
        <h3>{t("userpref.header")}</h3>
      </Header>
      <div>
        <PreferenceSelectionSection>
          <h2>{t("userpref.welcometitle")}</h2>
          <p>{t("userpref.welcome.desc1")}</p>
          <p>{t("userpref.welcome.desc2")}</p>
          <Selection>
            {canSelectLanguage && (
              <>
                <Title>{t("userpref.language")}</Title>
                <Select
                  options={languagesOptions}
                  disabled={loading}
                  onChange={(option: any) => {
                    setLanguage(option.value);
                  }}
                  value={[language]}
                  clearIcon={false}
                  placeholder={t("preferences.selectlang")}
                />
              </>
            )}
            {canSetLocalizationCode && currentUser.customerIds.length > 0 && (
              <>
                <TitleWrapper>
                  <Title>{t("userpref.localization")}</Title>
                  {!isMobileOrTablet && (
                    <Tooltip
                      content={t("userpref.localizationtooltip")}
                      contentHideDelayOnHover={0}
                      position="up"
                    >
                      <Icon name="information" />
                    </Tooltip>
                  )}
                </TitleWrapper>
                <Select
                  options={localizationOptions}
                  disabled={loading}
                  onChange={(option: any) => {
                    setLocalization(option.value);
                  }}
                  value={[localization]}
                  clearIcon={false}
                  placeholder={t("skulocalization.placeholder")}
                />
              </>
            )}
            {currentUser.persona?.isInternal && (
              <>
                <Title>{t("user.addUser.emailRegions")}</Title>
                <EmailRegionsDropDown>
                  <Select
                    name="emailRegions"
                    placeholder={t("user.addUser.selectEmailRegions")}
                    showSearch
                    multiple
                    options={emailRegionsOptions}
                    value={emailRegions}
                    onSearch={(searchText: string) => {
                      setEmailRegionsDropdownSearch(searchText);
                    }}
                    onChange={(option: SelectOption) => {
                      const newEmailRegions = [...emailRegions];
                      const index = newEmailRegions.indexOf(option.value);
                      if (index > -1) {
                        newEmailRegions.splice(index, 1);
                      } else {
                        newEmailRegions.push(option.value);
                      }
                      setEmailRegions(newEmailRegions);
                    }}
                    onClear={() => {
                      setEmailRegions([]);
                    }}
                  />
                </EmailRegionsDropDown>
              </>
            )}
            <ToggleWrapper>
              <Toggle
                on={sendEmails}
                onChange={(email: boolean) => setSendEmails(email)}
                disabled={loading}
              />
              <EmailWrapper>
                <ToggleTitle>{t("userpref.emailnote")}</ToggleTitle>
                {!isMobileOrTablet && (
                <Tooltip
                  content={t("userpref.emailtooltip")}
                  contentHideDelayOnHover={0}
                  position="right"
                >
                  <Icon name="information" />
                </Tooltip>
                  )}
              </EmailWrapper>
            </ToggleWrapper>
          </Selection>
          <ButtonWrapper>
            <Button
              disabled={loading}
              onClick={updatePreference}
              loading={buttonLoading}
            >
              {t("btn.done")}
            </Button>
          </ButtonWrapper>
          {<Error>{error.errorMessage}</Error>}
          <PrivacyNote />
        </PreferenceSelectionSection>
      </div>
    </Wrapper>
  );
};

export default UserPreferences;
