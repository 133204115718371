import { FetchError } from "types/fetch-error";
import { getRequestHeaders, logout } from "./oidc-user-manager";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const handleResponse = async (res: Response) => {
  if (res.status === 401) {
    logout(false);
  }

  if (res.status !== 200) {
    const error = new Error(res.statusText) as FetchError;
    error.status = res.status;

    try {
      const response = await res.json();
      error.details = response.details;
      error.name = response.name;

      if (res.status === 400 && response.message) {
        error.message = response.message;
      }
    } catch {
      // some responses may not be json
      error.details = [];
    }

    throw error;
  } else {
    return res.json();
  }
};

export const handleResponseBlob = async (res: Response) => {
  if (res.status === 401) {
    logout(false);
  }

  if (res.status !== 200) {
    const error = new Error(res.statusText) as FetchError;
    error.status = res.status;

    try {
      const response = await res.json();
      error.details = response.details;
      error.name = response.name;

      if (res.status === 400 && response.message) {
        error.message = response.message;
      }
    } catch {
      // some responses may not be json
      error.details = [];
    }

    throw error;
  } else {
    const blob = await res.blob();
    const fileName = res.headers.get("x-file-name");
    return { blob, fileName };
  }
};

export const getS3Blob = async (url: string): Promise<Blob> => {
  const res = await fetch(url);
  return res.blob();
};

// responseCallback should only be overwritten in unusual cases that don't follow common API flows
// e.g. getMe is called from an unauthenticated context and skips the 401 check.
export const getJson = async (
  endpoint: string,
  responseCallback: (res: Response) => any = handleResponse,
): Promise<any> => {
  if (!("fetch" in window)) {
    return [];
  }

  return fetch(`${API_ENDPOINT}${endpoint}`, {
    headers: await getRequestHeaders(),
  })
    .then(responseCallback);
};

export const postForm = async (endpoint: string, data: FormData) =>
  fetch(`${API_ENDPOINT}${endpoint}`, {
    method: "POST",
    body: data,
    headers: await getRequestHeaders(),
  }).then(handleResponse);

export const postJson = async (endpoint: string, body: any) =>
  fetch(`${API_ENDPOINT}${endpoint}`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      ...await getRequestHeaders(),
    },
  }).then(handleResponse);

export const putJson = async (endpoint: string, body: any) =>
  fetch(`${API_ENDPOINT}${endpoint}`, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      ...await getRequestHeaders(),
    },
  }).then(handleResponse);

export const deleteRequest = async (endpoint: string) =>
  fetch(`${API_ENDPOINT}${endpoint}`, {
    method: "DELETE",
    headers: await getRequestHeaders(),
  }).then(handleResponse);

export default getJson;
