import { FormikErrors } from "formik";
import { ValidationError, ValidationErrorItem } from "joi";
import { FetchError } from "../types/fetch-error";
import { useTranslation } from "react-i18next";

export type MakeMessageFunction = (item: ValidationErrorItem) => string | undefined;

const USE_NAME = [
  "string.regex.name",
  "any.custom",
];

export const useQuickMakeMessage = (): MakeMessageFunction => {
  const { t } = useTranslation();

  return (item: ValidationErrorItem) => {
    const context = item.context || {};
    const { name, label } = item.context || {};
    const field = label ? t(label, { ...item.context }) : t("formErrors.unknownField");
    const params: any = { ...item.context, field };
    let type = "";

    if (context?.key === "otp" && context?.value.length !== 6) {
      type = "string.regex.name.otp";
    } else {
      type = USE_NAME.includes(item.type) && name
        ? `${item.type}.${name}`
        : item.type;
    }

    if (typeof params.limit === "number" && params.count === undefined) {
      params.count = params.limit;
    }

    return `${t(`formErrors.${type}`, params)}\n`;
  };
};

const getFormikErrors = <T extends any>(
  error?: FetchError | ValidationError,
  makeMessage?: MakeMessageFunction,
): FormikErrors<T> => {
  if (!error?.details) {
    return {};
  }

  const result: FormikErrors<T> = {};

  for (const item of error.details) {
    const message = makeMessage ? makeMessage(item) : item.message;

    if (message && item.path?.[0] !== undefined) {
      let ref: any = result;

      for (let i = 0; i < item.path.length - 1; i++) {
        const prop = item.path[i];
        if (!ref[prop]) {
          ref[prop] = typeof item.path[i + 1] === "number" ? [] : {};
        }
        ref = ref[prop];
      }

      ref[item.path[item.path.length - 1]] = message;
    }
  }

  return result;
};

export default getFormikErrors;
