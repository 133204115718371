import Permission from "../types/permission";
import User from "../types/user";

export type AndPermission = Permission[];
export type OrPermission = (Permission | AndPermission)[];

export interface UserLike {
  persona: {
    permissions: string[];
  }
}

/**
 * It checks whether current user have that given permissions
 */
const hasPermission = (...permissions: OrPermission) => (user?: User | UserLike) => {
  if (!user) {
    return false;
  }

  if (!permissions.length) {
    return true;
  }

  const userPermissions = user.persona?.permissions ?? [];

  for (const entry of permissions) {
    const andPermissions = Array.isArray(entry) ? entry : [entry];
    if (andPermissions.every(({ id }) => userPermissions.includes(id))) {
      return true;
    }
  }

  return false;
};

export default hasPermission;
