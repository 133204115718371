import { CartItem } from "../../../types/order";
import { formatCurrency } from "../../../shared/currency-utils";

export const formatPricePerMonth = (item?: CartItem) => {
  if (!item) {
    return "-";
  }

  const {
    bundleCurrency,
    bundleCurrencyOrdered = bundleCurrency,
    bundlePricePerMonth,
    bundlePricePerMonthOrdered = bundlePricePerMonth,
  } = item;

  return formatCurrency(bundlePricePerMonthOrdered, bundleCurrencyOrdered);
};

export const calculateTotalPerMonth = (shoppingCart: CartItem[]) => {
  const firstItem = shoppingCart[0];

  if (!firstItem) {
    return "-";
  }

  const total = shoppingCart.reduce<number>(
    (v, { bundlePricePerMonth, bundlePricePerMonthOrdered = bundlePricePerMonth, quantity }) =>
      v + (bundlePricePerMonthOrdered * quantity),
    0,
  );

  const { bundleCurrency, bundleCurrencyOrdered = bundleCurrency } = firstItem;

  return formatCurrency(total, bundleCurrencyOrdered);
};

export const calculateTotalPerTerm = (shoppingCart: CartItem[]) => {
  const firstItem = shoppingCart[0];

  if (!firstItem) {
    return "-";
  }

  const total = shoppingCart.reduce<number>(
    (v, { bundlePricePerMonth, bundlePricePerMonthOrdered = bundlePricePerMonth, bundleTermLength, quantity }) =>
      v + (bundlePricePerMonthOrdered * quantity * bundleTermLength),
    0,
  );

  const { bundleCurrency, bundleCurrencyOrdered = bundleCurrency } = firstItem;

  return formatCurrency(total, bundleCurrencyOrdered);
};
