import React, { useMemo } from "react";
import { Field, Formik, FormikHelpers as FormikActions } from "formik";
import styled from "styled-components";
import { useCaseHardwareParams, useCaseContactInfoParams } from "../../../redux/case-request-hooks";
import AutoSubmit from "../../../components/forms/auto-submit";
import { RootActionTypes } from "../../../redux/root-action";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import {
  changeCaseRequestDevice,
  setCaseRequestServiceLocation,
  changCaseRequestCountry,
} from "../../../redux/case-request-actions";
import CaseRequestModalReverseButton from "./case-request-modal-reverse-button";
import CaseRequestModalForwardButton from "./case-request-modal-forward-button";
import CaseRequestModalButtonWrapper from "./case-request-modal-button-wrapper";
import { useTranslation } from "react-i18next";
import TextBox from "../../../components/forms/text-box";
import Select from "../../../components/forms/select";
import { countries } from "@mssi/pssp-shared";

const StyleError = styled.div`
  color: ${(props) => props.theme.colors.red};
  margin-top: 10px;
  @media ${(props) => props.theme.breakpoints.laptop} {
    position: relative;
    left: 180px;
  }
`;

const CreateNew = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  padding: 20px;
`;

const Title = styled.div`
  padding-bottom: 15px;
  font-weight: bold;
  font-size: 15px;
`;

interface CaseRequestHardwareForm {
  productNumber: string;
  serialNumber: string;

  createLocation: boolean;

  locationId: string;
  locationName: string;
  name1: string;
  name2: string;
  line1: string;
  line2: string;
  city: string;
  region: string;
  postalCode: string;
  country: string;
}

const CaseRequestHardwareFallbackStep: React.FC = () => {
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();
  const { t } = useTranslation();
  const { serialNumber, productNumber, contact, serviceLocation, deviceState } = useCaseHardwareParams();
  const { country } = useCaseContactInfoParams();

  const initialValues: CaseRequestHardwareForm = useMemo(
    () => ({
      productNumber: productNumber ?? "",
      serialNumber: serialNumber ?? "",
      createLocation: true,
      locationName: serviceLocation?.locationName || serviceLocation?.address?.name1 || "",
      locationId: serviceLocation?.locationId ?? "",
      name1: serviceLocation?.address?.name1 ?? "",
      name2: serviceLocation?.address?.name2 ?? "",
      line1: serviceLocation?.address?.line1 ?? "",
      line2: serviceLocation?.address?.line2 ?? "",
      city: serviceLocation?.address?.city ?? "",
      region: serviceLocation?.address?.region ?? "",
      postalCode: serviceLocation?.address?.postalCode ?? "",
      country: serviceLocation?.address?.country ?? "",
    }),
    [productNumber, serialNumber, serviceLocation],
  );

  const customLocationEntered = !!(
    serviceLocation?.address?.name1
    && serviceLocation?.address?.line1
    && serviceLocation?.address?.city
    && serviceLocation?.address?.postalCode
    && serviceLocation?.address?.country
  );

  const canContinue = !!(
    productNumber
    && serialNumber
    && customLocationEntered
  );

  const onSubmit = async (values: CaseRequestHardwareForm, formikActions: FormikActions<CaseRequestHardwareForm>) => {
    dispatch(changCaseRequestCountry(values.country));

    if (values.serialNumber !== serialNumber || values.productNumber !== productNumber) {
      // dump location when changing device - but do it second; device reload responds to both events anyway
      dispatch(changeCaseRequestDevice(undefined, values.serialNumber, values.productNumber));
      dispatch(setCaseRequestServiceLocation({}));
    }

    if (values.createLocation) {
      dispatch(setCaseRequestServiceLocation({
        locationName: values.name1,
        locationId: "",
        address: {
          name1: values.name1,
          name2: values.name2,
          line1: values.line1,
          line2: values.line2,
          city: values.city,
          region: values.region,
          postalCode: values.postalCode,
          country: values.country,
        },
      }));
    } else {
      dispatch(setCaseRequestServiceLocation({
        locationName: values.locationName,
        locationId: values.locationId,
        address: {
          name1: values.name1,
          name2: values.name2,
          line1: values.line1,
          line2: values.line2,
          city: values.city,
          region: values.region,
          postalCode: values.postalCode,
          country: values.country,
        },
      }));
    }

    formikActions.resetForm({ values });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, submitForm }) => (
          <>
            <AutoSubmit values={values} submitForm={submitForm} />

            <Field
              label={t("serialnumber")}
              name="serialNumber"
              placeholder={t("serialnumber")}
              component={TextBox}
              value={values.serialNumber}
              required
            />

            <Field
              label={t("support.caseRequest.model")}
              name="productNumber"
              placeholder={t("support.caseRequest.model")}
              component={TextBox}
              value={values.productNumber}
              required
            />

            <CreateNew>
              <Title>{t("enterlocation")}</Title>
              <Field
                label={t("locationName")}
                name="name1"
                placeholder={t("locationName.placeholder")}
                value={values.name1}
                component={TextBox}
                required
              />
              <Field
                label={t("street")}
                name="line1"
                placeholder={t("street.placeholder")}
                value={values.line1}
                component={TextBox}
                required
              />
              <Field
                label={t("city")}
                name="city"
                placeholder={t("city.placeholder")}
                value={values.city}
                component={TextBox}
                required
              />
              <Field
                label={t("state/province")}
                name="region"
                placeholder={t("state/province.placeholder")}
                value={values.region}
                component={TextBox}
              />
              <Field
                label={t("postalcode")}
                name="postalCode"
                placeholder={t("postalcode.placeholder")}
                value={values.postalCode}
                component={TextBox}
                required
              />
              <Field
                label={t("orderdetails.country")}
                name="country"
                values={country ? [country] : []}
                options={countries.map(({ id, country }) => ({ value: id, label: country }))}
                component={Select}
                required
                showInputSearch={true}
                placeholder={t("createcontactcountry")}
              />
            </CreateNew>
          </>
        )}
      </Formik>
      {contact?.contactId && (contact.firstName || contact.lastName) && (
        <p>
          {t("customerdetails.contact")}: {contact.firstName} {contact.lastName}
        </p>
      )}
      {deviceState === "Retired" && <StyleError>{t("support.caseRequest.retired.error")}</StyleError>}
      <CaseRequestModalButtonWrapper>
        <CaseRequestModalReverseButton />
        <CaseRequestModalForwardButton canContinue={canContinue} />
      </CaseRequestModalButtonWrapper>
    </>
  );
};

export default CaseRequestHardwareFallbackStep;
