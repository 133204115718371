import React, { Dispatch, useMemo } from "react";
import { Formik, FormikHelpers as FormikActions } from "formik";
import { useTranslation } from "react-i18next";
import { useIssueType } from "../../../redux/case-request-hooks";
import { FormField, RadioButtons, RadioButton } from "@veneer/core";
import styled from "styled-components";
import { RootActionTypes } from "redux/root-action";
import { useDispatch } from "react-redux";
import { changeContractIssueType } from "../../../redux/case-request-actions";
import CaseRequestModalReverseButton from "./case-request-modal-reverse-button";
import CaseRequestModalForwardButton from "./case-request-modal-forward-button";
import CaseRequestModalButtonWrapper from "./case-request-modal-button-wrapper";
import useContracts from "api/hooks/use-contracts";
import { useCurrentCustomerID } from "redux/current-customer-hooks";
import BlockSpinner from "components/block-spinner";


const RadioButtonHeaders = styled.div`
  margin-top: 10px;
`;

const RadiogroupWrapper = styled.div`
  margin: 10px;
`;

interface CaseRequestIssueType {
  issueType: any;
}

const CaseRequestContractSelection: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();
  const customerId = useCurrentCustomerID();
  const { issueType } = useIssueType();
  const { contracts, loading: loadingContract } = useContracts({ customerId });

  const canSubmitCatalogSupportCases = useMemo(() => (contracts || []).some((it) => it.catalog), [contracts]);

  const initialValues: CaseRequestIssueType = useMemo(() => ({ issueType: issueType ?? "" }), [issueType]);

  const options = [
    t("caserequest.issueType.contract.inquires"),
    t("caserequest.issueType.catalog.issue1"),
    t("caserequest.issueType.catalog.issue2"),
    t("caserequest.issueType.catalog.issue3"),
  ];

  const onSubmit = async (values: CaseRequestIssueType, formikActions: FormikActions<CaseRequestIssueType>) => {
    if (values.issueType === issueType) {
      dispatch(changeContractIssueType(values.issueType));
    }
    formikActions.resetForm({ values });
  };

  if (loadingContract) {
    return <BlockSpinner />;
  }
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {() => (
          <FormField>
            {!loadingContract &&
              <>
                <h5>{t("caserequest.step.issueType.title")}</h5>
                <RadioButtonHeaders>
                  <p>{t("caserequest.step.issueType.contarct")}</p>
                </RadioButtonHeaders>
                <RadiogroupWrapper>
                  <RadioButtons
                    alignment="vertical"
                    name="issueType"
                    selectedIndex={issueType?.index}
                    onChange={(value: number) => {
                    dispatch(changeContractIssueType({ index: value, type: options[value] }));
                  }}
                  >
                    <RadioButton index={0} label={options[0]} />
                  </RadioButtons>
                </RadiogroupWrapper>
                { canSubmitCatalogSupportCases ?
                  <>
                    <RadioButtonHeaders>
                      <p>{t("caserequest.step.issueType.catalog")}</p>
                    </RadioButtonHeaders>
                    <RadiogroupWrapper>
                      <RadioButtons
                        alignment="vertical"
                        name="issueType"
                        selectedIndex={issueType && (issueType.index - 1)}
                        onChange={(value: number) => {
                          value += 1;
                          dispatch(changeContractIssueType({ index: value, type: options[value] }));
                        }}
                      >
                        <RadioButton index={1} label={options[1]} />
                        <RadioButton index={2} label={options[2]} />
                        <RadioButton index={3} label={options[3]} />
                      </RadioButtons>
                    </RadiogroupWrapper>
                  </> : <></>}
              </> }
          </FormField>
        )}
      </Formik>
      <CaseRequestModalButtonWrapper>
        <CaseRequestModalReverseButton />
        <CaseRequestModalForwardButton canContinue={!!(issueType && (issueType?.index < 4))} />
      </CaseRequestModalButtonWrapper>
    </>
  );
};

export default CaseRequestContractSelection;
