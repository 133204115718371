import React, { useEffect } from "react";
import useCachedObjectMemo from "../../api/hooks/use-cached-object-memo";
import { useDebounce } from "use-debounce";

export interface Props<T> {
  values: T;
  submitForm: () => void;
}

const AutoSubmit = <T extends any>({ values, submitForm }: Props<T>): React.ReactElement => {
  const valuesMemo: T = useCachedObjectMemo(values);
  const [debouncedValues] = useDebounce(valuesMemo, 250);

  useEffect(
    () => {
      if (debouncedValues === undefined || typeof submitForm !== "function") {
        return;
      }

      submitForm();
    },
    [submitForm, debouncedValues],
  );

  return <></>;
};

export default AutoSubmit;
