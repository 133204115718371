import { Button, ListItemLeftSection, ListItemRightSection } from "@veneer/core";

import { Dispatch } from "redux";
import React from "react";
import { RootActionTypes } from "../redux/root-action";
import { changeCurrentCustomer } from "../redux/current-customer-actions";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const Wrapper = styled.span`
  font-size: 15px;
`;

const StyledButton = styled(Button)`
@media ${(props) => props.theme.breakpoints.mobileMaximum} {
  text-align: start;
}
`;

interface CustomerSelectionProps {
customer: any;
onClose?: () => void;
onSelect?: (id: string) => void;
}

export const CustomerSelection: React.FC<CustomerSelectionProps> = ({ customer, onSelect, onClose }) => {
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();
  const { t } = useTranslation();
  const changeCustomerWrapper = (customerId: string) => {
    dispatch(changeCurrentCustomer(customerId, true));

    if (onSelect) {
      onSelect(customerId);
    }

    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <ListItemLeftSection>
        <h6>{customer.itsmCustomerName || customer.name}</h6>
        <Wrapper> {customer.contracts || 0}</Wrapper>
        <Wrapper> contract(s) </Wrapper>
      </ListItemLeftSection>
      <ListItemRightSection>
        <StyledButton type="button" onClick={() => changeCustomerWrapper(customer.id)}>
          {t("customer.switch")}
        </StyledButton>
      </ListItemRightSection>
    </>
  );
};
