import { Resource, ResourceLanguage } from "i18next";
import en from "./public/locales/en/translation.json";
import zh from "./public/locales/zh/translation.json";
import da from "./public/locales/da/translation.json";
import nl from "./public/locales/nl/translation.json";
import fi from "./public/locales/fi/translation.json";
import fr from "./public/locales/fr/translation.json";
import de from "./public/locales/de/translation.json";
import it from "./public/locales/it/translation.json";
import ja from "./public/locales/ja/translation.json";
import nbNO from "./public/locales/nb-NO/translation.json";
import ptBR from "./public/locales/pt-BR/translation.json";
import ptPT from "./public/locales/pt-PT/translation.json";
import es from "./public/locales/es/translation.json";
import sv from "./public/locales/sv/translation.json";

export const languages = [
  "en",
  "zh",
  "da",
  "nl",
  "fi",
  "fr",
  "de",
  "it",
  "ja",
  "nb-NO",
  "pt-BR",
  "pt-PT",
  "es",
  "sv",
];

export type LanguageType = string & typeof languages[number];

interface TranslationMap {
  [key: string]: ResourceLanguage;
}

const importMap: TranslationMap = { en, zh, da, nl, fi, fr, de, it, ja, nbNO, ptBR, ptPT, es, sv };

export const languageResources = languages.reduce<Resource>(
  (obj: Resource, lang: LanguageType) => {
    const importCode = lang.replace(/[^A-Z0-9]]/ig, "");
    obj[lang] = { translation: importMap[importCode] || {} };
    return obj;
  },
  {},
);
