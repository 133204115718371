/* eslint-disable */
const log = (msg: string, ...rest: any) => {
  console.log(msg, ...rest);
};

log.error = (msg: string, ...rest: any) => {
  console.error(msg, ...rest);
};

export default log;
