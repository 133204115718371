import React, { useState } from "react";
import { Form, Formik, Field } from "formik";
import { useTranslation } from "react-i18next";
import TextBox from "../components/forms/text-box";
import Button from "../components/button";
import styled from "styled-components";
import EmailUnsubscribe from "./emailUnsubscribe";

const UserMessage = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
`;

const EmailSubmitForm: React.FC = () => {
  const { t } = useTranslation();
  const [unsubscribeEmail, setUnsubscribeEmail] = useState<any>();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const initialValues = {
    emailAddress: "",
    orderNumber: "",
  };

  if (openModal) {
    return (
      <EmailUnsubscribe emailUnsubscribe={unsubscribeEmail} />
    );
  }

  return (
    <Formik initialValues={initialValues} onSubmit={(values) => { setUnsubscribeEmail(values); setOpenModal(true); }}>
      {({ isSubmitting, values }) => (
        <Form>
          <UserMessage>{t("order.caseSensitiveUserMessage")}</UserMessage>
          <Field
            label={t("contactemail")}
            name="emailAddress"
            placeholder={t("contactemail")}
            component={TextBox}
            value={values.emailAddress}
            required
            readOnly={isSubmitting}
          />
          <Button type="submit" loading={isSubmitting}>{t("btn.submit")}</Button>
        </Form>
        )}
    </Formik>
  );
};

export default EmailSubmitForm;
