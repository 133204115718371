import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useCurrentCustomerID } from "../redux/current-customer-hooks";
import { useAddError } from "../redux/toast-hooks";
import { useHistory } from "react-router";
import { getRedirectionDataByRoute } from "api/feature";

// these paths support deep linking
const paths = [
  "/contracts",
  "/orders",
  "/user-catalog",
  "/devices",
  "/bundles",
  "/reports",
  "/dashboard",
  "/get-support",
  "/support-tickets",
  "/service-requests",
];

const regExps = [
  { apiPath: "devices", regExp: /^\/device\/([^/]+)(\/.*)?$/i },
  { apiPath: "order", regExp: /^\/order\/([^/]+)(\/.*)?$/i },
  { apiPath: "contracts", regExp: /^\/contract\/([^/]+)(\/.*)?$/i },
  { apiPath: "caserequest", regExp: /^\/support-ticket\/([^/]+)(\/.*)?$/i },
  { apiPath: "servicerequest", regExp: /^\/service-request\/([^/]+)(\/.*)?$/i },
];

const processPathData = (pathname?: string) => {
  const match = regExps.find((rule) => { return pathname?.match(rule.regExp) !== null; });
  if (match) {
     const [, pathKey, pathExtras] = pathname?.match(match.regExp) ?? [undefined, undefined, undefined];
     return { pathKey, pathExtras, apiPath: match.apiPath };
  }
  return { patKey: undefined, pathExtras: undefined, apiPath: undefined };
};

const DeepLinkHelper: React.FC = () => {
  const { pathname } = useLocation();
  const customerId = useCurrentCustomerID();
  const addError = useAddError();
  const history = useHistory();

  const pathInfo = processPathData(pathname);

  useEffect(
    () => {
      if (pathInfo.pathKey && pathInfo.apiPath) {
        let redirectTo = "";
        getRedirectionDataByRoute(pathInfo.apiPath, pathInfo.pathKey)
          .then(({ customerId, redirectParam }) => {
            if (pathInfo.apiPath === "caserequest") {
              redirectTo = "support-tickets/case";
            } else if (pathInfo.apiPath === "servicerequest") {
              redirectTo = "service-requests/request";
            } else if (pathInfo.apiPath === "order") {
              redirectTo = "orders";
            } else {
              redirectTo = pathInfo.apiPath;
            }
            history.replace(`/${customerId}/${redirectTo}/${redirectParam}${pathInfo.pathExtras || ""}`);
          })
          .catch((error) => {
            addError(error.message);
          });
      }
    },
    [pathInfo.pathKey, pathInfo.apiPath, pathInfo.pathExtras, history, addError],
  );

  if (paths.includes(pathname) && customerId) {
    history.replace(`/${customerId}${pathname}`);
  }

  return null;
};

export default DeepLinkHelper;
