import { FieldProps, getIn } from "formik";
import {
  FormField,
  DatePicker as VeneerDatePicker,
} from "@veneer/core";

import React from "react";
import { useTranslation } from "react-i18next";

interface DatePickerProps extends FieldProps {
  name: string;
  alignment: string;
  label?: string;
  placeholder?: string;
  required: boolean;
  value: string;
  blockPastDates?: boolean;
  onChange?: (e: string) => void;
}

const DatePicker: React.FC<DatePickerProps> = ({
  label,
  form,
  field,
  required,
  value,
  placeholder,
  onChange,
  blockPastDates = true,
}) => {
  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);
  const hasError = error && touched;
  const { t } = useTranslation();

  const handleClear = () => {
      form.setFieldValue(field.name, null);
  };

  const handleChange = (value: string) => {
    form.setFieldValue(field.name, value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <FormField
      label={label}
      htmlFor={field.name}
      errorMessage={hasError && error}
      required={required}
    >
      <VeneerDatePicker blockPastDates={blockPastDates} value={value} placeholder={placeholder ? placeholder : t("orderdetails.selectdate")} format="l" onClear={handleClear} onChange={(value: any) => handleChange(value.toISOString())} />
    </FormField>
  );
};

export default DatePicker;
