import { Log, UserManager, UserManagerSettings, User, WebStorageStateStore } from "oidc-client";
import Cookies from "js-cookie";
import { FetchError } from "../types/fetch-error";
import getConfig from "../client-config";
import { useState } from "react";

if (getConfig("logOIDC")) {
  Log.logger = console;
  Log.level = Log.DEBUG;
}

export const LOGIN_PATHS = {
  PATH_AUTH: "/auth", // no longer used, but kept for legacy support
  PATH_SSO: "/sso", // future use
  PATH_LOGOUT: "/logout",
};

export type CognitoProvider = "HPID" | "HPEmployee" | "Cognito";

export interface Headers {
  [key: string]: string;
}

export interface InviteError extends FetchError {
  details: any;
}

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const REDIRECT_URI = `https://${window.location.host}`;
const CLIENT_ID = getConfig("cognitoClientId");
const POOL_ID = getConfig("cognitoPoolId");
const POOL_REGION = getConfig("cognitoRegion");
const COGNITO_DOMAIN = getConfig("cognitoDomain");
const HPID_URL = getConfig("hpidUrl");
const ISSUER = `https://cognito-idp.${POOL_REGION}.amazonaws.com/${POOL_ID}`;
const DISCOVERY = `https://cognito-idp-fips.${POOL_REGION}.amazonaws.com/${POOL_ID}`;
const OAUTH = `${COGNITO_DOMAIN}`;
const HPID_LOGOUT = `${HPID_URL}/directory/v1/oauth/logout?post_logout_redirect_uri=${encodeURIComponent(`https://${window.location.host}/logout`)}`;
const SILENT_URI = `${API_ENDPOINT}/heartbeat`;

const oidcConfig: UserManagerSettings = {
  client_id: CLIENT_ID,
  redirect_uri: REDIRECT_URI,
  authority: DISCOVERY,
  scope: "openid profile email",
  response_type: "code",
  silent_redirect_uri: SILENT_URI,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  metadata: {
    issuer: ISSUER,
    authorization_endpoint: `${OAUTH}/oauth2/authorize`,
    token_endpoint: `${OAUTH}/oauth2/token`,
    userinfo_endpoint: `${OAUTH}/oauth2/userInfo`,
    end_session_endpoint: `${OAUTH}/logout?client_id=${CLIENT_ID}&logout_uri=${REDIRECT_URI}`,
  },
  clockSkew: 180,
};

const userManager = new UserManager(oidcConfig);

let cachedIdToken: string | undefined;

/**
 * Use to get request headers
 */
export const getRequestHeaders = async (): Promise<Headers> => {
  const xsrfToken = Cookies.get("XSRF-TOKEN");
  const cyUser = Cookies.get("cy_user");
  const user = await userManager.getUser();
  const idToken = user?.id_token;
  cachedIdToken = idToken;

  const headers: Headers = {};

  if (xsrfToken) {
    headers["XSRF-TOKEN"] = xsrfToken;
  }

  if (cyUser) {
    headers["X-CY-USER"] = cyUser;
  }

  if (idToken) {
    headers.Authorization = `Bearer ${idToken}`;
  }

  return headers;
};

/**
 * Use for Bloodhound and cases where absolutely necessary
 */
export const getCachedRequestHeaders = (user?: User): Headers => {
  const xsrfToken = Cookies.get("XSRF-TOKEN");
  const cyUser = Cookies.get("cy_user");
  const headers: Headers = {};

  if (xsrfToken) {
    headers["XSRF-TOKEN"] = xsrfToken;
  }

  if (cyUser) {
    headers["X-CY-USER"] = cyUser;
  }

  if (user) {
    headers.Authorization = `Bearer ${user.id_token}`;
  } else if (cachedIdToken) {
    headers.Authorization = `Bearer ${cachedIdToken}`;
  }

  return headers;
};

/**
 * Convert value from (optionally url-safe) base64 to plain string.
 * If value is nullish or invalid, returns undefined.
 * @param value
 */
const tryUnBase64 = (value?: string | null): string | undefined => {
  if (value === null || value === undefined) {
    return undefined;
  }

  const expectedLength = value.length + ((4 - (value.length % 4)) % 4);

  const fixedValue = value
    .replace(/-/g, "+")
    .replace(/_/g, "/")
    .padEnd(expectedLength, "=");

  try {
    return atob(fixedValue);
  } catch {
    return undefined;
  }
};

export const redirectPath = (path: string) => {
  if (Object.values(LOGIN_PATHS).includes(path)) {
    return "/";
  }

  return path || "/";
};

export const logIn = async (provider: CognitoProvider) => {
  const windowUrl = new URL(window.location.href);
  const path = windowUrl.pathname || "/";
  const token = windowUrl.searchParams.get("token") || undefined;
  const hint = tryUnBase64(windowUrl.searchParams.get("hint")) || undefined;
  const data = JSON.stringify({ path: redirectPath(path), token });
  const params = { data, login_hint: hint, extraQueryParams: { identity_provider: provider } };

  return userManager.signinRedirect(params);
};

export const processInvite = async (inviteToken: string, user: User) => {
  await fetch(
    SILENT_URI,
    {
      method: "GET",
      headers: { ...getCachedRequestHeaders(user) },
    },
  );

  const response = await fetch(
    SILENT_URI,
    {
      method: "POST",
      headers: {
        ...getCachedRequestHeaders(user),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ inviteToken }),
    },
  );

  if (response.redirected && /\/invitation\//.test(response.url)) {
    window.location.href = response.url;
    return;
  }

  if (response.status !== 200) {
    const error = new Error(response.statusText) as InviteError;
    error.status = response.status;

    try {
      error.details = await response.json();
    } catch {
      error.details = null;
    }

    console.error(error); // todo: show user
  }
};

export const logoutCognito = async () => {
  await userManager.signoutRedirect()
    .catch((error) => {
      console.error(error);
    });
};

export const logoutHPID = () => {
  window.location.href = HPID_LOGOUT;
};

export const getLoginProvider = async (): Promise<CognitoProvider | undefined> => {
  try {
    const user = await userManager.getUser();
    return user?.profile?.username?.split("_")?.[0];
  } catch {
    return undefined;
  }
};

export const logout = async (clearCustomer: boolean = true) => {
  const provider = await getLoginProvider();
  if (provider === "HPID") {
    logoutHPID();
    return;
  }

  if (clearCustomer) {
    localStorage.removeItem("lastCustomerId");
  }

  await logoutCognito();
};

export const useJupiterAuthToken = () => {
  const cyUser = Cookies.get("cy_user");
  const [token, setToken] = useState<string | undefined>();

  userManager.getUser()
    .then((user) => {
      setToken(user?.id_token || undefined);
    })
    .catch(() => undefined);

  if (cyUser) {
    return `_CY:${cyUser}`;
  }

  return token;
};

export default userManager;
