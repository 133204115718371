import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "@veneer/core";
import styled from "styled-components";
import NoticeBoard from "types/notice-board";
import dates from "../../../shared/dates";
import MessageAttachment from "./message-attachment";
import Button from "components/button";

interface Props {
  message: NoticeBoard;
  onClose(): void;
  show: boolean;
}

const DateTime = styled.div`
  font-size: 12px;
`;

const MainMessage = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
`;

const DetailedMessage = styled.div`
  margin-bottom: 10px;
  @media ${(props) => props.theme.breakpoints.tablet} {
    max-height: calc(100vh - 430px);
    overflow-y: auto;
  }
`;

const DetailedMessageModal: React.FC<Props> = ({ message, show, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal size="lg" show={show} onClose={onClose} dismissOnOverlayClick={false}>
      <DateTime>{t("posted")} {dates.format(message.createdDate ? message.createdDate : "", dates.formats.stringFormat)}
        {t(" ")}{t("at")}{t(" ")}
        {dates.format(message.createdDate ? message.createdDate : "", dates.formats.timeOnly)}
      </DateTime>
      {message.expiryDate && (
        <DateTime>{t("Expires")} {dates.format(message.expiryDate ? message.expiryDate : "", dates.formats.stringFormat)}
        </DateTime>
      )}
      <MainMessage>{message.mainMessage}</MainMessage>
      <DetailedMessage>{t("detailed.message")} : {message.detailedMessage}</DetailedMessage>
      <MessageAttachment message={message} />
      <Button onClick={onClose}>{t("customer.setup.close.btn")}</Button>
    </Modal>
  );
};

export default DetailedMessageModal;
