import merge from "deepmerge";
import clone from "clone-deep";

import baseConfig from "./default";
import LOCALDEV from "./localdev";
import TEST from "./test";
import DEV from "./dev";
import PIE from "./pie";
import ITG from "./itg";
import PRO from "./pro";
import { useMemo } from "react";

const environmentMapping: any = {
  LOCALDEV,
  TEST,
  DEV,
  PIE,
  ITG,
  PRO,
};

const currentEnvironment = `${process.env.REACT_APP_CONFIG_ENV || "LOCALDEV"}`.toUpperCase();
const envConfig = environmentMapping[currentEnvironment] || {};
const mergedEnv: any = merge(baseConfig, envConfig);

export default function getConfig(key?: string): any {
  let env = clone(mergedEnv);

  if (!key) {
    return env;
  }

  const tokens = key.split(".");

  for (const token of tokens) {
    if (env !== undefined) {
      env = env[token];
    }
  }

  return env;
}

export function useConfig(key?: string): any {
  return useMemo(() => getConfig(key), [key]);
}

if (getConfig("exposeConfigDebugMethod")) {
  (window as any).getConfig = getConfig;
}
