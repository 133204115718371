import React, { useState, useMemo, useEffect } from "react";
import { Icon, Select, Toggle, Tooltip } from "@veneer/core";
import { geoRegions, countries, makeRegExp } from "@mssi/pssp-shared";
import ProfilePreferenceSelect from "./profile-preference-select";
import lookupLanguages from "../../shared/language-select";
import { useTranslation } from "react-i18next";
import { useAuth, useFeature } from "redux/auth-hooks";
import Panel from "components/panel";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { RootActionTypes } from "../../redux/root-action";
import { asyncUpdatePreference } from "../../redux/auth-thunks";
import useLocalizationCodeList from "../../hooks/use-localization-code-list";
import PrivacyNote from "../privacy-note";
import { SelectOption } from "shared/map";
import _ from "lodash";
import useIsDevice from "../../shared/isDevice";
import deviceType from "../../shared/devices";

const ToggleWrapper = styled.div`
  display: flex;
  margin: 15px 0px;
`;

const ToggleTitle = styled.div`
  margin-left: 25px;
`;

const Color = styled.div`
  background-color: ${props => props.theme.colors.bgV1};
`;

const EmailWrapper = styled.div`
    display: flex;
    .vn-tooltip {
      position: relative !important;
      bottom: 2px;
      left: 4px;
    }
    .vn-tooltip__content {
      width: 185px;
    }
`;

const Component = styled.div`
  display: flex;
  flex-direction: row;
  @media ${(props) => props.theme.breakpoints.tabletMaximum} {
    flex-direction: column;
  }
`;

const Label = styled.div`
  width: 50%;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 10px 5px 10px;
  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 150px;
    padding: 30px 20px 20px 20px;
    margin-bottom: 0px;
    border-right: 3px solid ${props => props.theme.colors.background};
  }
`;

const Value = styled.div`
  flex: 1;
  width: auto;
  padding: 10px 10px 5px 10px;
  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 20px 20px 20px 75px;
  }
`;


const Container = styled.div`
  margin-bottom: 20px;
  position: relative;

  .vn-options-list {
    overflow-y: auto;
    max-height: 180px !important;
  }

  svg.vn-spinner {
    position: absolute;
    bottom: 5px;
    left: 50%;
    margin-left: -16px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  .vn-tooltip {
    position: relative !important;
    bottom: 2px;
    left: 4px;
  }
  .vn-tooltip__content {
    width: 185px;
  }
`;

const SelectContainer = styled.div`
  display: flex;
`;

const SelectWrapper = styled.div`
  width: 382px;

  .vn-options-list {
    min-height: 220px;
    max-height: 220px !important;
  }

  .vn-option {
    height: fit-content;
  }

  .vn-option__multiple a {
    padding-left: unset;
  }
`;

const ProfilePreferences: React.FC = () => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();
  const languagePreferenceUserProfile = useFeature<boolean>("languagePreferenceUserProfile");
  const { loading, canSetLocalizationCode, localizationCodeList } = useLocalizationCodeList();
  const [emailRegionsDropdownSearch, setEmailRegionsDropdownSearch] = useState<string>("");
  const [emailRegions, setEmailRegions] = useState<string[]>(currentUser.emailRegions || []);
  const [emailRegionsDisabled, setEmailRegionsDisabled] = useState<boolean>(false);
  const [emailRegionsChanged, setEmailRegionsChanged] = useState<boolean>(false);
  const isMobileOrTablet = useIsDevice(deviceType.MOBILE, deviceType.MOBILELARGE, deviceType.TABLET);

  const emailRegionsOptions: SelectOption[] = useMemo(() => {
    if (!geoRegions.length || !countries.length) {
      return [];
    }

    const geoRegionsOptions = geoRegions.map((geoRegion) => ({
      label: geoRegion,
      value: geoRegion,
    }));

    const countriesOptions = countries.map(({ country }) => ({
      label: country,
      value: country,
    }));

    const search = emailRegionsDropdownSearch ? makeRegExp(emailRegionsDropdownSearch) : undefined;

    return [...geoRegionsOptions, ...countriesOptions].filter((option) => !search || search.test(option.label));
  }, [emailRegionsDropdownSearch]);

  useEffect(
    () => {
      setEmailRegionsDisabled(false);
    },
    [currentUser],
  );

  const handleEmailRegionsChange = _.debounce((option: SelectOption) => {
    setEmailRegionsDisabled(true);
    setEmailRegionsChanged(true);

    const newEmailRegions = [...emailRegions];
    const index = newEmailRegions.indexOf(option.value);
    if (index > -1) {
      newEmailRegions.splice(index, 1);
    } else {
      newEmailRegions.push(option.value);
    }
    setEmailRegions(newEmailRegions);

    dispatch(asyncUpdatePreference("emailRegions", newEmailRegions) as any);

    setTimeout(() => {
      setEmailRegionsChanged(false);
    }, 20000);
  }, 200);

  const handleEmailRegionsClear = () => {
    setEmailRegionsDisabled(true);
    setEmailRegionsChanged(true);

    const newEmailRegions: string[] = [];
    setEmailRegions(newEmailRegions);

    dispatch(asyncUpdatePreference("emailRegions", newEmailRegions) as any);

    setTimeout(() => {
      setEmailRegionsChanged(false);
    }, 20000);
  };

  return (
    <Panel isRequiredUpdatedPanel={true} title={t("preferences")}>
      <Component>
        <Label>{t("field.email")}</Label>
        {
          <Value>
            <ToggleWrapper>
              <Toggle
                on={currentUser.preferences.sendEmails}
                onChange={(email: boolean) =>
                      dispatch(asyncUpdatePreference("sendEmails", email) as any)}
                disabled={loading}
              />
              <EmailWrapper data-cy="email">
                <ToggleTitle>{t("userpref.emailnote")}</ToggleTitle>
                {!isMobileOrTablet && (
                <Tooltip
                  content={t("userpref.emailtooltip")}
                  contentHideDelayOnHover={0}
                  position="left"
                >
                  <Icon name="information" />
                </Tooltip>
                    )}
              </EmailWrapper>
            </ToggleWrapper>
          </Value>
        }
      </Component>
      {languagePreferenceUserProfile && (
        <Color>
          <Component>
            <Label>{t("userpref.language")}</Label>
            <Value>
              <ProfilePreferenceSelect
                label={t("preferences.preferredlang")}
                preference="language"
                choices={lookupLanguages}
                placeholder={t("preferences.selectlang")}
                disabled={loading}
              />
            </Value>
          </Component>
        </Color>
      )}
      {canSetLocalizationCode && (
        <Component>
          <Label>{t("skulocalization.label")}</Label>
          <Value>
            <ProfilePreferenceSelect
              label={t("userpref.localization")}
              preference="defaultLocalizationCode"
              choices={localizationCodeList}
              placeholder={t("skulocalization.placeholder")}
              disabled={loading}
              tooltipContent={t("userpref.localizationtooltip")}
            />
          </Value>
        </Component>
      )}
      {currentUser.persona?.isInternal && (
        <Color>
          <Component>
            <Label>{t("user.addUser.emailRegions")}</Label>
            <Value>
              <Container>
                <Wrapper data-cy="localization">
                  <label htmlFor="pref_emailRegions">
                    {t("user.addUser.preferredEmailRegions")}
                  </label>
                </Wrapper>
                <SelectContainer>
                  <SelectWrapper>
                    <Select
                      id="pref_emailRegions"
                      name="emailRegions"
                      placeholder={t("user.addUser.selectEmailRegions")}
                      showSearch
                      multiple
                      options={emailRegionsOptions}
                      value={emailRegions}
                      disabled={emailRegionsDisabled || loading}
                      onSearch={(searchText: string) => {
                        setEmailRegionsDropdownSearch(searchText);
                      }}
                      onChange={handleEmailRegionsChange}
                      onClear={handleEmailRegionsClear}
                      optionsOrientation={["up"]}
                    />
                  </SelectWrapper>
                  {!emailRegionsDisabled && !loading && emailRegionsChanged && <Icon name="enabled" size={32} color="green" />}
                </SelectContainer>
              </Container>
            </Value>
          </Component>
        </Color>
      )}
      <PrivacyNote />
    </Panel>
  );
};

export default ProfilePreferences;
