import styled from "styled-components";
import { Panel } from "@veneer/core";

const StyledPanel = styled(Panel)`
  padding: 20px;
  max-width: 720px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
`;

export default StyledPanel;
