import queryString from "query-string";
import { SDMStatus } from "@mssi/pssp-database";
import { getJson, postJson } from "./request";
import { SdmSignedUrlResponse, SdmUploadStatus } from "../types/upload";
import download from "../shared/download";

export const getSdmUploadUrl = (file: File): Promise<SdmSignedUrlResponse> =>
  postJson("/sdm/upload-url", { fileName: file.name, contentType: file.type, contentLength: file.size });

export const uploadSdmDocument = async (signedUrl: string, file: File): Promise<void> => {
  await fetch(signedUrl, {
    method: "PUT",
    headers: {
      "Content-Type": file.type,
      "Content-Length": `${file.size}`,
    },
    body: file,
  });
};

export const getSdmDocumentStatus = (id: string): Promise<SdmUploadStatus> =>
  getJson(`/sdm/document-status?${queryString.stringify({ id })}`);

export type DownloadFunction = (id: string) => Promise<void>;

export const downloadSdmDocument: DownloadFunction = async (id: string): Promise<void> => {
  const sdmData = await getJson(`/sdm/get-document?${queryString.stringify({ id })}`)
    .catch(() => undefined);

  if (sdmData?.status !== SDMStatus.valid || !sdmData?.url) {
    return;
  }

  const blob = await (await fetch(sdmData.url)).blob();
  download.blob(blob, sdmData.fileName || "file");
};
