import React from "react";
import { Modal } from "@veneer/core";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "redux/auth-hooks";
import DaasTilesPopup from "components/daas-tiles-popup";

interface ToolsProps {
  setShowPopup(state: boolean): void;
}

const ModalWrapper = styled.div`
  @media ${(props) => `${props.theme.breakpoints.tablet} and ${props.theme.breakpoints.tabletMaximum}`} {
    .vn-modal__content {
      padding: 10px;
    }
    .vn-modal__header {
      margin-left: 20px;
    }
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    .vn-modal {
      background-color: rgba(0, 0, 0, 0);
      overflow-y: hidden;
    }

    .vn-modal__close {
      display: none;
    }
    .vn-modal__dialog {
      position: absolute;
      top: 30px;
      right: 80px;
      width: 25%;
      justify-content: right;
    }

    .vn-modal__content {
      box-shadow: 0 5px 15px rgba(102, 102, 102, 0.5);
      padding: 0;
      margin: 0;
      max-height: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: hidden;
      align-items: stretch;
      
      > div {
        width: 100%;
        overflow-x: hidden;
      }
    }

    .vn-modal__header {
      h4 {
        font-weight: bold;
        font-size: 0.9rem;
        color: ${(props) => props.theme.colors.textColor3};
        padding: 0.75rem 1rem;
      }

      background-color: ${(props) => props.theme.colors.background3};
      margin-bottom: 0;
      min-height: 41px;
      height: 41px;
    }

    .vn-modal__body {
      padding: 10px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: initial;
      }
      flex: 2 auto;
    }

    .vn-modal__footer {
      margin-top: 0;
      flex: 1 auto;
    }

    .vn-panel__content {
      padding: 0px 20px 15px 7px !important;
    }

    .vn-panel__header {
      border-bottom: none;
    }
  }
`;

const Footer = styled.div`
  padding-top: 19px;
  text-align: right;
  margin-right: 25px;
    button {
      width: 100%;
    }
`;

const StyleTileGrids = styled.div`
  margin-top: 15px;
  height: 265px;
  overflow-y: scroll;
`;

const ToolsAndEnables: React.FC<ToolsProps> = ({ setShowPopup }) => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();

  return (
    <>
      <ModalWrapper>
        <Modal
          align="left"
          size="sm"
          title={t("toolsandenablers")}
          show
          onClose={() => setShowPopup(false)}
          dismissOnOverlayClick={true}
        >
          <StyleTileGrids>
            {currentUser.persona &&
              <DaasTilesPopup permissions={currentUser.persona.permissions} />}
          </StyleTileGrids>
          <Footer>
            <Link data-cy="goToToolsPage" to="/tools">{t("tools.linkMsg")}</Link>
          </Footer>
        </Modal>
      </ModalWrapper>
    </>
  );
};

export default ToolsAndEnables;
