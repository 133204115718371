import React from "react";
import { ToastContainer, ToastNotification } from "@veneer/core";
import { useToasts } from "../redux/toast-hooks";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { RootActionTypes } from "../redux/root-action";
import { removeToast } from "../redux/toast-actions";
import styled from "styled-components";

const ToastWrapper = styled.div`
  .vn-toast {
    padding-right: 20px;
  }
`;

const Toasts: React.FC = () => {
  const { t } = useTranslation();
  const toasts = useToasts();
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();

  const close = (id: number) => {
    dispatch(removeToast(id));
  };

  return (
    <ToastWrapper>
      <ToastContainer position="top" newOnTop>
        {toasts.map(({ id, message, toastType }) => (
          <ToastNotification
            id={id}
            key={id}
            i18n={{ close: t("close") }}
            text={typeof message === "string" ? message : `${message || ""}`}
            timeout={10}
            type={toastType}
            onClose={() => close(id)}
          />
        ))}
      </ToastContainer>
    </ToastWrapper>
  );
};

export default Toasts;
