import React, { useMemo } from "react";
import { Icon, ProgressBar } from "@veneer/core";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import useIsDevice from "shared/isDevice";
import deviceType from "shared/devices";
import { OrderStatus } from "types/order";
import OrderStatusTrackerItem from "./order-status-tracker-item";
import StatusTrackerAdvanced, {
  StatusTrackerItem,
  StatusTrackerState,
} from "../../../components/status-tracker-advanced";
import dates from "shared/dates";
import { GuestOrderStatus, GuestOrderStatusItem } from "../../order-status/types/guest-order-status";

const OrderStatusPanel = styled.div`
  padding: 20px 25px;
`;

const StyleSummaryBlock = styled.div`
  padding: 0px 25px;
  display: flex;
  justify-content: flex-end;
`;

const ShippingSummaryBlock = styled.div`
  order: 2;

  background-color: ${(props) => props.theme.colors.border};
  width: 200px;

  .vn-progress-bar__percentage {
    visibility: hidden;
    height: 0px;
  }
  
  > span {
    display: block;
    margin: 20px 20px 0 20px;
    font-weight: bold;

    > svg {
      position: relative;
      float: right;
      top: -6px;
    }
  }

  > div {
    display: block;
    margin: 4px 20px 20px 20px;
  }

  @media ${(props) => props.theme.breakpoints.tabletMaximum} {
    order: 1;
    width: 100%;
  }
`;

interface Props {
  order: GuestOrderStatus;
}

const getAcceptedDate = (order: GuestOrderStatus): string | undefined => {
  if (!order.items?.length || !order.orderOriginationDate) {
    return undefined;
  }

  return dates.formatUTC(order.orderOriginationDate, "YYYY-MM-DD");
};

const getShippedDate = (order: GuestOrderStatus): string | undefined => {
  if (!order.items?.length || !order.shipDate) {
    return undefined;
  }

  return dates.formatUTC(order.shipDate, "YYYY-MM-DD");
};

const getDeliveredDate = (order: GuestOrderStatus): string | undefined => {
  if (!order.items?.length || !order.deliveryDate) {
    return undefined;
  }

  return dates.formatUTC(order.deliveryDate, "YYYY-MM-DD");
};

const getShipmentGroupQuantity = (sg: GuestOrderStatusItem): number => {
  let total = 0;

  for (const p of sg.products) {
    total += p.quantity;
  }

  return total;
};

const getStep2State = (status: OrderStatus): { state: StatusTrackerState; barState: StatusTrackerState } => {
  switch (status) {
    case OrderStatus.AWAITING_CANCELLATION:
      return { state: StatusTrackerState.Pending, barState: StatusTrackerState.Active };
    case OrderStatus.CANCELLED:
    case OrderStatus.REJECTED:
      return { state: StatusTrackerState.Cancelled, barState: StatusTrackerState.Cancelled };
    default:
      return { state: StatusTrackerState.Normal, barState: StatusTrackerState.Normal };
  }
};

const isShipped = (group: GuestOrderStatusItem) => /^(?:shipped|delivered)$/i.test(group.status || "");
const isDelivered = (group: GuestOrderStatusItem) => /^delivered$/i.test(group.status || "");

const buildStatuses = (order: GuestOrderStatus): StatusTrackerItem[] => {
  const result: StatusTrackerItem[] = [];

  if (order.submittedDate) {
    const submittedDate = dates.format(order.submittedDate, dates.formats.international);

    result.push({
      key: OrderStatus.SUBMITTED,
      value: <OrderStatusTrackerItem status={OrderStatus.SUBMITTED} date={submittedDate} />,
      state: StatusTrackerState.Active,
      barState: StatusTrackerState.Active
    });
  }

  if (order.rejectedDate || order.status?.toUpperCase() === "REJECTED") {
    const date = order.rejectedDate
      ? dates.format(order.rejectedDate, dates.formats.international)
      : undefined;

    result.push({
      key: OrderStatus.REJECTED,
      value: <OrderStatusTrackerItem status={OrderStatus.REJECTED} date={date} />,
      ...getStep2State(OrderStatus.CANCELLED),
    });

    return result;
  } else if (order.cancellationDate || order.status?.toUpperCase() === "CANCELLED") {
    const date = order.cancellationDate
      ? dates.format(order.cancellationDate, dates.formats.international)
      : undefined;

    result.push({
      key: OrderStatus.CANCELLED,
      value: <OrderStatusTrackerItem status={OrderStatus.CANCELLED} date={date} />,
      ...getStep2State(OrderStatus.CANCELLED),
    });

    return result;
  }

  // Step 3: ITSM order statuses
  const delivered = order.status === OrderStatus.DELIVERED;
  const shipped = delivered || order.status === OrderStatus.SHIPPED;
  const accepted = shipped || order.status === OrderStatus.ACCEPTED;

  const itemsCount = order.items.length;
  const shippedCount = order.items.filter((it) => isShipped(it)).length;
  const deliveredCount = order.items.filter((it) => isDelivered(it)).length;

  const someShipmentGroupsNotShipped = shipped && shippedCount > 0 && shippedCount < itemsCount;
  const someShipmentGroupsNotDelivered = shipped && deliveredCount > 0 && deliveredCount < itemsCount;

  const acceptedState = accepted ? StatusTrackerState.Active : StatusTrackerState.Normal;
  let shippedState = shipped ? StatusTrackerState.Active : StatusTrackerState.Normal;
  let deliveredState = delivered ? StatusTrackerState.Active : StatusTrackerState.Normal;

  if (someShipmentGroupsNotShipped) {
    shippedState = StatusTrackerState.Pending;
  }

  if (someShipmentGroupsNotDelivered) {
    deliveredState = StatusTrackerState.Pending;
  }

  result.push({
    key: OrderStatus.ACCEPTED,
    value: <OrderStatusTrackerItem status={OrderStatus.ACCEPTED} date={getAcceptedDate(order)} />,
    state: acceptedState,
  });

  result.push({
    key: OrderStatus.SHIPPED,
    value: <OrderStatusTrackerItem status={OrderStatus.SHIPPED} date={getShippedDate(order)} />,
    state: shippedState,
  });

  result.push({
    key: OrderStatus.DELIVERED,
    value: <OrderStatusTrackerItem status={OrderStatus.DELIVERED} date={getDeliveredDate(order)} />,
    state: deliveredState,
  });

  return result;
};

const OrderStatusTrackerGuestView: React.FC<Props> = ({ order }) => {
  const isMobile = useIsDevice(deviceType.MOBILE, deviceType.MOBILELARGE, deviceType.TABLET);
  const isLaptop = useIsDevice(deviceType.LAPTOP);
  const isDesktop = useIsDevice(deviceType.DESKTOP);
  const { t } = useTranslation();

  const items = buildStatuses(order);
  const vertical = isMobile;
  const barWidth = isMobile ? "70px" : isLaptop ? "120px" : isDesktop ? "170px" : "130px";

  const shippingSummary = useMemo(
    () => {
      if (!order.items || !order.items.length) {
        return { total: 0, shipped: 0, delivered: 0, shippedPercent: 0, deliveredPercent: 0 };
      }

      let total = 0;
      const shipmentGroups = order.items.filter((sg) => !/cancell?ed|rejected/i.test(sg.status));

      for (const sg of shipmentGroups) {
        if (!/cancell?ed|rejected/i.test(sg.status)) {
          total += getShipmentGroupQuantity(sg);
        }
      }

      if (!total) {
        return { total: 0, shipped: 0, delivered: 0, shippedPercent: 0, deliveredPercent: 0 };
      }

      const groups = shipmentGroups
        .filter((it) => it.status.toUpperCase() === OrderStatus.SHIPPED || it.status.toUpperCase() === OrderStatus.DELIVERED)
        .map((it) => ({
          status: it.status,
          quantity: getShipmentGroupQuantity(it),
        }));

      let shipped = 0;
      let delivered = 0;

      for (const group of groups) {
        if (group.status.toUpperCase() === OrderStatus.DELIVERED) {
          delivered += group.quantity;
        }

        shipped += group.quantity;
      }

      const shippedPercent = shipped === total ? 100 : Math.floor(100 * (shipped / total));
      const deliveredPercent = delivered === total ? 100 : Math.floor(100 * (delivered / total));

      return { total, shipped, delivered, shippedPercent, deliveredPercent };
    },
    [order],
  );

  return (
    <>
      <OrderStatusPanel>
        <StatusTrackerAdvanced items={items} vertical={vertical} barWidth={barWidth} />
      </OrderStatusPanel>
      <StyleSummaryBlock>
        {!!shippingSummary.total && (
          <ShippingSummaryBlock>
            <span>
              {t("orderdetails.shipping.compactNumberShipped", { number: shippingSummary.shipped, total: shippingSummary.total, postProcess: "upper" })}
              <Icon name="shipped" size={32} />
            </span>
            <ProgressBar value={shippingSummary.shippedPercent} color="blue" />
            <span>
              {t("orderdetails.shipping.compactNumberDelivered", { number: shippingSummary.delivered, total: shippingSummary.total, postProcess: "upper" })}
              <Icon name="delivered" size={32} />
            </span>
            <ProgressBar value={shippingSummary.deliveredPercent} color="blue" />
          </ShippingSummaryBlock>
        )}
      </StyleSummaryBlock>
    </>
  );
};

export default OrderStatusTrackerGuestView;
